export const contain = ['model_tags.Tags'];

export const types = {
  '1': 'email',
  '2': 'sms',
  '3': 'rvm',
  '4': 'call',
  '5': 'post',
};

export const options = [
  {
    name: 'Email',
    value: 'email',
    id: 1,
  },
  {
    name: 'SMS',
    value: 'sms',
    id: 2,
  },
  {
    name: 'RVM',
    value: 'rvm',
    id: 3,
  },
  {
    name: 'Call',
    value: 'call',
    id: 4,
  },
  {
    name: 'POST',
    value: 'post',
    id: 5,
  },
];

export const filters = (vertical_id) => ([
  JSON.stringify({
    field: 'vertical_id',
    operator: '=',
    value: vertical_id,
  }),
]);
