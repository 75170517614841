import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import SingleReportingStep from './singleReportingStep';
import SingleReportTransition from './singleReportingTransition';
import DeletedSteps from './deletedSteps';
import { useSelector } from 'react-redux';
import { lowestToHighest, convertReportingSteps } from '../config';
import { formatToLocalDate } from '../../../../config/helpers';

const SingleFlowReporting = ({ rowIsOpen, stepsData }) => {
  const classes = useStyles();
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const [showDeletedSteps, setShowDeletedSteps] = useState(false);
  const [allSteps, setAllSteps] = useState(null);
  const [hasDeleted, setHasDeleted] = useState(false);

  useEffect(() => {
    if (stepsData && stepsData.length > 0) {
      let orderedSteps = lowestToHighest(stepsData);
      let convertedData = convertReportingSteps(orderedSteps);
      setAllSteps(convertedData);
    } else {
      setAllSteps(null);
    }
  }, [stepsData]);

  useEffect(() => {
    if (allSteps && allSteps.length > 0) {
      let step = allSteps.find(item => item.archived);
      if (step) {
        setHasDeleted(true);
      } else {
        setHasDeleted(false);
      }
    } else {
      setHasDeleted(false);
    }
  }, [allSteps]);

  useEffect(() => {
    if (!rowIsOpen) {
      setHasDeleted(false);
      setShowDeletedSteps(false);
    }
  }, [rowIsOpen]);

  const handleDeletedSteps = () => {
    setShowDeletedSteps(!showDeletedSteps);
  };

  const renderStepTransitions = (transitions, is_active_step) => {
    if (transitions && transitions.length > 0) {
      return transitions.map(transition => {
        let {
          To_Flow_Name,
          flow_step_transition_map_id,
          to_flow_id,
        } = transition;
        let key = `${To_Flow_Name}${flow_step_transition_map_id}${to_flow_id}`;
        return (
          <SingleReportTransition
            key={key}
            transition={transition}
            isDeleted={false}
            isActiveStep={is_active_step}
            vertical_id={vertical_id}
          />
        );
      });
    }
  };

  const handleRenderSteps = () => {
    if (allSteps && allSteps.length > 0) {
      return allSteps.map(step => {
        let {
          flow_step_map_id,
          current_version,
          archived,
          flow_step_position,
          flow_step_transitions,
          drip_type,
          created_date,
        } = step;
        if (!archived) {
          let hasTransitions =
            step && flow_step_transitions && flow_step_transitions.length > 0
              ? true
              : false;

          let key = `${flow_step_map_id}_${current_version}_${archived}_${flow_step_position}_}`;
          let date = '';
          if (current_version && created_date) {
            date = formatToLocalDate(created_date, 'dd.MM.yyyy');
          }
          return (
            <Fragment key={key}>
              <SingleReportingStep
                type={drip_type}
                data={step}
                isActive={current_version}
                order={flow_step_position}
                hasTransitions={hasTransitions}
                date={date}
              />
              {renderStepTransitions(
                step.flow_step_transitions,
                current_version,
              )}
            </Fragment>
          );
        } else {
          return '';
        }
      });
    }
  };

  return (
    <div className={classes.singleFlowReportingContainer}>
      {allSteps && allSteps.length > 0 ? (
        handleRenderSteps()
      ) : (
        <div className={classes.noStepsFound}>No items</div>
      )}
      {hasDeleted && (
        <DeletedSteps
          classes={classes}
          showDeletedSteps={showDeletedSteps}
          allSteps={allSteps}
          handleDeletedSteps={handleDeletedSteps}
          vertical_id={vertical_id}
        />
      )}
    </div>
  );
};

export default SingleFlowReporting;

SingleFlowReporting.propTypes = {
  rowIsOpen: PropTypes.bool.isRequired,
};
