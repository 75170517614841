import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const Subject = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
  disabled,
  type,
}) => {
  const validate = values => {
    if (!values) {
      return 'Required';
    }
    if (type === 'drip-folders' && values.substring(0, 4) === 'ROOT') {
      return `The name field can't contain the word ROOT in the name.`;
    }
  };

  return (
    <div className={wrapperClass}>
      {hideLabel ? null : (
        <label htmlFor="subject">{labelText || 'Subject'}</label>
      )}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'subject'] && touched[name || 'subject']
            ? errorClass || 'has-error'
            : ''
        }`}
        name={name || 'subject'}
        placeholder={placeholder || 'Subject'}
        type="text"
        disabled={disabled}
        validate={validate}
        onChange={e => {
          handleChange(e);
        }}
      />
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'subject'}
          component="div"
          className="field-error"
          data-testid="name-error"
        />
      )}
    </div>
  );
};

export default Subject;

Subject.propTypes = {
  hideLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  wrapperClass: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};
