import React, { useState, lazy, Suspense, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import ReportingIcon from '../../../../assets/images/Reports_blue_icon.svg';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../components/loader';
import CustomTable from './customTable';
import { useHistory } from 'react-router-dom';
import {
  getList,
  cancelList,
  saveFilters,
  saveContain,
} from '../../../../store/actions/actions/rest';
import {
  getListReporting,
  cancelListReporting,
  clearListReporting,
} from '../../../../store/actions/actions/reporting';
import { getFilterDates } from '../../singleReportingPage/config';
import { getFilters } from '../../../../hoc/withTable/getFilters';
import {
  contain,
  getFlowIds,
  convertReportingData,
  mobileColumns as columns,
  filters,
  renderElements,
  sortBy,
} from '../config';
// import CustomExportButton from '../../../components/customButtons/customExport';
// import Search from '../../../components/search';

const ReportingTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.app.loader);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const account_id = useSelector(state => state.auth.account_id);
  const auth = useSelector(state => state.auth);
  const flowList = useSelector(state => state.flows.list);
  const flowTotals = useSelector(state => state.flowTotals.list);
  const classes = useStyles();
  const [search] = useState('');
  const [filterByDate, setFilterByDate] = useState('today');
  const [filterDates, setFilterDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [convertedData, setConvertedData] = useState(null);
  const ExportModal = lazy(() =>
    import('../../../../components/exportModal/exportModal'),
  );

  useEffect(() => {
    if (auth && vertical_id && account_id) {
      let allFilters = getFilters(filters, auth);
      dispatch(saveFilters('flows', allFilters));
      dispatch(saveContain('flows', contain));
      dispatch(cancelList('flows', null, dispatch));
      dispatch(cancelList('flow-step-maps', null, dispatch));
      dispatch(
        getList(
          'flows',
          { contain: contain, filters: [...allFilters] },
          dispatch,
        ),
      );
      dispatch(
        getList(
          'flow-step-maps',
          { filters: [...allFilters], showArchived: 'any' },
          dispatch,
        ),
      );
      return () => {
        dispatch(cancelList('flows', null, dispatch));
        dispatch(cancelList('flow-step-maps', null, dispatch));
      };
    }
  }, [dispatch, account_id, vertical_id, auth]);

  useEffect(() => {
    if (flowTotals && flowTotals.length > 0) {
      let convertedReportingData = convertReportingData(flowTotals);
      if (convertedReportingData && convertedReportingData.length > 0) {
        setConvertedData(convertedReportingData);
      } else {
        setConvertedData(null);
      }
    } else {
      setConvertedData(null);
    }
  }, [flowTotals]);

  const getReportingFilters = () => {
    let filterData = getFilterDates(filterByDate, filterDates, false);
    let flowIds = getFlowIds(flowList);
    return [...filterData, flowIds];
  };

  const getFlowTotalsData = () => {
    let allFilters = getReportingFilters();
    dispatch(cancelListReporting('flow-totals', null, dispatch));
    dispatch(clearListReporting('flow-totals', null));
    dispatch(
      getListReporting('flow-totals', { filters: allFilters }, dispatch),
    );
  };

  useEffect(() => {
    if (flowList && flowList.length > 0) {
      if (filterByDate !== 'custom-filter') {
        getFlowTotalsData();
      }
    }
    return () => {
      dispatch(cancelListReporting('flow-totals', null, dispatch));
    };
    // eslint-disable-next-line
  }, [flowList, filterByDate, filterDates]);

  const handleApplyFilter = () => {
    getFlowTotalsData();
  };

  const handleClickName = item => () => {
    history.push({
      pathname: `/flows/flow/edit/${item}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleChangeFilter = event => {
    setFilterByDate(event.target.value);
  };

  const handleChangeDate = (event, name) => {
    setFilterDates({
      ...filterDates,
      [name]: event,
    });
  };

  // eslint-disable-next-line
  const handleExportModalOpen = () => {
    setIsExportModalOpen(true);
  };

  const handleExportModalClose = () => {
    setIsExportModalOpen(false);
  };

  const renderExportModal = isExportModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={isExportModalOpen} />
        </Grid>
      }
    >
      <ExportModal
        open={isExportModalOpen}
        handleClose={handleExportModalClose}
      />
    </Suspense>
  ) : null;

  return (
    <Grid container className={classes.container}>
      <img
        src={ReportingIcon}
        alt="Reporting icon"
        className={classes.titleIcon}
      />
      <Grid
        container
        item
        xs={6}
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Reports
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            component="h5"
            gutterBottom
            className={classes.subtitle}
          >
            Flows
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={6}>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justify="flex-end"
          alignItems="flex-end"
          className={classes.searchGridContainer}
        >
          <Grid item className={classes.topActionContainer}>
            {/* <CustomExportButton
              customClass={classes.exportButton}
              onClick={handleExportModalOpen}
            /> */}
          </Grid>
          <Grid item className={classes.searchContainer}>
            {/* <Search type="Reports" setSearch={setSearch} classes={classes} /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.contentContainer}>
        {flowTotals && (
          <CustomTable
            list={convertedData}
            renderElements={renderElements}
            columns={columns}
            handleClickName={handleClickName}
            search={search}
            itemSortBy={sortBy}
            handleChangeFilter={handleChangeFilter}
            filterByDate={filterByDate}
            handleChangeDate={handleChangeDate}
            filterDates={filterDates}
            handleApplyFilter={handleApplyFilter}
          />
        )}
      </Grid>
      {renderExportModal}
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default ReportingTable;
