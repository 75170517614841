import React from 'react';
import withDashboard from '../../hoc/withDashboard/withDashboard';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RecentNotesTable from '../../components/recentNotesTable';
import withTitle from '../../hoc/withTitle';
import {
  params,
  filters,
  contain,
  renderElements,
  columns,
  getItemLink,
} from './config';
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickAllNotes = () => {
    history.push('/notes');
  };

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={12} lg={6}>
        <Typography variant="h1" component="h2" className={classes.title}>
          Welcome !
        </Typography>
        <Typography variant="subtitle1" component="h2" paragraph className={classes.subtitle}>
          View your widgets and your organization’s latest activity and alerts.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RecentNotesTable
          classes={classes}
          params={params}
          filters={filters}
          contain={contain}
          renderElements={renderElements}
          columns={columns}
          getItemLink={getItemLink}
          handleClickAllNotes={handleClickAllNotes}
        />
      </Grid>
    </Grid>
  );
};

export default withTitle(withDashboard(Dashboard, true), '');
