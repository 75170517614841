import { makeStyles } from '@material-ui/core/styles';
import SelectArrow from '../../../../assets/images/select-arrow-down.png';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '90%',
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
    '& > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '95% 18px',
      paddingRight: 24,
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
      position: 'absolute',
      zIndex: 1,
    },
  },
  transitionTextField: {
    '& > select': {
      height: 36,
      backgroundPosition: '95% 13px',
    },
  },
  addFlowStepBtn: {
    backgroundColor: theme.palette.lightBlue[300],
    color: theme.palette.white[100],
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 14,
    width: 108,
    height: 35,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
    '&:disabled': {
      color: '#FFFFFF',
      boxShadow: 'none',
      backgroundColor: '#D8D8D8',
    },
  },

  flowStatusLabel: {
    position: 'absolute',
    right: -47,
    bottom: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textTransform: 'uppercase',
    '& > div:nth-child(1)': {
      fontSize: 10,
      marginBottom: 5,
    },
    '& > div:nth-child(2)': {
      fontSize: 8,
    },
  },
  flowStatusLabelActive: {
    color: theme.palette.green[300],
  },
  flowTitle: {
    color: '#4F4F4F',
    display: 'block',
    marginBottom: 0,
    fontFamily: 'Lato',
    fontSize: 24,
  },
  flowContainer: {
    minWidth: 600,
    maxWidth: 600,
    width: '90%',
    justifyContent: 'space-between',
    paddingBottom: 12,
    marginTop: 30,
    position: 'relative',
  },
  flowContainerBorder: {
    borderBottom: `1px solid #828282`,
  },
  flowStep: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  flowStepButtonAdd: {
    position: 'relative',
    bottom: 6,
  },
  flowStepButton: {
    marginTop: 17,
    marginRight: 8,
    width: 35,
    minWidth: 35,
    padding: 0,
    height: 35,

    background: '#D8D8D8',
    '& > span > svg': {
      fontSize: 24,
      color: '#FFF',
    },
  },
  transitionButton: {
    marginTop: 17,
    background: 'transparent',
  },
  transitionButtonMarginTop: {
    marginTop: 5,
  },
  addTransition: {
    marginTop: 17,
    background: '#D8D8D8',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.lightBlue[300],
    color: theme.palette.white[100],
    height: 35,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
    '& > span > svg': {
      fontSize: 24,
      color: '#FFF',
    },
    '&:disabled': {
      color: '#FFFFFF',
      boxShadow: 'none',
      backgroundColor: '#D8D8D8',
    },
  },
  singleFlowStep: {
    paddingLeft: 12,
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    paddingTop: 15,
    paddingBottom: 25,
    position: 'relative',
    marginBottom: 15,
  },
  singleFlowStepTransition: {
    paddingTop: 15,
    paddingLeft: 70,
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    background: '#F2F2F2',
    position: 'relative',
    paddingBottom: 25,
    marginTop: -15,
  },
  singleFlowStepTransitionPaddingTop: {
    paddingTop: 5,
  },
  order: {
    marginRight: 10,
    '& > .value': {
      border: '1px solid #828282',
      borderRadius: 2,
      display: 'flex',
      flex: '1',
      justifyContent: 'center',
      alignItems: 'center',
      height: 45,
      width: 45,
      marginTop: 10,
    },
  },
  orderTransition: {
    '& > .value': {
      height: 35,
      width: 35,
    },
  },
  stepName: {
    marginRight: -10,
    width: 237,
    minWidth: 237,
    '& > .label': {
      fontFamily: 'Lato',
      textTransform: 'uppercase',
      fontSize: 10,
    },
  },
  transitionName: {
    marginRight: -5,
    width: 236,
    minWidth: 236,
    '& > .label': {
      fontFamily: 'Lato',
      textTransform: 'uppercase',
      fontSize: 10,
      color: '#4F4F4F',
    },
  },
  delay: {
    position: 'absolute',
    fontSize: 10,
    bottom: 8,
    left: 67,
  },
  toFlow: {
    position: 'absolute',
    fontSize: 10,
    bottom: 10,
    left: 71,
    zIndex: 1,
  },
  label: {
    fontFamily: 'Lato',
    textTransform: 'uppercase',
    fontSize: 10,
    color: '#4F4F4F',
  },
  stepBorder: {
    border: '1px solid #D8D8D8',
    borderRadius: 2,
    width: 600,
    minWidth: 600,
    marginBottom: 15,
  },
  add: {
    backgroundColor: '#61A1F3',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
  },
  delete: {
    backgroundColor: '#D6595C',
    '&:hover': {
      backgroundColor: '#ad484a',
    },
  },
  archive: {
    backgroundColor: '#2F972A',
    '&:hover': {
      backgroundColor: '#277f22',
    },
  },
  addColor: {
    '& > span > svg': {
      color: '#61A1F3',
    },
  },
  deleteColor: {
    '& > span > svg': {
      color: '#D6595C',
    },
  },
  archiveColor: {
    '& > span > svg': {
      color: '#2F972A',
    },
  },
  disabledColor: {
    '& > span > svg': {
      color: '#D8D8D8',
    },
  },
  stepReportBox: {
    position: 'absolute',
    top: -1,
    right: -62,
    height: 111,
    width: 61,
    border: '1px solid #D8D8D8',
    borderLeft: 'none',
    borderBottom: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepReportTitle: {
    position: 'absolute',
    color: '#4F4F4F',
    fontSize: 10,
    textTransform: 'uppercase',
    top: 13,
  },
  stepReportBoxLong: {
    height: 126,
    borderBottom: '1px solid #D8D8D8',
  },
  reportStepNumber: {
    width: 35,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 900,
    color: theme.palette.lightBlue[300],
    border: `2px solid ${theme.palette.lightBlue[300]}`,
    cursor: 'pointer',
    position: 'relative',
    top: 3,
    '&:hover': {
      color: theme.palette.white[100],
      backgroundColor: theme.palette.lightBlue[300],
      border: `2px solid transparent`,
    },
  },
  reportStepNumberLong: {
    top: -5,
  },
  activeReportStepNumber: {
    color: theme.palette.white[100],
    backgroundColor: theme.palette.lightBlue[300],
    border: `2px solid transparent`,
  },
  transitionReportBox: {
    width: 61,
    position: 'absolute',
    right: -62,
    top: 0,
    background: '#F2F2F2',
    height: '100%',
    borderBottom: '1px solid #D8D8D8',
    borderRight: '1px solid #D8D8D8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transitionReportBoxFirst: {
    top: 1,
  },
  reportTransitionNumber: {
    width: 35,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 900,
    color: theme.palette.lightBlue[300],
    position: 'relative',
    top: 4,
  },
  reportTransitionNumberFirst: {
    top: 4,
  },
  stepsWrapper: {
    height: '100%',
  },

  stepsSection: {
    width: '100%',
    minWidth: 1200,
    maxWidth: 1900,
  },
  inlineReportingContainer: {
    paddingLeft: 50,
  },
  inlineReporting: {
    width: 250,
    marginLeft: 70,
    padding: '7px 7px 0 7px',
    fontFamily: 'Lato',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  reportTitle: {
    color: '#828282',
    fontSize: 14,
    marginBottom: 20,
    display: 'block',
    width: '100%',
    fontWeight: 900,
  },
  reportTitleLink: {
    color: theme.palette.lightBlue[300],
    fontSize: 14,
    display: 'inline-block',
    width: '100%',
    marginBottom: 15,
    cursor: 'pointer',
    transition: 'all .4s',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  reportInformation: {
    color: '#828282',
    fontSize: 14,
    marginBottom: 30,
    '& > span': {
      marginBottom: 10,
      display: 'block',
      width: '100%',
    },
  },
  singleReport: {
    background: '#F3F3F3',
    borderRadius: 2,
    padding: '20px 0 20px 17px',
    marginBottom: 20,
    '& > h4': {
      margin: 0,
      marginBottom: 20,
      fontSize: 16,
      fontWeight: 900,
      fontFamily: 'Lato',
      textTransform: 'uppercase',
    },
  },
  reportItems: {
    color: '#828282',
    fontSize: 14,
    fontWeight: 900,
    marginBottom: 10,
    display: 'block',
    width: '100%',
  },
  reportItemsSuccess: {
    color: '#62B75E',
    fontSize: 14,
    fontWeight: 900,
    marginBottom: 10,
    display: 'block',
    width: '100%',
  },
}));

export default useStyles;
