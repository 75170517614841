import React, { useState } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../hoc/withDashboard/withDashboard';
import withTitle from '../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Table from './table';
import AlertsModal from './alertsModal';

const Alerts = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSearchInput = event => {
    setSearchValue(event.target.value);
  };

  const handleCloseModal = () => {
    // setClickedElement(null);
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h2" className={classes.title}>
          Alerts
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid
          item
          xs={6}
          container
          alignContent="flex-end"
          justify="flex-start"
        >
          <Typography
            variant="subtitle1"
            component="h2"
            className={classes.subtitle}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod.
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item className={classes.addButton}>
            <Button onClick={handleOpenModal} startIcon={<AddIcon />}>
              Add Alerts
            </Button>
          </Grid>
          <Grid item>
            <TextField
              value={searchValue}
              onChange={handleSearchInput}
              className={classes.searchField}
              variant="outlined"
              label="Search Alerts"
              placeholder="Search Alerts"
              size="small"
              InputLabelProps={{
                classes: {
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table />
      </Grid>
      <AlertsModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        classes={classes}
      />
    </Grid>
  );
};

export default withTitle(withDashboard(Alerts, true), 'Alerts');
