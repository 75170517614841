import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const Description = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
  onChangeDescription,
  type,
}) => {

  const validate = values => {
    if (!values) {
      return 'Required';
    }
  };

  const validateSmsBodyText = values => {
    if (!values) {
      return 'Required';
    } else if (values.length > 160) {
      return `You have exceeded the number of characters (160)`;
    }
  };

  return (
    <div className={wrapperClass}>
      {hideLabel ? null : (
        <label htmlFor="description">{labelText || 'Description'}</label>
      )}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'description'] && touched[name || 'name']
            ? errorClass || 'has-error'
            : ''
        }`}
        name={name || 'description'}
        as="textarea"
        placeholder={placeholder || 'Description'}
        type="description"
        validate={type === 'smsBodyText' ? validateSmsBodyText : validate}
        onChange={e => {
          onChangeDescription && onChangeDescription(e);
          handleChange(e);
        }}
      />
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'description'}
          component="div"
          className="field-error"
          data-testid="parameters-error"
        />
      )}
    </div>
  );
};

export default Description;

Description.propTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  wrapperClass: PropTypes.string,
  hideLabel: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  type: PropTypes.any,
  onChangeDescription: PropTypes.func,
  handleChange: PropTypes.func,
};
