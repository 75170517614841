import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/loader';
import Search from '../../../components/search';
import CallComponentIcon from '../../../assets/images/email-from.svg';
import Modal from '../../../components/modal/modal';
import PropTypes from 'prop-types';
import { clearNameUnique } from '../../../store/actions/actions/rest';

const DripComponent = ({
  title,
  item,
  urlPath,
  model_name,
  populateValues,
  initialValues,
  fields,
  Table,
  customHandleNewButton,
  customHandleClickItem,
  modal,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.app.loader);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [parameters, setParameters] = useState(null);
  const [clickedElement, setClickedElement] = useState(null);
  const [encodingIsVisible, setEncodingIsVisible] = useState(false);

  useEffect(() => {
    if (
      urlPath === 'email-body-components' ||
      urlPath === 'rvm-components' ||
      urlPath === 'sms-body-components'
    ) {
      dispatch(clearNameUnique(urlPath));
    }
  }, [urlPath, dispatch]);

  const handleCloseModal = () => {
    setClickedElement(null);
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleClickItem = item => () => {
    setClickedElement(item);
    handleOpenModal();
  };

  const handlePostTypeField = event => {
    if (event.target.value === 'post') {
      setEncodingIsVisible(true);
    } else {
      setEncodingIsVisible(false);
    }
  };

  useEffect(() => {
    if (urlPath === 'generic-post-components' && clickedElement) {
      if (clickedElement.post_type === 'post') {
        setEncodingIsVisible(true);
      } else {
        setEncodingIsVisible(false);
      }
    }
  }, [clickedElement, urlPath]);

  const renderHandleClickItem = customHandleClickItem
    ? customHandleClickItem
    : handleClickItem;

  const renderHandleNewButton = customHandleNewButton
    ? customHandleNewButton
    : handleOpenModal;

  const renderAddButtonStyles =
    title === 'Email Subject Text'
      ? classes.addButtonSubjectText
      : classes.addButton;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} container alignItems="flex-end">
        <Grid item xs={1} className={classes.iconContainer}>
          <img
            src={CallComponentIcon}
            alt={`${title} icon`}
            className={classes.titleIcon}
          />
        </Grid>
        <Grid container item xs={10} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.subtitle}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={6}>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item className={renderAddButtonStyles}>
            <Button onClick={renderHandleNewButton} startIcon={<AddIcon />}>
              {`New ${item}`}
            </Button>
          </Grid>
          <Grid item className={classes.searchContainer}>
            <Search type={item} setSearch={setSearch} classes={classes} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <Grid item xs={12}>
          <Table
            type={urlPath}
            search={search}
            classes={classes}
            setParameters={setParameters}
            handleClickName={renderHandleClickItem}
          />
        </Grid>
        {Boolean(loader) && <Loader open={Boolean(loader)} />}
      </Grid>
      {modal && (
        <Modal
          open={isModalOpen}
          fields={fields}
          title={item}
          handleClose={handleCloseModal}
          element={clickedElement || initialValues}
          parameters={parameters}
          path={urlPath}
          populateValues={populateValues}
          model_name={model_name}
          encodingIsVisible={title === 'HTTP Request' && encodingIsVisible}
          handlePostTypeField={title === 'HTTP Request' && handlePostTypeField}
          dripComponent={urlPath}
          clearUniqueObject
        />
      )}
    </Grid>
  );
};

export default withTitle(withDashboard(DripComponent, false), 'Drip Component');

DripComponent.propTypes = {
  title: PropTypes.string.isRequired,
  urlPath: PropTypes.string.isRequired,
  model_name: PropTypes.string.isRequired,
  populateValues: PropTypes.func.isRequired,
  initialValues: PropTypes.any.isRequired,
  fields: PropTypes.array.isRequired,
  Table: PropTypes.elementType.isRequired,
  customHandleNewButton: PropTypes.func,
  customHandleClickItem: PropTypes.func,
  modal: PropTypes.bool,
};
