import { makeStyles } from '@material-ui/core/styles';
import SelectArrow from '../../assets/images/select-arrow-down.png';

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    minWidth: 430,
    maxWidth: 750,
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: `1px solid #828282`,
    paddingBottom: 4,
    marginTop: 30,
  },
  addFilterBtn: {
    backgroundColor: theme.palette.lightBlue[300],
    color: theme.palette.white[100],
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 14,
    width: 108,
    height: 35,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
  },
  filtersTitle: {
    fontSize: 16,
  },
  filterRule: {
    paddingBottom: 20,
  },
  filterSelectContainer: {
    minWidth: 430,
    maxWidth: 750,
    width: '100%',
    margin: '8px 0',
  },
  filtersColumnTitle: {
    fontFamily: 'Lato',
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text[100],
    width: '33.3%',
  },
  filterSelects: {
    width: '100%',
    display: 'flex',
    flex: '1',
  },
  filterField: {
    width: '33.3%',
    paddingRight: 10,
    '& > div > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '97% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > div > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
    '& > div > input': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      backgroundColor: theme.palette.white[100],
    },
    '& > div > input.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    "&:nth-child(3)":{
      paddingRight: 0,
    }
  },
  filterActionButton: {
    height: 35,
    width: 35,
    borderRadius: 2,
    color: theme.palette.white[100],
    position: 'relative',
    backgroundColor: '#D8D8D8',
    top: 5,
    cursor: 'pointer',
    transition: 'all .4s',
    padding: 2,
    '&:hover': {
      backgroundColor: '#b7b5b5',
    },
  },
  deleteFilterButton: {
    backgroundColor: '#D6595C',
      '&:hover': {
        backgroundColor: '#b54a4c',
      },
  },
  deleteFilterButtonContainer: {
    position: 'relative',
    textAlign: 'right'
  }
}));

export default useStyles;
