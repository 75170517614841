export const createOperation = name => {
  const START = `${name}/START`;
  const SUCCESS = `${name}/SUCCESS`;
  const FAILURE = `${name}/FAILURE`;

  const start = (route, payload) => ({ type: START, route, payload });
  const success = (route, payload) => ({ type: SUCCESS, route, payload });
  const failure = (route, payload) => ({ type: FAILURE, route, payload });

  const actionCreators = { start, success, failure };
  const actionTypes = { START, SUCCESS, FAILURE };

  return {
    actionCreators,
    actionTypes,
  };
};
