import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import Logo from '../../../assets/images/login-logo.png';
import Button from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import Table from './table';
import withTitle from '../../../hoc/withTitle';
import {
  getUserAccounts,
  setActiveAccount,
} from '../../../store/actions/actions/auth';

const SwitchAccount = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const account_id = useSelector(state => state.auth.account_id);
  const list = useSelector(state => state.auth && state.auth.accounts);
  const permissions = useSelector(
    state => state.auth && state.auth.permissions,
  );
  const [selected, setSelected] = useState(false);
  const [accountsList, setAccountsList] = useState(null);

  useEffect(() => {
    dispatch(getUserAccounts());
  }, [dispatch]);

  useEffect(() => {
    if (list && list.length > 0 && permissions) {
      if (permissions['0'] !== undefined) {
        let accountManager = {
          id: '0',
          name: 'Account Manager',
        };
        setAccountsList([accountManager, ...list]);
      } else {
        setAccountsList(list);
      }
    }
  }, [list, permissions]);

  useEffect(() => {
    setSelected(account_id);
  }, [account_id, list]);

  const handleBack = () => {
    history.goBack();
  };

  const handleContinue = () => {
    if (selected === account_id) {
      handleBack();
    } else {
      dispatch(setActiveAccount(selected));
      history.push({
        pathname: '/',
      });
    }
  };

  return (
    <div className={classes.backgroundLayer}>
      <div className={classes.switchAccountContainer}>
        <div>
          <img src={Logo} alt="logo" />
        </div>
        <div className={classes.accountsList}>
          <Table list={accountsList} selected={selected} setSelected={setSelected} />
        </div>
        <div className={classes.buttons}>
          <Button onClick={handleBack} className={classes.backButton}>
            Back
          </Button>
          <Button onClick={handleContinue} className={classes.continueButton}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTitle(SwitchAccount, 'Switch Accounts');
