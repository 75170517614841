import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../../../assets/images/login-logo.png';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import Input from '@material-ui/core/Input';
import { I18n } from '@aws-amplify/core';

export class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ['forgotPassword'];
    this.state = { delivery: null };
  }

  sendView = classes => (
    <div>
      <label htmlFor="username" className={classes.inputLabel}>
        Username *
      </label>
      <Input
        id="username"
        key="username"
        name="username"
        onChange={this.handleInputChange}
        className={classes.inputField}
        type="text"
        placeholder="Username"
        disableUnderline={true}
      />
    </div>
  );

  submitView = classes => (
    <div>
      <label htmlFor="code" className={classes.inputLabel}>
        Code *
      </label>
      <Input
        type="text"
        placeholder="Code"
        key="code"
        name="code"
        className={classes.inputField}
        autoComplete="off"
        onChange={this.handleInputChange}
        disableUnderline={true}
      />
      <label htmlFor="password" className={classes.inputLabel}>
        New Password *
      </label>
      <Input
        placeholder="New Password"
        type="password"
        key="password"
        name="password"
        className={classes.inputField}
        autoComplete="off"
        onChange={this.handleInputChange}
        disableUnderline={true}
      />
    </div>
  );

  showComponent = () => {
    const { authData = {}, classes } = this.props;

    return (
      <div className={classes.backgroundLayer}>
        <div className={classes.loginContainer}>
          <div>
            <img src={Logo} alt="logo" />
            <div className={classes.forgotPassowrdTitle}>
              Reset your password
            </div>
            <div className={classes.inputField}></div>
            {this.state.delivery || authData.username
              ? this.submitView(classes)
              : this.sendView(classes)}
            <div>
              {this.state.delivery || authData.username ? (
                <div
                  className={classes.forgotPasswordLink}
                  onClick={() => super.send()}
                >
                  {I18n.get('Resend Code')}
                </div>
              ) : (
                <div
                  className={classes.forgotPasswordLink}
                  onClick={() => super.changeState('signIn')}
                >
                  {I18n.get('Back to Sign In')}
                </div>
              )}
            </div>
            <div>
              {this.state.delivery || authData.username ? (
                <Button
                  className={classes.forgotPasswordButton}
                  onClick={() => {
                    this.props.handleMessage(1);
                    return super.submit();
                  }}
                >
                  {I18n.get('Submit')}
                </Button>
              ) : (
                <Button
                  className={classes.forgotPasswordButton}
                  onClick={() => super.send()}
                >
                  {I18n.get('Send Code')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(CustomForgotPassword);
