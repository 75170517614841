import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import { Authenticator } from 'aws-amplify-react';
import CustomSignIn from './pages/auth/login/customSignIn';
import CustomForgotPassword from './pages/auth/forgotPassword/customForgotPassword';
import CustomRequireNewPassword from './pages/auth/requireNewPassword/customRequireNewPassword';
import CustomVerifyContact from './pages/auth/verifyContact/customVerifyContact';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
  },
});

const mapErrorMsg = message => {
  if (/incorrect.*username.*password/i.test(message)) {
    return 'Incorrect username or password';
  }

  return message;
};

class AppWithAuth extends Component {
  constructor(props) {
    super(props);
    this.state = { message: 0 };
  }

  handleMessage = show => this.setState({ message: show });
  handleLoader = show => this.setState({ loader: show });

  render() {
    return (
      <Authenticator hideDefault={true} errorMessage={mapErrorMsg}>
        <CustomSignIn
          handleMessage={this.handleMessage}
          message={this.state.message}
          loader={this.state.loader}
        />
        <CustomForgotPassword handleMessage={this.handleMessage} />
        <CustomRequireNewPassword />
        <CustomVerifyContact />
        <App />
      </Authenticator>
    );
  }
}

ReactDOM.render(<AppWithAuth />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
