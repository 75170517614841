import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';

const Hours = () => {
  const classes = useStyles();
  return (
    <div className={classes.hoursRow}>
      <span className={classNames(classes.hourCell, classes.dayPeriod)}>
        <span className={classes.period}>AM</span>
        <span>12</span>
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        1
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        2
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        3
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        4
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        5
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        6
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        7
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        8
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        9
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellLeft)}>
        10
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classNames.hourCellLeft)}>
        11
      </span>
      <span className={classes.minuteCell}>30</span>
      <span
        className={classNames(
          classes.hourCell,
          classes.dayPeriod,
          classes.hourCellLeft,
        )}
      >
        <span className={classes.period}>PM</span>
        <span>12</span>
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        1
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        2
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        3
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        4
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        5
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        6
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        7
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        8
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellPadding)}>
        9
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellLeft)}>
        10
      </span>
      <span className={classes.minuteCell}>30</span>
      <span className={classNames(classes.hourCell, classes.hourCellLeft)}>
        11
      </span>
      <span className={classes.minuteCell}>30</span>
    </div>
  );
};

export default Hours;
