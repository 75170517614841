import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/loader';
import Table from './flowTransitionsTable';
import Search from '../../../components/search';
import FlowTransitionIcon from '../../../assets/images/flow-transition.svg';
import { clearNameUnique } from '../../../store/actions/actions/rest';

const FlowTransitions = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.app.loader);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(clearNameUnique('flow-step-transitions'));
  }, [dispatch]);

  const handleClickFlowTransition = item => () => {
    history.push({
      pathname: `/flows/transitions/edit/${item.id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleClickFlowName = item => () => {
    history.push({
      pathname: `/flows/flow/edit/${item.to_flow_id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleNewButton = () => {
    history.push({
      pathname: `/flows/transitions/add`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} container alignItems="flex-end">
        <Grid item xs={1} className={classes.iconContainer}>
          <img
            src={FlowTransitionIcon}
            alt="Flows icon"
            className={classes.titleIcon}
          />
        </Grid>
        <Grid container item xs={10} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Flow Transitions
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={6}>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item className={classes.addButton}>
            <Button onClick={handleNewButton} startIcon={<AddIcon />}>
              New Flow Transitions
            </Button>
          </Grid>
          <Grid item>
            <Search
              type="Flow Transition"
              setSearch={setSearch}
              classes={classes}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <Grid item xs={12}>
          <Table
            type="flow-step-transitions"
            search={search}
            classes={classes}
            handleClickName={handleClickFlowTransition}
            handleClickFlowName={handleClickFlowName}
            model_name="FlowStepTransition"
            size="small"
          />
        </Grid>
        {Boolean(loader) && <Loader open={Boolean(loader)} />}
      </Grid>
    </Grid>
  );
};

export default withTitle(
  withDashboard(FlowTransitions, false),
  'Flow Transitions',
);
