import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const FieldList = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
  disabled,
}) => {

  const validate = values => {
    if (!values) {
      return 'Required';
    }
  };

  return (
    <div className={wrapperClass}>
      {hideLabel ? null : (
        <label htmlFor="post_fields">{labelText || 'Field List'}</label>
      )}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'parameters'] && touched[name || 'name']
            ? errorClass || 'has-error'
            : ''
        }`}
        name={name || 'post_fields'}
        as="textarea"
        placeholder={placeholder || 'Field List (Key Value Pairs Separated by Equals Sign'}
        type="post_fields"
        validate={validate}
        disabled={disabled}
        onChange={e => {
          handleChange(e);
        }}
      />
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'namfieldlist'}
          component="div"
          className="field-error"
          data-testid="post_fields-error"
        />
      )}
    </div>
  );
};

export default FieldList;

FieldList.propTypes = {
  hideLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  wrapperClass: PropTypes.string,
  disabled: PropTypes.bool,
};
