import { GET_LIST, CLEAR_LIST } from '../actionTypes/reporting';
import { displayLoader, hideLoader } from './root';

export const getListReporting = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(displayLoader());
  }
  return GET_LIST(type).actionCreators.start(type, payload);
};

export const saveListReporting = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return GET_LIST(type).actionCreators.success(type, payload);
};

export const cancelListReporting = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return GET_LIST(type).actionCreators.failure(type, payload);
};

export const clearListReporting = (type, payload) => {
  return CLEAR_LIST(type).actionCreators.success(type, payload);
};
