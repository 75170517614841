import React from 'react';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Table from './callCampaignIdTable';
import { initialValues, populateValues } from './config';
import DripComponent from '../dripComponent/dripComponent';

const CallCampaignId = () => {
  return (
    <DripComponent
      title="Call Campaign ID"
      item="Call Component"
      urlPath="call-components"
      model_name="CallComponent"
      fields={['name', 'cpt_channel_id']}
      Table={Table}
      populateValues={populateValues}
      initialValues={initialValues}
      modal
    />
  );
};

export default withTitle(
  withDashboard(CallCampaignId, false),
  'Call Campaign ID',
);
