import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setActiveVertical,
  getAccountVerticals,
  cancelActiveVertical,
} from '../../store/actions/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import classNames from 'classnames';
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
  loaderIcon: {
    width: '20px !important',
    height: '20px !important',
  },
  verticalsInput: {
    width: 215,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12,
    cursor: 'pointer',
    transition: 'all .4s',
    padding: '10px 0px',
    borderBottom: `none`,
    position: 'absolute',
    left: 0,
    bottom: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '& > svg': {
      width: '10%',
    },
    [theme.breakpoints.up(1200)]: {
      borderBottom: `1px solid ${theme.palette.white[100]}`,
      marginLeft: 48,
      position: 'relative',
      top: 5,
      bottom: 'auto',
      padding: '10px 5px',
    },
  },
  verticalInputText: {
    width: '90%',
    fontSize: 18,
    textAlign: 'center',
    display: 'none',
    [theme.breakpoints.up(1200)]: {
      display: 'block',
    },
  },
  verticalInputTextMobile: {
    textAlign: 'left',
    width: '90%',
    fontSize: 14,
    display: 'block',
    paddingLeft: 5,
    [theme.breakpoints.up(1200)]: {
      display: 'none',
    },
  },
  menuItem: {
    background: '#5D83B4',
    color: theme.palette.white[100],
    fontFamily: 'Lato',
    borderBottom: `1px solid rgba(157, 196, 246, 0.5)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
    fontSize: 18,
    height: 51,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  menuItemActive: {
    background: 'rgba(0, 0, 0, 0.04)',
  },
}));

const StyledMenu = withStyles({
  paper: {
    width: 220,
    borderRadius: 5,
    background: '#5D83B4',
    marginTop: 2,
    top: '80px !important',
    '& > ul': {
      padding: '0',
      position: 'relative',
    },
  },
})(props => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    {...props}
  />
));

const VerticalMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const verticalsList = useSelector(state => state.auth.verticals);
  const activeVertical = useSelector(state => state.auth.vertical_id);
  const activeAccount = useSelector(state => state.auth.account_id);
  const loader = useSelector(state => state.auth.loader);
  const [list, setList] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null);

  const handleClick = event => {
    if (!Boolean(loader)) {
      setMenuOpen(event.currentTarget);
    }
  };

  const handleClose = () => {
    setMenuOpen(null);
  };

  useEffect(() => {
    if (activeAccount && !verticalsList) dispatch(getAccountVerticals());
  }, [activeAccount, dispatch, verticalsList]);

  useEffect(() => {
    if (verticalsList && verticalsList.length > 0 && activeAccount !== 0) {
      setList(verticalsList);
    }
  }, [verticalsList, activeAccount]);

  const handleClickMenuItem = id => () => {
    if (+id !== activeVertical) {
      const clickedVertical = list.find(item => item.id === +id);
      if (clickedVertical) {
        dispatch(cancelActiveVertical(null, dispatch));
        dispatch(setActiveVertical(clickedVertical.id, dispatch));
      }
    }
    history.push({
      pathname: '/vertical',
      search: `vertical_id=${id}`,
    });
    handleClose();
  };

  const checkPathName = path => {
    let verticalIsVisible = false;
    if (path === '/notes') {
      if (location) {
        let locationParams = queryString.parse(location.search);
        let verticalId = locationParams.vertical_id;
        if (verticalId) {
          verticalIsVisible = false;
        } else {
          verticalIsVisible = true;
        }
      }
    } else if (path === '/') {
      verticalIsVisible = true;
    } else if (path.substring(0, 9) === '/settings') {
      verticalIsVisible = true;
    } else {
      verticalIsVisible = false;
    }
    return verticalIsVisible;
  };

  const findVerticalName = id => {
    let name = '';
    if (location && location.pathname) {
      if (checkPathName(location.pathname)) {
        name = 'SELECT VERTICAL';
      } else {
        let vertical = verticalsList.find(item => item.id === +id);
        if (vertical) {
          name = vertical.name;
        }
      }
    }
    return name;
  };

  const renderVerticals =
    list && list.length
      ? list.map(item => (
          <MenuItem
            key={item.id}
            value={item.id}
            className={classNames(classes.menuItem, {
              [classes.menuItemActive]: activeVertical === +item.id,
            })}
            onClick={handleClickMenuItem(item.id)}
          >
            {item.name}
          </MenuItem>
        ))
      : null;

  const renderLabel =
    activeVertical && verticalsList && verticalsList.length > 0
      ? findVerticalName(activeVertical)
      : !list
      ? 'Loading verticals...'
      : 'SELECT VERTICAL';

  return (
    <>
      <div>
        <div
          className={classes.verticalsInput}
          onClick={handleClick}
          aria-controls="verticals-menu"
          aria-haspopup="true"
        >
          <div className={classes.verticalInputText}>
            {loader ? (
              <CircularProgress
                color="inherit"
                className={classes.loaderIcon}
              />
            ) : (
              renderLabel
            )}
          </div>
          {menuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          <div className={classes.verticalInputTextMobile}>
            {loader ? (
              <CircularProgress
                color="inherit"
                className={classes.loaderIcon}
              />
            ) : (
              renderLabel
            )}
          </div>
        </div>
      </div>
      <StyledMenu
        id="verticals-menu"
        anchorEl={menuOpen}
        open={Boolean(menuOpen)}
        onClose={handleClose}
      >
        {renderVerticals}
        {!list && (
          <MenuItem value="" disabled className={classes.menuItem}>
            Loading...
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};

export default VerticalMenu;
