import React from 'react';
import Grid from '@material-ui/core/Grid';
import CustomFilterSelect from '../../../components/customFilterSelect';
import CustomDateAndTimePicker from './customDateAndTimePicker';
import PropTypes from 'prop-types';

const CustomFilterDropDown = ({
  handleChange,
  value,
  handleChangeDate,
  filterDates,
  handleApplyFilter,
}) => {
  return (
    <Grid container alignItems="center">
      <CustomFilterSelect
        labelText="Date"
        placeholder="Date"
        name="date"
        handleChange={handleChange}
        value={value}
      />
      {value === 'custom-filter' && (
        <CustomDateAndTimePicker
          handleChangeDate={handleChangeDate}
          filterDates={filterDates}
          handleApplyFilter={handleApplyFilter}
        />
      )}
    </Grid>
  );
};

export default CustomFilterDropDown;

CustomFilterDropDown.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  filterDates: PropTypes.object.isRequired,
  handleApplyFilter: PropTypes.func.isRequired,
};
