import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {
  editStart,
  removeStart,
  createStart,
} from '../store/actions/actions/rest';
import { useHistory } from 'react-router-dom';
import Loader from '../components/loader';
import PropTypes from 'prop-types';

const withDripForm = Component => ({
  formValues,
  populateValues,
  classes,
  elementId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const user_name = useSelector(state => state.auth.username);
  const serviceProvidersList = useSelector(
    state => state.serviceProviders.list,
  );
  const loader = useSelector(state => state.app.loader);
  const [flag, setFlag] = useState(false);

  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const DeleteConfirmationDialog = lazy(() =>
    import('../components/deleteConfirmationModal'),
  );
  const NotesModal = lazy(() => import('../components/notes/notes'));

  useEffect(() => {
    if (flag && !loader) {
      history.push({
        pathname: '/drips/list',
        search: `vertical_id=${vertical_id}`,
      });
      setFlag(false);
    }
    // eslint-disable-next-line
  }, [flag, loader, history]);

  const handleDelete = notes => {
    const data = {
      body: {
        id: formValues.id,
        notes,
      },
    };
    dispatch(removeStart('drips', data, dispatch));
    history.push({
      pathname: '/drips/list',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const onSubmit = values => {
    if (elementId) {
      handleOpenNotesModal();
    } else {
      let allValues = populateValues(values);
      let data = {
        body: {
          ...allValues,
          account_id,
          vertical_id,
          notes: [
            {
              account_id,
              vertical_id,
              model_name: 'Drip',
              model_id: '',
              user_name,
              note: `Created ${values && values.name ? values.name : ""}`,
            },
          ],
        },
      };
      dispatch(createStart('drips', data, dispatch));
      setFlag(true);
    }
  };

  const submitForm = (values, notes) => {
    let allValues = populateValues(values);
    let data = {
      body: {
        ...allValues,
        account_id,
        vertical_id,
        notes,
      },
    };
    dispatch(editStart('drips', data, dispatch));
    history.push({
      pathname: '/drips/list',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleDeleteModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setNotesModalOpen(false);
  };

  const handleOpenNotesModal = () => {
    setNotesModalOpen(true);
  };

  const renderDeleteConfirmationDialog = isDeleteConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <DeleteConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        handleDelete={handleDelete}
        handleClose={handleDeleteModalClose}
        itemName={'call drip'}
        model_id={elementId}
        model_name="Drip"
      />
    </Suspense>
  ) : null;

  const renderNotesModal = props =>
    notesModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={notesModalOpen} />
          </Grid>
        }
      >
        <NotesModal
          props={props}
          classes={classes}
          model_name="Drip"
          model_id={elementId}
          open={notesModalOpen}
          handleClose={handleCloseNotesModal}
          submitForm={submitForm}
        />
      </Suspense>
    ) : null;

  return (
    <Component
      onSubmit={onSubmit}
      handleDeleteModalOpen={handleDeleteModalOpen}
      classes={classes}
      formValues={formValues}
      elementId={elementId}
      serviceProvidersList={serviceProvidersList}
      renderDeleteConfirmationDialog={renderDeleteConfirmationDialog}
      renderNotesModal={renderNotesModal}
    />
  );
};

export default withDripForm;

withDripForm.propTypes = {
  elementType: PropTypes.elementType.isRequired,
  formValues: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  elementId: PropTypes.string,
};
