import React, { Fragment, useState, Suspense, lazy } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Loader from '../../components/loader';
import { createStart, editStart } from '../../store/actions/actions/rest';
import { useSelector } from 'react-redux';
import { populateValues as populateFlowStepValues } from '../../pages/flows/steps/config';
import { populateValues as populateFlowStepTransitionValues } from '../../pages/flows/transitions/config';
import { populateValues as populateFlowValues } from '../../pages/flows/list/config';
import Archive from '@material-ui/icons/Archive';
import PropTypes from 'prop-types';

const TableWrapper = ({
  classes,
  sortBy,
  sortedElement,
  handleSortBy,
  rows,
  handleClickName,
  handleClickDripName,
  handleClickFlowName,
  renderNoDataSection,
  renderPaginaton,
  columns,
  model_name,
  type,
  dispatch,
  size,
  itemContain,
}) => {
  const loader = useSelector(state => state.app.loader);
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const [item, setItem] = useState(null);
  const [
    isCloneConfirmationModalOpen,
    setIsCloneConfirmationModalOpen,
  ] = useState(false);
  const [
    isArchiveConfirmationModalOpen,
    setIsArchiveConfirmationModalOpen,
  ] = useState(false);
  const CloneConfirmationDialog = lazy(() =>
    import('../../components/saveAsCopyConfirmationModal'),
  );
  const ArchiveConfirmationDialog = lazy(() =>
    import('../../components/archiveConfirmationModal'),
  );

  const handleCloneModalClose = () => {
    setIsCloneConfirmationModalOpen(false);
    setItem(null);
  };

  const handleCloneModalOpen = () => {
    setIsCloneConfirmationModalOpen(true);
  };

  const handleArchiveModalClose = () => {
    setIsArchiveConfirmationModalOpen(false);
    setItem(null);
  };

  const handleArchiveModalOpen = () => {
    setIsArchiveConfirmationModalOpen(true);
  };

  const handleClickCloneBtn = item => () => {
    setItem(item);
    handleCloneModalOpen();
  };

  const handleClickArchiveBtn = item => () => {
    setItem(item);
    handleArchiveModalOpen();
  };

  const handleArchive = notes => {
    let entity = getThePopulateFunction(model_name, item);
    let data = {
      body: {
        ...entity,
        archived: 1,
        account_id,
        vertical_id,
        notes,
      },
    };
    if (model_name === 'FlowStep')
      data.body.is_async = data.body.is_async ? 1 : 0;
    dispatch(editStart(type, data, dispatch));
  };

  const getThePopulateFunction = (model_name, item) => {
    switch (model_name) {
      case 'FlowStep':
        return populateFlowStepValues(item);
      case 'Flow':
        return populateFlowValues(item);
      case 'FlowStepTransition':
        return populateFlowStepTransitionValues(item);
      default:
        break;
    }
  };

  const handleClone = notes => {
    let entity = getThePopulateFunction(model_name, item);
    let data = {
      body: {
        ...entity,
        name: `Copy of ${entity.name}`,
        account_id,
        vertical_id,
        notes,
      },
    };
    delete data.body.id;
    if (itemContain) data['contain'] = [...itemContain];
    dispatch(createStart(type, data, dispatch));
  };

  const renderCloneConfirmationDialog = isCloneConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <CloneConfirmationDialog
        open={isCloneConfirmationModalOpen}
        handleSaveAsCopy={handleClone}
        handleClose={handleCloneModalClose}
        model_id={item.id}
        model_name={model_name}
      />
    </Suspense>
  ) : null;

  const renderArchiveConfirmationDialog = isArchiveConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <ArchiveConfirmationDialog
        open={isArchiveConfirmationModalOpen}
        handleArchive={handleArchive}
        handleClose={handleArchiveModalClose}
        itemName={model_name.replace(/([A-Z])/g, ' $1').toLowerCase()}
        model_id={item.id}
        model_name={model_name}
      />
    </Suspense>
  ) : null;

  const renderArchiveButton = (model_name, isDisabled, row) => {
    if (model_name === 'Flow' && isDisabled) {
      return (
        <Tooltip
          title="The Flow is connected to Flow Step Transitions"
          arrow
          placement="top"
        >
          <span>
            <IconButton disabled={isDisabled}>
              <Archive />
            </IconButton>
          </span>
        </Tooltip>
      );
    } else if (
      (model_name === 'FlowStep' && !row.item.is_archivable) ||
      (model_name === 'FlowStepTransition' && !row.item.is_archivable)
    ) {
      const renderTextMessage =
        model_name === 'FlowStep'
          ? 'The Flow Step is connected to Flows'
          : 'The Flow Step Transition is connected to Flows';

      return (
        <Tooltip title={renderTextMessage} arrow placement="top">
          <span>
            <IconButton disabled>
              <Archive />
            </IconButton>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Archive" arrow placement="top">
          <IconButton
            disabled={isDisabled}
            onClick={handleClickArchiveBtn(row.item)}
          >
            <Archive />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const renderColumn = (id, row, value) => {
    if (id === 'name') {
      if (type === 'uploads') {
        return <span>{value}</span>;
      } else {
        return (
          <span className={classes.link} onClick={handleClickName(row.item)}>
            {value}
          </span>
        );
      }
    } else if (id === 'drip_id') {
      return (
        <span className={classes.link} onClick={handleClickDripName(row.item)}>
          {value}
        </span>
      );
    } else if (id === 'to_flow_id') {
      return (
        <span className={classes.link} onClick={handleClickFlowName(row.item)}>
          {value}
        </span>
      );
    } else if (id === 'actions') {
      let archivedDisabled = false;
      if (model_name === 'Flow') {
        if (
          row.item.flow_step_transitions &&
          row.item.flow_step_transitions.length > 0
        )
          archivedDisabled = true;
      }
      return (
        <>
          <Tooltip title="Clone" arrow placement="top">
            <IconButton onClick={handleClickCloneBtn(row.item)}>
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
          {renderArchiveButton(model_name, archivedDisabled, row)}
        </>
      );
    } else if (id === 'status' && type === 'uploads') {
      if (row.item.status === 3) {
        return (
          <div className={classes.statusError}>
            <span>Error</span>
            <Tooltip
              title={
                row.item.error_message
                  ? row.item.error_message
                  : 'Something went wrong.'
              }
              arrow
              placement="top"
            >
              <InfoIcon />
            </Tooltip>
          </div>
        );
      } else {
        return value;
      }
    } else {
      return value;
    }
  };
  return (
    <Fragment>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          aria-label="sticky table"
          className={classes.table}
          size={size || 'medium'}
        >
          <TableHead>
            <TableRow>
              {columns.map(column => {
                if (column.id !== 'actions' && column.table !== 'upload') {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontSize: 16 }}
                      sortDirection={sortBy[sortedElement]}
                    >
                      <TableSortLabel
                        onClick={handleSortBy(column.id)}
                        active={sortedElement === column.id}
                        direction={
                          sortedElement === column.id
                            ? sortBy[sortedElement]
                            : 'asc'
                        }
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontSize: 16 }}
                    >
                      <b>{column.label}</b>
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map(row => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.item.id}
                  >
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={Math.floor(Math.random() * 123132)}
                          align={column.align}
                          style={{ fontSize: 16 }}
                        >
                          {renderColumn(column.id, row, value)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {renderNoDataSection()}
          </TableBody>
        </Table>
      </TableContainer>
      {renderPaginaton()}
      {renderCloneConfirmationDialog}
      {renderArchiveConfirmationDialog}
    </Fragment>
  );
};

export default TableWrapper;

TableWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  sortedElement: PropTypes.string.isRequired,
  handleSortBy: PropTypes.func.isRequired,
  rows: PropTypes.array,
  handleClickName: PropTypes.func,
  handleClickDripName: PropTypes.func,
  handleClickFlowName: PropTypes.func,
  renderNoDataSection: PropTypes.func,
  renderPaginaton: PropTypes.func,
  columns: PropTypes.array.isRequired,
  model_name: PropTypes.string,
  type: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  size: PropTypes.string,
  itemContain: PropTypes.array,
};
