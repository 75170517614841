import React from 'react';
import Pagination from 'react-js-pagination';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  pagination: {
    width: '100%',
    margin: '20px 0',
    '& > ul': {
      display: 'flex',
      justifyContent: 'center',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      width: '100%',
    },
    '& > ul > li': {
      width: 45,
      height: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.text[100],
      cursor: 'pointer',
      transition: 'all .6s',
      backgroundColor: '#F2F2F2',
      borderRadius: 2,
      userSelect: 'none',
      margin: '0 5px',
      padding: '0 20px',
      '&:hover': {
        backgroundColor: '#E0E0E0',
      },
    },
    '& > ul > li > a': {
      textDecoration: 'none',
      color: theme.palette.text[100],
    },
    '& > ul > li.active': {
      backgroundColor: '#E0E0E0',
      '& > a': {
        color: theme.palette.text[100],
      },
    },
    '& > ul > li.disabled': {
      cursor: 'no-drop',
      '& > a': {
        color: 'gray',
        cursor: 'no-drop',
      },
      '&:hover': {
        backgroundColor: '#F2F2F2',
      },
    },
  },
}));

const PaginationComponent = props => {
  const classes = useStyles();
  return (
    <div className={classes.pagination}>
      <Pagination
        activePage={props.activePage}
        itemsCountPerPage={props.itemsPerPage}
        totalItemsCount={100}
        onChange={props.onChange}
      />
    </div>
  );
};

PaginationComponent.propTypes = {
  activePage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PaginationComponent;
