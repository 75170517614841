import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const Parameters = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
  required,
  disabled,
}) => {
  const validateJSONString = text => {
    if (typeof text !== 'string') {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  };

  const validate = values => {
    if (!values) {
      return 'Required';
    } else {
      if (!validateJSONString(values)) {
        return 'Wrong format';
      }
    }
  };

  const validateFormat = values => {
    if (values && !validateJSONString(values)) {
      return 'Wrong format';
    }
  };

  return (
    <div className={wrapperClass}>
      {hideLabel ? null : (
        <label htmlFor="parameters">{labelText || 'Parameters'}</label>
      )}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'parameters'] && touched[name || 'name']
            ? errorClass || 'has-error'
            : ''
        }`}
        name={name || 'parameters'}
        as="textarea"
        placeholder={placeholder || 'Parameters'}
        type="parameters"
        validate={required ? validate : validateFormat}
        disabled={disabled}
        onChange={e => {
          handleChange(e);
        }}
      />
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'namparameterse'}
          component="div"
          className="field-error"
          data-testid="parameters-error"
        />
      )}
    </div>
  );
};

export default Parameters;

Parameters.propTypes = {
  hideLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  wrapperClass: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
