import { makeStyles } from '@material-ui/core/styles';
import SelectArrow from '../../../assets/images/select-arrow-down.png';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '50px 0 20px 60px',
    [theme.breakpoints.up('xl')]: {
      padding: '50px 0 20px 270px',
    },
  },
  titleIcon: {
    position: 'relative',
    top: 8,
    marginRight: 10,
  },
  subtitle: {
    fontSize: 18,
    lineHeight: '22px',
    marginTop: 5,
    [theme.breakpoints.up(1900)]: {
      marginTop: 10,
    },
  },
  activeLockedButton: {
    '& > button': {
      background: `linear-gradient(180deg, #BDBDBD 0%, #E0E0E0 12.15%)`,
    },
  },
  lockButton: {
    paddingRight: 10,
    '& > button': {
      backgroundColor: '#F2F2F2',
      fontFamily: 'Lato',
      width: 146,
      height: 45,
      borderRadius: 2,
      '& > span': {
        color: '#828282',
        fontWeight: 900,
      },
    },
  },
  addButton: {
    paddingRight: 10,
    '& > button': {
      backgroundColor: '#F2F2F2',
      width: 194,
      height: 45,
      '& > span': {
        color: '#828282',
        fontWeight: 900,
      },
    },
  },
  searchField: {
    backgroundColor: '#F2F2F2',
    '& > .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& > .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
      },
      '& > button': {
        padding: 9,
      },
      '& > svg': {
        color: '#828282',
        position: 'relative',
        left: 7,
      },
    },
  },
  tableWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  tableContainer: {
    marginTop: 30,
  },
  table: {
    minWidth: 650,
    color: theme.palette.text[100],
    '& > thead > tr > th': {
      color: theme.palette.text[100],
    },
    '& > tbody > tr > th ': {
      color: theme.palette.text[100],
    },
    '& > tbody > tr > td ': {
      color: theme.palette.text[100],
    },
  },
  link: {
    color: '#036BF0',
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'all .7s',
    '&:hover': {
      color: theme.palette.text[100],
    },
  },
  pageInput: {
    backgroundColor: '#F2F2F2',
    width: 115,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
        padding: '13px 10px',
      },
      '& > span': {
        padding: 10,
        cursor: 'pointer',
      },
    },
  },
  cssFocused: {
    color: '#828282 !important',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#828282 !important',
  },
  resultsPerPageLabel: {
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.text[100],
    paddingRight: 10,
    '& > span': {
      display: 'block',
    },
  },
  resultsPerPage: {
    width: 75,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        padding: 13,
      },
      '& > div': {
        backgroundColor: theme.palette.grey[100],
        padding: 2,
        '& > svg': {
          fontSize: 19,
        },
      },
    },
  },
  formControl: {
    width: 262,
    backgroundColor: theme.palette.white[100],
  },
  dropDownSelect: {
    '& > div': {
      padding: '14px 32px 12px 14px',
    },
  },
  selectSection: {
    marginTop: 15,
  },
  switchSection: {
    marginTop: 15,
    '& > h4': {
      color: theme.palette.text[100],
      marginBottom: 10,
      fontWeight: 'normal',
      marginTop: 0,
    },
  },
  newConfigBtn: {
    height: 45,
    backgroundColor: theme.palette.lightBlue[300],
    color: theme.palette.white[100],
    fontSize: 16,
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
  },
  actionsContainer: {
    justifyContent: 'space-between !important',
    padding: '25px 0 10px 0 !important',
  },
  deleteActionBtn: {
    backgroundColor: theme.palette.secondary.main,
    height: 47,
    transition: 'all .4s',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: '#b54a4c !important',
    },
    '& > span': {
      color: theme.palette.white[100],
      fontSize: 20,
    },
  },
  saveActionBtn: {
    backgroundColor: theme.palette.green[300],
    transition: 'all .4s',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: `${theme.palette.green[400]} !important`,
    },
    '& > span': {
      color: theme.palette.white[100],
      fontSize: 20,
      '& > span': {
        marginLeft: 10,
        fontWeight: 'bold',
      },
    },
  },
  inputLabel: {
    color: theme.palette.text[100],
    fontWeight: 400,
    fontFamily: 'Lato',
    fontSize: 16,
    marginTop: 15,
    marginBottom: 9,
  },
  noData: {
    height: 60,
    position: 'relative',
    left: 20,
    fontSize: 20,
  },
  loaderContainer: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: 150,
    zIndex: 10,
  },
  errorText: {
    fontFamily: 'Lato',
    color: theme.palette.red[300],
  },
  formWrapper: {
    width: '100%',
    marginTop: 30,
  },
  textField: {
    marginBottom: 20,
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
    '& > input': {
      display: 'block',
      border: '1px solid #828282',
      height: 45,
      width: 500,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      backgroundColor: theme.palette.white[100],
    },
    '& > input.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > textarea': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 90,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      paddingTop: 10,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
    },
    '& > select': {
      display: 'block',
      width: 500,
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '98% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  modalLoader: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: 0,
    zIndex: 10,
  },
  iconContainer: {
    maxWidth: 90,
  },
  actionButtons: {
    marginTop: 100,
    maxWidth: 900,
    width: 900,
    '& > button': {
      marginRight: 27,
    },
  },
  tagsContainer: {
    minWidth: 430,
    maxWidth: 750,
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: `1px solid #828282`,
    paddingBottom: 4,
    marginTop: 30,
  },
  addTagBtn: {
    backgroundColor: theme.palette.lightBlue[300],
    color: theme.palette.white[100],
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 14,
    width: 108,
    height: 35,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
  },
  tagsTitle: {
    fontSize: 16,
  },
  tagSelectContainer: {
    minWidth: 430,
    maxWidth: 750,
    width: '100%',
    margin: '8px 0',
  },
  tagsColumnTitle: {
    fontFamily: 'Lato',
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text[100],
    width: '50%',
  },
  tagsSelects: {
    width: '100%',
  },
  tagColumn: {
    width: '50%',
    paddingRight: 10,
    '& > div > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
    },
    '& > div > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  tagsActionButton: {
    height: 35,
    width: 35,
    backgroundColor: '#D8D8D8',
    borderRadius: 2,
    color: theme.palette.white[100],
    position: 'relative',
    top: 5,
    cursor: 'pointer',
    transition: 'all .4s',
    padding: 2,
    '&:hover': {
      backgroundColor: '#b7b5b5',
    },
  },
  dripValue: {
    paddingRight: 22,
  },
  field: {
    width: 500,
    '& > div > div > select': {
      width: '100%',
    },
  },
}));

export default useStyles;
