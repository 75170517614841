import { GET_LIST, CLEAR_LIST } from '../actions/actionTypes/reporting';

export default type => {
  const REDUCER_TYPE = type.toUpperCase();
  const initialState = {
    list: null,
    count: 0,
    params: null,
    filters: null,
    contain: null,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case GET_LIST(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          list: action.payload,
        };
      case CLEAR_LIST(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          list: null,
        };
      default:
        return state;
    }
  };
};
