import { makeStyles } from '@material-ui/core/styles';
import OpenWithWhite from '../../../assets/images/dragIcon@2x.png';
import SelectArrow from '../../../assets/images/select-arrow-down.png';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '20px 0',
    paddingLeft: 60,
    [theme.breakpoints.up('xl')]:{
      padding: '20px 0',
      paddingLeft: 270,
    }
  },
  title: {
    paddingLeft: 80,
    marginBottom: 0,
    position: 'relative',
    top: 35,
    left: 15,
  },
  titleIcon: {
    position: 'absolute',
    left: 0,
    top: 19,
  },
  addButton: {
    '& > button': {
      backgroundColor: '#F2F2F2',
      width: 146,
      height: 45,
      '& > span': {
        color: '#828282',
        fontWeight: 900,
      },
    },
  },
  createTemplate: {
    width: 236,
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 20,
    background: theme.palette.green[300],
    borderRadius: 5,
    color: theme.palette.white[100],
    '&:hover': {
      background: theme.palette.green[400],
    },
  },
  addFolderButton: {
    paddingRight: 10,
    '& > button': {
      backgroundColor: '#F2F2F2',
      width: 198,
      height: 45,
      '& > span': {
        color: '#828282',
        fontWeight: 900,
      },
    },
  },
  cloneDripBtn: {
    marginRight: 10,
  },
  searchField: {
    backgroundColor: theme.palette.white[100],
    position: 'relative',
    bottom: 2,
    '& > .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& > .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
        width: 140,
        paddingRight: 10,
        paddingTop: 12,
      },
    },
  },
  searchIcon: {
    fontSize: 18,
    marginRight: 10,
    cursor: 'pointer',
  },
  searchArrows: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    padding: '10px 15px',
    backgroundColor: '#F2F2F2',
    '& > svg': {
      fontSize: 18,
      cursor: 'pointer',
    },
    '& > span': {
      marginRight: 5,
    },
  },
  pageInput: {
    backgroundColor: '#F2F2F2',
    width: 115,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
        padding: '13px 10px',
      },
      '& > span': {
        padding: 10,
        cursor: 'pointer',
      },
    },
  },
  expandIcons: {
    '& > svg': {
      cursor: 'pointer',
    },
  },
  cssFocused: {
    color: '#828282 !important',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#828282 !important',
  },
  resultsPerPageLabel: {
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.text[100],
    paddingRight: 10,
    '& > span': {
      display: 'block',
    },
  },
  resultsPerPage: {
    width: 75,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        padding: 13,
      },
      '& > div': {
        backgroundColor: theme.palette.grey[100],
        padding: 2,
        '& > svg': {
          fontSize: 19,
        },
      },
    },
  },
  link: {
    fontFamily: 'Lato',
    fontSize: 18,
    color: theme.palette.lightBlue[400],
    fontWeight: 'normal',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  modalContainer: {
    '& > div.MuiDialog-container': {
      '& > div': {
        width: 545,
        backgroundColor: '#F3F8FE',
        padding: 20,
      },
    },
  },
  modalTitle: {
    fontFamily: "'Lato'",
    fontSize: 24,
    color: '#4F4F4F',
    '& > h2': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  modalContent: {
    paddingTop: '30px !important',
    paddingBottom: '40px !important',
  },
  actionsContainer: {
    justifyContent: 'space-between !important',
    padding: '10px 0 !important',
  },
  deleteActionBtn: {
    backgroundColor: theme.palette.secondary.main,
    height: 47,
    transition: 'all .4s',
    '&:hover': {
      backgroundColor: '#b54a4c !important',
    },
    '& > span': {
      color: theme.palette.white[100],
      fontSize: 20,
    },
    '&:disabled': {
      backgroundColor: 'gray',
    },
  },
  saveActionBtn: {
    backgroundColor: theme.palette.green[300],
    transition: 'all .4s',
    '&:hover': {
      backgroundColor: `${theme.palette.green[400]} !important`,
    },
    '& > span': {
      color: theme.palette.white[100],
      fontSize: 20,
      '& > span': {
        marginLeft: 10,
        fontWeight: 'bold',
      },
    },
  },
  folderContainer: {
    padding: '20px 0 20px 5px',
    height: 'calc(100vh - 270px)',
  },
  dripsFolderWrapper: {
    borderBottom: '1px solid #BDBDBD',
    position: 'relative',
  },
  arrowIcon: {
    paddingRight: 25,
    width: 30,
    cursor: 'pointer',
  },
  folderIcon: {
    paddingRight: 20,
  },
  dripsFolderTitle: {
    cursor: 'pointer',
    '& > h3': {
      fontFamily: 'Lato',
      fontSize: 24,
      fontWeight: '400',
      color: '#000000',
    },
  },
  activeFolder: {
    '& > h3': {
      color: theme.palette.lightBlue[400],
    },
  },
  nestedWrapper: {
    borderBottom: '1px solid #BDBDBD',
  },
  nested: {
    paddingLeft: theme.spacing(14),
  },
  textField: {
    marginBottom: 20,
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
    '& > input': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      backgroundColor: theme.palette.white[100],
    },
    '& > input.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '98% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  dragAndDropIcon: {
    position: 'absolute',
    right: 20,
    top: 30,
    color: '#C4C4C4',
    cursor: 'pointer',
  },
  collapsedRow: {
    width: '100%',
    position: 'relative',
  },
  modalLoader: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: 0,
    zIndex: 10,
  },
  openedFolder: {
    display: 'block',
  },
  closedFolder: {
    display: 'none',
  },
  treeContainer: {
    height: 'calc(100vh - 222px)',
    width: '100%',
  },

  dripContent: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      height: 35,
    },
    '& > span': {
      fontWeight: 400,
      marginLeft: 10,
    },
  },
  selectedFolder: {
    '& > div.rst__rowContents': {
      backgroundColor: '#6D9DD3',
      color: '#FFFFFF',
      borderRadius: 0,
    },
    '& > div.rst__moveHandle': {
      background: `#6D9DD3 url(${OpenWithWhite}) no-repeat center`,
      backgroundSize: 23,
    },
  },
  drip: {
    '& > div.rst__rowContents': {
      color: theme.palette.text[100],
    },
  },
  selectedDrip: {
    '& > div.rst__rowContents': {
      color: '#FFFFFF',
      backgroundColor: '#6D9DD3',
    },
    '& > div.rst__moveHandle': {
      background: `#6D9DD3 url(${OpenWithWhite}) no-repeat center`,
      backgroundSize: 23,
    },
  },
  actionButtonsContainer: {
    marginTop: 30,
    marginBottom: 20,
    width: '100%',
  },
  topSection: {
    paddingBottom: 30,
    borderBottom: '1px solid black',
    marginLeft: 15,
    position: 'relative',
  },
  saveChangesBtn: {
    backgroundColor: '#2E972A',
    borderRadius: 2,
    color: theme.palette.white[100],
    width: 149,
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 16,
    marginLeft: 15,
    transition: 'all .4s',
    "&:hover":{
      background:'#22721d'
    }
  },
}));

export default useStyles;
