import {
  DISPLAY_SNACKBAR,
  HIDE_SNACKBAR,
  DISPLAY_LOADER,
  HIDE_LOADER,
  SAVE_DRAWER,
  IS_SET_VERTICAL,
  SET_ITEM_FLAG,
} from '../actions/actionTypes/root';
import { LOGOUT } from '../actions/actionTypes/auth';

const initialState = {
  isSnackBarOpen: false,
  snackBarType: '',
  snackBarMessage: '',
  loader: 0,
  setVertical: false,
  itemFlag: false,
  drawer: {
    drips: false,
    flows: false,
    dripModule: false,
    reporting: false,
  },
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_SNACKBAR:
      return {
        ...state,
        isSnackBarOpen: true,
        snackBarMessage: action.payload.message,
        snackBarType: action.payload.type,
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        isSnackBarOpen: false,
        snackBarMessage: '',
        snackBarType: '',
      };
    case DISPLAY_LOADER:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loader: state.loader > 0 ? state.loader - 1 : state.loader,
      };
    case SAVE_DRAWER:
      return {
        ...state,
        drawer: action.payload,
      };
    case IS_SET_VERTICAL:
      return {
        ...state,
        setVertical: state.setVertical ? state.setVertical : action.payload,
      };
    case SET_ITEM_FLAG:
      return {
        ...state,
        itemFlag: action.payload,
      };
    case LOGOUT:
      return {
        isSnackBarOpen: false,
        snackBarType: '',
        snackBarMessage: '',
        loader: 0,
        setVertical: false,
        itemFlag: false,
        drawer: {
          drips: false,
          flows: false,
          dripModule: false,
          reporting: false,
        },
      };
    default:
      return state;
  }
};

export default rootReducer;
