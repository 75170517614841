import { makeStyles } from '@material-ui/core/styles';
import SelectArrow from '../../../../../assets/images/select-arrow-down.png';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '90%',
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
    '& > select': {
      display: 'block',
      width: 193,
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      color: '#828282',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '95% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  select: {
    '& > select': {
      marginLeft: 'auto',
    },
  },
  title: {
    minWidth: 500,
    width: 500,
    fontFamily: 'Lato',
    '& > h3': {
      color: theme.palette.text[100],
      fontSize: 24,
      fontWeight: 400,
      marginBottom: 0,
    },
    '& > h4': {
      color: '#828282',
      fontSize: 16,
      fontWeight: 400,
      marginTop: 0,
    },
  },
  hoursOfOperationContainer: {
    width: 1090,
    padding: 0,
    marginBottom: 90,
    marginTop: 36,
    [theme.breakpoints.up('xl')]: {
      width: 1130,
    },
  },
  topSection: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },
  table: {
    display: 'flex',
    flex: '1',
    width: '100%',
  },
  days: {
    width: 125,
    maxWidth: 125,
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 25,
    height: 386,
    fontSize: 16,
    '& > div': {
      lineHeight: 1,
      height: 45,
      minWidth: 128,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: '1',
      '& > span': {
        color: '#828282',
      },
    },
    [theme.breakpoints.up('xl')]: {
      width: 160,
      maxWidth: 160,
      '& > div': {
        minWidth: 148,
      },
    },
  },
  tableContainer: {
    width: 969,
    marginBottom: 20,
  },
  hoursRow: {
    width: 969,
    paddingLeft: 2,
  },
  hourCell: {
    width: 19,
    fontSize: 16,
    display: 'inline-block',
    textAlign: 'center',
    color: '#828282',
  },
  hourCellPadding: {
    paddingLeft: 5,
  },
  hourCellLeft: {
    position: 'relative',
    left: 3,
  },
  minuteCell: {
    width: 21,
    display: 'inline-block',
    textAlign: 'center',
    color: '#828282',
    fontSize: 11,
    position: 'relative',
    left: 2,
  },
  dayPeriod: {
    position: 'relative',
  },
  period: {
    position: 'absolute',
    top: -15,
    left: 1,
    fontSize: 11,
  },
  tableBorder: {
    border: '2px solid #BDBDBD',
  },
  savePreset: {
    color: '#828282',
    fontWeight: 900,
    background: '#F2F2F2',
    borderRadius: 2,
    fontSize: 16,
    width: 212,
    '& > hover': {
      backgroundColor: '#828282',
      color: '#F2F2F2',
    },
  },
  actionContainer: {
    width: '100%',
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  undoButton: {
    marginRight: 10,
  },
  actionButton: {
    color: '#FFFFFF',
    background: theme.palette.lightBlue[300],
    width: 100,
    height: 35,
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 900,
    transition: 'all .4s',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
      color: '#F2F2F2',
    },
    '&:disabled': {
      color: '#FFFFFF',
      boxShadow: 'none',
      backgroundColor: '#D8D8D8',
    },
  },
}));

export default useStyles;
