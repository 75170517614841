import { Auth } from 'aws-amplify';
import {
  LOGOUT,
  AUTH_FULFILLED,
  SET_ACTIVE_VERTICAL,
  SET_ACTIVE_ACCOUNT,
  GET_USER_ACCOUNTS,
  GET_ACCOUNT_VERTICALS,
  HIDE_VERTICAL_LOADER,
  SHOW_VERTICAL_LOADER,
  AUTH_REJECTED,
} from '../actionTypes/auth';

export const getAuth = (data, bypassCache = false) => {
  return {
    type: 'AUTH',
    payload: {
      auth: Auth.currentAuthenticatedUser({ bypassCache: bypassCache }),
      data: data,
    },
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const authFulfilled = payload => {
  return {
    type: AUTH_FULFILLED,
    payload,
  };
};

export const authRejected = payload => {
  return {
    type: AUTH_REJECTED,
    payload,
  };
};

export const hideVerticalLoader = () => ({
  type: HIDE_VERTICAL_LOADER,
});

export const showVerticalLoader = () => ({
  type: SHOW_VERTICAL_LOADER,
});

export const setActiveVertical = (payload, dispatch) => {
  if (dispatch) {
    dispatch(showVerticalLoader());
  }
  return SET_ACTIVE_VERTICAL.actionCreators.start(false, payload);
};

export const saveActiveVertical = (payload, dispatch) => {
  if (dispatch) {
    dispatch(hideVerticalLoader());
  }
  return SET_ACTIVE_VERTICAL.actionCreators.success(false, payload);
};

export const cancelActiveVertical = (payload, dispatch) => {
  if (dispatch) {
    dispatch(hideVerticalLoader());
  }
  return SET_ACTIVE_VERTICAL.actionCreators.success(false, payload);
};

export const setActiveAccount = payload =>
  SET_ACTIVE_ACCOUNT.actionCreators.start(false, payload);

export const saveActiveAccount = payload =>
  SET_ACTIVE_ACCOUNT.actionCreators.success(false, payload);

export const cancelActiveAccount = payload =>
  SET_ACTIVE_ACCOUNT.actionCreators.failure(false, payload);

export const getUserAccounts = payload =>
  GET_USER_ACCOUNTS.actionCreators.start(false, payload);

export const saveUserAccounts = payload =>
  GET_USER_ACCOUNTS.actionCreators.success(false, payload);

export const cancelUserAccounts = payload =>
  GET_USER_ACCOUNTS.actionCreators.failure(false, payload);

export const getAccountVerticals = payload =>
  GET_ACCOUNT_VERTICALS.actionCreators.start(false, payload);

export const saveAccountVerticals = payload =>
  GET_ACCOUNT_VERTICALS.actionCreators.success(false, payload);

export const cancelAccountVerticals = payload =>
  GET_ACCOUNT_VERTICALS.actionCreators.failure(false, payload);
