import React, { useState, useEffect, lazy } from 'react';
import useStyles from '../styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import DripsIcon from '../../../../assets/images/drips-icon.svg';
import EmailDripForm from './emailDripForm';
import EmailDripPreview from './emailDripPreview';
import Loader from '../../../../components/loader';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getList,
  cancelList,
  getItem,
  cancelItem,
} from '../../../../store/actions/actions/rest';
import { setItemFlag, displaySnackbar } from '../../../../store/actions/actions/root';
import {
  initialValues,
  populateValues,
  contain,
  filters,
  params,
} from './config';
import {
  checkIfParentExist,
  findFolderPath,
  getPathString,
  checkIfElementExist,
} from '../helper';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { getFilters } from '../../../../hoc/withTable/getFilters';

const EmailDrip = ({ location, match }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const allVerticals = useSelector(state => state.auth.verticals);
  const loader = useSelector(state => state.app.loader);
  const itemFlag = useSelector(state => state.app.itemFlag);
  const dripFoldersList = useSelector(state => state.dripFolders.list);
  const dripsList = useSelector(state => state.drips.list);
  const serviceProvidersList = useSelector(
    state => state.serviceProviders.list,
  );
  const fromEmailComponentsList = useSelector(
    state => state.fromEmailComponents.list,
  );
  const friendlyFromComponentsList = useSelector(
    state => state.friendlyFromComponents.list,
  );
  const emailSubjectComponentsList = useSelector(
    state => state.emailSubjectComponents.list,
  );
  const emailBodyComponentsList = useSelector(
    state => state.emailBodyComponents.list,
  );
  const messageTypesList = useSelector(state => state.messageTypes.list);
  const element = useSelector(
    state =>
      (state.drips.list &&
        state.drips.list.find(item => item.id === +match.params.id)) ||
      state.drips.item,
  );
  const auth = useSelector(state => state.auth);
  const DeleteConfirmationDialog = lazy(() =>
    import('../../../../components/deleteConfirmationModal'),
  );
  const [folderPath, setFolderPath] = useState('');
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const [formValues, setFormValues] = useState(initialValues());
  const [emailPreview, setEmailPreview] = useState('');
  const [rootFolderId, setRootFolderId] = useState(null);
  const [elementId, setElementId] = useState(null);
  const [textPlainPreview, setTextPlainPreview] = useState('');
  const [activeTab, setActiveTab] = useState({
    emailTemplate: true,
    mobileTemplate: false,
    plainText: false,
  });

  const handleCancelAllAPICalls = () => {
    dispatch(cancelList('service-providers', null, dispatch));
    dispatch(cancelList('drips', null, dispatch));
    dispatch(cancelList('drip-folders', null, dispatch));
    dispatch(cancelList('message-types', null, dispatch));
    dispatch(cancelList('from-email-components', null, dispatch));
    dispatch(cancelList('friendly-from-components', null, dispatch));
    dispatch(cancelList('email-subject-components', null, dispatch));
    dispatch(cancelList('email-body-components', null, dispatch));
  };

  useEffect(() => {
    if (auth && vertical_id) {
      let allFilters = getFilters(filters, auth);
      handleCancelAllAPICalls();
      dispatch(getList('service-providers', { params }, dispatch));
      dispatch(
        getList(
          'drips',
          { params, contain, filters: [...allFilters] },
          dispatch,
        ),
      );
      dispatch(getList('drip-folders', { filters: [...allFilters] }, dispatch));
      dispatch(getList('message-types', null, dispatch));
      dispatch(
        getList(
          'from-email-components',
          { params, filters: [...allFilters] },
          dispatch,
        ),
      );
      dispatch(
        getList(
          'friendly-from-components',
          { params, filters: [...allFilters] },
          dispatch,
        ),
      );
      dispatch(
        getList(
          'email-subject-components',
          { params, filters: [...allFilters] },
          dispatch,
        ),
      );
      dispatch(
        getList(
          'email-body-components',
          { params, contain, filters: [...allFilters] },
          dispatch,
        ),
      );
      return () => {
        handleCancelAllAPICalls();
      };
    }
    // eslint-disable-next-line
  }, [dispatch, auth, vertical_id]);

  useEffect(() => {
    if (rootFolderId) {
      if (location && location.search) {
        let params = queryString.parse(location.search);
        let path = findFolderPath(dripFoldersList, params.parent_id);
        setFolderPath(getPathString(path));
        if (checkIfParentExist(dripFoldersList, params.parent_id)) {
          setFormValues({
            ...formValues,
            parent_id: params.parent_id,
          });
        } else {
          setFolderPath('Root/');
          setFormValues({
            ...formValues,
            parent_id: rootFolderId,
          });
        }
      } else {
        setFormValues({
          ...formValues,
          parent_id: rootFolderId,
        });
        setFolderPath('Root/');
      }
    }
    // eslint-disable-next-line
  }, [location.search, rootFolderId]);

  useEffect(() => {
    if (messageTypesList && messageTypesList.length > 0) {
      messageTypesList.forEach(type => {
        if (type.name === 'send_email') {
          setFormValues({
            ...formValues,
            message_type_id: type.id,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [messageTypesList, element]);

  useEffect(() => {
    if (
      location &&
      dripsList &&
      dripsList.length > 0 &&
      dripFoldersList &&
      dripFoldersList.length > 0
    ) {
      let id = location.pathname.replace('/drips/email-drip/edit/', '');
      if (checkIfElementExist(dripsList, id)) {
        let element = dripsList.find(drips => drips.id === id);
        let path = findFolderPath(dripFoldersList, element.parent_id);
        setFolderPath(getPathString(path));
        setElementId(id);
      } else {
        if (location.pathname.includes('/drips/email-drip/edit/')) {
          history.push({
            pathname: '/drips/list',
            search: `vertical_id=${vertical_id}`,
          });
          dispatch(
            displaySnackbar({
              type: 'warning',
              message: `Drip not found.`,
            }),
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [location, dripsList, dripFoldersList]);

  useEffect(() => {
    if (
      dripFoldersList &&
      dripFoldersList.length > 0 &&
      allVerticals &&
      allVerticals.length > 0 &&
      vertical_id
    ) {
      let currentVerticalName = '';
      allVerticals.forEach(vertical => {
        if (vertical.id === vertical_id) {
          currentVerticalName = `ROOT ${vertical.name}`;
        }
      });
      dripFoldersList.forEach(folder => {
        if (folder.name === currentVerticalName) {
          setRootFolderId(folder.id);
        }
      });
    }
    // eslint-disable-next-line
  }, [dripFoldersList, allVerticals, vertical_id]);

  useEffect(() => {
    if (itemFlag) {
      history.push('/404');
      dispatch(setItemFlag(false));
    }
  }, [itemFlag, dispatch, history]);

  useEffect(() => {
    if (match.params.id && !element && auth) {
      let allFilters = getFilters(filters, auth);
      dispatch(
        getItem(
          'drips',
          {
            id: match.params.id,
            contain,
            filters: [...allFilters],
          },
          dispatch,
        ),
      );
      return () => {
        dispatch(cancelItem('drips', null, dispatch));
      };
    }
  }, [element, match.params.id, dispatch, auth]);

  useEffect(() => {
    if (
      dripsList &&
      dripsList.length > 0 &&
      dripFoldersList &&
      dripFoldersList.length > 0 &&
      emailBodyComponentsList &&
      emailBodyComponentsList.length > 0
    ) {
      if (elementId) {
        let element = dripsList.find(drips => drips.id === elementId);
        if (element) {
          let path = findFolderPath(dripFoldersList, element.parent_id);
          setFolderPath(getPathString(path));
          setFormValues(populateValues(element));
          let emailHTMLComponent = emailBodyComponentsList.find(
            item => item.id === element.email_body_component_id,
          );
          setEmailPreview(emailHTMLComponent.email_html_body);
          setTextPlainPreview(emailHTMLComponent.email_plain_text_body);
        }
      }
    }
    // eslint-disable-next-line
  }, [elementId, dripsList, dripFoldersList, emailBodyComponentsList]);

  const handleSwitchTab = value => () => {
    setActiveTab({
      emailTemplate: false,
      mobileTemplate: false,
      plainText: false,
      [value]: true,
    });
  };

  const handleEmailPreview = event => {
    if (event.target.value) {
      let element = emailBodyComponentsList.find(
        item => +item.id === +event.target.value,
      );
      setEmailPreview(element.email_html_body);
      setTextPlainPreview(element.email_plain_text_body);
    } else {
      setEmailPreview('');
      setTextPlainPreview('');
    }
  };

  const renderFolderPath = folderPath ? folderPath : '';

  return (
    <Grid container className={classes.emailContainer}>
      <img
        src={DripsIcon}
        alt="Flows icon"
        className={classes.emailTitleIcon}
      />
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Email Drip
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            component="h5"
            gutterBottom
            className={classes.title}
          >
            {renderFolderPath}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.contentContainer}>
        <Grid item xs={6} md={4} xl={6} className={classes.dripFormContainer}>
          <EmailDripForm
            classes={classes}
            values={formValues}
            serviceProvidersList={serviceProvidersList}
            fromEmailComponentsList={fromEmailComponentsList}
            friendlyFromComponentsList={friendlyFromComponentsList}
            emailSubjectComponentsList={emailSubjectComponentsList}
            emailBodyComponentsList={emailBodyComponentsList}
            formValues={formValues}
            setFormValues={setFormValues}
            model_id={match.params.id || ''}
            elementId={elementId}
            handleEmailPreview={handleEmailPreview}
            DeleteConfirmationDialog={DeleteConfirmationDialog}
            isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
            setIsDeleteConfirmationModalOpen={setIsDeleteConfirmationModalOpen}
            emailPreview={emailPreview}
            textPlainPreview={textPlainPreview}
          />
        </Grid>
        <Grid item xs={6} md={8} xl={6}>
          <EmailDripPreview
            classes={classes}
            activeTab={activeTab}
            handleSwitchTab={handleSwitchTab}
            emailPreview={emailPreview}
            textPlainPreview={textPlainPreview}
            elementId={elementId}
          />
        </Grid>
      </Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(withDashboard(EmailDrip, false), 'Email Drip');

EmailDrip.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
