import React from 'react';
import withDashboard from '../../hoc/withDashboard/withDashboard';
import withTitle from '../../hoc/withTitle';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Image from '../../assets/images/image_broken_faucet.png';

const NotFoundPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.container}>
      <div className={classes.holder}>
        <div className={classes.image}>
          <img src={Image} alt="404" />
        </div>
        <div className={classes.text}>
          <h2>Oops</h2>
          <h4>
            Seems like the page you <span>are looking for does not exist!</span>
          </h4>
        </div>
        <div className={classes.buttonHolder}>
          <Button
            className={classes.backButton}
            onClick={handleClickBack}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTitle(withDashboard(NotFoundPage, false), '404');
