import React from 'react';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CustomSelect from '../form/customSelect';
import classNames from 'classnames';
import useStyles from './styles';
import {
  initialValues,
  actionOptions,
  fieldNameOptions,
  operatorOptions,
} from './config';
import ValueField from '../../components/form/value';
import PropTypes from 'prop-types';

const FilterRules = ({
  props,
  model_name,
  model_id,
  formValues,
  setFormValues,
}) => {

  const classes = useStyles();
  const handleActionChange = (action, i) => {
    const filter_rules = [...formValues.filter_rules];
    filter_rules[i].action = action;
    setFormValues({
      ...{ ...formValues, ...props.values },
      filter_rules,
    });
  };

  const handleRuleChange = (field, value, i) => {
    const filter_rules = [...formValues.filter_rules];
    filter_rules[i][field] = value;
    setFormValues({
      ...{ ...formValues, ...props.values },
      filter_rules,
    });
  };

  const removeFilterRule = i => {
    formValues.filter_rules.splice(i, 1);
    setFormValues({
      ...{ ...formValues, ...props.values },
      ...formValues.filter_rules,
    });
  };

  const addNewFilterRule = () => {
    setFormValues({
      ...{ ...formValues, ...props.values },
      filter_rules: [...formValues.filter_rules, {
        ...initialValues(model_id, model_name),
        action: '',
        field_name: '',
        operator: '',
        value: '',
      }],
    });
  };

  const filter_rules = props.values.filter_rules;

  return (
    <Grid item xs={12}>
      <Grid
        container
        item
        xs={12}
        className={classes.filtersContainer}
        alignItems="flex-end"
      >
        <Grid item>
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.filtersTitle}
          >
            Filter Rules
          </Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.addFilterBtn}
            startIcon={<AddIcon />}
            onClick={() => addNewFilterRule()}
          >
            Add Rule
          </Button>
        </Grid>
      </Grid>
      {filter_rules &&
        filter_rules.map((filter_rule, i) => {
          return (
            <div key={i} className={classes.filterRule}>
              <Grid
                container
                item
                xs={12}
                className={classes.filterSelectContainer}
              >
                <Grid item xs={10} container className={classes.filterSelects}>
                  <Grid item className={classes.filtersColumnTitle}>
                    Action
                  </Grid>
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.filterSelectContainer}
              >
                <Grid item xs={12} className={classes.filterSelects}>
                  <Grid item xs={11} className={classes.filterField}>
                    <CustomSelect
                      {...props}
                      placeholder="Action"
                      labelText=" "
                      name={`filter_rules[${i}].action`}
                      options={actionOptions}
                      handleChange={e => handleActionChange(e.target.value, i)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className={classes.deleteFilterButtonContainer}
                  >
                    <DeleteIcon
                      className={classNames(
                        classes.filterActionButton,
                        classes.deleteFilterButton,
                      )}
                      onClick={() => removeFilterRule(i)}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container item xs={8}>
                    <Grid item xs={6} container justify="flex-start" className={classes.deleteFilterButtonContainer}>
                      <DeleteIcon
                        className={classNames(
                          classes.filterActionButton,
                          classes.deleteFilterButton,
                        )}
                        onClick={() => removeFilterRule(i)}
                      />
                    </Grid>
                  </Grid> */}
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.filterSelectContainer}
              >
                <Grid item xs={12} container className={classes.filterSelects}>
                  <Grid item className={classes.filtersColumnTitle}>
                    Field Name
                  </Grid>
                  <Grid item className={classes.filtersColumnTitle}>
                    Operator
                  </Grid>
                  <Grid item className={classes.filtersColumnTitle}>
                    Value
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.filterSelectContainer}
              >
                <Grid item xs={12} className={classes.filterSelects}>
                  <Grid item className={classes.filterField}>
                    <CustomSelect
                      {...props}
                      placeholder="Field Name"
                      labelText=" "
                      name={`filter_rules[${i}].field_name`}
                      options={fieldNameOptions}
                      handleChange={e =>
                        handleRuleChange('field_name', e.target.value, i)
                      }
                    />
                  </Grid>
                  <Grid item className={classes.filterField}>
                    <CustomSelect
                      {...props}
                      placeholder="Operator"
                      labelText=" "
                      name={`filter_rules[${i}].operator`}
                      options={operatorOptions}
                      handleChange={e =>
                        handleRuleChange('operator', e.target.value, i)
                      }
                    />
                  </Grid>
                  <Grid item className={classes.filterField}>
                    <ValueField
                      {...props}
                      placeholder="Value"
                      labelText=" "
                      name={`filter_rules[${i}].value`}
                      handleChange={e =>
                        handleRuleChange('value', e.target.value, i)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          );
        })}
    </Grid>
  );
};

export default FilterRules;

FilterRules.propTypes = {
  model_name: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  props: PropTypes.object,
};
