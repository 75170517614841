import React, { useState, useEffect, Suspense, lazy } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory, useLocation } from 'react-router-dom';
import FlowStepIcon from '../../../assets/images/flow-step.svg';
import NameField from '../../../components/form/name';
import CustomSelect from '../../../components/form/customSelect';
import { Formik, Form, Field } from 'formik';
import DeleteButton from '../../../components/customButtons/customDelete';
import PublishButton from '../../../components/customButtons/customPublish';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from 'formik-material-ui';
import {
  getList,
  cancelList,
  getItem,
  cancelItem,
  editStart,
  removeStart,
  createStart,
} from '../../../store/actions/actions/rest';
import Loader from '../../../components/loader';
import ModelTags from '../../../components/modelTags/modelTags';
import FilterRules from '../../../components/filterRules/filterRules';
import {
  initialValues,
  populateValues,
  contain,
  scheduleTypeList,
  delayTypeList,
  generateDelayValueList,
  params,
} from './config';
import PropTypes from 'prop-types';
import { filters } from '../../drips/list/config';
import { setItemFlag } from '../../../store/actions/actions/root';
import { checkEqualityOfVerticalIds } from '../list/singleFlow/helper';

const SindleFlowStep = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const user_name = useSelector(state => state.auth.username);
  const loader = useSelector(state => state.app.loader);
  const dripsList = useSelector(state => state.drips.list);
  const messageTypesList = useSelector(state => state.messageTypes.list);
  const itemFlag = useSelector(state => state.app.itemFlag);
  const element = useSelector(
    state =>
      (state.flowSteps.list &&
        state.flowSteps.list.find(item => item.id === +match.params.id)) ||
      state.flowSteps.item,
  );
  const [formValues, setFormValues] = useState(initialValues());
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [filteredDripList, setFilteredDripList] = useState(null);
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const DeleteConfirmationDialog = lazy(() =>
    import('../../../components/deleteConfirmationModal'),
  );
  const NotesModal = lazy(() => import('../../../components/notes/notes'));

  useEffect(() => {
    if (itemFlag) {
      history.push('/404');
      dispatch(setItemFlag(false));
    }
  }, [itemFlag, dispatch, history]);

  useEffect(() => {
    if (
      match.params.id &&
      !element &&
      vertical_id &&
      checkEqualityOfVerticalIds(vertical_id, location)
    ) {
      dispatch(
        getItem(
          'flow-steps',
          {
            id: match.params.id,
            contain,
            filters: filters(vertical_id),
          },
          dispatch,
        ),
      );
      return () => {
        dispatch(cancelItem('flow-steps', null, dispatch));
      };
    }
  }, [element, match.params.id, dispatch, vertical_id, location]);

  useEffect(() => {
    if (vertical_id && checkEqualityOfVerticalIds(vertical_id, location)) {
      dispatch(cancelList('flow-steps', null, dispatch));
      dispatch(cancelList('drips', null, dispatch));
      dispatch(cancelList('message-types', null, dispatch));
      dispatch(
        getList(
          'flow-steps',
          { contain, filters: filters(vertical_id) },
          dispatch,
        ),
      );
      dispatch(
        getList('drips', { params, filters: filters(vertical_id) }, dispatch),
      );
      dispatch(getList('message-types', null, dispatch));
      return () => {
        dispatch(cancelList('flow-steps', null, dispatch));
        dispatch(cancelList('drips', null, dispatch));
        dispatch(cancelList('message-types', null, dispatch));
      };
    }
  }, [dispatch, vertical_id, location]);

  const getDripType = element => {
    let type = messageTypesList.find(
      messageType => messageType.id === element.drip.message_type_id,
    );
    return type.id;
  };

  useEffect(() => {
    if (
      element &&
      element.drip &&
      element.drip.message_type_id &&
      messageTypesList &&
      messageTypesList.length > 0
    ) {
      let pickedElement = { ...element };
      let type = getDripType(element);
      pickedElement.drip_type = type;
      setFormValues(populateValues(pickedElement));
    }
    // eslint-disable-next-line
  }, [element, messageTypesList]);

  useEffect(() => {
    if (
      formValues.drip_id &&
      dripsList &&
      dripsList.length > 0 &&
      messageTypesList &&
      messageTypesList.length > 0
    ) {
      let drip = dripsList.find(drip => drip.id === formValues.drip_id);
      if (drip) {
        let selecetedType = messageTypesList.find(
          messageType => messageType.id === drip.message_type_id,
        );
        setFormValues({
          ...formValues,
          drip_type: `${selecetedType.id}`,
        });
        filterDrips(selecetedType.id);
      }
    }
    // eslint-disable-next-line
  }, [dripsList, formValues.drip_id, messageTypesList]);

  const handleDelete = notes => {
    const data = {
      body: {
        id: formValues.id,
        notes,
      },
    };
    dispatch(removeStart('flow-steps', data, dispatch));
    history.push({
      pathname: `/flows/steps`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const onSubmit = values => {
    if (match.params.id) {
      handleOpenNotesModal();
    } else {
      let allValues = populateValues(values);
      let data = {
        body: {
          ...allValues,
          account_id,
          vertical_id,
          notes: [
            {
              account_id,
              vertical_id,
              model_name: 'FlowStep',
              model_id: '',
              user_name,
              note: `Created ${values && values.name ? values.name : ""}`,
            },
          ],
        },
      };
      delete data.body.drip_type;
      data.body.is_async = data.body.is_async ? 1 : 0;
      dispatch(createStart('flow-steps', data, dispatch));
      history.push({
        pathname: `/flows/steps`,
        search: `vertical_id=${vertical_id}`,
      });
    }
  };

  const submitForm = (values, notes) => {
    let allValues = populateValues(values);
    let data = {
      body: {
        ...allValues,
        account_id,
        vertical_id,
        notes,
      },
    };
    delete data.body.drip_type;
    data.body.is_async = data.body.is_async ? 1 : 0;
    dispatch(editStart('flow-steps', data, dispatch));
    history.push({
      pathname: `/flows/steps`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleDeleteModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleOpenNotesModal = () => {
    setNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setNotesModalOpen(false);
  };

  const filterDrips = type => {
    let drips = dripsList.filter(drip => drip.message_type_id === +type);
    if (drips.length > 0) {
      setFilteredDripList(drips);
    } else {
      setFilteredDripList(null);
    }
  };

  const handleDripType = event => {
    let type = event.target.value;
    filterDrips(type);
  };

  const renderDeleteConfirmationDialog = isDeleteConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <DeleteConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        handleDelete={handleDelete}
        handleClose={handleDeleteModalClose}
        itemName={'flow step'}
        model_id={+match.params.id || ''}
        model_name="FlowStep"
      />
    </Suspense>
  ) : null;

  const renderNotesModal = props =>
    notesModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={notesModalOpen} />
          </Grid>
        }
      >
        <NotesModal
          props={props}
          classes={classes}
          model_name="FlowStep"
          model_id={+match.params.id || ''}
          open={notesModalOpen}
          handleClose={handleCloseNotesModal}
          submitForm={submitForm}
        />
      </Suspense>
    ) : null;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} container alignItems="flex-end">
        <Grid item xs={1} className={classes.iconContainer}>
          <img
            src={FlowStepIcon}
            alt="Flows icon"
            className={classes.titleIcon}
          />
        </Grid>
        <Grid container item xs={6} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              {match.params.id ? 'Edit ' : 'New '}Flow Step
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          onSubmit={values => {
            onSubmit(values);
          }}
        >
          {props => (
            <Form className={classes.formWrapper}>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <NameField
                    {...props}
                    placeholder="Flow Step Name"
                    labelText="Flow Step Name"
                    name="name"
                    wrapperClass={classes.textField}
                    type="flowSteps"
                    flowStep={true}
                    id={match.params.id}
                  />
                  <CustomSelect
                    {...props}
                    placeholder="Drip Type"
                    labelText="Drip Type"
                    name="drip_type"
                    wrapperClass={classes.textField}
                    customHandleChange={handleDripType}
                    options={messageTypesList}
                  />
                  <CustomSelect
                    {...props}
                    placeholder="Drip Name"
                    labelText="Drip Name"
                    name="drip_id"
                    wrapperClass={classes.textField}
                    options={filteredDripList}
                  />
                  <Grid item xs={12} container className={classes.field}>
                    <Grid item xs={4} className={classes.dripValue}>
                      <CustomSelect
                        {...props}
                        placeholder="Delay Value"
                        labelText="Delay Value"
                        name="delay_in_time"
                        wrapperClass={classes.textField}
                        options={generateDelayValueList()}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <CustomSelect
                        {...props}
                        placeholder="Delay Increment"
                        labelText="Delay Increment"
                        name="delay_type"
                        wrapperClass={classes.textField}
                        options={delayTypeList}
                      />
                    </Grid>
                  </Grid>
                  <CustomSelect
                    {...props}
                    placeholder="Outside Schedule Behavior"
                    labelText="Outside Schedule Behavior"
                    name="schedule_type"
                    wrapperClass={classes.textField}
                    options={scheduleTypeList}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        component={Switch}
                        type="checkbox"
                        color="primary"
                        name="is_async"
                        disabled={false}
                      />
                    }
                    label="Async"
                  />
                  <Grid item xs={12} className={classes.actionButtons}>
                    {match.params.id && (
                      <DeleteButton onClick={handleDeleteModalOpen} />
                    )}
                    <PublishButton type="submit" />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <ModelTags
                    props={props}
                    classes={classes}
                    model_name="FlowStep"
                    model_id={match.params.id || ''}
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
                  <FilterRules
                    props={props}
                    classes={classes}
                    model_name="FlowStep"
                    model_id={+match.params.id || ''}
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
                </Grid>
              </Grid>
              {renderDeleteConfirmationDialog}
              {renderNotesModal(props)}
            </Form>
          )}
        </Formik>
      </Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(withDashboard(SindleFlowStep, false), 'Flow Steps');

SindleFlowStep.propTypes = {
  match: PropTypes.object.isRequired,
};
