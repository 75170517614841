import React from 'react';

const Log = ({ classes, user, events }) => {
  const renderEvents =
    events && events.length > 0
      ? events.map(event => <div className={classes.log} key={event}>{event}</div>)
      : null;

  return (
    <div className={classes.singleLog}>
      <div className={classes.logContent}>
        <div>
          <span>{user}</span>
        </div>
        <div>{renderEvents}</div>
      </div>
    </div>
  );
};

export default Log;
