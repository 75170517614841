import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CustomSelect from '../form/customSelect';
import DeleteIcon from '@material-ui/icons/Delete';

const AccountsPermissions = ({ classes, formik, values, setValues }) => {
  const handleNameChange = name => {};

  const handleDeleteAccountPair = index => () => {
    values.accountsPermissions.splice(index, 1);
    setValues({
      ...{ ...values },
      accountsPermissions: values.accountsPermissions,
    });
  };

  const handleAddUserPermission = () => {
    setValues({
      ...{ ...values },
      accountsPermissions: [
        ...values.accountsPermissions,
        {
          account: {
            name: '',
            role: '',
          },
        },
      ],
    });
  };

  const renderForms = () => {
    if (
      values &&
      values.accountsPermissions &&
      values.accountsPermissions.length > 0
    ) {
      return values.accountsPermissions.map((_item, i) => (
        <div className={classes.smallAcountsForm} key={i}>
          <div className={classes.accountSelect}>
            <CustomSelect
              {...formik}
              placeholder="Account"
              labelText="Account"
              name={`accountsPermissions[${i}].account.name`}
              wrapperClass={classes.accountsSelectField}
              options={[]}
              handleChange={e => handleNameChange(e.target.value)}
            />
            <Button
              onClick={handleDeleteAccountPair(i)}
              color="secondary"
              className={classes.deleteAccountBtn}
            >
              <DeleteIcon />
            </Button>
          </div>
          <CustomSelect
            {...formik}
            placeholder="Role"
            labelText="Role"
            name={`accountsPermissions[${i}].account.role`}
            wrapperClass={classes.accountsSelectField}
            options={[]}
            handleChange={e => handleNameChange(e.target.value)}
          />
        </div>
      ));
    }
  };

  return (
    <div>
      <p className={classes.accountsTitle}>Accounts Permissions</p>
      {renderForms()}
      <div className={classes.accountPermissionActionContainer}>
        <Button
          variant="contained"
          onClick={handleAddUserPermission}
          color="primary"
          className={classes.addAccountPermissionBtn}
          startIcon={<AddIcon />}
        >
          Add permission
        </Button>
      </div>
    </div>
  );
};

export default AccountsPermissions;

AccountsPermissions.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
};
