import { getModelTags } from '../../../config/helpers';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import React from 'react';
import {
  checkUsedDays,
  generateUsedDays,
} from './singleFlow/hoursOfOperation/helper';
import { formatToLocalDate } from '../../../config/helpers';

export const contain = ['model_tags.Tags', 'flow_step_transitions'];

export const columns = [
  { id: 'name', label: 'Flow Name', minWidth: 60, align: 'left' },
  { id: 'id', label: 'Flow ID', minWidth: 60, align: 'left' },
  { id: 'status', label: 'Status', minWidth: 60, align: 'left' },
  {
    id: 'created',
    label: 'Created',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'modified',
    label: 'Modified',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 150,
    align: 'center',
  },
];

export const filters = [
  {
    field: 'vertical_id',
    operator: '=',
    path: 'vertical_id',
  },
  {
    field: 'account_id',
    operator: '=',
    path: 'account_id',
  },
];

export const generateTransitionAdditionalFilter = id =>
  JSON.stringify({
    field: 'to_flow_id',
    operator: '!=',
    value: id,
  });

const createData = (name, id, status, created, modified, actions, item) => {
  return {
    name,
    id,
    status,
    created,
    modified,
    actions,
    item,
  };
};

const statuses = {
  1: 'Draft',
  2: 'Live',
  3: 'Paused',
  4: 'Stopped',
};

const getStatus = statusId => statuses[+statusId];

export const renderElements = (list, setRows) => {
  let row = [];
  list &&
    list.forEach(item => {
      const { name, id, status, created, modified } = item;
      !item.archived &&
        row.push(
          createData(
            name,
            id,
            getStatus(status),
            formatToLocalDate(created, 'MMM, dd yyyy'),
            formatToLocalDate(modified, 'MMM, dd yyyy'),
            '',
            item,
          ),
        );
    });
  setRows(row);
};

export const sortBy = {
  name: 'asc',
  id: 'asc',
  status: 'asc',
  created: 'asc',
  modified: 'desc',
};

export const initialValues = {
  id: '',
  name: '',
  days_times: '',
  status: 1,
  parameters: '',
  list_database_enabled: false,
  model_tags: [],
  notes: [],
  steps: [],
};

export const populateValues = (values, copy) => {
  const {
    id,
    name,
    days_times,
    status,
    list_database_enabled,
    model_tags,
    notes,
    parameters,
    steps,
  } = values;
  return {
    id: copy ? '' : id,
    name: copy ? `Copy of ${name}` : name,
    days_times,
    list_database_enabled: list_database_enabled ? list_database_enabled : false,
    status: copy ? 1 : status,
    parameters: parameters ? parameters : '',
    model_tags: getModelTags(model_tags, copy),
    notes: notes || [],
    steps: steps || [],
  };
};

export const params = {
  direction: 'asc',
  sort: 'name',
  page: 1,
  limit: 500,
  search: '',
};

const populateFlowStepMap = (step, flow_id, index, vertical_id) => {
  let singleStep = {
    position: index + 1,
    flow_step_id: step.id,
    flow_id: flow_id,
    vertical_id: vertical_id,
  };
  if (step.flowStepMap_id) {
    singleStep.id = step.flowStepMap_id;
  }
  return singleStep;
};

const populateFlowStepTransitionMap = (
  transition,
  flow_id,
  step,
  vertical_id,
  index,
) => {
  let singleTransition = {
    flow_step_transition_id: +transition.id,
    vertical_id: vertical_id,
    position: index + 1,
  };
  if (transition.fstm) {
    singleTransition.from_flow_step_id = +transition.fstm.from_flow_step_id;
    singleTransition.from_flow_id = transition.fstm.from_flow_id;
    singleTransition.from_flow_step_map_id =
      transition.fstm.from_flow_step_map_id;
  } else {
    singleTransition.from_flow_step_id = +step.id;
    singleTransition.from_flow_id = flow_id;
  }
  if (step.flowStepMap_id) {
    singleTransition.from_flow_step_map_id = step.flowStepMap_id;
  }
  if (transition.flowStepTransitionMap_id) {
    singleTransition.id = transition.flowStepTransitionMap_id;
  }
  return singleTransition;
};

export const populateFSMandFSTMValues = (values, vertical_id) => {
  if (values && values.steps) {
    const { steps } = values;
    let allFlowSteps = [];
    let allFlowStepTransitions = [];
    if (steps && steps.length > 0) {
      steps.forEach((step, index) => {
        allFlowSteps.push(
          populateFlowStepMap(step, values.id, index, vertical_id),
        );
        if (step.transitions && step.transitions.length > 0) {
          step.transitions.forEach(transition => {
            allFlowStepTransitions.push(
              populateFlowStepTransitionMap(
                transition,
                values.id,
                step,
                vertical_id,
                index,
              ),
            );
          });
        }
      });
    }
    return {
      FlowStepMaps: [...allFlowSteps],
      FlowStepTransitionMaps: [...allFlowStepTransitions],
    };
  }
};

export const renderFlowStatus = (status, classes) => {
  if (+status === 2) {
    return (
      <div className={classes.flowStatus}>
        Published <LockIcon />
      </div>
    );
  } else if (+status === 3) {
    return (
      <div className={classes.flowStatus}>
        Published <LockOpenIcon />
      </div>
    );
  } else if (+status === 4) {
    return (
      <div className={classes.flowStatus}>
        Stopped <LockIcon />
      </div>
    );
  } else {
    return <div className={classes.flowStatus}>Draft</div>;
  }
};

export const checkIfTheFlowIsLocked = status => {
  let isLocked = false;
  if (+status === 2 || +status === 4) {
    isLocked = true;
  }
  return isLocked;
};

export const isStopStatus = status => (+status === 4 ? true : false);

export const convertCells = cells => {
  let days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  let usedDaysIds = checkUsedDays(cells);
  let convertedDayHours = {};
  usedDaysIds.forEach(day => {
    convertedDayHours[days[day]] = generateUsedDays(cells[day]);
  });
  return convertedDayHours;
};

export const compareInitialValuesAndCurrentValues = (initial, current) => {
  let objectsAreNotSame = false;
  if (initial && current) {
    if (initial.name !== current.name) objectsAreNotSame = true;
    if (initial.parameters !== current.parameters) objectsAreNotSame = true;
    if (+initial.status !== +current.status) objectsAreNotSame = true;
  }
  return objectsAreNotSame;
};
