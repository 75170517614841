import React from 'react';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import useStyles from './styles';
import PropTypes from 'prop-types';

const TemplateTabs = ({ activeTab, handleSwitchTab }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.templateTabs}>
      <Grid
        item
        className={classNames(classes.tab, {
          [classes.activeTab]: activeTab['emailTemplate'],
        })}
        onClick={handleSwitchTab('emailTemplate')}
      >
        Desktop preview
      </Grid>
      <Grid
        item
        className={classNames(classes.tab, {
          [classes.activeTab]: activeTab['mobileTemplate'],
        })}
        onClick={handleSwitchTab('mobileTemplate')}
      >
        Mobile preview
      </Grid>
      <Grid
        item
        className={classNames(classes.tab, {
          [classes.activeTab]: activeTab['plainText'],
        })}
        onClick={handleSwitchTab('plainText')}
      >
        plain text
      </Grid>
    </Grid>
  );
};

export default TemplateTabs;

TemplateTabs.propTypes = {
  activeTab: PropTypes.object.isRequired,
  handleSwitchTab: PropTypes.func.isRequired,
};
