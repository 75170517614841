import React from 'react';
import NameField from '../form/name';
import AliasField from '../form/alias';
import ParametersField from '../form/parameters';
import DescriptionField from '../form/description';
import CptChannelId from '../form/cptChannelId';
import ValueField from '../form/value';
import UrlField from '../form/url';
import SuccessMessageField from '../form/successMessage';
import FieldListField from '../form/fieldList';
import EncodingTypesField from '../form/encodingTypes';
import PostTypesFields from '../form/postTypes';
import SmsPhoneNumber from '../form/smsPhone';
import ToEmailField from '../form/toEmail';
import EmailField from '../form/email';
import SubjectField from '../form/subject';
import CallbackUrl from '../form/callbackUrl';
import Checkbox from '../form/checkbox';

const labels = {
  name: 'Name',
  parameters: 'Parameters',
  value: 'Value',
  alias: 'Alias',
  description: 'Description',
  cpt_channel_id: 'CPT Channel ID',
  post_url: 'URL',
  post_response: 'Success Message',
  post_fields: 'Field List (Key Value Pairs Separated by Equals Sign)',
  post_encoding: 'Post Encoding',
  post_type: 'Post Types',
  sms_to: 'Phone Number',
  to_email: 'Email Address',
  email: 'Email Address',
  subject: 'Subject',
  callback_url: "Callback URL"
};

const fields = {
  name: props => <NameField {...props} />,
  parameters: props => <ParametersField {...props} />,
  value: props => <ValueField {...props} />,
  alias: props => <AliasField {...props} />,
  description: props => <DescriptionField {...props} />,
  cpt_channel_id: props => <CptChannelId {...props} />,
  post_url: props => <UrlField {...props} />,
  post_response: props => <SuccessMessageField {...props} />,
  post_fields: props => <FieldListField {...props} />,
  post_encoding: props => <EncodingTypesField {...props} />,
  post_type: props => <PostTypesFields {...props} />,
  sms_to: props => <SmsPhoneNumber {...props} />,
  to_email: props => <ToEmailField {...props} />,
  email: props => <EmailField {...props} />,
  subject: props => <SubjectField {...props} />,
  callback_url: props => <CallbackUrl {...props} />,
  list_database_enabled: props => <Checkbox {...props} labelText="List Database Flow" />,
};

const generateField = (
  props,
  field,
  classes,
  encodingIsVisible,
  handlePostTypeField,
  setEditNewFlow,
  dripComponent,
  id,
) => {
  const Field = props => fields[field](props);
  return Field({
    ...props,
    key: field,
    name: field,
    wrapperClass: classes.textField,
    placeholder: labels[field],
    labelText: labels[field],
    required: ['parameters'].includes(field),
    disabled: ['post_encoding'].includes(field) && encodingIsVisible,
    customHandleChange: ['post_type'].includes(field) && handlePostTypeField,
    type:
      ['name'].includes(field) &&
      typeof setEditNewFlow === 'function' &&
      'flows',
    dripComponent,
    id,
    classes,
  });
};

export const renderFields = (
  props,
  classes,
  fields,
  encodingIsVisible,
  handlePostTypeField,
  setEditNewFlow,
  dripComponent,
  id,
) =>
  fields.map(field =>
    generateField(
      props,
      field,
      classes,
      encodingIsVisible,
      handlePostTypeField,
      setEditNewFlow,
      dripComponent,
      id,
    ),
  );
