import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const Value = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
}) => {
  const validate = values => {
    if (!values) {
      return 'Required';
    }
  };

  return (
    <div className={wrapperClass}>
      {hideLabel ? null : <label htmlFor="value">{labelText || 'Value'}</label>}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'value'] && touched[name || 'value']
            ? errorClass || 'has-error'
            : ''
        }`}
        name={name || 'value'}
        placeholder={placeholder || 'value'}
        type="text"
        validate={validate}
        onChange={e => {
          handleChange(e);
        }}
      />
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'value'}
          component="div"
          className="field-error"
          data-testid="name-error"
        />
      )}
    </div>
  );
};

export default Value;

Value.propTypes = {
  hideLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  wrapperClass: PropTypes.string,
};
