import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/loader';
import Table from './flowsListTable';
import Search from '../../../components/search';
import FlowsIcon from '../../../assets/images/flows-dashboard-icon.svg';
import { useHistory } from 'react-router-dom';
import NewFlowModal from '../../../components/modal/modal';
import { initialValues, populateValues } from './config';
import {
  clearNewFlow,
} from '../../../store/actions/actions/rest';

const FlowsList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loader = useSelector(state => state.app.loader);
  const newFlow = useSelector(state => state.flows.newFlow);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const [editNewFlow, setEditNewFlow] = useState(false);
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClickName = item => () => {
    const id = item.id;
    history.push({
      pathname: `/flows/flow/edit/${id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleClickNewButton = () => {
    handleOpenModal();
  };

  useEffect(() => {
    if (newFlow && editNewFlow) {
      history.push({
        pathname: `/flows/flow/edit/${newFlow.id}`,
        search: `vertical_id=${vertical_id}`,
      });
      setEditNewFlow(false);
    } else if (newFlow) {
      dispatch(clearNewFlow());
    }
    // eslint-disable-next-line
  }, [newFlow, editNewFlow, dispatch, history]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} container alignItems="flex-end">
        <Grid item xs={1} className={classes.iconContainer}>
          <img src={FlowsIcon} alt="Flows icon" className={classes.titleIcon} />
        </Grid>
        <Grid container item xs={10} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Flows
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.subtitle}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={6}>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item className={classes.addButton}>
            <Button onClick={handleClickNewButton} startIcon={<AddIcon />}>
              New Flow
            </Button>
          </Grid>
          <Grid item className={classes.searchContainer}>
            <Search type="Flow" setSearch={setSearch} classes={classes} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <Grid item xs={12}>
          <Table
            type="flows"
            search={search}
            classes={classes}
            setParameters={false}
            handleClickName={handleClickName}
            model_name="Flow"
            size="small"
          />
        </Grid>
        {Boolean(loader) && <Loader open={Boolean(loader)} />}
      </Grid>
      <NewFlowModal
        open={isModalOpen}
        fields={['name', 'list_database_enabled']}
        title="Flow"
        handleClose={handleCloseModal}
        element={initialValues}
        parameters={false}
        path="flows"
        populateValues={values => populateValues(values, false)}
        model_name="Flow"
        setEditNewFlow={setEditNewFlow}
        clearUniqueObject
      />
    </Grid>
  );
};

export default withTitle(withDashboard(FlowsList, false), 'Flows');
