import React from 'react';
import { Formik, Form } from 'formik';
import CustomSelect from '../../../components/form/customSelect';
import SubmitButton from '../../../components/customButtons/customSubmit';
import PropTypes from 'prop-types';
import Suppressions from '../../../components/form/suppressions';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

const TYPES = [
  {
    id: 'sms',
    name: 'SMS',
  },
  {
    id: 'phone',
    name: 'Phone',
  },
  {
    id: 'email',
    name: 'Email',
  },
  {
    id: 'oep',
    name: 'OEP',
  },
];

const SuppressionsListForm = ({
  formValues,
  handleSubmit,
  classes,
  handleChangeType,
  currentType,
}) => (
  <Formik
    initialValues={formValues}
    enableReinitialize={true}
    onSubmit={(values, { resetForm}) => {
      handleSubmit(values);
      resetForm({values: ''})
    }}
  >
    {props => (
      <Form className={classes.formContainer}>
        <Grid container justify="flex-end">
          <Grid item xs={12}>
            <CustomSelect
              {...props}
              placeholder="Type"
              labelText="Type"
              name="type"
              wrapperClass={classes.textField}
              options={TYPES}
              customHandleChange={handleChangeType}
            />
          </Grid>
          <Grid item xs={12} className={classes.suppressionField}>
            <Suppressions
              {...props}
              placeholder="Suppressions"
              labelText="Suppressions"
              name="suppressions"
              wrapperClass={classes.textField}
              currentType={currentType}
            />
            <Tooltip
              title={
                'Use a comma between numbers or Email addresses, and skip the comma at the end of the list.'
              }
              placement="top"
              arrow
            >
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <SubmitButton type="submit" />
          </Grid>
        </Grid>
      </Form>
    )}
  </Formik>
);

export default SuppressionsListForm;

SuppressionsListForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleChangeType: PropTypes.func.isRequired,
  currentType: PropTypes.string.isRequired,
};
