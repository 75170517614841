import React from 'react';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    fontSize: 20,
    width: 155,
    borderRadius: 5,
    height: 60,
    fontWeight: 900,
    '& > span > span > svg': {
      fontSize: '28px !important',
    },
  },
}));

const CustomDelete = props => {
  const classes = useStyles();

  return (
    <Button
      className={classes.deleteButton}
      variant="contained"
      color="secondary"
      {...props}
      startIcon={<DeleteForeverIcon />}
    >
      Delete
    </Button>
  );
};

export default CustomDelete;
