import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 999,
    color: theme.palette.blue[300],
  },
}));

const Loader = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;

Loader.propTypes = {
  open: PropTypes.bool.isRequired,
};
