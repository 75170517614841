import React from 'react';
import withDashboard from '../../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../../hoc/withTitle';
import RvmDripForm from './rvmDripForm';
import * as config from './config';
import SingleDrip from '../singleDrip/singleDrip';
import PropTypes from 'prop-types';

const RvmDrip = ({ location }) => {
  return (
    <SingleDrip
      name="RVM Drip"
      urlPath="rvm-drip"
      messageType="send_rvm"
      childComponents={['rvm-components']}
      location={location}
      config={config}
      Form={RvmDripForm}
    />
  );
};

export default withTitle(withDashboard(RvmDrip, false), 'RvmDrip');

RvmDrip.propTypes = {
  location: PropTypes.object.isRequired,
};
