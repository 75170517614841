import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import FaucetScreenShot from '../assets/images/faucet-screenshot.png';

const TitleComponent = ({ title }) => {
  var defaultTitle = 'Faucet';
  return (
    <Helmet>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <meta name="description" content="Faucet: Emails can't send themselves" />
      <meta property="og:image" content={FaucetScreenShot} />
    </Helmet>
  );
};

TitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
};

const withTitle = (Component, title) => props => {
  return (
    <Fragment>
      <TitleComponent title={title} />
      <Component {...props} />
    </Fragment>
  );
};

export default withTitle;

withTitle.propTypes = {
  elementType: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
};
