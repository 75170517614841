import { get } from '../../../config/api';
import { mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { saveListReporting } from '../../actions/actions/reporting';
import { displaySnackbar } from '../../actions/actions/root';
import { getAuth, authRejected } from '../../actions/actions/auth';
import { GET_LIST } from '../../actions/actionTypes/reporting';
import { of, merge } from 'rxjs';
import { consoleError } from '../../../config/helpers';
import { hideLoader } from '../../actions/actions/root';
import { handleErrorMessage } from './auth';

const auth = action$ => {
  return action$.pipe(
    mergeMap(action => of(getAuth(action))),
    mergeMap(({ payload }) =>
      payload.auth
        .then(data => ({
          token: data.signInUserSession.accessToken.jwtToken,
          params: payload.data.payload,
        }))
        .catch(() => window.location.reload()),
    ),
    catchError(error => {
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          authRejected(error),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
      );
    }),
  );
};

export const getListEpic = type => (action$, state$) => {
  return action$.pipe(
    ofType(GET_LIST(type).actionTypes.START),
    action$ => auth(action$),
    mergeMap(({ params, token }) =>
      get(
        `reporting-events/${type}`,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        params,
      ).pipe(
        mergeMap(data => [
          saveListReporting(type, data.response),
          hideLoader(),
        ]),
        catchError(error => {
          consoleError(error);
          let errorMessage = error.xhr.response;
          let message = errorMessage
            ? errorMessage.Error
            : 'Service temporarily unavailable.';
          return merge(
            of(
              hideLoader(),
              displaySnackbar({
                type: 'error',
                message,
              }),
            ),
          );
        }),
        takeUntil(action$.pipe(ofType(GET_LIST(type).actionTypes.FAILURE))),
      ),
    ),
  );
};
