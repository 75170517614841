import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  deletedTransitionLabelContainer: {
    paddingLeft: 55,
    marginBottom: 31,
  },
  deletedTransitionLabel: {
    width: '100%',
    background: '#C4C4C4',
    borderRadius: '3px 3px 0px 0px',
    color: theme.palette.text[100],
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 900,
    padding: '5px 14px',
  },
}));

const DeletedTransitionLabel = ({ date }) => {
  const classes = useStyles();
  return (
    <div className={classes.deletedTransitionLabelContainer}>
      <div className={classes.deletedTransitionLabel}>Deleted: {date}</div>
    </div>
  );
};

export default DeletedTransitionLabel;
