import { GET_CHANGE_LOGS, CLEAR_CHANGE_LOGS } from '../actionTypes/changeLogs';
import { hideLoader, displayLoader } from './root';

export const getChangeLogs = (payload, dispatch) => {
  if (dispatch) {
    dispatch(displayLoader());
  }
  return GET_CHANGE_LOGS.actionCreators.start(false, payload);
};

  export const clearChangeLogs = (payload, dispatch) => {
    if (dispatch) {
      dispatch(hideLoader());
    }
    return CLEAR_CHANGE_LOGS.actionCreators.success(false, payload);
  };

export const saveChangeLogs = (payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return GET_CHANGE_LOGS.actionCreators.success(false, payload);
};

export const cancelChangeLogs = (payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return GET_CHANGE_LOGS.actionCreators.failure(false, payload);
};
