import React, { useState, lazy, Suspense } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Log from './log';
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import Loader from '../../components/loader';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

const LogsTimeline = ({ classes, loader }) => {
  const changeLogsList = useSelector(state => state.changeLogs.list);
  const [clickedNote, setClickedNote] = useState(null);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const NoteModal = lazy(() => import('../notes/noteModal'));

  const handleOpenNoteModal = note => () => {
    setIsNoteModalOpen(true);
    setClickedNote(note);
  };

  const handleCloseNoteModal = () => {
    setIsNoteModalOpen(false);
    setClickedNote(null);
  };

  const renderNotesModal = isNoteModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <NoteModal
        open={isNoteModalOpen}
        handleClose={handleCloseNoteModal}
        item={clickedNote}
      />
    </Suspense>
  ) : null;

  const formatToLocalDate = dateString => {
    let date = new Date(`${dateString}.000000Z`);
    return format(new Date(date.toString()), 'LLL dd, yyyy - hh:mm:ss aaa');
  };

  const renderTimelineItem = () => {
    if (changeLogsList && changeLogsList.length > 0) {
      return changeLogsList.map(log => (
        <TimelineItem key={log.date}>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className={classes.timelineContent}>
            <div className={classes.logHeader}>
              <div className={classes.logDate}>
                {formatToLocalDate(log && log.date)}
              </div>
              <div className={classes.notesTitle}>
                <span>Notes</span>{' '}
                <IconButton
                  className={classes.notesIcon}
                  onClick={handleOpenNoteModal(log.note)}
                >
                  <DescriptionIcon />
                </IconButton>
              </div>
            </div>
            <Log
              key={log.date}
              events={log.events}
              classes={classes}
              user={log.user}
            />
          </TimelineContent>
        </TimelineItem>
      ));
    } else {
      return <div className={classes.noLogs}>No changes were made.</div>;
    }
  };

  return (
    <Timeline className={classes.timelineContainer}>
      {renderTimelineItem()}
      {renderNotesModal}
    </Timeline>
  );
};

export default LogsTimeline;
