import { formatToLocalDate } from '../../../config/helpers';
import { getModelTags } from '../../../config/helpers';

export const contain = ['model_tags.Tags', 'webhook_actions', 'flows'];

export const columns = [
  { id: 'name', label: 'Name', minWidth: 200, align: 'left' },
  {
    id: 'webhook_action_id',
    label: 'Webhook Action',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'to_flow_id',
    label: 'Go To Flow',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'created',
    label: 'Created',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'modified',
    label: 'Modified',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 50,
    align: 'center',
  },
];

export const filters = [
  {
    field: 'vertical_id',
    operator: '=',
    path: 'vertical_id',
  },
  {
    field: 'account_id',
    operator: '=',
    path: 'account_id',
  },
];

const createData = (
  name,
  webhook_action_id,
  to_flow_id,
  created,
  modified,
  actions,
  item,
) => {
  return {
    name,
    webhook_action_id,
    to_flow_id,
    created,
    modified,
    actions,
    item,
  };
};

export const renderElements = (list, setRows) => {
  let row = [];
  list &&
    list.forEach(item => {
      const { name, created, modified } = item;
      const webhook_action_id = item.webhook_action && item.webhook_action.name;
      const to_flow_id = item.flow && item.flow.name;
      !item.archived &&
        row.push(
          createData(
            name,
            webhook_action_id,
            to_flow_id,
            formatToLocalDate(created, 'MMM, dd yyyy'),
            formatToLocalDate(modified, 'MMM, dd yyyy'),
            '',
            item,
          ),
        );
    });
  setRows(row);
};

export const sortBy = {
  name: 'asc',
  webhook_action_id: 'asc',
  to_flow_id: 'asc',
  created: 'asc',
  modified: 'desc',
};

export const initialValues = () => ({
  id: '',
  name: '',
  webhook_action_id: '',
  to_flow_id: '',
  model_tags: [],
  notes: [],
});

export const populateValues = (values, copy) => {
  const { id, name, webhook_action_id, to_flow_id, model_tags, notes } = values;
  return {
    id: copy ? '' : id,
    name: copy ? `Copy of ${name}` : name,
    webhook_action_id,
    to_flow_id,
    model_tags: getModelTags(model_tags, copy),
    notes: notes || [],
  };
};

export const params = {
  direction: 'asc',
  sort: 'name',
  page: 1,
  limit: 500,
  search: '',
};

export const filterFlowsForUniqueElements = (array1, array2) => {
  let newArray = [];
  array1.forEach(item1 => {
    if (!array2.find(item2 => item1.id === item2.from_flow_id)) {
      newArray.push(item1);
    }
  });
  return newArray;
};
