import React, { useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const Suppressions = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
  currentType,
  validateForm,
}) => {
  useEffect(() => {
    if (currentType) {
      validateForm();
    }
  }, [currentType, validateForm]);

  const trimFieldValues = values => {
    const trimedValues = [];
    const allValues = [...values];
    allValues.forEach(value => trimedValues.push(value.trim()));
    return trimedValues;
  };

  const duplicates = list =>
    list.reduce(
      (acc, v, i, arr) =>
        arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc,
      [],
    );

  const checkDuplicates = list => {
    let allItems = trimFieldValues(list);
    let duplicateItems = '';
    let allDuplicates = duplicates(allItems);
    if (allDuplicates && allDuplicates.length > 0) {
      duplicateItems = allDuplicates.join();
    }
    return duplicateItems;
  };

  const validateEmail = email => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  const validateEmailList = list => {
    let errorMessage = '';
    let duplicates = '';
    let wrongEmails = '';
    if (list) {
      let fieldValues = list.split(',');
      if (fieldValues && fieldValues.length > 0) {
        let trimedList = trimFieldValues(fieldValues);
        duplicates = checkDuplicates(trimedList);
        trimedList.forEach(email => {
          if (!validateEmail(email)) {
            errorMessage = 'Wrong email format.';
            wrongEmails = wrongEmails ? `${wrongEmails}, ${email}` : `${email}`;
          }
        });
      }
    }
    return [wrongEmails, errorMessage, duplicates];
  };

  const validateNumberList = list => {
    let errorMessage = '';
    let duplicates = '';
    let wrongNumbers = '';
    if (list) {
      let fieldValues = list.split(',');
      if (fieldValues && fieldValues.length > 0) {
        let trimedList = trimFieldValues(fieldValues);
        duplicates = checkDuplicates(trimedList);
        trimedList.forEach(number => {
          if (/^\d+$/.test(number)) {
            if (number.length !== 10) {
              errorMessage =
                'Wrong format. The phone number must contain 10 digits.';
              wrongNumbers = wrongNumbers
                ? `${wrongNumbers}, ${number}`
                : `${number}`;
            }
          } else {
            errorMessage =
              'Wrong format. The phone number must contain 10 digits.';
            wrongNumbers = wrongNumbers
              ? `${wrongNumbers}, ${number}`
              : `${number}`;
          }
        });
      }
    }
    return [wrongNumbers, errorMessage, duplicates];
  };

  const validate = values => {
    if (!values) {
      return 'Required';
    } else if (values) {
      if (
        currentType === 'sms' ||
        currentType === 'phone' ||
        currentType === 'oep'
      ) {
        let [wrongNumbers, errorMessage, duplicates] = validateNumberList(
          values,
        );
        if (wrongNumbers) {
          return `Invalid numbers format: ${wrongNumbers}`;
        } else if (duplicates) {
          return `Duplicates numbers: ${duplicates}`;
        } else {
          return errorMessage;
        }
      } else if (currentType === 'email') {
        let [wrongEmails, errorMessage, duplicates] = validateEmailList(values);
        if (wrongEmails) {
          return `Invalid email format: ${wrongEmails}`;
        } else if (duplicates) {
          return `Duplicates emails: ${duplicates}`;
        } else {
          return errorMessage;
        }
      }
    }
  };

  return (
    <div className={wrapperClass}>
      {hideLabel ? null : (
        <label htmlFor="suppressions">{labelText || 'Suppressions'}</label>
      )}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'suppressions'] && touched[name || 'suppressions']
            ? errorClass || 'has-error'
            : ''
        }`}
        name={name || 'suppressions'}
        as="textarea"
        placeholder={placeholder || 'Suppressions'}
        type="text"
        validate={validate}
        onChange={e => {
          handleChange(e);
        }}
      />
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'suppressions'}
          component="div"
          className="field-error"
          data-testid="suppressions-error"
        />
      )}
    </div>
  );
};

export default Suppressions;

Suppressions.propTypes = {
  hideLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  wrapperClass: PropTypes.string,
};
