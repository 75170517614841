import {
  DISPLAY_SNACKBAR,
  HIDE_SNACKBAR,
  DISPLAY_LOADER,
  HIDE_LOADER,
  SAVE_DRAWER,
  IS_SET_VERTICAL,
  SET_ITEM_FLAG,
} from '../actionTypes/root.js';

export const displaySnackbar = payload => {
  return {
    type: DISPLAY_SNACKBAR,
    payload,
  };
};

export const hideSnackbar = payload => {
  return {
    type: HIDE_SNACKBAR,
    payload,
  };
};

export const displayLoader = () => {
  return {
    type: DISPLAY_LOADER,
  };
};

export const hideLoader = () => {
  return {
    type: HIDE_LOADER,
  };
};

export const saveDrawer = payload => {
  return {
    type: SAVE_DRAWER,
    payload,
  };
};


export const setVerticalFlag = payload => {
  return {
    type: IS_SET_VERTICAL,
    payload,
  };
};

export const setItemFlag = payload => {
  return {
    type: SET_ITEM_FLAG,
    payload,
  };
};
