import { reportingEventsValues } from '../singleReportingPage/config';

export const emailDripCells = [
  {
    label: 'Total sends',
    id: 'totalSends',
  },
  {
    label: 'Delivered',
    id: 'delivered',
  },
//   {
//     label: 'Invalid emails',
//     id: 'invalidEmails',
//   },
  {
    label: 'Bounced',
    id: 'bounced',
  },
  {
    label: 'Spam Complaint',
    id: 'spamComplaint',
  },
  {
    label: 'Unsubscribe',
    id: 'unsubscribe',
  },
  {
    label: 'Opens',
    id: 'opens',
  },
  {
    label: 'Clicks',
    id: 'clicks',
  },
];
export const smsDripCells = [
  {
    label: 'Total sends',
    id: 'totalSends',
  },
  {
    label: 'Delivered',
    id: 'delivered',
  },
  {
    label: 'Undelivered',
    id: 'undelivered',
  },
  {
    label: 'Unsubscribe',
    id: 'unsubscribe',
  },
  {
    label: 'Clicks',
    id: 'clicks',
  },
];
export const rvmDripCells = [
  {
    label: 'Total sends',
    id: 'totalSends',
  },
  {
    label: 'Delivered',
    id: 'delivered',
  },
  {
    label: 'Cannot RVM',
    id: 'cannotRvm',
  },
];
export const callDripCells = [
  {
    label: 'Call Initiated',
    id: 'callInitiated',
  },
];
export const genericDataPostDripCells = [
  {
    label: 'Total sends',
    id: 'totalSends',
  },
  {
    label: 'Delivered',
    id: 'delivered',
  },
  {
    label: 'Undelivered',
    id: 'undelivered',
  },
  {
    label: 'Clicks',
    id: 'clicks',
  },
];

// TYPE_EMAIL = 1;
// TYPE_SMS = 2;
// TYPE_RVM = 3;
// TYPE_CALL = 4;
// TYPE_POST = 5;

export const convertReportingData = data => {
  let elementList = [];
  if (data && data.length > 0) {
    data.forEach(item => {
      let element = {
        type: item.drip_type,
      };
      Object.entries(item.counts).forEach(([key, value]) => {
        let elementEvent = reportingEventsValues.find(
          event => +event.key === +key,
        );
        if (elementEvent) {
          element = {
            ...element,
            [elementEvent.name]: value,
          };
        }
      });
      elementList.push(element);
    });
  }
  return elementList;
};
