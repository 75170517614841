import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CustomSelect from '../form/customSelect';
import { getList, cancelList } from '../../store/actions/actions/rest';
import classNames from 'classnames';
import useStyles from './styles';
import { initialValues, generateNames, generateValues } from './config';
import PropTypes from 'prop-types';

const ModelTags = ({
  props,
  model_id,
  model_name,
  formValues,
  setFormValues,
  isDisabled,
  handlePromptFlag,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tags = useSelector(state => state.tags.list);
  useEffect(() => {
    dispatch(cancelList('tags', null, dispatch));
    dispatch(getList('tags', null, dispatch));
    return () => {
      dispatch(cancelList('tags', null, dispatch));
    };
  }, [dispatch]);

  const handleNameChange = (name, i) => {
    handlePromptFlag && handlePromptFlag();
    const model_tags = [...formValues.model_tags];
    model_tags[i].tag.name = name;
    model_tags[i].tag.value = '';
    setFormValues({
      ...{ ...formValues, ...props.values },
      model_tags,
    });
  };

  const handleValueChange = (name, value, i) => {
    handlePromptFlag && handlePromptFlag();
    const model_tags = [...formValues.model_tags];
    model_tags[i].tag.value = value;

    // find the tag_id for the given tag name/value pair and populate the field
    const tag_id = tags.filter(tag => {
      return tag.name === name && tag.value === value;
    })[0].id;
    model_tags[i].tag_id = tag_id;

    setFormValues({
      ...{ ...formValues, ...props.values },
      model_tags,
    });
  };

  const removeTag = i => {
    handlePromptFlag && handlePromptFlag();
    formValues.model_tags.splice(i, 1);
    setFormValues({
      ...{ ...formValues, ...props.values },
      model_tags: formValues.model_tags,
    });
  };

  const addNewTag = () => {
    handlePromptFlag && handlePromptFlag();
    setFormValues({
      ...{ ...formValues, ...props.values },
      model_tags: [
        ...formValues.model_tags,
        {
          ...initialValues(model_id, model_name),
          tag: {
            name: '',
            value: '',
          },
        },
      ],
    });
  };

  const model_tags = props.values.model_tags;

  return (
    <Grid item xs={12}>
      <Grid
        container
        item
        xs={12}
        className={classes.tagsContainer}
        alignItems="flex-end"
      >
        <Grid item>
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.tagsTitle}
          >
            Tags
          </Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.addTagBtn}
            startIcon={<AddIcon />}
            disabled={isDisabled}
            onClick={() => addNewTag()}
          >
            Add Tag
          </Button>
        </Grid>
      </Grid>
      {model_tags &&
        model_tags
          .sort((a, b) => (a < b ? -1 : 1))
          .map((model_tag, i) => {
            return (
              <div key={i}>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.tagSelectContainer}
                >
                  <Grid item xs={11} container className={classes.tagsSelects}>
                    <Grid item className={classes.tagsColumnTitle}>
                      Key
                    </Grid>
                    <Grid item className={classes.tagsColumnTitle}>
                      Value
                    </Grid>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.tagSelectContainer}
                >
                  <Grid item xs={11} className={classes.tagsSelects}>
                    <Grid container className={classes.tagsSelects}>
                      <Grid item className={classes.tagColumn}>
                        <CustomSelect
                          {...props}
                          placeholder="Key"
                          labelText=" "
                          name={`model_tags[${i}].tag.name`}
                          wrapperClass={classes.tagField}
                          options={generateNames(tags)}
                          disabled={isDisabled}
                          handleChange={e =>
                            handleNameChange(e.target.value, i)
                          }
                        />
                      </Grid>
                      <Grid item className={classes.tagColumn}>
                        <CustomSelect
                          {...props}
                          placeholder="Value"
                          labelText=" "
                          name={`model_tags[${i}].tag.value`}
                          wrapperClass={classes.tagField}
                          options={generateValues(
                            model_tag.tag.name,
                            model_tag.tag.value,
                            formValues,
                            tags,
                            model_id,
                            model_name,
                          )}
                          handleChange={e =>
                            handleValueChange(
                              model_tag.tag.name,
                              e.target.value,
                              i,
                            )
                          }
                          disabled={!model_tag.tag.name || isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={1} justify="flex-end">
                    <DeleteIcon
                      className={classNames(
                        classes.tagsActionButton,
                        classes.deleteTagButton,
                        { [classes.deleteTagButtonDisabled]: isDisabled },
                      )}
                      onClick={() => !isDisabled && removeTag(i)}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
    </Grid>
  );
};

export default ModelTags;

ModelTags.propTypes = {
  props: PropTypes.object.isRequired,
  model_name: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
};
