import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../../../assets/images/login-logo.png';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import Input from '@material-ui/core/Input';

class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ['requireNewPassword'];
  }

  showComponent = () => {
    const { classes } = this.props;

    return (
      <div className={classes.backgroundLayer}>
        <div className={classes.loginContainer}>
          <div>
            <img src={Logo} alt="logo" />
            <div className={classes.requireNewPassowrdTitle}>
              Change password
            </div>
            <div className={classes.inputField}></div>
            <div>
              <label htmlFor="password" className={classes.inputLabel}>
                New Password *
              </label>
              <Input
                placeholder="New Password"
                type="password"
                key="password"
                name="password"
                className={classes.inputField}
                autoComplete="off"
                onChange={this.handleInputChange}
                disableUnderline={true}
              />
            </div>
            <Button
              type="button"
              className={classes.requireNewPasswordButton}
              onClick={() => super.change()}
            >
              Change
            </Button>
            <div
              className={classes.requireNewPasswordLink}
              onClick={() => super.changeState('signIn')}
            >
              Back to Sign In
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(CustomRequireNewPassword);
