import React from 'react';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Table from './fromEmailTable';
import { initialValues, populateValues } from './config';
import DripComponent from '../dripComponent/dripComponent';

const FromEmail = () => {
  return (
    <DripComponent
      title="Email From Addresses"
      item="Email From"
      urlPath="from-email-components"
      model_name="FromEmailComponent"
      fields={['name']}
      Table={Table}
      populateValues={populateValues}
      initialValues={initialValues}
      modal
    />
  );
};

export default withTitle(
  withDashboard(FromEmail, false),
  'Email From Addresses',
);
