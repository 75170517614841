import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import StepReport from './stepReport';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const InlineFlowReports = ({ classes, step }) => {
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const history = useHistory();

  const handleClickStepName = id => () => {
    history.push({
      pathname: `/flows/steps/edit/${id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  return (
    <Grid className={classes.inlineReporting}>
      <span className={classes.reportTitle}>Step Report</span>
      <span
        className={classes.reportTitleLink}
        onClick={handleClickStepName(step.id)}
      >
        {step && step.step.name}
      </span>
      <StepReport type="send_sms" classes={classes} />
    </Grid>
  );
};

export default InlineFlowReports;

InlineFlowReports.propTypes = {
  classes: PropTypes.object.isRequired,
};
