import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '0 0 20px 0',
    marginTop: -55,
    overflowX: 'hidden',
    paddingLeft: 0,
    [theme.breakpoints.up(1200)]: {
      paddingLeft: 60,
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 0 20px 0',
      paddingLeft: 270,
    },
  },
  title: {
    paddingLeft: 80,
    marginBottom: 0,
    position: 'relative',
    left: -32,
    top: 22,
    fontSize: 20,
    fontWeight: 900,
    [theme.breakpoints.up(1200)]: {
      fontSize: 36,
      fontWeight: 400,
      top: 46,
      left: 48,
    },
  },
  subtitle: {
    paddingLeft: 80,
    marginBottom: 0,
    position: 'relative',
    left: -32,
    top: 22,
    fontSize: 16,
    [theme.breakpoints.up(1200)]: {
      fontSize: 20,
      top: 46,
      left: 48,
    },
  },
  titleContainer: {
    position: 'relative',
    top: 60,
  },
  titleIcon: {
    position: 'absolute',
    left: 14,
    top: 110,
    width: 40,
    [theme.breakpoints.up(1200)]: {
      width: 75,
      left: 106,
      top: 117,
    },
    [theme.breakpoints.up('xl')]: {
      left: 317,
    },
  },
  contentContainer: {
    marginTop: 60,
    paddingLeft: 0,
    [theme.breakpoints.up(1200)]: {
      marginTop: 120,
      paddingLeft: 23,
    },
  },
  formContainer: {
    width: '100%',
  },
  searchField: {
    backgroundColor: '#F2F2F2',
    '& > .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& > .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
      },
      '& > button': {
        padding: 9,
      },
      '& > svg': {
        color: '#828282',
        position: 'relative',
        left: 7,
      },
    },
  },
  searchContainer: {
    '& > div': {
      width: 200,
    },
  },
  searchGridContainer: {
    maxWidth: 700,
    width: 700,
    position: 'absolute',
    right: 40,
    top: 90,
  },
  tableContainer: {
    marginTop: 30,
    boxShadow: 'none',
    background: '#FAFAFA',
    paddingRight: 17,
  },
  tableCell: {
    maxWidth: 125,
    width: 125,
    borderRight: '1px solid #E0E0E0',
    fontSize: 14,
    [theme.breakpoints.up(1200)]: {
      fontSize: 16,
    },
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tableSortLable: {
    lineHeight: '17px',
  },
  tableCellHeader: {
    maxWidth: 125,
    width: 125,
    fontSize: 14,
    fontWeight: 900,
  },
  stickyNameCell: {
    position: 'sticky',
    left: 0,
    zIndex: 10,
    background: '#fafafa',
    [theme.breakpoints.up(1200)]: {
      position: 'static',
      zIndex: 1,
    },
  },
  tableNameCell: {
    maxWidth: 145,
    width: 145,
    fontSize: 14,
    position: 'sticky',
    left: 0,
    zIndex: 10,
    background: 'inherit',
    [theme.breakpoints.up(1200)]: {
      fontSize: 16,
      position: 'static',
      zIndex: 1,
    },
  },
  tableBody: {
    '& > tr:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '& > tr:nth-of-type(even)': {
      backgroundColor: '#fafafa',
    },
  },
  tableCellHeaderActive: {
    maxWidth: 125,
    width: 125,
    fontSize: 14,
    fontWeight: 900,
  },
  tableCellLink: {
    fontSize: 14,
    color: '#036BF0',
    transition: 'all .7s',
    cursor: 'pointer',
    [theme.breakpoints.up(1200)]: {
      fontSize: 16,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  expandIcons: {
    '& > svg': {
      cursor: 'pointer',
    },
  },
  cssFocused: {
    color: '#828282 !important',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#828282 !important',
  },
  pageInput: {
    backgroundColor: '#F2F2F2',
    width: 115,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
        padding: '13px 10px',
      },
      '& > span': {
        padding: 10,
        cursor: 'pointer',
      },
    },
  },
  resultsPerPageLabel: {
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.text[100],
    paddingRight: 10,
    '& > span': {
      display: 'block',
    },
  },
  resultsPerPage: {
    width: 75,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        padding: 13,
      },
      '& > div': {
        backgroundColor: theme.palette.grey[100],
        padding: 2,
        '& > svg': {
          fontSize: 19,
        },
      },
    },
  },
  noData: {
    height: 60,
    background: '#fafafa',
    fontSize: 14,
    [theme.breakpoints.up(1200)]: {
      fontSize: 20,
    },
  },
  topActionContainer: {
    height: 42,
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    top: 42,
    display: 'none',
    [theme.breakpoints.up(1200)]: {
      display: 'flex',
    },
  },
}));

export default useStyles;
