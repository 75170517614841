import React from 'react';
import { columns as emailsColumns } from '../../../reporting/emails/config';
import { columns as smsColumns } from '../../../reporting/sms/config';
import { columns as rvmColumns } from '../../../reporting/rvm/config';
import { columns as postColumns } from '../../../reporting/genericDataPost/config';
import PropTypes from 'prop-types';

const StepReport = ({ type, classes }) => {
  const getColumnsByType = stepType => {
    switch (stepType) {
      case 'send_email':
        return emailsColumns;
      case 'send_sms':
        return smsColumns;
      case 'send_rvm':
        return rvmColumns;
      case 'send_post':
        return postColumns;
      default:
        return emailsColumns; // temporary
      // break;
    }
  };
  const renderColumns = () => {
    let columns = getColumnsByType(type);
    if (columns) {
      return columns.map(column => (
        <span
          className={classes.reportItems}
          key={`${column.id}${column.label}`}
        >
          {column.label}: 15%
        </span>
      ));
    }
  };

  return (
    <div className={classes.reportInformation}>
      <div className={classes.reports}>
        <div className={classes.singleReport}>{renderColumns()}</div>
      </div>
    </div>
  );
};

export default StepReport;

StepReport.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};
