import React, { useState, useEffect } from 'react';
import { columns as smsColumns } from '../../sms/config';
import { columns as emailColumns } from '../../emails/config';
import { columns as rvmColumns } from '../../rvm/config';
import { columns as dataPostColumns } from '../../genericDataPost/config';
import { columns as callColumns } from '../../calls/config';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatToLocalDate } from '../../../../config/helpers';

const useStyles = makeStyles(theme => ({
  deletedStepContainer: {
    background: '#DADADA',
    paddingBottom: 20,
  },
  dripRowContainer: {
    position: 'relative',
    paddingLeft: 55,
  },
  dripTable: {
    width: '100%',
    border: '1px solid #828282',
    color: theme.palette.text[100],
    background: '#FFFFFF',
    marginBottom: 20,
  },
  headers: {
    display: 'flex',
    alignItems: 'center',
  },
  dripData: {
    display: 'flex',
    alignItems: 'center',
  },
  headerCellName: {
    height: 42,
    transition: 'all .7s',
    cursor: 'pointer',
    paddingLeft: 16,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 900,
    color: theme.palette.grey[200],
  },
  headerCell: {
    height: 42,
    fontSize: 14,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 900,
    color: theme.palette.grey[200],
  },
  dripCell: {
    height: 52,
    fontSize: 16,
    textAlign: 'center',
    borderLeft: '1px solid #828282',
    borderTop: '1px solid #828282',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[200],
  },
  dripCellName: {
    height: 52,
    fontSize: 16,
    color: '#036BF0',
    transition: 'all .7s',
    borderTop: '1px solid #828282',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  stepNumber: {
    width: 45,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    background: '#FFFFFF',
    border: '1px solid #828282',
    position: 'absolute',
    left: 5,
    top: 22,
    '& > span': {
      position: 'absolute',
      top: -16,
      fontSize: 10,
    },
  },
  deletedStepLabel: {
    padding: '5px 14px',
    fontSize: 12,
    fontFamily: 'Lato',
    background: '#C4C4C4',
    color: theme.palette.text[100],
    fontWeight: 900,
    marginBottom: 10,
  },
}));

const SingleReportingStep = ({ type, data }) => {
  const classes = useStyles();
  const history = useHistory();
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const [activeColumns, setActiveColumns] = useState(null);
  const [columnWidth, setColumnWidth] = useState(0);

  const handleClickLink = id => () => {
    history.push({
      pathname: `/flows/steps/edit/${id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const getTheColumns = type => {
    switch (type) {
      case 'send_sms':
        return smsColumns;
      case 'send_email':
        return emailColumns;
      case 'send_rvm':
        return rvmColumns;
      case 'send_call':
        return callColumns;
      case 'send_post':
        setActiveColumns(dataPostColumns);
        return dataPostColumns;
      default:
        break;
    }
  };

  useEffect(() => {
    if (type) {
      let columns = getTheColumns(type);
      let percents = 100 / columns.length;
      setColumnWidth(`${percents}%`);
      setActiveColumns(columns);
    }
  }, [type]);

  const renderHeaders = activeColumns
    ? activeColumns.map(column => {
        return (
          <div
            className={
              column.id === 'name' ? classes.headerCellName : classes.headerCell
            }
            style={{ width: columnWidth }}
            key={column.id}
          >
            {column.id === 'name' ? `Step ${column.label}` : column.label}
          </div>
        );
      })
    : null;

  const renderData =
    data && activeColumns
      ? activeColumns.map(column => {
          return (
            <div
              onClick={column.id === 'name' ? handleClickLink(data.id) : null}
              className={
                column.id === 'name' ? classes.dripCellName : classes.dripCell
              }
              style={{ width: columnWidth }}
              key={`${column.id}${data.id}`}
            >
              {data[column.id]}
            </div>
          );
        })
      : null;

  const renderDeletedDate = () => {
    let date = '';
    if (data && data.archived_date) {
      date = formatToLocalDate(data.archived_date, 'dd.MM.yyyy');
    }
    return date;
  };

  return (
    <div className={classes.deletedStepContainer}>
      <div className={classes.deletedStepLabel}>
        Deleted step: {renderDeletedDate()}
      </div>
      <div className={classes.dripRowContainer}>
        <div className={classes.stepNumber}>
          <span>STEP</span>
          <div className={classes.number}>-</div>
        </div>
        <div className={classes.dripTable}>
          <div className={classes.headers}>{renderHeaders}</div>
          <div className={classes.dripData}>{renderData}</div>
        </div>
      </div>
    </div>
  );
};

export default SingleReportingStep;
