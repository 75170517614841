import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  backgroundLayer: {
    backgroundColor: '#2D435F',
    width: '100%',
    minHeight: '100vh',
  },
  switchAccountContainer: {
    background: `linear-gradient(180deg, #42638E 0%, rgba(41, 77, 122, 0) 100%)`,
    minHeight: '100%',
    display: 'flex',
    paddingTop: 100,
    paddingBottom: 50,
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: "'Lato', sans-serif",
    '& > div': {
      textAlign: 'center',
      '& > form': {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& > img': {
          width: 334,
          marginBottom: 65,
        },
      },
    },
  },
  buttons: {
    textAlign: 'center',
    width: 550,
    margin: '0 auto',
  },
  continueButton: {
    backgroundColor: '#FFBF58',
    color: theme.palette.common.white,
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 900,
    width: 170,
    padding: '11px 0',
    borderRadius: 2,
    marginTop: 45,
    '&:hover': {
      backgroundColor: '#FF9E00',
    },
  },
  backButton: {
    backgroundColor: '#D8D8D8',
    color: '#828282',
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 900,
    width: 170,
    padding: '11px 0',
    borderRadius: 2,
    marginTop: 45,
    marginRight: 180,
    '&:hover': {
      backgroundColor: '#adabab',
    },
  },
  accountsList: {
    '& > ul': {
      listStyle: 'none',
      '& > li': {
        cursor: 'pointer',
        color: theme.palette.common.white,
        fontFamily: 'Lato',
        fontSize: 20,
        margin: '20px 0',
      },
    },
  },
}));

export default useStyles;
