import React, { useState, useEffect, lazy, Suspense } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
// import Button from '@material-ui/core/Button';
import DripsIcon from '../../../assets/images/drips-icon.svg';
import DeleteButton from '../../../components/customButtons/customDelete';
import PublishButton from '../../../components/customButtons/customPublish';
import { useSelector, useDispatch } from 'react-redux';
import {
  editStart,
  removeStart,
  createStart,
} from '../../../store/actions/actions/rest';
import { sendTestSms } from '../../../store/actions/actions/test';
import SingleSmsBodyForm from './singleSmsBodyForm';
import SmsPreview from './smsPreview';
import { Formik, Form } from 'formik';
import ModelTags from '../../../components/modelTags/modelTags';
import { initialValues, populateValues, contain } from './config';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Loader from '../../../components/loader';
import { testInitialValues, populateTestValues } from './config';
import withSingleDripPage from '../../../hoc/withSingleDripPage/withSingleDripPage';
import PropTypes from 'prop-types';

const SigleSmsBodyPage = ({ element }) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [formValues, setFormValues] = useState(initialValues);
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const user_name = useSelector(state => state.auth.username);
  const serviceProvidersList = useSelector(
    state => state.serviceProviders.list,
  );
  const loader = useSelector(state => state.app.loader);
  const [characterCount, setCharacterCount] = useState(0);
  const [placeholders, setPlaceholders] = useState({
    smsSenderName: '',
    sms_body: '',
  });
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const [isSendTestSmsModalOpen, setIsSendTestSmsModalOpen] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const DeleteConfirmationDialog = lazy(() =>
    import('../../../components/deleteConfirmationModal'),
  );
  const NotesModal = lazy(() => import('../../../components/notes/notes'));
  const SendTestSmsModal = lazy(() =>
    import('../../../components/testModal/testModal'),
  );

  useEffect(() => {
    if (element) {
      setFormValues(populateValues(element));
      setPlaceholders({
        ...placeholders,
        sms_body: element.sms_body,
      });
      setCharacterCount(element.sms_body.length);
    }
    // eslint-disable-next-line
  }, [element]);

  const handleDelete = notes => {
    const data = {
      body: {
        id: formValues.id,
        notes,
      },
    };
    dispatch(removeStart('sms-body-components', data, dispatch));
    history.push({
      pathname: '/drip-components/sms-body-text',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const onSubmit = values => {
    if (match.params.id) {
      handleOpenNotesModal();
    } else {
      let allValues = populateValues(values);
      let data = {
        body: {
          ...allValues,
          account_id: account_id,
          vertical_id: vertical_id,
          slinky_link: values.slinky_link ? 1 : 0,
          notes: [
            {
              account_id,
              vertical_id,
              model_name: 'SmsBodyComponent',
              model_id: '',
              user_name,
              note: `Created ${values && values.name ? values.name : ''}`,
            },
          ],
        },
      };
      dispatch(createStart('sms-body-components', data, dispatch));
      history.push({
        pathname: '/drip-components/sms-body-text',
        search: `vertical_id=${vertical_id}`,
      });
    }
  };

  const submitForm = (values, notes) => {
    let allValues = populateValues(values);
    let data = {
      body: {
        ...allValues,
        account_id: account_id,
        vertical_id: vertical_id,
        slinky_link: values.slinky_link ? 1 : 0,
        notes,
      },
    };
    dispatch(editStart('sms-body-components', data, dispatch));
    history.push({
      pathname: '/drip-components/sms-body-text',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleDeleteModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setNotesModalOpen(false);
  };

  const handleOpenNotesModal = () => {
    setNotesModalOpen(true);
  };

  const handleCloseSendTestSmsModal = () => {
    setIsSendTestSmsModalOpen(false);
  };

  // eslint-disable-next-line
  const handleOpenSendTestSmsModal = () => {
    setIsSendTestSmsModalOpen(true);
  };

  const handleSendTestSms = smsValues => {
    let data = populateTestValues(smsValues);
    dispatch(sendTestSms(data));
    handleCloseSendTestSmsModal();
  };

  const renderNotesModal = props =>
    notesModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={notesModalOpen} />
          </Grid>
        }
      >
        <NotesModal
          props={props}
          classes={classes}
          model_name="SmsBodyComponent"
          model_id={+match.params.id || ''}
          open={notesModalOpen}
          handleClose={handleCloseNotesModal}
          submitForm={submitForm}
        />
      </Suspense>
    ) : null;

  const renderDeleteConfirmationDialog = isDeleteConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <DeleteConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        handleDelete={handleDelete}
        handleClose={handleDeleteModalClose}
        itemName={'sms body text'}
        model_id={+match.params.id || ''}
        model_name="SmsBodyComponent"
      />
    </Suspense>
  ) : null;

  const renderSendTestSmsModal = props =>
    isSendTestSmsModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={isSendTestSmsModalOpen} />
          </Grid>
        }
      >
        <SendTestSmsModal
          open={isSendTestSmsModalOpen}
          fields={['sms_to']}
          title="SMS"
          handleClose={handleCloseSendTestSmsModal}
          serviceProvidersList={serviceProvidersList}
          values={testInitialValues(props)}
          onSubmit={handleSendTestSms}
        />
      </Suspense>
    ) : null;

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {props => (
          <Form>
            <Grid container item xs={12}>
              <Grid item xs={5}>
                <SingleSmsBodyForm
                  values={formValues}
                  classes={classes}
                  setPlaceholders={setPlaceholders}
                  placeholders={placeholders}
                  props={props}
                  characterCount={characterCount}
                  setCharacterCount={setCharacterCount}
                  id={element && element.id}
                />
                {/* <Button
                  className={classes.sendTestSMSBtn}
                  onClick={() => {
                    props.validateForm().then(errors => {
                      if (
                        Object.keys(errors).length === 0 &&
                        errors.constructor === Object
                      ) {
                        handleOpenSendTestSmsModal();
                      } else {
                        props.submitForm();
                      }
                    });
                  }}
                >
                  Send Test SMS
                </Button> */}
              </Grid>
              <Grid item xs={7} className={classes.rightSideContainer}>
                <SmsPreview classes={classes} placeholders={placeholders} />
                <ModelTags
                  props={props}
                  classes={classes}
                  model_name="SmsBodyComponent"
                  model_id={match.params.id || ''}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.actionButtons}>
              {element && <DeleteButton onClick={handleDeleteModalOpen} />}
              <PublishButton type="submit" />
            </Grid>
            {renderDeleteConfirmationDialog}
            {renderNotesModal(props)}
            {renderSendTestSmsModal(props)}
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default withSingleDripPage(
  withTitle(withDashboard(SigleSmsBodyPage, false), 'SMS Drip Example'),
  {
    title: 'SMS Body Text Component',
    image: DripsIcon,
    path: 'sms-body-components',
    contain: contain,
    lists: ['sms-body-components', 'service-providers'],
  },
);

SigleSmsBodyPage.propTypes = {
  element: PropTypes.object,
};
