import React from 'react';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Table from './emailFriendlyFromTable';
import { initialValues, populateValues } from './config';
import DripComponent from '../dripComponent/dripComponent';

const EmailFriendlyFrom = () => {
  return (
    <DripComponent
      title="Email Friendly Froms"
      item="Friendly From"
      urlPath="friendly-from-components"
      model_name="FriendlyFromComponent"
      fields={['name']}
      Table={Table}
      populateValues={populateValues}
      initialValues={initialValues}
      modal
    />
  );
};

export default withTitle(
  withDashboard(EmailFriendlyFrom, false),
  'Email Friendly Froms',
);
