import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '0 0 20px 0',
    marginTop: 30,
    [theme.breakpoints.up(1200)]: {
      paddingLeft: 60,
      marginTop: 0,
    },
    [theme.breakpoints.up('xl')]: {
      padding: '20px 0',
      paddingLeft: 270,
    },
  },
  title: {
    paddingLeft: 80,
    marginBottom: 0,
    position: 'relative',
    left: -32,
    top: 7,
    fontSize: 20,
    fontWeight: 900,
    [theme.breakpoints.up(1200)]: {
      fontSize: 36,
      fontWeight: 400,
      top: 65,
      left: 37,
    },
    [theme.breakpoints.up(1440)]: {
      top: 46,
    },
  },
  titleContainer: {
    position: 'relative',
    [theme.breakpoints.up(1200)]: {
      top: -15,
    },
  },
  titleIcon: {
    position: 'absolute',
    left: 14,
    top: 110,
    width: 40,
    [theme.breakpoints.up(1200)]: {
      width: 75,
      left: 106,
      top: 117,
    },
    [theme.breakpoints.up('xl')]: {
      left: 317,
    },
  },
  contentContainer: {
    marginTop: 10,
    [theme.breakpoints.up(1200)]: {
      marginTop: 50,
      paddingLeft: 23,
    },
  },
  cellTitle: {
    width: 132,
    minWidth: 132,
    fontSize: 16,
    color: '#828282',
    height: 90,
    position: 'absolute',
    left: 15,
    background: '#fafafa',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      background: theme.palette.white[100],
      display: 'flex',
      alignItems: 'center',
      height: 50,
      borderTop: '1px solid #828282',
      borderBottom: '1px solid #828282',
      width: 132,
      paddingLeft: 10,
    },
    [theme.breakpoints.up(1200)]: {
      minWidth: 'auto',
      left: 0,
      position: 'static',
      zIndex: 1,
    },
  },
  cell: {
    position: 'relative',
    width: 115,
    minWidth: 115,
    height: 50,
    color: theme.palette.text[100],
    fontFamily: 'Lato',
    background: theme.palette.grey[100],
    borderRight: '1px solid #E0E0E0',
    borderTop: '1px solid #828282',
    borderBottom: '1px solid #828282',
    [theme.breakpoints.up(1200)]: {
      minWidth: 'auto',
    },
  },
  cellLabel: {
    position: 'absolute',
    fontSize: 14,
    top: -25,
    textAlign: 'center',
    width: '100%',
  },
  cellValue: {
    fontSize: 16,
    textAlign: 'center',
  },
  dripContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 70,
    width: '100%',
    paddingLeft: 132,
    [theme.breakpoints.up(1200)]: {
      paddingLeft: 0,
    },
  },
  exportButton: {
    position: 'absolute',
    right: 15,
    top: 48,
  },
  rowContainer: {
    width: '100%',
    overflowY: 'scroll',
  },
}));

export default useStyles;
