import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { initialValues as notesInitialValues } from '../components/notes/config';
import PropTypes from 'prop-types';

const formatReduxSelector = string =>
  string && `${string.charAt(0).toLowerCase() + string.slice(1)}s`;

const useStyles = makeStyles(theme => ({
  modalContainer: {
    '& > div.MuiDialog-container': {
      '& > div': {
        backgroundColor: '#F3F8FE',
      },
    },
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  content: {
    fontFamily: 'Lato',
    fontSize: 18,
    color: theme.palette.text[100],
    fontWeight: 400,
    margin: 40,
  },
  deleteActionBtn: {
    backgroundColor: theme.palette.secondary.main,
    height: 47,
    transition: 'all .4s',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: '#b54a4c !important',
    },
    '& > span': {
      color: theme.palette.white[100],
      fontSize: 20,
    },
  },
  actionsContainer: {
    padding: '8px 24px 10px 24px',
  },
}));

const DeleteConfirmationDialog = ({
  open,
  handleClose,
  handleDelete,
  itemName,
  model_id,
  model_name,
}) => {
  const classes = useStyles();
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const username = useSelector(state => state.auth.username);
  const list = useSelector(state =>
    model_name !== 'Users' ? null : state[formatReduxSelector(model_name)].list,
  );
  const [name, setName] = useState('');

  useEffect(() => {
    if (model_name && model_id && list && list.length > 0) {
      let entity = list.find(item => item.id === model_id);
      if (entity && entity.name) {
        setName(entity.name);
      }
    }
  }, [model_name, model_id, list]);

  const handleDeleteButton = () => {
    const notes = [
      {
        ...notesInitialValues(
          account_id,
          vertical_id,
          username,
          model_id,
          model_name,
        ),
        note: `Deleted ${name}`,
      },
    ];
    handleDelete(notes);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.modalContainer}
      >
        <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <h5 className={classes.content}>
            {`Are you sure you want to delete this ${itemName} ?`}
          </h5>
        </DialogContent>
        <DialogActions className={classes.actionsContainer}>
          <Button
            onClick={handleDeleteButton}
            color="secondary"
            className={classes.deleteActionBtn}
          >
            <DeleteIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteConfirmationDialog;

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  model_name: PropTypes.string.isRequired,
};
