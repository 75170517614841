import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import ReportingIcon from '../../../assets/images/Reports_blue_icon.svg';
import Typography from '@material-ui/core/Typography';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Loader from '../../../components/loader';
import { useSelector, useDispatch } from 'react-redux';
import {
  emailDripCells,
  smsDripCells,
  rvmDripCells,
  callDripCells,
  genericDataPostDripCells,
  convertReportingData,
} from './config';
import CustomFilterDropDown from '../customFilterDropDown/customFilterDropDown';
import {
  getListReporting,
  cancelListReporting,
  clearListReporting,
} from '../../../store/actions/actions/reporting';
import { getFilterDates } from '../singleReportingPage/config';

const Overall = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.app.loader);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const typeTotals = useSelector(state => state.typeTotals.list);
  const [convertedData, setConvertedData] = useState(null);
  const [filterByDate, setFilterByDate] = useState('today');
  const [filterDates, setFilterDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleGetData = () => {
    let filterData = getFilterDates(filterByDate, filterDates, false);
    let verticalFilters = JSON.stringify({
      field: 'vertical_id',
      operator: '=',
      value: vertical_id,
    });
    let allFilters = [...filterData, verticalFilters];
    dispatch(clearListReporting('type-totals', null));
    dispatch(cancelListReporting('type-totals', null, dispatch));
    dispatch(
      getListReporting('type-totals', { filters: allFilters }, dispatch),
    );
  };

  useEffect(() => {
    if (vertical_id) {
      if (filterByDate !== 'custom-filter') {
        handleGetData();
      }
    }
    return () => {
      dispatch(cancelListReporting('type-totals', null, dispatch));
    };
    // eslint-disable-next-line
  }, [vertical_id, filterByDate, filterDates, dispatch]);

  useEffect(() => {
    if (typeTotals) {
      let convertedReportingData = convertReportingData(typeTotals);
      if (convertedReportingData && convertedReportingData.length > 0) {
        setConvertedData(convertedReportingData);
      } else {
        setConvertedData(null);
      }
    }
  }, [typeTotals]);

  const handleChangeFilter = event => {
    setFilterByDate(event.target.value);
  };

  const handleChangeDate = (event, name) => {
    setFilterDates({
      ...filterDates,
      [name]: event,
    });
  };

  const renderValue = (cell_id, drip_type) => {
    let value = '0';
    if (drip_type && convertedData && convertedData.length > 0) {
      let drip = convertedData.find(item => item.type === drip_type);
      if (drip) {
        value = drip[cell_id];
      }
    }
    return value;
  };

  const renderCells = (list, drip_type) => {
    if (list && list.length > 0) {
      return (
        list &&
        list.map(cell => (
          <div
            className={classes.cell}
            key={`${cell.label}${Math.random() * 1231}`}
          >
            <span className={classes.cellLabel}>{cell.label}</span>
            <p className={classes.cellValue}>
              {renderValue(cell.id, drip_type)}
            </p>
          </div>
        ))
      );
    }
  };

  const handleApplyFilter = () => {
    handleGetData();
  };

  return (
    <Grid container className={classes.container}>
      <img
        src={ReportingIcon}
        alt="Reporting icon"
        className={classes.titleIcon}
      />
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Reports
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12}>
          <CustomFilterDropDown
            handleChange={handleChangeFilter}
            value={filterByDate}
            handleChangeDate={handleChangeDate}
            filterDates={filterDates}
            handleApplyFilter={handleApplyFilter}
          />
        </Grid>
        <Grid item xs={12} className={classes.rowContainer}>
          <div className={classes.dripContainer}>
            <div className={classes.cellTitle}>
              <div>Email Drips</div>
            </div>
            {renderCells(emailDripCells, 1)}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.rowContainer}>
          <div className={classes.dripContainer}>
            <div className={classes.cellTitle}>
              <div>SMS Drips</div>
            </div>
            {renderCells(smsDripCells, 2)}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.rowContainer}>
          <div className={classes.dripContainer}>
            <div className={classes.cellTitle}>
              <div>RVM Drips</div>
            </div>
            {renderCells(rvmDripCells, 3)}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.rowContainer}>
          <div className={classes.dripContainer}>
            <div className={classes.cellTitle}>
              <div>Call Drips</div>
            </div>
            {renderCells(callDripCells, 4)}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.rowContainer}>
          <div className={classes.dripContainer}>
            <div className={classes.cellTitle}>
              <div>
                Generic Data <br /> Post Drips
              </div>
            </div>
            {renderCells(genericDataPostDripCells, 5)}
          </div>
        </Grid>
      </Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(withDashboard(Overall, false), 'Overall Reports');
