import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { CheckboxWithLabel } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CustomCheckbox = ({ name, classes, labelText }) => {
  return (
    <div className={classes.checkboxField}>
      <FormControlLabel
        label={labelText}
        labelPlacement="start"
        control={
          <Field
            type="checkbox"
            component={CheckboxWithLabel}
            name={name}
            color="primary"
          />
        }
      />
    </div>
  );
};

export default CustomCheckbox;

CustomCheckbox.propTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  classes: PropTypes.object,
};
