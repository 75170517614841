import React, { useState, lazy, Suspense, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import ReportingIcon from '../../../assets/images/Reports_blue_icon.svg';
import Typography from '@material-ui/core/Typography';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/loader';
import CustomTable from './customTable';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import DenseTableSwitch from '../../../components/denseTableSwitch';
import {
  getList,
  clearList,
  cancelList,
} from '../../../store/actions/actions/rest';
import {
  getListReporting,
  cancelListReporting,
  clearListReporting,
} from '../../../store/actions/actions/reporting';
import { getFilterDates, getDripIds, convertReportingData } from './config';
// import CustomExportButton from '../../../components/customButtons/customExport';
// import Search from '../../../components/search';

const ReportingTable = ({
  name,
  renderElements,
  columns,
  sortBy,
  itemPath,
  filters,
  messageTypeId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.app.loader);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const dripsList = useSelector(state => state.drips.list);
  const dripTotals = useSelector(state => state.dripTotals.list);
  const [dense, setDense] = useState(false);
  const classes = useStyles();
  const [search] = useState('');
  const [filterByDate, setFilterByDate] = useState('today');
  const [filterDates, setFilterDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [convertedData, setConvertedData] = useState(null);
  const ExportModal = lazy(() =>
    import('../../../components/exportModal/exportModal'),
  );

  useEffect(() => {
    if (dripTotals) {
      let convertedReportingData = convertReportingData(dripTotals);
      if (convertedReportingData && convertedReportingData.length > 0) {
        setConvertedData(convertedReportingData);
      } else {
        setConvertedData(null);
      }
    }
  }, [dripTotals]);

  useEffect(() => {
    if (vertical_id && filters) {
      dispatch(clearList('drips'));
      dispatch(cancelList('drips', null, dispatch));
      dispatch(getList('drips', { filters: filters(vertical_id) }, dispatch));
      return () => {
        dispatch(cancelList('drips', null, dispatch));
        dispatch(clearList('drips'));
      };
    }
  }, [filters, vertical_id, dispatch]);

  const getReportingFilters = () => {
    let filterData = getFilterDates(filterByDate, filterDates, false);
    let dripsIds = getDripIds(dripsList, messageTypeId);
    return [...filterData, dripsIds];
  };

  const getDripTotalsData = () => {
    const allFilters = getReportingFilters();
    dispatch(clearListReporting('drip-totals', null));
    dispatch(cancelListReporting('drip-totals', null, dispatch));
    dispatch(
      getListReporting('drip-totals', { filters: allFilters }, dispatch),
    );
  };

  useEffect(() => {
    if (dripsList && dripsList.length > 0) {
      if (filterByDate !== 'custom-filter') {
        getDripTotalsData();
      }
    }
    return () => {
      dispatch(cancelListReporting('drip-totals', null, dispatch));
    };
    // eslint-disable-next-line
  }, [dripsList, filterByDate, filterDates, messageTypeId]);

  const handleApplyFilter = () => {
    getDripTotalsData();
  };

  const handleClickName = item => () => {
    history.push({
      pathname: `/drips/${itemPath}/edit/${item.id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleChangeFilter = event => {
    setFilterByDate(event.target.value);
  };

  const handleChangeDate = (event, name) => {
    setFilterDates({
      ...filterDates,
      [name]: event,
    });
  };

  // eslint-disable-next-line
  const handleExportModalOpen = () => {
    setIsExportModalOpen(true);
  };

  const handleExportModalClose = () => {
    setIsExportModalOpen(false);
  };

  const renderExportModal = isExportModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={isExportModalOpen} />
        </Grid>
      }
    >
      <ExportModal
        open={isExportModalOpen}
        handleClose={handleExportModalClose}
      />
    </Suspense>
  ) : null;

  return (
    <Grid container className={classes.container}>
      <img
        src={ReportingIcon}
        alt="Reporting icon"
        className={classes.titleIcon}
      />
      <Grid
        container
        item
        xs={6}
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Reports
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            component="h5"
            gutterBottom
            className={classes.subtitle}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={6}>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justify="flex-end"
          alignItems="flex-end"
          className={classes.searchGridContainer}
        >
          <Grid item className={classes.topActionContainer}>
            <DenseTableSwitch
              dense={dense}
              setDense={setDense}
              wrapperClass={classes.denseTableSwitch}
            />
            {/* <CustomExportButton
              customClass={classes.exportButton}
              onClick={handleExportModalOpen}
            /> */}
          </Grid>
          <Grid item className={classes.searchContainer}>
            {/* <Search type="Reports" setSearch={setSearch} classes={classes} /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.contentContainer}>
        {dripTotals && (
          <CustomTable
            list={convertedData}
            renderElements={renderElements}
            columns={columns}
            handleClickName={handleClickName}
            search={search}
            itemSortBy={sortBy}
            handleChangeFilter={handleChangeFilter}
            filterByDate={filterByDate}
            handleChangeDate={handleChangeDate}
            filterDates={filterDates}
            name={name}
            dense={dense}
            handleApplyFilter={handleApplyFilter}
          />
        )}
      </Grid>
      {renderExportModal}
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(withDashboard(ReportingTable, false), 'Reporting');

ReportingTable.propTypes = {
  name: PropTypes.string.isRequired,
  renderElements: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  sortBy: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  filters: PropTypes.func.isRequired,
};
