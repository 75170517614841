import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles';

const PromptDialog = ({
  open,
  handleCloseModal,
  handleConfirmNavigationClick,
  titleText,
  confirmButtonText,
  contentText,
  cancelButtonText,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.modalContainer}
    >
      <DialogTitle id="alert-dialog-title"
      className={classes.modalTitle}
      >{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.content}>
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button onClick={handleCloseModal} color="primary" className={classes.cancelButton}>
          {cancelButtonText ? cancelButtonText : 'Disagree'}
        </Button>
        <Button onClick={handleConfirmNavigationClick} color="secondary" variant="contained" className={classes.confirmButton}>
          {confirmButtonText ? confirmButtonText : 'Agree'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PromptDialog;
