import React, { useState, useEffect, lazy, Suspense } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import ReportingIcon from '../../../assets/images/Reports_blue_icon.svg';
import Typography from '@material-ui/core/Typography';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/loader';
import { useHistory } from 'react-router-dom';
import CustomFilterDropDown from '../customFilterDropDown/customFilterDropDown';
import { columns, sortBy as itemSortBy } from './config';
import SingleRow from './row';
import classNames from 'classnames';
import {
  convertReportingData,
  filters,
  contain,
  getFlowIds,
  getAllFlowStepMapsIds,
} from './config';
import {
  getList,
  cancelList,
  saveFilters,
  saveContain,
} from '../../../store/actions/actions/rest';
import { getFilters } from '../../../hoc/withTable/getFilters';
import { getFilterDates } from '../singleReportingPage/config';
import {
  getListReporting,
  cancelListReporting,
  clearListReporting,
} from '../../../store/actions/actions/reporting';
import ReportingFlowMobileView from './mobileFlow/table';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const FlowsReporting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallTable = useMediaQuery(theme.breakpoints.down(1200));
  const loader = useSelector(state => state.app.loader);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const account_id = useSelector(state => state.auth.account_id);
  const auth = useSelector(state => state.auth);
  const flowList = useSelector(state => state.flows.list);
  const fsmList = useSelector(state => state.flowStepMaps.list);
  const flowTotals = useSelector(state => state.flowTotals.list);
  const stepTotals = useSelector(state => state.stepTotals.list);
  const classes = useStyles();
  const [filterByDate, setFilterByDate] = useState('today');
  const [filterDates, setFilterDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [showMoreList, setShowMoreList] = useState({});
  /* eslint-disable */
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(10);
  const [jumpToPage, setJumpToPage] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [isUserChanged, setIsUserChanged] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  /* eslint-enable */
  const [sortedElement, setSortedElement] = useState('name');
  const [sortBy, setSortBy] = useState(itemSortBy);
  const count = 10; // temporarily
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [stepsData, setStepsData] = useState(null);
  const ExportModal = lazy(() =>
    import('../../../components/exportModal/exportModal'),
  );

  useEffect(() => {
    if (stepTotals && stepTotals.length > 0) {
      setStepsData(stepTotals);
    } else {
      setStepsData(null);
    }
  }, [stepTotals]);

  useEffect(() => {
    if (auth && vertical_id && account_id) {
      let allFilters = getFilters(filters, auth);
      dispatch(saveFilters('flows', allFilters));
      dispatch(saveContain('flows', contain));
      dispatch(cancelList('flows', null, dispatch));
      dispatch(cancelList('flow-step-maps', null, dispatch));
      dispatch(
        getList(
          'flows',
          { contain: contain, filters: [...allFilters] },
          dispatch,
        ),
      );
      dispatch(
        getList(
          'flow-step-maps',
          { filters: [...allFilters], showArchived: 'any' },
          dispatch,
        ),
      );
      return () => {
        dispatch(cancelList('flows', null, dispatch));
        dispatch(cancelList('flow-step-maps', null, dispatch));
      };
    }
  }, [dispatch, account_id, vertical_id, auth]);

  useEffect(() => {
    if (flowTotals && flowTotals.length > 0) {
      let convertedReportingData = convertReportingData(flowTotals);
      if (convertedReportingData && convertedReportingData.length > 0) {
        setData(convertedReportingData);
      } else {
        setData(null);
      }
    } else {
      setData(null);
    }
  }, [flowTotals]);

  useEffect(() => {
    setTotalPages(Math.ceil(count / (data && data.length)));
  }, [count, data]);

  useEffect(() => {
    if (data && data.length > 0) {
      let showMoreObject = {};
      data.forEach(item => (showMoreObject[item.id] = false));
      setShowMoreList(showMoreObject);
    }
  }, [data]);

  const getReportingFilters = () => {
    let filterData = getFilterDates(filterByDate, filterDates, false);
    let flowIds = getFlowIds(flowList);
    return [...filterData, flowIds];
  };

  const getFlowTotalsData = () => {
    let allFilters = getReportingFilters();
    dispatch(cancelListReporting('flow-totals', null, dispatch));
    dispatch(clearListReporting('flow-totals', null));
    dispatch(
      getListReporting('flow-totals', { filters: allFilters }, dispatch),
    );
  };

  useEffect(() => {
    if (flowList && flowList.length > 0) {
      if (filterByDate !== 'custom-filter') {
        getFlowTotalsData();
      }
    }
    return () => {
      dispatch(cancelListReporting('flow-totals', null, dispatch));
    };
    // eslint-disable-next-line
  }, [flowList, filterByDate, filterDates]);

  const handleApplyFilter = () => {
    getFlowTotalsData();
  };

  const getFlowStepMapId = id => {
    if (id && fsmList && fsmList.length > 0) {
      let allFlowSteps = fsmList.filter(step => step.flow_id === id);
      if (allFlowSteps && allFlowSteps.length > 0) {
        let allFSMIds = getAllFlowStepMapsIds(allFlowSteps);
        let filterData = getFilterDates(filterByDate, filterDates, false);
        let allTotalStepsFilters = [...filterData, allFSMIds];
        setStepsData(null);
        dispatch(clearListReporting('step-totals', null));
        dispatch(
          getListReporting(
            'step-totals',
            { filters: allTotalStepsFilters },
            dispatch,
          ),
        );
      }
    }
  };

  const handleShowMoreBtn = id => () => {
    let object = { ...showMoreList };
    Object.entries(object).forEach(([key]) => (object[key] = false));
    object[id] = !showMoreList[id];
    setShowMoreList(object);
    if (object[id]) {
      getFlowStepMapId(id);
    } else {
      setStepsData(null);
    }
  };

  const handleClickName = item => () => {
    history.push({
      pathname: `/flows/flow/edit/${item}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleChangeFilter = event => {
    setFilterByDate(event.target.value);
  };

  const handleChangeDate = (event, name) => {
    setFilterDates({
      ...filterDates,
      [name]: event,
    });
  };

  // eslint-disable-next-line
  const renderDirectionClassName = id => {
    let itemClass = null;
    if (sortedElement === id) {
      if (sortBy[sortedElement] === 'desc') {
        itemClass = classes.sortingArrowDesc;
      } else {
        itemClass = classes.sortingArrowAsc;
      }
    }
    return itemClass;
  };

  const handleSorting = id => () => {
    setSortedElement(id);
    let object = { ...sortBy };
    Object.entries(object).forEach(([key]) => (object[key] = 'asc'));
    object[id] = sortBy[id] === 'asc' ? 'desc' : 'asc';
    setSortBy(object);
  };

  const renderColumnsHeaders =
    columns && columns.length > 0
      ? columns.map(column => (
          <span
            className={classNames(
              classes.tableCellHeader,
              {
                [classes.tableCellHeaderActive]: column.id === sortedElement,
              },
              {
                [classes.tableCellNameHeader]: column.id === 'name',
              },
            )}
            key={column.id}
            onClick={handleSorting(column.id)}
          >
            <span>{column.label}</span>
            {/* {column.id !== 'action' && (
              <ArrowUpwardIcon
                className={classNames(
                  classes.sortingArrow,
                  {
                    [classes.sortingArrowActive]: sortedElement === column.id,
                  },
                  renderDirectionClassName(column.id),
                )}
              />
            )} */}
          </span>
        ))
      : null;

  // eslint-disable-next-line
  const handleExportModalOpen = () => {
    setIsExportModalOpen(true);
  };

  const handleExportModalClose = () => {
    setIsExportModalOpen(false);
  };

  const renderExportModal = isExportModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={isExportModalOpen} />
        </Grid>
      }
    >
      <ExportModal
        open={isExportModalOpen}
        handleClose={handleExportModalClose}
      />
    </Suspense>
  ) : null;

  const renderRows = () => {
    if (data && data.length > 0 && Object.keys(showMoreList).length > 0) {
      return data.map(item => (
        <SingleRow
          key={item.id}
          classes={classes}
          item={item}
          showMoreList={showMoreList}
          handleShowMoreBtn={handleShowMoreBtn}
          handleClickName={handleClickName}
          stepsData={stepsData}
        />
      ));
    } else {
      return <div className={classes.noItems}>No Items</div>;
    }
  };

  return (
    <>
      {smallTable ? (
        <Grid className={classes.mobileContainer}>
          <ReportingFlowMobileView sortBy={sortBy} />
        </Grid>
      ) : (
        <Grid container className={classes.container}>
          <img
            src={ReportingIcon}
            alt="Reporting icon"
            className={classes.titleIcon}
          />
          <Grid
            container
            item
            xs={6}
            alignItems="center"
            className={classes.titleContainer}
          >
            <Grid item xs={12}>
              <Typography
                variant="h1"
                component="h2"
                gutterBottom
                className={classes.title}
              >
                Reports
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                component="h5"
                gutterBottom
                className={classes.title}
              >
                Flows
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid
              container
              item
              xs={12}
              lg={12}
              justify="flex-end"
              alignItems="flex-end"
              className={classes.searchGridContainer}
            >
              <Grid item xs={6} className={classes.topActionContainer}>
                {/* <CustomExportButton
              customClass={classes.exportButton}
              onClick={handleExportModalOpen}
            /> */}
              </Grid>
              <Grid item className={classes.searchContainer}>
                {/* <Search type="Reports" setSearch={setSearch} classes={classes} /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.contentContainer}>
            <Grid item xs={12}>
              <CustomFilterDropDown
                handleChange={handleChangeFilter}
                value={filterByDate}
                handleChangeDate={handleChangeDate}
                filterDates={filterDates}
                handleApplyFilter={handleApplyFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.table}>
                <div className={classes.tableHeader}>
                  {renderColumnsHeaders}
                </div>
                <div className={classes.tableContent}>
                  {data && data.length > 0 ? (
                    renderRows()
                  ) : (
                    <div className={classes.noData}>No items</div>
                  )}
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={12}>
          <div className={classes.paginationWrapper}>
            <TablePagination
              list={data}
              classes={classes}
              setTotalCount={setTotalCount}
              setPage={setPage}
              count={count}
              setRowsPerPage={setRowsPerPage}
              setElementsPerPage={setElementsPerPage}
              rowsPerPage={rowsPerPage}
              search={search}
              elementsPerPage={elementsPerPage}
              setIsUserChanged={setIsUserChanged}
              isUserChanged={isUserChanged}
              page={page}
              jumpToPage={jumpToPage}
              totalCount={totalCount}
              totalPages={totalPages}
              setJumpToPage={setJumpToPage}
            />
          </div>
        </Grid> */}
          </Grid>
          {renderExportModal}
          {Boolean(loader) && <Loader open={Boolean(loader)} />}
        </Grid>
      )}
    </>
  );
};

export default withTitle(withDashboard(FlowsReporting, false), 'Reporting');
