import React from 'react';
import { Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

const phoneNumberMask = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const validate = values => {
  if (!values) {
    return 'Required';
  } else if (values.replace(/[^0-9]/g, '')[0] === '0') {
    return 'Number can not start with 0';
  } else if (values.replace(/[^0-9]/g, '')[0] === '1') {
    return 'Number can not start with 1';
  } else if (values.replace(/[^0-9]/g, '').length < 10) {
    return 'Number must be 10 chars long';
  } else if (values.replace(/[^0-9]/g, '').substring(6, 10) === '0000') {
    return 'Not valid phone number';
  }
};

const SmsPhoneNumber = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
}) => (
  <div className={wrapperClass}>
    {hideLabel ? null : <label htmlFor="sms_to">SMS To</label>}
    <Field
      validate={validate}
      name={name || 'sms_to'}
      // render={}
    >
      {({ field }) => (
        <MaskedInput
          {...field}
          mask={phoneNumberMask}
          guide={false}
          placeholder={placeholder || 'Enter your phone number'}
          type="text"
          className={`${className || 'field'} ${
            errors[name || 'sms_to'] && touched[name || 'sms_to']
              ? errorClass || 'has-error'
              : ''
          }`}
          onChange={e => {
            handleChange(e);
          }}
        />
      )}
    </Field>
    {noErrorMessage ? null : (
      <ErrorMessage
        name={name || 'sms_to'}
        component="div"
        className="field-error"
        data-testid="sms_to-error"
      />
    )}
  </div>
);

export default SmsPhoneNumber;

SmsPhoneNumber.propTypes = {
  hideLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  wrapperClass: PropTypes.string,
};
