export const initialValues = (
  account_id,
  vertical_id,
  user_name,
  model_id,
  model_name,
) => ({
  id: '',
  account_id,
  vertical_id,
  model_id,
  model_name,
  user_name,
  note: '',
});
