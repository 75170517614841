import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from '../styles';
import FlowsIcon from '../../../../assets/images/flows-dashboard-icon.svg';
import Typography from '@material-ui/core/Typography';
import withDashboard from '../../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../../hoc/withTitle';
import { useSelector, useDispatch } from 'react-redux';
import {
  getList,
  cancelList,
  getItem,
  cancelItem,
  clearNewFlow,
} from '../../../../store/actions/actions/rest';
import Loader from '../../../../components/loader';
import {
  initialValues,
  contain,
  populateValues,
  filters,
  params,
  generateTransitionAdditionalFilter,
} from '../config';
import SingleFlowForm from './singleFlowForm';
import CustomExport from '../../../../components/customButtons/customExport';
import { populateCells, populateDefaultDays } from './hoursOfOperation/helper';
import {
  defaultStepValue,
  sortElements,
  defaultStepTransitionValue,
  findTheFlowStepTransition,
} from './helper';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getFilters } from '../../../../hoc/withTable/getFilters';
import { setItemFlag } from '../../../../store/actions/actions/root';
import { checkEqualityOfVerticalIds } from './helper';

const SingleFlow = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const flowsList = useSelector(state => state.flows.list);
  const auth = useSelector(state => state.auth);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const flowStepsList = useSelector(state => state.flowSteps.list);
  const itemFlag = useSelector(state => state.app.itemFlag);
  const flowStepMapsList = useSelector(state => state.flowStepMaps.list);
  const flowStepTransitionMapsList = useSelector(
    state => state.flowStepTransitionMaps.list,
  );
  const flowStepTransitionList = useSelector(
    state => state.flowStepTransitions.list,
  );
  const loader = useSelector(state => state.app.loader);
  const [formValues, setFormValues] = useState(initialValues);
  const element = useSelector(
    state =>
      (state.flows.list &&
        state.flows.list.find(item => item.id === match.params.id)) ||
      state.flows.item,
  );
  const [cells, setCells] = useState(null);
  const [stepsFlag, setStepsFlag] = useState(false);
  const [showSteps, setShowSteps] = useState(false);
  const newFlow = useSelector(state => state.flows.newFlow);

  useEffect(() => {
    if (itemFlag) {
      history.push('/404');
      dispatch(setItemFlag(false));
    }
  }, [itemFlag, dispatch, history]);

  useEffect(() => {
    if (match.params.id && flowsList && flowsList.length > 0 && auth) {
      let flow = flowsList.find(item => item.id === match.params.id);
      let allFilters = getFilters(filters, auth);
      if (flow) {
        if (match.params.id && !element) {
          dispatch(
            getItem(
              'flows',
              {
                id: match.params.id,
                contain,
                filters: [...allFilters],
              },
              dispatch,
            ),
          );
          return () => {
            dispatch(cancelItem('flows', null, dispatch));
          };
        }
      } else {
        if (!newFlow && checkEqualityOfVerticalIds(vertical_id, location)) {
          history.push({
            pathname: '/flows/list',
            search: `vertical_id=${vertical_id}`,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [
    element,
    match.params.id,
    dispatch,
    flowsList,
    history,
    newFlow,
    auth,
    location,
  ]);

  useEffect(() => {
    if (element) {
      dispatch(clearNewFlow());
    }
  }, [element, dispatch]);

  useEffect(() => {
    if (match.params.id && vertical_id && auth && element) {
      let allFilters = getFilters(filters, auth);
      dispatch(cancelList('flow-step-maps', null, dispatch));
      dispatch(cancelList('flow-step-transition-maps', null, dispatch));
      dispatch(cancelList('flow-steps', null, dispatch));
      dispatch(cancelList('flow-step-transitions', null, dispatch));
      dispatch(
        getList('flow-step-maps', { filters: [...allFilters] }, dispatch),
      );
      dispatch(
        getList(
          'flow-step-transition-maps',
          { filters: [...allFilters] },
          dispatch,
        ),
      );
      dispatch(
        getList('flow-steps', { params, filters: [...allFilters] }, dispatch),
      );
      dispatch(
        getList(
          'flow-step-transitions',
          {
            params,
            filters: [
              ...allFilters,
              generateTransitionAdditionalFilter(element.id),
            ],
          },
          dispatch,
        ),
      );
      return () => {
        dispatch(cancelItem('flow-step-maps', null, dispatch));
        dispatch(cancelItem('flow-step-transition-maps', null, dispatch));
        dispatch(cancelList('flow-steps', null, dispatch));
        dispatch(cancelList('flow-step-transitions', null, dispatch));
      };
    }
  }, [match.params.id, dispatch, flowsList, vertical_id, auth, element]);

  useEffect(() => {
    if (auth && vertical_id) {
      let allFilters = getFilters(filters, auth);
      if (checkEqualityOfVerticalIds(vertical_id, location)) {
        dispatch(
          getList('flows', { contain, filters: [...allFilters] }, dispatch),
        );
      }
      return () => {
        dispatch(cancelList('flows', null, dispatch));
      };
    }
  }, [dispatch, auth, vertical_id, location]);

  // sorting flow step maps
  useEffect(() => {
    if (element) {
      if (
        element &&
        flowStepMapsList &&
        flowStepMapsList.length > 0 &&
        flowStepsList &&
        flowStepsList.length > 0
      ) {
        let allSteps = flowStepMapsList.filter(
          step => step.flow_id === element.id && !step.archived,
        );
        if (allSteps && allSteps.length > 0) {
          let orderedSteps = sortElements(allSteps);
          let steps = [];
          orderedSteps.forEach((step, index) => {
            let fullStepInfo = flowStepsList.find(
              flowStep => flowStep.id === step.flow_step_id,
            );
            let populatedStep = defaultStepValue(fullStepInfo, index + 1);
            populatedStep.flowStepMap_id = step.id;
            steps.push(populatedStep);
          });
          let flow = populateValues(element);
          flow.steps = steps;
          setFormValues(flow);
          setStepsFlag(true);
        } else {
          let flow = populateValues(element);
          flow.steps = [];
          setFormValues(flow);
          setStepsFlag(true);
        }
      } else {
        setFormValues(populateValues(element));
      }
    }
  }, [flowStepMapsList, element, flowStepsList]);

  // sorting flow step transition maps to the proper flow step maps
  useEffect(() => {
    if (
      flowStepTransitionMapsList &&
      flowStepTransitionMapsList.length > 0 &&
      flowStepTransitionList &&
      flowStepTransitionList.length > 0 &&
      formValues.steps &&
      formValues.steps.length > 0 &&
      stepsFlag
    ) {
      let allSteps = JSON.parse(JSON.stringify(formValues.steps));
      allSteps.forEach(step => {
        let transitions = [];
        flowStepTransitionMapsList.forEach(fstm => {
          if (
            +fstm.from_flow_step_id === +step.id &&
            +fstm.from_flow_step_map_id === +step.flowStepMap_id
          ) {
            let transitionItem = findTheFlowStepTransition(
              fstm.flow_step_transition_id,
              flowStepTransitionList,
            );
            let item = defaultStepTransitionValue(transitionItem);
            item.flowStepTransitionMap_id = fstm.id;
            item.fstm = fstm;
            transitions.push(item);
          }
        });
        if (transitions && transitions.length > 0) {
          step.transitions = transitions;
        } else {
          step.transitions = [];
        }
      });
      setFormValues({
        ...{ ...formValues },
        steps: [...allSteps],
      });
      setStepsFlag(false);
      setShowSteps(true);
    } else {
      if (
        flowStepTransitionMapsList &&
        flowStepTransitionMapsList.length === 0
      ) {
        let allSteps = JSON.parse(JSON.stringify(formValues.steps));
        allSteps.forEach(step => (step.transitions = []));
        setFormValues({
          ...{ ...formValues },
          steps: [...allSteps],
        });
        setStepsFlag(false);
        setShowSteps(true);
      }
    }
    // eslint-disable-next-line
  }, [stepsFlag, flowStepTransitionMapsList, flowStepTransitionList]);

  // setting default values for hours of operation
  useEffect(() => {
    if (element) {
      if (element.days_times) {
        let allGeneratedCells = populateCells(7, 48);
        let allCells = populateDefaultDays(
          element.days_times,
          allGeneratedCells,
        );
        setCells(allCells);
      }
    } else {
      let allGeneratedCells = populateCells(7, 48);
      setCells(allGeneratedCells);
    }
  }, [element]);

  const handleOpenChangeLog = () => {
    if (element && element.id) {
      history.push({
        pathname: `/change-logs/${element.id}`,
        search: `vertical_id=${vertical_id}`,
      });
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} container alignItems="flex-end">
        <Grid item xs={1} className={classes.iconContainer}>
          <img src={FlowsIcon} alt="Flows icon" className={classes.titleIcon} />
        </Grid>
        <Grid container item xs={10} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h1" component="h2" gutterBottom>
              Flow
            </Typography>
          </Grid>
        </Grid>
        {element && (
          <CustomExport
            customClass={classes.changeLogButton}
            onClick={handleOpenChangeLog}
            label="Change Log"
          />
        )}
      </Grid>
      <Grid container className={classes.contentContainer}>
        <SingleFlowForm
          formValues={formValues}
          classes={classes}
          flowStepsList={flowStepsList}
          flowStepTransitionList={flowStepTransitionList}
          elementId={match.params.id || ''}
          setFormValues={setFormValues}
          setCells={setCells}
          cells={cells}
          defaultCells={element && element.days_times}
          element={element}
          flowsList={flowsList}
          flowStepMapsList={flowStepMapsList}
          flowStepTransitionMapsList={flowStepTransitionMapsList}
          showSteps={showSteps}
          setShowSteps={setShowSteps}
        />
      </Grid>
      <Grid container item xs={12}></Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(withDashboard(SingleFlow, false), 'Flow');

SingleFlow.propTypes = {
  match: PropTypes.object.isRequired,
};
