import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Pagination from '../../../../components/pagination/paginationWrapper';
import CustomFilterDropDown from '../../customFilterDropDown/customFilterDropDown';
import classNames from 'classnames';

const CustomReportingTable = ({
  list,
  renderElements,
  columns,
  handleClickName,
  search,
  itemSortBy,
  handleChangeFilter,
  filterByDate,
  handleChangeDate,
  filterDates,
  dense,
  handleApplyFilter,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [jumpToPage, setJumpToPage] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [elementsPerPage, setElementsPerPage] = useState(10);
  const [isUserChanged, setIsUserChanged] = useState(false);
  const count = 10;
  const [sortedElement] = useState('name');
  const [sortBy] = useState(itemSortBy);

  useEffect(() => {
    if (list && list.length > 0) {
      renderElements(list, setRows);
    } else {
      setRows([]);
    }
  }, [list, renderElements]);

  useEffect(() => {
    setTotalPages(Math.ceil(count / (list && list.length)));
  }, [count, list]);

  useEffect(() => {
    setTotalCount(count);
    setPage(1);
    if (count === 0) {
      setRowsPerPage(10);
      setElementsPerPage(10);
    } else if (+rowsPerPage > count) {
      setRowsPerPage(count);
      setElementsPerPage(count);
    } else if (+rowsPerPage === count) {
      setRowsPerPage(rowsPerPage);
      setElementsPerPage(rowsPerPage);
    } else if (count > +rowsPerPage && count > 10) {
      setRowsPerPage(+rowsPerPage);
      setElementsPerPage(+rowsPerPage);
    } else if (+rowsPerPage < count) {
      if (search === '') {
        if (isUserChanged) {
          setRowsPerPage(+rowsPerPage);
          setElementsPerPage(+rowsPerPage);
        } else {
          setRowsPerPage(+count);
          setElementsPerPage(+count);
        }
      } else {
        setRowsPerPage(+rowsPerPage);
        setElementsPerPage(+rowsPerPage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const onChangePagination = pageNumber => {
    setPage(pageNumber);
  };

  const handleChangeDirectlyPage = event => {
    if (Number.isInteger(+event.target.value)) {
      setElementsPerPage(event.target.value);
    }
  };

  const handleElementsPerPage = event => {
    if (event.key === 'Enter') {
      if (+event.target.value === 0) {
        setRowsPerPage(10);
        setElementsPerPage(10);
        setIsUserChanged(true);
        setPage(1);
        setJumpToPage('');
      } else {
        setRowsPerPage(event.target.value);
        setElementsPerPage(event.target.value);
        setIsUserChanged(true);
        setPage(1);
        setJumpToPage('');
      }
    }
  };

  const handlePerPageArrow = data => () => {
    if (data === 'less') {
      if (+elementsPerPage === 1) {
        setElementsPerPage(+elementsPerPage);
        setRowsPerPage(+rowsPerPage);
      } else {
        setElementsPerPage(+elementsPerPage - 1);
        setRowsPerPage(+rowsPerPage - 1);
      }
    } else if (data === 'more') {
      setElementsPerPage(+elementsPerPage + 1);
      setRowsPerPage(+rowsPerPage + 1);
    }
  };

  const handleJumpToPageInput = event => {
    if (Number.isInteger(+event.target.value)) {
      if (+event.target.value <= totalPages) {
        setJumpToPage(event.target.value);
      }
    }
    if (event.key === 'Enter') {
      setPage(+event.target.value);
    }
  };

  const handleJumpToPageButton = () => {
    setPage(+jumpToPage);
  };

  const renderNoDataSection = () => {
    if (!list || list.length === 0) {
      return (
        <TableRow>
          <td className={classes.noData}>No Items</td>
        </TableRow>
      );
    }
  };

  // eslint-disable-next-line
  const renderPaginaton = () => {
    if (list || (list && list.length > 0)) {
      return (
        totalCount !== 0 && (
          <Pagination
            classes={classes}
            handlePerPageArrow={handlePerPageArrow}
            elementsPerPage={elementsPerPage}
            handleElementsPerPage={handleElementsPerPage}
            handleChangeDirectlyPage={handleChangeDirectlyPage}
            page={page}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            onChangePagination={onChangePagination}
            jumpToPage={jumpToPage}
            handleJumpToPageInput={handleJumpToPageInput}
            handleJumpToPageButton={handleJumpToPageButton}
          />
        )
      );
    }
  };

  const renderColumn = (id, row, value) => {
    if (id === 'name') {
      return (
        <span
          className={classes.tableCellLink}
          onClick={handleClickName(row.item)}
        >
          {value}
        </span>
      );
    } else return value;
  };

  const renderHeaderClassName = active =>
    active ? classes.tableCellHeaderActive : classes.tableCellHeader;

  return (
    <Grid container>
      <Grid item xs={12}>
        <CustomFilterDropDown
          handleChange={handleChangeFilter}
          value={filterByDate}
          handleChangeDate={handleChangeDate}
          filterDates={filterDates}
          handleApplyFilter={handleApplyFilter}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className={classNames(
                      renderHeaderClassName(sortedElement === column.id),
                      {
                        [classes.stickyNameCell]: column.id === 'name',
                      },
                    )}
                    sortDirection={sortBy[sortedElement]}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {rows &&
                rows.map(row => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.item.id}>
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={Math.floor(Math.random() * 123132)}
                            align={column.align}
                            className={classNames(classes.tableCell, {
                              [classes.tableNameCell]: column.id === 'name',
                            })}
                          >
                            {renderColumn(column.id, row, value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {renderNoDataSection()}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* <Grid item xs={12}>
        {renderPaginaton()}
      </Grid> */}
    </Grid>
  );
};

export default CustomReportingTable;

CustomReportingTable.propTypes = {
  renderElements: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  handleClickName: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  itemSortBy: PropTypes.object.isRequired,
  list: PropTypes.any,
  handleChangeFilter: PropTypes.func.isRequired,
  filterByDate: PropTypes.string.isRequired,
  filterDates: PropTypes.object.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  handleApplyFilter: PropTypes.func.isRequired,
};
