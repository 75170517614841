import {
  startOfYesterday,
  startOfToday,
  startOfMonth,
  endOfYesterday,
  addMinutes,
} from 'date-fns';

export const convertFilter = (field, operator, value) =>
  JSON.stringify({
    field: field,
    operator: operator,
    value: value,
  });

export const reduceUTCString = string =>
  `${string.substr(0, 10)} ${string.substr(11, 8)}`;

export const generateUTCDate = (date, shouldAdd) => {
  let generatedDate = date;
  if (shouldAdd) {
    generatedDate = addMinutes(new Date(date), 1);
  }
  let generatedUTCDate = new Date(generatedDate).toISOString();
  let convertedGeneratedUTCDate = reduceUTCString(generatedUTCDate);
  return convertedGeneratedUTCDate;
};

export const getFilterDates = (filterByDate, filterDates, isChangeLog) => {
  let filters = [];
  let filterName = isChangeLog ? "Notes.created" : 'created';
  const currentTodayDate = new Date();
  let currentUTCTodayDate = new Date(currentTodayDate).toISOString();
  let convertedCurrentUTCTodayDate = reduceUTCString(currentUTCTodayDate);
  switch (filterByDate) {
    case 'today':
      const startOfTodayDate = startOfToday();
      let convertedCurrentUTCDate = generateUTCDate(startOfTodayDate, true);
      filters.push(
        convertFilter(filterName, '>', convertedCurrentUTCDate),
        convertFilter(filterName, '<', convertedCurrentUTCTodayDate),
      );
      break;
    case 'yesterday':
      const startOfYesterdayDate = startOfYesterday();
      const endOfYesterdayDate = endOfYesterday();
      let convertedYesterdayUTCDate = generateUTCDate(
        startOfYesterdayDate,
        true,
      );
      let convertedEndYesterdayUTCDate = generateUTCDate(
        endOfYesterdayDate,
        false,
      );
      filters.push(
        convertFilter(filterName, '>', convertedYesterdayUTCDate),
        convertFilter(filterName, '<', convertedEndYesterdayUTCDate),
      );
      break;
    case 'this-month':
      const todayDate = new Date();
      const startOfTheMonthDate = startOfMonth(todayDate);
      let convertedStartOfTheMonthDate = generateUTCDate(
        startOfTheMonthDate,
        true,
      );
      filters.push(
        convertFilter(filterName, '>', convertedStartOfTheMonthDate),
        convertFilter(filterName, '<', convertedCurrentUTCTodayDate),
      );
      break;
    case 'custom-filter':
      const { startDate, endDate } = filterDates;
      let convertedUTCStartDate = generateUTCDate(startDate, false);
      let convertedUTCEndDate = generateUTCDate(endDate, false);
      filters.push(
        convertFilter(filterName, '>', convertedUTCStartDate),
        convertFilter(filterName, '<', convertedUTCEndDate),
      );
      break;
    default:
      break;
  }
  return filters;
};

export const getDripIds = (list, message_type_id) => {
  let dripIds = ``;
  if (list && list.length > 0) {
    list.forEach(item => {
      if (+item.message_type_id === +message_type_id) {
        dripIds = dripIds ? `${dripIds},${item.id}` : `${item.id}`;
      }
    });
  }

  return convertFilter('drip_id', 'in', dripIds);
};

export const reportingEventsValues = [
  {
    key: '1',
    value: 'Email Send',
    name: 'totalSends',
  },
  {
    key: '2',
    value: 'Email delivered',
    name: 'delivered',
  },
  {
    key: '3',
    value: 'Invalid Emails',
    name: 'invalidEmails',
  },
  {
    key: '4',
    value: 'Email bounce',
    name: 'bounced',
  },
  {
    key: '5',
    value: 'Email spam complaint',
    name: 'spamComplaint',
  },
  {
    key: '6',
    value: 'Email unsubscribe',
    name: 'unsubscribe',
  },
  {
    key: '7',
    value: 'Email Open',
    name: 'opens',
  },
  {
    key: '8',
    value: 'Email Click',
    name: 'clicks',
  },
  {
    key: '9',
    value: 'SMS Send',
    name: 'totalSends',
  },
  {
    key: '10',
    value: 'SMS Click',
    name: 'clicks',
  },
  {
    key: '11',
    value: 'SMS Unsubscribe',
    name: 'unsubscribe',
  },
  {
    key: '12',
    value: 'RVM Send',
    name: 'totalSends',
  },
  {
    key: '13',
    value: 'Call Send',
    name: 'callInitiated',
  },
  {
    key: '14',
    value: 'Post Send',
    name: 'totalSends',
  },
  {
    key: '15',
    value: 'Post Click',
    name: 'clicks',
  },
  {
    key: '16',
    value: 'Flow End',
    name: 'finishedFlow',
  },
  {
    key: '17',
    value: 'Flow Start',
    name: 'startedFlow',
  },
  {
    key: '18',
    value: 'SMS Failed',
    name: 'undelivered',
  },
  {
    key: '19',
    value: 'SMS Delivered',
    name: 'delivered',
  },
  {
    key: '20',
    value: 'RVM Delivered',
    name: 'delivered',
  },
  {
    key: '21',
    value: 'Cannot RVM',
    name: 'cannotRvm',
  },
  {
    key: '22',
    value: 'Post Delivered',
    name: 'delivered',
  },
  {
    key: '23',
    value: 'Post Undelivered',
    name: 'undelivered',
  },
  {
    key: '24',
    value: 'Flow Undelivered',
    name: 'undelivered',
  },
  {
    key: '25',
    value: 'Flow Unsubscribed',
    name: 'unsubscribed',
  },
];

export const convertReportingData = data => {
  let elementList = [];
  if (data && data.length > 0) {
    data.forEach(item => {
      let element = {
        id: item.drip_id,
        name: item.Drip_Name,
      };
      Object.entries(item.counts).forEach(([key, value]) => {
        let elementEvent = reportingEventsValues.find(
          event => +event.key === +key,
        );
        if (elementEvent) {
          element = {
            ...element,
            [elementEvent.name]: value,
          };
        }
      });
      elementList.push(element);
    });
  }
  return elementList;
};
