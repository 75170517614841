import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '20px 50px 0 50px',
    flexGrow: 1,
    [theme.breakpoints.up('xl')]: {
      padding: '20px 166px 0 166px',
    },
  },
  settingsItem: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 5,
    padding: '10px 17px',
    width: '100%',
    display: 'flex',
    [theme.breakpoints.up('xl')]: {
      padding: '25px 28px',
    },
  },
  settingsText: {
    fontSize: 16,
    [theme.breakpoints.up('xl')]: {
      fontSize: 18,
    },
  },
  title: {
    position: 'relative',
    top: 20,
  },
}));

export default useStyles;
