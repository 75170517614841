import {
  reportingEventsValues,
  convertFilter,
} from '../singleReportingPage/config';

export const sortBy = {
  name: 'asc',
  startedFlow: 'asc',
  finishedFlow: 'asc',
  undelivered: 'asc',
  unsubscribe: 'asc',
  //   exited: 'asc',
  //   active: 'asc',
};

export const columns = [
  {
    id: 'name',
    label: 'Flow Name',
    align: 'left',
  },
  {
    id: 'startedFlow',
    label: 'Started Flow',
    align: 'left',
  },
  {
    id: 'finishedFlow',
    label: 'Finished Flow',
    align: 'left',
  },
  {
    id: 'undelivered',
    label: 'Undelivered',
    align: 'left',
  },
  {
    id: 'unsubscribed',
    label: 'Unsubscribed',
    align: 'left',
  },
  {
    id: 'action',
    label: '',
    align: 'left',
  },
];

export const convertReportingData = data => {
  let elementList = [];
  if (data && data.length > 0) {
    data.forEach(item => {
      let element = {
        id: item.flow_id,
        name: item.Flow_Name,
      };
      Object.entries(item.counts).forEach(([key, value]) => {
        let elementEvent = reportingEventsValues.find(
          event => +event.key === +key,
        );
        if (elementEvent) {
          element = {
            ...element,
            [elementEvent.name]: value,
          };
        }
      });
      elementList.push(element);
    });
  }
  return elementList;
};

export const filters = [
  {
    field: 'vertical_id',
    operator: '=',
    path: 'vertical_id',
  },
  {
    field: 'account_id',
    operator: '=',
    path: 'account_id',
  },
];

export const contain = ['model_tags.Tags', 'flow_step_transitions'];

export const getFlowIds = list => {
  let flowIds = ``;
  if (list && list.length > 0) {
    list.forEach(
      item => (flowIds = flowIds ? `${flowIds},${item.id}` : `${item.id}`),
    );
  }

  return convertFilter('flow_id', 'in', flowIds);
};

export const getAllFlowStepMapsIds = list => {
  let flowStepMapsIds = ``;
  if (list && list.length > 0) {
    list.forEach(
      item =>
        (flowStepMapsIds = flowStepMapsIds
          ? `${flowStepMapsIds},${item.id}`
          : `${item.id}`),
    );
  }
  return convertFilter('flow_step_map_id', 'in', flowStepMapsIds);
};

export const getStepType = step => {
  let typeName = '';
  if (step.counts.hasOwnProperty('1')) {
    typeName = 'send_email';
  } else if (step.counts.hasOwnProperty('9')) {
    typeName = 'send_sms';
  } else if (step.counts.hasOwnProperty('12')) {
    typeName = 'send_rvm';
  } else if (step.counts.hasOwnProperty('13')) {
    typeName = 'send_call';
  } else if (step.counts.hasOwnProperty('14')) {
    typeName = 'send_post';
  }
  return typeName;
};

export const convertReportingSteps = data => {
  let elementList = [];
  if (data && data.length > 0) {
    data.forEach(item => {
      let element = {
        flow_step_map_id: item.flow_step_map_id,
        flow_step_position: item.flow_step_position,
        archived: item.archived,
        current_version: item.current_version,
        flow_step_transitions: item.flow_step_transitions,
        name: item.Step_Name,
        created_date: item && item.created_date ? item.created_date : null,
        archived_date: item && item.archived_date ? item.archived_date : null,
      };
      let drip_type = getStepType(item);
      element.drip_type = drip_type;
      Object.entries(item.counts).forEach(([key, value]) => {
        let elementEvent = reportingEventsValues.find(
          event => +event.key === +key,
        );
        if (elementEvent) {
          element = {
            ...element,
            [elementEvent.name]: value,
          };
        }
      });
      elementList.push(element);
    });
  }
  return elementList;
};

export const lowestToHighest = data => {
  let copiedData = [...data];
  copiedData.sort((a, b) => a.flow_step_position - b.flow_step_position);
  return copiedData;
};

export const getFlowStepId = (id, list) => {
  let flowStepId = null;
  if (list && list.length > 0) {
    let item = list.find(listItem => +listItem.id === +id);
    if (item) {
      flowStepId = item.flow_step_id;
    }
  }
  return flowStepId;
};

const createData = (
  id,
  name,
  startedFlow,
  finishedFlow,
  undelivered,
  unsubscribed,
  item,
) => {
  return {
    id,
    name,
    startedFlow,
    finishedFlow,
    undelivered,
    unsubscribed,
    item,
  };
};

export const renderElements = (list, setRows) => {
  let allRows = [];
  list &&
    list.forEach(item => {
      const {
        id,
        name,
        startedFlow,
        finishedFlow,
        undelivered,
        unsubscribed,
      } = item;
      allRows.push(
        createData(
          id,
          name,
          startedFlow,
          finishedFlow,
          undelivered,
          unsubscribed,
          item,
        ),
      );
    });
  setRows(allRows);
};

export const mobileColumns = [
  {
    id: 'name',
    label: 'Flow Name',
    align: 'left',
    minWidth: 150,
  },
  {
    id: 'startedFlow',
    label: 'Started Flow',
    align: 'left',
    minWidth: 150,
  },
  {
    id: 'finishedFlow',
    label: 'Finished Flow',
    align: 'left',
    minWidth: 150,
  },
  {
    id: 'undelivered',
    label: 'Undelivered',
    align: 'left',
    minWidth: 150,
  },
  {
    id: 'unsubscribed',
    label: 'Unsubscribed',
    align: 'left',
    minWidth: 150,
  },
];
