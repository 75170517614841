import React, { useEffect } from 'react';
import withDashboard from '../../hoc/withDashboard/withDashboard';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withTitle from '../../hoc/withTitle';
import { useSelector, useDispatch } from 'react-redux';
import {
  setActiveVertical,
  cancelActiveVertical,
} from '../../store/actions/actions/auth';
import Loader from '../../components/loader';
import {
  params,
  filters,
  contain,
  renderElements,
  columns,
  getItemLink,
} from './config';
import { useHistory } from 'react-router-dom';
import RecentNotesTable from '../../components/recentNotesTable';

const Dashboard = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const allVerticals = useSelector(state => state.auth.verticals);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const loader = useSelector(state => state.app.loader);

  useEffect(() => {
    if (match.params.id && allVerticals && allVerticals.length > 0) {
      allVerticals.forEach(vertical => {
        if (+match.params.id === vertical.id) {
          dispatch(cancelActiveVertical(null, dispatch));
          dispatch(setActiveVertical(vertical.id, dispatch));
        }
      });
    }
  }, [match, allVerticals, dispatch]);

  const handleClickAllNotes = () => {
    history.push({
      pathname: `/notes`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  return (
    <Grid container className={classes.container} spacing={4}>
      <Grid item xs={12} lg={6}>
        <Typography variant="h1" component="h2" className={classes.title}>
          Welcome!
        </Typography>
        <Typography variant="subtitle1" component="h2" paragraph className={classes.subtitle}>
          View your widgets and your organization’s latest activity and alerts.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RecentNotesTable
          classes={classes}
          params={params}
          filters={filters}
          contain={contain}
          renderElements={renderElements}
          columns={columns}
          getItemLink={getItemLink}
          handleClickAllNotes={handleClickAllNotes}
        />
      </Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(withDashboard(Dashboard), 'Vertical');
