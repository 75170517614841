import queryString from 'query-string';

export const getTheFlowName = (id, list) => {
  let flow = list.find(item => item.id === id);
  return flow ? flow.name : '';
};

export const populateStepMapValue = (
  flow_id,
  step,
  index,
  next_flow_step,
  account_id,
  vertical_id,
) => {
  let data = {
    body: {
      flow_id: flow_id,
      flow_step_id: step.id,
      position: index,
      account_id: account_id,
      vertical_id: vertical_id,
      next_flow_step_map_id: next_flow_step ? next_flow_step.id : null,
    },
  };
  return data;
};

export const populateStepTransitionMapValue = (
  flow_id,
  step,
  transition,
  account_id,
  vertical_id,
  flow_step_map_id,
) => {
  let data = {
    body: {
      from_flow_step_id: step.id,
      from_flow_id: flow_id,
      account_id: account_id,
      vertical_id: vertical_id,
      flow_step_transition_id: transition.id,
      from_flow_step_map_id: flow_step_map_id,
    },
  };
  return data;
};

export const defaultStepValue = (step, order, transitions) => {
  let id = step ? step.id : '';
  let allTransitions = transitions ? transitions : [];
  let newStep = {
    order: order,
    transitions: allTransitions,
    id: id,
    step: step || null,
  };
  return newStep;
};

export const defaultStepTransitionValue = transition => {
  let id = transition ? transition.id : '';
  let newTransition = {
    id: id,
    transition: transition || null,
  };
  return newTransition;
};

export const sortElements = list =>
  list.sort((a, b) => +a.position - +b.position);

export const findTheFlowStepMapById = (id, list) =>
  list.find(item => item.id === id);

export const findTheFlowStep = (flowStepId, list) =>
  list.find(item => item.id === flowStepId);

export const findTheFlowStepTransitionMap = (id, list) =>
  list.find(item => item.flow_step_transition_id === id);

export const findTheFlowStepTransition = (id, list) =>
  list.find(item => item.id === id);

export const getReportingObject = steps => {
  let object = {};
  steps.forEach(step => (object[step.id] = false));
  return object;
};

export const getReportingStep = (id, steps) =>
  steps.find(step => +step.id === +id);

export const getDripType = id => {
  switch (id) {
    case 1:
      return 'send_sms';
    case 2:
      return 'send_email';
    case 3:
      return 'send_rvm';
    case 4:
      return 'send_call';
    case 5:
      return 'send_post';
    default:
      break;
  }
};

export const checkEqualityOfVerticalIds = (vertical_id, location) => {
  let locationParams = queryString.parse(location.search);
  return +vertical_id === +locationParams.vertical_id;
};

export const filterArraysForUniqueElements = (array1, array2) => {
  let newArray = [];
  array1.forEach(item1 => {
    if (!array2.find(item2 => +item1.id === +item2.id)) {
      newArray.push(item1);
    }
  });
  return newArray;
};

export const updateFlowStepOrder = steps => {
    let allSteps = [...steps];
    allSteps.forEach((step, index) => (step.order = index));
    return allSteps;
  };
