import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import PromptDialog from './promptDialog';

const LeavingRoutePrompt = ({ when, navigate, shouldBlockNavigation }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleCloseModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  // prevent reloading the page or closing the tab with unsaved changes
  useEffect(() => {
    window.onbeforeunload = when ? () => true : null;
    return () => {
      window.onbeforeunload = null;
    };
  }, [when]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <PromptDialog
        open={modalVisible}
        titleText="You have unsaved changes!"
        contentText="Leaving the page will not save changes you made. Are you sure you want to leave this page without saving?"
        cancelButtonText="No"
        confirmButtonText="Yes"
        handleCloseModal={handleCloseModal}
        handleConfirmNavigationClick={handleConfirmNavigationClick}
      />
    </>
  );
};
export default LeavingRoutePrompt;
