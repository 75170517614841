import React, { Fragment, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import Pagination from './pagination';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const columns = [
  { id: 'subject', label: 'Subject', minWidth: 60, align: 'left' },
  { id: 'description', label: 'Description', minWidth: 190, align: 'left' },
  {
    id: 'created',
    label: 'Created',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'modified',
    label: 'Modified',
    minWidth: 150,
    align: 'left',
  },
];

const createData = (subject, description, created, modified) => {
  return { subject, description, created, modified };
};

const rows = [
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
  createData(
    'Alert Subject',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi...',
    'Jan, 1 2018',
    'Jan, 1 2018',
  ),
];

const AlertTable = () => {
  const classes = useStyles();
  const [page] = useState('');
  const [rowsPerPage] = useState(10);

  const onChangePagination = pageNumber => {
    //
  };

  const handleChangeDirectlyPage = event => {
    //
  };

  return (
    <Fragment>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={Math.floor(Math.random() * 123132)}
                >
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={Math.floor(Math.random() * 123132)}
                        align={column.align}
                      >
                        {column.id === 'subject' ? (
                          <span className={classes.link}>{value}</span>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container alignItems="center">
        <Grid container item xs={3} lg={2} xl={3} justify="flex-end">
          <div className={classes.resultsPerPageLabel}>
            Results <span>Per Page</span>
          </div>
          <TextField
            value={page}
            onChange={handleChangeDirectlyPage}
            className={classes.resultsPerPage}
            variant="outlined"
            placeholder="10"
            InputLabelProps={{
              classes: {
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              endAdornment: (
                <div>
                  <ExpandLessIcon /> <ExpandMoreIcon />
                </div>
              ),
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </Grid>
        <Grid item xs={6} lg={8} xl={6}>
          <Pagination
            activePage={1}
            itemsPerPage={rowsPerPage}
            totalItemsCount={100}
            onChange={onChangePagination}
          />
        </Grid>
        <Grid item xs={3} lg={2} xl={3}>
          <TextField
            value={page}
            onChange={handleChangeDirectlyPage}
            className={classes.pageInput}
            variant="outlined"
            placeholder="Page #"
            InputLabelProps={{
              classes: {
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              endAdornment: <span>Go</span>,
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AlertTable;
