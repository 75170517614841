import React from 'react';
import { renderElements, columns, sortBy, filters } from './config';
import ReportingTable from '../singleReportingPage/table';

const CallsDataReporting = () => {
  return (
    <ReportingTable
      name="Calls"
      renderElements={renderElements}
      columns={columns}
      sortBy={sortBy}
      itemPath="call-drip"
      filters={filters}
      messageTypeId="4"
    />
  );
};

export default CallsDataReporting;
