import React from 'react';
import { VerifyContact } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../../../assets/images/login-logo.png';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class CustomRequireNewPassword extends VerifyContact {
  constructor(props) {
    super(props);
    this._validAuthStates = ['verifyContact'];
    this.state = { verifyAttr: null };
  }

  verifyView = classes => {
    const user = this.props.authData;
    if (!user) {
      return null;
    }
    const { unverified } = user;
    if (!unverified) {
      return null;
    }
    const { email } = unverified;
    return (
      <>
        {email ? (
          <FormControlLabel
            placeholder="Email"
            key="email"
            name="contact"
            value="email"
            onChange={this.handleInputChange}
            className={classes.inputCheckBox}
            control={<Checkbox color="primary" />}
            label="Email"
            labelPlacement="Email"
          />
        ) : null}
      </>
    );
  };

  submitView = classes => (
    <Input
      type="text"
      placeholder="Code"
      key="code"
      name="code"
      autoComplete="off"
      onChange={this.handleInputChange}
      disableUnderline={true}
      className={classes.inputField}
    />
  );

  showComponent = () => {
    const { classes } = this.props;

    return (
      <div className={classes.backgroundLayer}>
        <div className={classes.loginContainer}>
          <div>
            <img src={Logo} alt="logo" />
            <div className={classes.verifyContactTitle}>
              Account recovery requires verified contact information
            </div>
            {this.state.verifyAttr
              ? this.submitView(classes)
              : this.verifyView(classes)}
            {this.state.verifyAttr ? (
              <Button
                type="button"
                className={classes.verifyContactButton}
                onClick={() => super.submit('heelo')}
              >
                Submit
              </Button>
            ) : (
              <Button
                type="button"
                className={classes.verifyContactButton}
                onClick={() => super.verify()}
              >
                Verify
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(CustomRequireNewPassword);
