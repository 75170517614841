import { get } from '../../../config/api';
import { switchMap, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { GET_CHANGE_LOGS } from '../../actions/actionTypes/changeLogs';
import { displaySnackbar, hideLoader } from '../../actions/actions/root';
import { saveChangeLogs } from '../../actions/actions/changeLogs';
import { of, merge } from 'rxjs';
import { consoleError } from '../../../config/helpers';
import { getAuth, authRejected } from '../../actions/actions/auth';
import { handleErrorMessage } from './auth';

const auth = action$ => {
  return action$.pipe(
    mergeMap(action => of(getAuth(action))),
    mergeMap(({ payload }) =>
      payload.auth
        .then(data => ({
          token: data.signInUserSession.accessToken.jwtToken,
          params: payload.data.payload,
        }))
        .catch(() => window.location.reload()),
    ),
    catchError(error => {
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          authRejected(error),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
      );
    }),
  );
};

export const getChangeLogsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(GET_CHANGE_LOGS.actionTypes.START),
    action$ => auth(action$),
    switchMap(({ params, token }) =>
      get(
        `flows/${params.id}/change-logs`,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        params.params,
      ).pipe(
        mergeMap(data => [saveChangeLogs(data.response), hideLoader()]),
        catchError(error => {
          consoleError(error);
          let message = handleErrorMessage(error);
          return merge(
            of(
              hideLoader(),
              displaySnackbar({
                type: 'error',
                message,
              }),
            ),
          );
        }),
        takeUntil(action$.pipe(ofType(GET_CHANGE_LOGS.actionTypes.FAILURE))),
      ),
    ),
    catchError((error, caught) => {
      consoleError(error);
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
        caught,
      );
    }),
  );
};
