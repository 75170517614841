import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './styles';
import TransitionRow from './transitionRow';
import StepRow from './stepRow';
import classNames from 'classnames';
import {
  defaultStepValue,
  populateStepMapValue,
  defaultStepTransitionValue,
  findTheFlowStep,
  findTheFlowStepMapById,
  updateFlowStepOrder,
} from './helper';
import {
  editStart,
  clearNewFlowStepMap,
} from '../../../../store/actions/actions/rest';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InlineFlowReports from './inlineFlowReports';
import { getReportingObject, getReportingStep } from './helper';

const FlowStepList = ({
  formik,
  flowStepTransitionList,
  flowStepsList,
  flowsList,
  elementId,
  formValues,
  setFormValues,
  dispatch,
  account_id,
  vertical_id,
  flowStepMapsList,
  showSteps,
  setShowSteps,
  isFlowLive,
  element,
  handlePromptFlag,
}) => {
  const classes = useStyles();
  const { steps } = formValues;
  const loader = useSelector(state => state.app.loader);
  const newFlowStepMap = useSelector(
    state => state.flowStepMaps.newFlowStepMap,
  );
  const [currentStepRowId, setCurrentStepRowId] = useState(null);
  const [currentStepTransitionRowId, setCurrentStepTransitionRowId] = useState(
    null,
  );
  const [stepSelected, setStepSelected] = useState(null);
  const [stepIndex, setStepIndex] = useState(null);
  const [shouldUpdateFsm, setShouldUpdateFsm] = useState(false);
  const [stepsReporting, setStepsReporting] = useState(null);
  const [activeStepReport, setActiveStepReport] = useState(null);

  useEffect(() => {
    if (steps && steps.length > 0) {
      let reportingObject = getReportingObject(steps);
      setStepsReporting(reportingObject);
    }
  }, [steps]);

  const handleAddStep = () => {
    handlePromptFlag();
    setShowSteps(true);
    let newStep = defaultStepValue(null, 0);
    let allSteps = JSON.parse(JSON.stringify(steps));
    let orderedSteps = updateFlowStepOrder(allSteps);
    let allNewSteps = [newStep, ...orderedSteps];
    setFormValues({
      ...{ ...formik.values },
      steps: [...allNewSteps],
    });
  };

  const updateTheFlowStep = (index, _selectedStep, newFlowStepMap) => {
    let previousStepId = steps[index].flowStepMap_id;
    let previousFlowStepMap = findTheFlowStepMapById(
      previousStepId,
      flowStepMapsList,
    );
    let flowStep = findTheFlowStep(
      previousFlowStepMap.flow_step_id,
      flowStepsList,
    );
    let data = populateStepMapValue(
      elementId,
      flowStep,
      index,
      newFlowStepMap,
      account_id,
      vertical_id,
    );
    data.body.id = previousFlowStepMap.id;
    dispatch(editStart('flow-step-maps', data, dispatch));
  };

  useEffect(() => {
    if (newFlowStepMap && shouldUpdateFsm) {
      updateTheFlowStep(stepIndex, stepSelected, newFlowStepMap);
      setStepSelected(null);
      setStepIndex(null);
      setShouldUpdateFsm(false);
      dispatch(clearNewFlowStepMap());
    } else {
      dispatch(clearNewFlowStepMap());
    }
    // eslint-disable-next-line
  }, [newFlowStepMap, dispatch, shouldUpdateFsm, stepIndex, stepSelected]);

  const handleChangeSteps = (event, index) => {
    handlePromptFlag();
    let selectedStep = flowStepsList.find(
      step => step.id === +event.target.value,
    );
    const allSteps = JSON.parse(JSON.stringify(steps));
    allSteps[index].step = selectedStep;
    allSteps[index].id = selectedStep.id;
    setFormValues({
      ...{ ...formik.values },
      steps: [...allSteps],
    });
  };

  const handleAddStepBellow = index => () => {
    handlePromptFlag();
    const allSteps = JSON.parse(JSON.stringify(steps));
    const previousStep = allSteps[index];
    let newStep = defaultStepValue(null, previousStep.order + 1);
    allSteps.splice(index + 1, 0, newStep);
    setFormValues({
      ...{ ...formik.values },
      steps: [...allSteps],
    });
  };

  const handleDeleteStep = () => {
    handlePromptFlag();
    const steps = [...formValues.steps];
    steps.splice(currentStepRowId, 1);
    setFormValues({
      ...{ ...formik.values },
      steps,
    });
  };

  const handleDeleteEmptyStep = index => () => {
    handlePromptFlag();
    const steps = [...formValues.steps];
    steps.splice(index, 1);
    setFormValues({
      ...{ ...formik.values },
      steps,
    });
  };

  const handleAddTransition = index => () => {
    handlePromptFlag();
    let totalStepTransitionsNumber = steps[index].transitions.length;
    let newTransition = defaultStepTransitionValue(
      null,
      totalStepTransitionsNumber + 1,
    );
    let allSteps = [...formValues.steps];
    let step = allSteps[index];
    step.transitions.push(newTransition);
    setFormValues({
      ...{ ...formik.values },
      allSteps: [
        ...formValues.steps,
        { ...steps[index], transitions: step.transitions },
      ],
    });
  };

  const handleChangeTransition = (event, index, stepIndex) => {
    handlePromptFlag();
    let selectedStepTransition = flowStepTransitionList.find(
      transition => transition.id === +event.target.value,
    );
    let allFlowSteps = [...formValues.steps];
    let step = allFlowSteps[stepIndex];
    let transition = step.transitions[index];
    transition.transition = selectedStepTransition;
    transition.id = selectedStepTransition.id;
    setFormValues({
      ...{ ...formik.values },
      allFlowSteps,
    });
  };

  const handleAddBellowTransition = (stepIndex, index) => () => {
    handlePromptFlag();
    let newTransition = defaultStepTransitionValue(null, index + 1);
    let allSteps = [...formValues.steps];
    let transitions = allSteps[stepIndex].transitions;
    transitions.splice(index + 1, 0, newTransition);
    setFormValues({
      ...{ ...formik.values },
      allSteps: [
        ...formValues.steps,
        { ...steps[stepIndex], transitions: transitions },
      ],
    });
  };

  const handleDeleteEmptyTransition = (stepIndex, index) => () => {
    handlePromptFlag();
    let allSteps = [...formValues.steps];
    let transitions = allSteps[stepIndex].transitions;
    transitions.splice(index, 1);
    setFormValues({
      ...{ ...formik.values },
      allSteps: [
        ...formValues.steps,
        { ...steps[stepIndex], transitions: transitions },
      ],
    });
  };

  const handleDeleteTransition = () => {
    handlePromptFlag();
    let allSteps = [...formValues.steps];
    let transitions = allSteps[currentStepRowId].transitions;
    transitions.splice(currentStepTransitionRowId, 1);
    setFormValues({
      ...{ ...formik.values },
      allSteps: [
        ...formValues.steps,
        { ...steps[stepIndex], transitions: transitions },
      ],
    });
  };

  const renderTransitions = (transitions, stepIndex, unselectedTransition, allUsedTransitions) => {
    return (
      transitions &&
      transitions.map((transition, index) => (
        <TransitionRow
          formik={formik}
          classes={classes}
          first={index === 0}
          transitionRow={transition}
          index={index}
          stepIndex={stepIndex}
          handleAddBellowTransition={handleAddBellowTransition}
          flowStepTransitionList={flowStepTransitionList}
          handleChangeTransition={handleChangeTransition}
          key={`${index}${transition.name}`}
          flowsList={flowsList}
          steps={formValues.steps}
          loader={loader}
          handleDelete={handleDeleteTransition}
          setCurrentStepTransitionRowId={setCurrentStepTransitionRowId}
          setCurrentStepRowId={setCurrentStepRowId}
          handleDeleteEmptyTransition={handleDeleteEmptyTransition}
          isFlowLive={isFlowLive}
          unselectedTransition={unselectedTransition}
          allUsedTransitions={allUsedTransitions}
        />
      ))
    );
  };

  const handleClickStepReporting = id => () => {
    let object = { ...stepsReporting };
    Object.entries(object).forEach(([key]) => (object[key] = false));
    object[id] = !stepsReporting[id];
    if (object[id]) {
      let step = getReportingStep(id, steps);
      setActiveStepReport(step);
    } else {
      setActiveStepReport(null);
    }
    setStepsReporting(object);
  };

  const renderSteps =
    steps && steps.length > 0 && showSteps
      ? steps.map((step, index) => {
          let unselectedTransition = false;
          let allUsedTransitions = [];
          if (step.transitions && step.transitions.length > 0) {
            let item = step.transitions.find(
              transition => !Boolean(transition.transition),
            );
            if (item) unselectedTransition = true;
            step.transitions.forEach(transition => {
                if(transition.transition) allUsedTransitions.push(transition.transition)
            })
          }
          return (
            <Grid item xs={12} className={classes.stepBorder} key={index}>
              <StepRow
                formik={formik}
                classes={classes}
                stepRow={step}
                index={index}
                handleDeleteStep={handleDeleteStep}
                handleAddStepBellow={handleAddStepBellow}
                handleAddTransition={handleAddTransition}
                flowStepsList={flowStepsList}
                handleChangeSteps={handleChangeSteps}
                key={index}
                steps={formValues.steps}
                handleDeleteEmptyStep={handleDeleteEmptyStep}
                setCurrentStepRowId={setCurrentStepRowId}
                loader={loader}
                handleClickStepReporting={handleClickStepReporting}
                stepsReporting={stepsReporting}
                isFlowLive={isFlowLive}
                unselectedTransition={unselectedTransition}
              />
              {renderTransitions(step.transitions, index, unselectedTransition, allUsedTransitions)}
            </Grid>
          );
        })
      : null;

  return (
    <Grid item xs={12}>
      <Grid
        container
        item
        xs={12}
        className={classNames(classes.flowContainer, {
          [classes.flowContainerBorder]: !steps.length > 0,
        })}
        alignItems="flex-end"
      >
        <Grid item>
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.flowTitle}
          >
            Flow Steps
          </Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.addFlowStepBtn}
            startIcon={<AddIcon />}
            onClick={handleAddStep}
            disabled={isFlowLive}
          >
            Add Step
          </Button>
        </Grid>
        {isFlowLive && (
          <Grid className={classes.flowStatusLabel}>
            <div>Status:</div>
            <div
              className={
                element && element.is_live ? classes.flowStatusLabelActive : ''
              }
            >
              {element && element.is_live ? 'Populated' : 'Empty'}
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} className={classes.stepsSection}>
        <Grid className={classes.stepsWrapper} item xs={6}>
          {renderSteps}
        </Grid>
        {activeStepReport && isFlowLive && (
          <Grid item xs={6} className={classes.inlineReportingContainer}>
            {steps && steps.length > 0 && (
              <InlineFlowReports classes={classes} step={activeStepReport} />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FlowStepList;

FlowStepList.propTypes = {
  formik: PropTypes.object.isRequired,
  flowStepTransitionList: PropTypes.array,
  flowStepsList: PropTypes.array,
  flowsList: PropTypes.array,
  elementId: PropTypes.string,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  account_id: PropTypes.number,
  vertical_id: PropTypes.number,
  flowStepMapsList: PropTypes.array,
  flowStepTransitionMapsList: PropTypes.array,
  stepsContainerHeight: PropTypes.number,
  showSteps: PropTypes.bool,
  setShowSteps: PropTypes.func.isRequired,
  isFlowLive: PropTypes.bool.isRequired,
  handlePromptFlag: PropTypes.func.isRequired,
};
