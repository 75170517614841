import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const CallbackUrl = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
  disabled,
}) => {

  return (
    <div className={wrapperClass}>
      {hideLabel ? null : (
        <label htmlFor="callback_url">{labelText || 'URL'}</label>
      )}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'callback_url'] && touched[name || 'callback_url']
            ? errorClass || 'has-error'
            : ''
        }`}
        name={name || 'callback_url'}
        placeholder={placeholder || 'URL'}
        type="text"
        disabled={disabled}
        onChange={e => {
          handleChange(e);
        }}
      />
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'callback_url'}
          component="div"
          className="field-error"
          data-testid="name-error"
        />
      )}
    </div>
  );
};

export default CallbackUrl;

CallbackUrl.propTypes = {
  hideLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  wrapperClass: PropTypes.string,
  disabled: PropTypes.bool,
};
