import Table from '../../../hoc/withTable/table';
import withTable from '../../../hoc/withTable/withTable';
import { contain, columns, renderElements, sortBy, filters } from './config';

export default withTable(Table, {
  contain,
  columns,
  renderElements,
  sortBy,
  filters,
});
