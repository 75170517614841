const getValue = (obj, path) => {
  if (!path) return obj;
  const properties = path.split('.');
  return getValue(obj[properties.shift()], properties.join('.'))
}

export const getFilters = (filters, state) => {
  let allFilters = [];
  if(filters){
    filters.forEach(filter => {
      let value = getValue(state, filter.path);
      allFilters.push(JSON.stringify({
        field:filter.field,
        operator: filter.operator,
        value: value
      }))
    })
  }
  return allFilters;
}
