import React from 'react';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Table from './emailSubjectTextTable';
import { initialValues, populateValues } from './config';
import DripComponent from '../dripComponent/dripComponent';

const EmailSubjectText = () => {
  return (
    <DripComponent
      title="Email Subject Text"
      item="Email Subject Text"
      urlPath="email-subject-components"
      model_name="EmailSubjectComponent"
      fields={['name']}
      Table={Table}
      populateValues={populateValues}
      initialValues={initialValues}
      modal
    />
  );
};

export default withTitle(
  withDashboard(EmailSubjectText, false),
  'Email Subject Text',
);
