import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import withDashboard from '../../hoc/withDashboard/withDashboard';
import withTitle from '../../hoc/withTitle';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../components/loader';
import Search from '../../components/search';
import CustomTable from './table';
import { useHistory } from 'react-router-dom';
import {
  notesSortyByAllVerticals,
  contain,
  generateFilters,
  columnsAllVerticals,
  renderElementsAllVertical,
  getItemLink,
} from './config';
import {
  getList,
  cancelList,
  saveParams,
  saveFilters,
  saveContain,
  cancelListCount,
  getListCount,
} from '../../store/actions/actions/rest';

const NotesTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.app.loader);
  const account_id = useSelector(state => state.auth.account_id);
  const list = useSelector(state => state.notes.list);
  const verticalsList = useSelector(state => state.verticals.list);
  const count = useSelector(state => state.notes.count);
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortedElement, setSortedElement] = useState('created');
  const [sortBy, setSortBy] = useState(notesSortyByAllVerticals);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isUserChanged, setIsUserChanged] = useState(false);
  const [elementsPerPage, setElementsPerPage] = useState(10);

  useEffect(() => {
    if (auth && account_id) {
      const allFilters = generateFilters(account_id, null);
      const params = {
        page: page,
        limit: rowsPerPage,
        direction: sortBy[sortedElement],
        search: search,
        sort: sortedElement,
      };
      dispatch(saveParams('notes', params));
      dispatch(saveFilters('notes', allFilters));
      dispatch(saveContain('notes', contain));
      dispatch(cancelList('notes', null, dispatch));
      dispatch(cancelListCount('notes', null, dispatch));
      dispatch(
        getList(
          'notes',
          { params, contain: contain, filters: [...allFilters] },
          dispatch,
        ),
      );
      dispatch(
        getListCount('notes', { params, filters: [...allFilters] }, dispatch),
      );
    }
    return () => {
      dispatch(cancelList('notes', null, dispatch));
    };
  }, [
    auth,
    account_id,
    dispatch,
    page,
    rowsPerPage,
    search,
    sortBy,
    sortedElement,
  ]);

  useEffect(() => {
    setTotalPages(Math.ceil(count / (list && list.length)));
  }, [count, list]);

  useEffect(() => {
    setTotalCount(count);
    setPage(1);
    if (count === 0) {
      setRowsPerPage(10);
      setElementsPerPage(10);
    } else if (+rowsPerPage > count) {
      setRowsPerPage(count);
      setElementsPerPage(count);
    } else if (+rowsPerPage === count) {
      setRowsPerPage(rowsPerPage);
      setElementsPerPage(rowsPerPage);
    } else if (count > +rowsPerPage && count > 10) {
      setRowsPerPage(+rowsPerPage);
      setElementsPerPage(+rowsPerPage);
    } else if (+rowsPerPage < count) {
      if (search === '') {
        if (isUserChanged) {
          setRowsPerPage(+rowsPerPage);
          setElementsPerPage(+rowsPerPage);
        } else {
          setRowsPerPage(+count);
          setElementsPerPage(+count);
        }
      } else {
        setRowsPerPage(+rowsPerPage);
        setElementsPerPage(+rowsPerPage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const handleClearSearch = () => {
    setRowsPerPage(10);
  };

  const handleClickName = item => () => {
    const itemPath = getItemLink(item);
    if (itemPath) {
      history.push({
        pathname: `${itemPath}${item.model_id}`,
        search: `vertical_id=${item.vertical_id}`,
      });
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.notesTopContainer}>
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          className={classes.titleContainer}
        >
          <Grid item xs={12}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.notesTitle}
            >
              Notes
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignContent="flex-end"
          justify="flex-start"
        >
          <Typography
            variant="subtitle1"
            component="h2"
            className={classes.subtitle}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod.
          </Typography>
        </Grid>
        <Grid container item xs={6}>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justify="flex-end"
            alignItems="flex-end"
            className={classes.searchGridContainerNotes}
          >
            <Grid item className={classes.searchContainer}>
              <Search
                type="Notes"
                setSearch={setSearch}
                classes={classes}
                handleClearSearch={handleClearSearch}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.contentContainer}>
        <CustomTable
          list={list}
          renderElements={renderElementsAllVertical}
          columns={columnsAllVerticals}
          handleClickName={handleClickName}
          search={search}
          itemSortBy={sortBy}
          setRowsPerPage={setRowsPerPage}
          count={count}
          rowsPerPage={rowsPerPage}
          setSortBy={setSortBy}
          sortBy={sortBy}
          setSortedElement={setSortedElement}
          sortedElement={sortedElement}
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setIsUserChanged={setIsUserChanged}
          setElementsPerPage={setElementsPerPage}
          elementsPerPage={elementsPerPage}
          verticalsList={verticalsList}
        />
      </Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(withDashboard(NotesTable, true), 'Notes');
