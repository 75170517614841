import React, { useState } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  pickersContainer: {
    display: 'flex',
    position: 'relative',
    marginBottom: 55,
    marginTop: 2,
    width: '100%',
    [theme.breakpoints.up(531)]: {
      marginBottom: 0,
      marginTop: 0,
      width: 'auto',
    },
  },
  pickerContainer: {
    height: 35,
    paddingTop: 22,
    width: '50%',
    [theme.breakpoints.up(531)]: {
      width: 133,
      marginLeft: 10,
      marginBottom: 19,
    },
    [theme.breakpoints.up(1200)]: {
      height: 72,
      marginLeft: 20,
      width: 'auto',
      marginBottom: 0,
    },
    '& > span': {
      color: '#4F4F4F',
      marginBottom: 9,
      position: 'relative',
      top: 4,
      display: 'block',
      fontSize: 10,
      [theme.breakpoints.up(1200)]: {
        fontSize: 14,
      },
    },
  },
  paddingLeft: {
    paddingLeft: 10,
    [theme.breakpoints.up(531)]: {
      paddingLeft: 0,
    },
  },
  picker: {
    marginBottom: -25,
    width: '100%',
    '& > div > input': {
      padding: '9px 14px',
      border: '1px solid #828282',
      borderRadius: 4,
      fontSize: 14,
      width: '100%',
      [theme.breakpoints.up(531)]: {
        width: 'auto',
        marginBottom: 0,
        fontSize: 12,
      },
      [theme.breakpoints.up(1200)]: {
        fontSize: 16,
        width: 214,
        padding: '13px 14px',
      },
      '&:hover': {
        border: '1px solid #828282',
      },
    },
    '& > p': {
      marginLeft: 0,
      width: 138,
      [theme.breakpoints.up(1200)]: {
        width: 214,
      },
    },
  },
  applyButton: {
    borderRadius: 2,
    position: 'absolute',
    bottom: -92,
    right: 0,
    height: 35,
    fontSize: 14,
    fontFamily: 'Lato',
    background: theme.palette.lightBlue[300],
    [theme.breakpoints.up(531)]: {
      position: 'relative',
      top: 45,
      marginLeft: 10,
      bottom: 'auto',
      right: 'auto',
      height: 33,
    },
    [theme.breakpoints.up(1200)]: {
      height: 47,
      marginLeft: 10,
      top: 50,
    },
  },
}));

const CustomDateAndTimePicker = ({
  handleChangeDate,
  filterDates,
  handleApplyFilter,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  return (
    <div className={classes.pickersContainer}>
      <div className={classes.pickerContainer}>
        <span>Start date</span>
        <DateTimePicker
          label=" "
          inputVariant="outlined"
          value={filterDates.startDate}
          name="startDate"
          onChange={e => handleChangeDate(e, 'startDate')}
          showTodayButton
          color="primary"
          className={classes.picker}
          InputLabelProps={{ shrink: false }}
          maxDate={new Date(filterDates.endDate)}
          strictCompareDates={true}
          maxDateMessage="Date & time should not be after end date."
          onError={error => (error ? setError(true) : setError(false))}
        />
      </div>
      <div className={classNames(classes.pickerContainer, classes.paddingLeft)}>
        <span>End date</span>
        <DateTimePicker
          label=" "
          inputVariant="outlined"
          value={filterDates.endDate}
          name="endDate"
          onChange={e => handleChangeDate(e, 'endDate')}
          showTodayButton
          strictCompareDates={true}
          color="primary"
          className={classes.picker}
          InputLabelProps={{ shrink: false }}
          disableFuture
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleApplyFilter}
        className={classes.applyButton}
        disabled={error}
      >
        Apply
      </Button>
    </div>
  );
};

export default CustomDateAndTimePicker;

CustomDateAndTimePicker.propTypes = {
  handleChangeDate: PropTypes.func.isRequired,
  filterDates: PropTypes.object.isRequired,
  handleApplyFilter: PropTypes.func.isRequired,
};
