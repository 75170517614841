import React from 'react';
import withDashboard from '../../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../../hoc/withTitle';
import SmsDripForm from './smsDripForm';
import * as config from './config';
import SingleDrip from '../singleDrip/singleDrip';
import PropTypes from 'prop-types';

const SmsDrip = ({location}) => {
  return (
      <SingleDrip
        name="SMS Drip"
        urlPath="sms-drip"
        messageType="send_sms"
        childComponents={['sms-body-components']}
        location={location}
        config={config}
        Form={SmsDripForm}
      />
  );
};

export default withTitle(withDashboard(SmsDrip, false), 'SMS Drip');

SmsDrip.propTypes = {
  location: PropTypes.object.isRequired,
};
