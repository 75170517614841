import { post } from '../../../config/api';
import { mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { displaySnackbar } from '../../actions/actions/root';
import { getAuth, authRejected } from '../../actions/actions/auth';
import { SAVE_FSM_AND_FSTM } from '../../actions/actionTypes/rest';
import { of, merge } from 'rxjs';
import { consoleError } from '../../../config/helpers';
import { hideLoader } from '../../actions/actions/root';
import { getList } from '../../actions/actions/rest';
import { handleErrorMessage } from './auth';

const auth = action$ => {
  return action$.pipe(
    mergeMap(action => of(getAuth(action))),
    mergeMap(({ payload }) =>
      payload.auth.then(data => ({
        token: data.signInUserSession.accessToken.jwtToken,
        params: payload.data.payload,
      }))
      .catch(() => window.location.reload())
    ),
    catchError(error => {
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          authRejected(error),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
      );
    }),
  );
};

export const createMapsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SAVE_FSM_AND_FSTM.actionTypes.START),
    action$ => auth(action$),
    mergeMap(({ params, token }) =>
      post(
        `flows/${params.id}/maps`,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        JSON.stringify(params.body),
      ).pipe(
        mergeMap(() => {
          return [
            getList('flow-step-maps', { filters: [...params.filters] }, null),
            getList(
              'flow-step-transition-maps',
              { filters: [...params.filters] },
              null,
            ),
            hideLoader(),
          ];
        }),
        takeUntil(action$.pipe(ofType(SAVE_FSM_AND_FSTM.actionTypes.FAILURE))),
      ),
    ),
    catchError((error, caught) => {
      consoleError(error);
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
        caught,
      );
    }),
  );
};
