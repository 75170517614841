import { makeStyles } from '@material-ui/core/styles';
import MobileImage from '../../../assets/images/mobilepreview.png';
import SelectArrow from '../../../assets/images/select-arrow-down.png';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '20px 0',
    paddingLeft: 270,
  },
  emailContainer: {
    width: '100% !important',
    padding: '0 0 20px 0',
    paddingLeft: 60,
    marginTop: 20,
    [theme.breakpoints.up('xl')]: {
      padding: '0 0 20px 0',
      paddingLeft: 270,
    },
  },
  title: {
    paddingLeft: 70,
    marginBottom: 0,
    position: 'relative',
    top: 35,
    left: 15,
  },
  titleContainer: {
    position: 'relative',
    top: -15,
  },
  titleIcon: {
    position: 'absolute',
    left: 293,
  },
  emailTitleIcon: {
    position: 'absolute',
    top: 117,
    left: 85,
    [theme.breakpoints.up('xl')]: {
      left: 293,
    },
  },
  activeLockedButton: {
    '& > button': {
      background: `linear-gradient(180deg, #BDBDBD 0%, #E0E0E0 12.15%)`,
    },
  },
  lockButton: {
    paddingRight: 10,
    '& > button': {
      backgroundColor: '#F2F2F2',
      fontFamily: 'Lato',
      width: 146,
      height: 45,
      borderRadius: 2,
      '& > span': {
        color: '#828282',
        fontWeight: 900,
      },
    },
  },
  actionButtonsContainer: {
    marginTop: 140,
    maxWidth: 900,
    width: 900,
  },

  actionButtons: {
    '& > button': {
      marginRight: 20,
    },
  },
  contentContainer: {
    marginTop: 100,
  },
  textField: {
    marginBottom: 13,
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
    '& > textarea.field': {
      width: '100%',
      minHeight: 70,
      backgroundColor: theme.palette.white[100],
      padding: 10,
      fontFamily: 'Lato',
      fontSize: 16,
    },
    '& > textarea.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > input': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      backgroundColor: theme.palette.white[100],
    },
    '& > input.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '98% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  tagField: {
    '& > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '98% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
  },
  changesModalBtn: {
    width: 236,
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 20,
    background: theme.palette.orange[300],
    borderRadius: 5,
    color: theme.palette.white[100],
    '&:hover': {
      background: theme.palette.orange[400],
    },
  },

  dripPreviewTitle: {
    color: theme.palette.text[100],
    fontSize: 24,
    fontFamily: 'Lato',
    marginBottom: 24,
  },
  previewContainer: {
    maxHeight: 800,
    height: 800,
    width: '100%',
    maxWidth: 950,
    border: `1px solid #BDBDBD`,
    borderRadius: 2,
    marginTop: 20,
    padding: '40px 50px',
  },
  template: {
    width: '100%',
    minWidth: 402,
    height: 700,
    margin: '0 auto',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  mobileTemplate: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  mobileTemplateWrapper: {
    backgroundImage: `url(${MobileImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: 350,
    height: 650,
    position: 'relative',
  },
  mobileViewTemplate: {
    position: 'absolute',
    top: 22,
    left: 10,
    width: 181,
    height: 370,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  sendTestEmailBtn: {
    width: '100%',
    marginBottom: 30,
    '& > button': {
      background: theme.palette.orange[300],
      borderRadius: 2,
      color: theme.palette.white[100],
      width: 223,
      height: 59,
      fontSize: 20,
      fontWeight: 'bold',
      '&:hover': {
        background: theme.palette.orange[400],
      },
    },
  },
  dripFormContainer: {
    paddingRight: 50,
  },
  tagsContainer: {
    minWidth: 430,
    maxWidth: 750,
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: `1px solid #828282`,
    paddingBottom: 4,
    marginTop: 30,
  },
  addTagBtn: {
    backgroundColor: theme.palette.lightBlue[300],
    color: theme.palette.white[100],
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 14,
    width: 108,
    height: 35,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
  },
  tagsTitle: {
    fontSize: 16,
  },
  tagSelectContainer: {
    minWidth: 430,
    maxWidth: 750,
    width: '100%',
    margin: '8px 0',
  },
  tagsColumnTitle: {
    fontFamily: 'Lato',
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text[100],
    width: '50%',
  },
  tagsSelects: {
    width: '100%',
  },
  tagColumn: {
    width: '50%',
    paddingRight: 10,
    '& > div > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
    },
    '& > div > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  tagsActionButton: {
    height: 35,
    width: 35,
    backgroundColor: '#D8D8D8',
    borderRadius: 2,
    color: theme.palette.white[100],
    position: 'relative',
    top: 5,
    cursor: 'pointer',
    transition: 'all .4s',
    padding: 2,
    '&:hover': {
      backgroundColor: '#b7b5b5',
    },
  },
  modalLoader: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: 0,
    zIndex: 10,
  },
  modalContainer: {
    '& > div.MuiDialog-container': {
      '& > div': {
        width: 545,
        backgroundColor: '#F3F8FE',
        padding: 20,
      },
    },
  },
  modalTitle: {
    fontFamily: "'Lato'",
    fontSize: 24,
    color: '#4F4F4F',
    '& > h2': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  modalContent: {
    paddingTop: '30px !important',
    paddingBottom: '40px !important',
  },
  actionsContainer: {
    justifyContent: 'space-between !important',
    padding: '10px 0 !important',
  },
  abContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
    '& > label': {
      marginRight: 0,
    },
  },
  blueText: {
    '& > select': {
      color: '#2D9CDB',
    },
  },
  testTitle: {
    marginTop: 50,
    fontSize: 36,
    fontFamily: 'Lato',
    marginBottom: 35,
  },
  testTab: {
    background: '#F3F3F3',
    color: '#BDBDBD',
    borderRadius: 2,
    fontSize: 18,
    fontWeight: 900,
    fontFamily: 'Lato',
    padding: '8px 54px',
    maxWidth: 234,
    textAlign: 'center',
    marginRight: 30,
    cursor: 'pointer',
  },
  activeTestTab: {
    background: '#2D9CDB',
    color: theme.palette.white[100],
  },
  testTabsContainer: {
    paddingBottom: 40,
    borderBottom: '1px solid #DFDFDF',
    marginBottom: 30,
  },
  testPlaceholder: {
    background: '#F3F3F3',
    borderRadius: 2,
    width: '100%',
    height: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > h3': {
      color: theme.palette.text[100],
      fontSize: 24,
      fontWeight: 400,
    },
  },
  iframe: {
    width: 320,
    height: 566,
    transform: 'scale(1)',
    display: 'block',
    margin: '0 auto',
    border: 'none',
    marginTop: 42,
  }
}));

export default useStyles;
