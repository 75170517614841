import React, { useState, useEffect, Suspense, lazy } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import { useHistory, useLocation } from 'react-router-dom';
import FlowStepIcon from '../../../assets/images/flow-step.svg';
import NameField from '../../../components/form/name';
import CustomSelect from '../../../components/form/customSelect';
import { Formik, Form } from 'formik';
import DeleteButton from '../../../components/customButtons/customDelete';
import PublishButton from '../../../components/customButtons/customPublish';
import { useSelector, useDispatch } from 'react-redux';
import {
  getList,
  cancelList,
  getItem,
  cancelItem,
  editStart,
  removeStart,
  createStart,
} from '../../../store/actions/actions/rest';
import Loader from '../../../components/loader';
import ModelTags from '../../../components/modelTags/modelTags';
import {
  initialValues,
  populateValues,
  contain,
  filters,
  params,
  filterFlowsForUniqueElements,
} from './config';
import PropTypes from 'prop-types';
import { setItemFlag } from '../../../store/actions/actions/root';
import { getFilters } from '../../../hoc/withTable/getFilters';
import { checkEqualityOfVerticalIds } from '../list/singleFlow/helper';
import { contain as transitionContain } from '../list/config';

const SingleFlowTransition = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const account_id = useSelector(state => state.auth.account_id);
  const auth = useSelector(state => state.auth);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const user_name = useSelector(state => state.auth.username);
  const loader = useSelector(state => state.app.loader);
  const flowsList = useSelector(state => state.flows.list);
  const itemFlag = useSelector(state => state.app.itemFlag);
  const flowStepTransitionMapsList = useSelector(
    state => state.flowStepTransitionMaps.list,
  );
  const webhookActionsList = useSelector(state => state.webhookActions.list);
  const element = useSelector(
    state =>
      (state.flowStepTransitions.list &&
        state.flowStepTransitions.list.find(
          item => item.id === +match.params.id,
        )) ||
      state.flowStepTransitions.item,
  );
  const [formValues, setFormValues] = useState(initialValues());
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const DeleteConfirmationDialog = lazy(() =>
    import('../../../components/deleteConfirmationModal'),
  );
  const NotesModal = lazy(() => import('../../../components/notes/notes'));
  const [avaliableFlowsList, setAvaliableFlows] = useState(null);

  useEffect(() => {
    if (itemFlag) {
      history.push('/404');
      dispatch(setItemFlag(false));
    }
  }, [itemFlag, dispatch, history]);

  useEffect(() => {
    if (
      match.params.id &&
      !element &&
      auth &&
      vertical_id &&
      checkEqualityOfVerticalIds(vertical_id, location)
    ) {
      let allFilters = getFilters(filters, auth);
      dispatch(
        getItem(
          'flow-step-transitions',
          {
            id: match.params.id,
            contain,
            filters: [...allFilters],
          },
          dispatch,
        ),
      );
      return () => {
        dispatch(cancelItem('flow-step-transitions', null, dispatch));
      };
    }
  }, [element, match.params.id, dispatch, auth, vertical_id, location]);

  useEffect(() => {
    if (
      vertical_id &&
      checkEqualityOfVerticalIds(vertical_id, location) &&
      account_id &&
      auth
    ) {
      let allFilters = getFilters(filters, auth);
      dispatch(cancelList('flow-step-transitions', null, dispatch));
      dispatch(cancelList('flows', null, dispatch));
      dispatch(cancelList('webhook-actions', null, dispatch));
      dispatch(cancelItem('flow-step-transition-maps', null, dispatch));
      dispatch(
        getList(
          'flow-step-transitions',
          { filters: [...allFilters] },
          dispatch,
        ),
      );
      dispatch(
        getList('flows', { params, filters: [...allFilters] }, dispatch),
      );
      dispatch(getList('webhook-actions', { params }, dispatch));
      dispatch(
        getList(
          'flow-step-transition-maps',
          { contain: transitionContain, filters: [...allFilters] },
          dispatch,
        ),
      );
      return () => {
        dispatch(cancelList('flow-step-transitions', null, dispatch));
        dispatch(cancelItem('flow-step-transition-maps', null, dispatch));
        dispatch(cancelList('flows', null, dispatch));
        dispatch(cancelList('webhook-actions', null, dispatch));
      };
    }
  }, [dispatch, vertical_id, account_id, auth, location]);

  useEffect(() => {
    if (element) {
      setFormValues(populateValues(element));
    }
  }, [element]);

  useEffect(() => {
    if (
      element &&
      flowStepTransitionMapsList &&
      flowStepTransitionMapsList.length > 0 &&
      flowsList &&
      flowsList.length > 0
    ) {
      let array = flowStepTransitionMapsList.filter(
        transition => +transition.flow_step_transition_id === +element.id,
      );
      if (array && array.length > 0) {
        let avaliableFlowsList = filterFlowsForUniqueElements(flowsList, array);
        setAvaliableFlows(avaliableFlowsList);
      } else {
        setAvaliableFlows(flowsList);
      }
    } else {
      setAvaliableFlows(flowsList);
    }
  }, [element, flowStepTransitionMapsList, flowsList]);

  const handleDelete = notes => {
    const data = {
      body: {
        id: formValues.id,
        notes,
      },
    };
    dispatch(removeStart('flow-step-transitions', data, dispatch));
    history.push({
      pathname: '/flows/transitions',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const onSubmit = values => {
    if (match.params.id) {
      handleOpenNotesModal();
    } else {
      let allValues = populateValues(values);
      let data = {
        body: {
          ...allValues,
          account_id,
          vertical_id,
          notes: [
            {
              account_id,
              vertical_id,
              model_name: 'FlowStepTransition',
              model_id: '',
              user_name,
              note: `Created ${values && values.name ? values.name : ""}`,
            },
          ],
        },
      };
      dispatch(createStart('flow-step-transitions', data, dispatch));
      history.push({
        pathname: '/flows/transitions',
        search: `vertical_id=${vertical_id}`,
      });
    }
  };

  const submitForm = (values, notes) => {
    let allValues = populateValues(values);
    let data = {
      body: {
        ...allValues,
        account_id,
        vertical_id,
        notes,
      },
    };
    dispatch(editStart('flow-step-transitions', data, dispatch));
    history.push({
      pathname: '/flows/transitions',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleDeleteModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleOpenNotesModal = () => {
    setNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setNotesModalOpen(false);
  };

  const renderDeleteConfirmationDialog = isDeleteConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <DeleteConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        handleDelete={handleDelete}
        handleClose={handleDeleteModalClose}
        itemName={'flow transition'}
        model_id={+match.params.id || ''}
        model_name="FlowStepTransition"
      />
    </Suspense>
  ) : null;

  const renderNotesModal = props =>
    notesModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={notesModalOpen} />
          </Grid>
        }
      >
        <NotesModal
          props={props}
          classes={classes}
          model_name="FlowStepTransition"
          model_id={+match.params.id || ''}
          open={notesModalOpen}
          handleClose={handleCloseNotesModal}
          submitForm={submitForm}
        />
      </Suspense>
    ) : null;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} container alignItems="flex-end">
        <Grid item xs={1} className={classes.iconContainer}>
          <img
            src={FlowStepIcon}
            alt="Flows icon"
            className={classes.titleIcon}
          />
        </Grid>
        <Grid container item xs={6} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              {match.params.id ? 'Edit ' : 'New '}Flow Transition
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          onSubmit={values => {
            onSubmit(values);
          }}
        >
          {props => (
            <Form className={classes.formWrapper}>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <NameField
                    {...props}
                    placeholder="Flow Transition Name"
                    labelText="Flow Transition Name"
                    name="name"
                    wrapperClass={classes.textField}
                    type="flowStepTransitions"
                    flowTransition={true}
                    id={match.params.id}
                  />
                  <CustomSelect
                    {...props}
                    placeholder="Action"
                    labelText="Action"
                    name="webhook_action_id"
                    wrapperClass={classes.textField}
                    options={webhookActionsList}
                  />
                  <CustomSelect
                    {...props}
                    placeholder="Go To Flow"
                    labelText="Go To Flow"
                    name="to_flow_id"
                    wrapperClass={classes.textField}
                    options={avaliableFlowsList}
                  />
                  <Grid item xs={12} className={classes.actionButtons}>
                    {match.params.id && (
                      <DeleteButton onClick={handleDeleteModalOpen} />
                    )}
                    <PublishButton type="submit" />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <ModelTags
                    props={props}
                    classes={classes}
                    model_name="FlowStepTransition"
                    model_id={match.params.id || ''}
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
                </Grid>
              </Grid>
              {renderDeleteConfirmationDialog}
              {renderNotesModal(props)}
            </Form>
          )}
        </Formik>
      </Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(
  withDashboard(SingleFlowTransition, false),
  'Flow Transitions',
);

SingleFlowTransition.propTypes = {
  match: PropTypes.object.isRequired,
};
