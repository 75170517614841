import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '0 0 20px 0',
    paddingLeft: 60,
    marginTop: 120,
    [theme.breakpoints.up('xl')]: {
      padding: '0 0 20px 0',
      paddingLeft: 300,
    },
  },
  title: {
    marginLeft: -10,
    [theme.breakpoints.up(1600)]: {
      marginLeft: -20,
    },
  },
  topSection: {
    paddingLeft: 24,
    marginBottom: -35,
  },
  titleIcon: {
    position: 'relative',
    top: 8,
    marginRight: 10,
  },
  tableWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
