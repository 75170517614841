import React, { Fragment } from 'react';
import NameField from '../../../components/form/name';
import DescriptionField from '../../../components/form/description';
import FormHelperText from '@material-ui/core/FormHelperText';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { Checkbox } from 'formik-material-ui';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const SingleSmsBodyForm = ({
  props,
  classes,
  setPlaceholders,
  placeholders,
  setCharacterCount,
  characterCount,
  id,
}) => {
  const handleChangePlaceholder = e => {
    setPlaceholders({
      ...placeholders,
      [e.target.name]: e.target.value,
    });
    setCharacterCount(e.target.value.length);
  };

  return (
    <Fragment>
      <NameField
        {...props}
        placeholder="SMS Body Text Name"
        labelText="SMS Body Text Name"
        name="name"
        wrapperClass={classes.textField}
        dripComponent="sms-body-components"
        id={id}
      />
      <DescriptionField
        {...props}
        placeholder="Message Text"
        labelText="Message Text"
        name="sms_body"
        wrapperClass={classes.textField}
        onChangeDescription={handleChangePlaceholder}
        type="smsBodyText"
      />
      <div className={classes.helperTextContainer}>
        <FormHelperText>{`Characters: ${characterCount}`}</FormHelperText>
      </div>
      <div className={classes.checkBox}>
        <Field
          component={Checkbox}
          type="checkbox"
          name="slinky_link"
          color="primary"
          disabled={false}
        />
        <span>Shorten link inside message text.</span>
        <Tooltip
          title="If you check the box, your link will be shorten."
          arrow
          placement="top"
        >
          <div className={classes.infoIcon}>
            <InfoIcon />
          </div>
        </Tooltip>
      </div>
    </Fragment>
  );
};

export default SingleSmsBodyForm;

SingleSmsBodyForm.propTypes = {
  props: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setPlaceholders: PropTypes.func.isRequired,
  placeholders: PropTypes.object.isRequired,
  setCharacterCount: PropTypes.func.isRequired,
  characterCount: PropTypes.number.isRequired,
};
