import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Field, ErrorMessage } from 'formik';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    width: 700,
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    fontSize: 20,
    width: 155,
    height: 60,
    fontWeight: 900,
    borderRadius: 5,
    lineHeight: 1,
    color: theme.palette.white[100],
    backgroundColor: theme.palette.lightBlue[300],
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
    '&.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
  },
  input: {
    display: 'none',
  },
  errorText: {
    fontFamily: 'Lato',
    color: theme.palette.red[300],
    fontSize: 12,
    marginTop: 5,
    marginRight: 107,
  },
  fileName: {
    marginLeft: 20,
    fontSize: 18,
    display: 'flex',
    alignContent: 'center',
    '& > span': {
      textAlign: 'left',
    },
    '& > svg': {
      cursor: 'pointer',
      transition: 'all .4s',
      marginLeft: 5,
      '&:hover': {
        color: theme.palette.red[300],
      },
    },
  },
}));

const validate = values => {
  if (!values) {
    return 'Required';
  }
};

const CustomUploadButton = ({
  onChange,
  handleChange,
  errors,
  touched,
  errorClass,
  uploadedFile,
  setUploadedFile,
  setFieldValue,
}) => {
  const classes = useStyles();
  const getFileName = () => {
    if (uploadedFile && uploadedFile.name) {
      return (
        <div className={classes.fileName}>
          <span>{uploadedFile.name} </span>
          <DeleteIcon
            onClick={() => {
              setUploadedFile(null);
              setFieldValue('file', '');
            }}
          />
        </div>
      );
    } else {
      return <div className={classes.fileName}>No file choosen</div>;
    }
  };

  return (
    <div className={classes.buttonContainer}>
      <div>
        <Field
          className={classes.input}
          name="file"
          type="file"
          id="upload-file-button"
          accept=".csv"
          validate={validate}
          onChange={e => {
            onChange && onChange(e);
            handleChange(e);
          }}
        />
        <label htmlFor="upload-file-button">
          <Button
            variant="contained"
            component="span"
            className={`${classes.button || 'field'} ${
              errors['file'] && touched['file'] ? errorClass || 'has-error' : ''
            }`}
          >
            Choose a file
          </Button>
        </label>
        <ErrorMessage
          name="file"
          component="div"
          className={classes.errorText}
          data-testid="file-error"
        />
      </div>
      {getFileName()}
    </div>
  );
};

export default CustomUploadButton;
