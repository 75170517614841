import React, { useState, useEffect, useRef } from 'react';
import { columns as smsColumns } from '../../sms/config';
import { columns as emailColumns } from '../../emails/config';
import { columns as rvmColumns } from '../../rvm/config';
import { columns as dataPostColumns } from '../../genericDataPost/config';
import { columns as callColumns } from '../../calls/config';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { clearListReporting } from '../../../../store/actions/actions/reporting';
import { getFlowStepId } from '../config';
import { formatToLocalDate } from '../../../../config/helpers';

const useStyles = makeStyles(theme => ({
  dripRowContainer: {
    position: 'relative',
    paddingLeft: 55,
  },
  dripTableWithTransiton: {
    width: '100%',
    borderBottom: '1px solid #828282',
    color: theme.palette.text[100],
    background: '#FFFFFF',
    marginBottom: 31,
  },
  dripTable: {
    width: '100%',
    borderBottom: '1px solid #828282',
    color: theme.palette.text[100],
    background: '#FFFFFF',
    marginBottom: 20,
  },
  headers: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid #828282',
    borderRight: '1px solid #828282',
  },
  dripData: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid #828282',
    borderRight: '1px solid #828282',
  },
  headerCellName: {
    height: 42,
    transition: 'all .7s',
    cursor: 'pointer',
    paddingLeft: 16,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 900,
  },
  headerCell: {
    height: 42,
    fontSize: 14,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 900,
  },
  dripCell: {
    height: '100%',
    minHeight: 52,
    fontSize: 16,
    textAlign: 'center',
    borderLeft: '1px solid #828282',
    borderTop: '1px solid #828282',
    display: 'flex',
    alignItems: 'center',
    'word-break': 'break-all',
    justifyContent: 'center',
  },
  dripCellName: {
    minHeight: 52,
    fontSize: 16,
    color: '#036BF0',
    transition: 'all .7s',
    borderTop: '1px solid #828282',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    'word-break': 'break-all',
    paddingLeft: 16,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  stepNumber: {
    width: 45,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    background: '#FFFFFF',
    border: '1px solid #828282',
    position: 'absolute',
    left: 5,
    top: 22,
    '& > span': {
      position: 'absolute',
      top: -16,
      fontSize: 10,
    },
  },
  headerLabel: {
    padding: '5px 14px',
    fontSize: 12,
    fontFamily: 'Lato',
    background: '#C4C4C4',
    borderRadius: '3px 3px 0px 0px',
    color: theme.palette.text[100],
    fontWeight: 900,
  },
}));

const SingleReportingStep = ({
  type,
  data,
  isActive,
  order,
  hasTransitions,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const stepRef = useRef();
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const flowStepMapsList = useSelector(state => state.flowStepMaps.list);
  const [rowHegith, setRowHeight] = useState(52);
  const [activeColumns, setActiveColumns] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [columnWidth, setColumnWidth] = useState(0);

  useEffect(() => {
    if (data && data.created_date) {
      setCurrentDate(formatToLocalDate(data.created_date, 'dd.MM.yyyy'));
    } else {
      setCurrentDate(null);
    }
  }, [data]);

  useEffect(() => {
    if (stepRef && stepRef.current) {
      setRowHeight(stepRef.current.clientHeight);
    }
  }, [stepRef, data]);

  const handleClickLink = id => () => {
    history.push({
      pathname: `/flows/steps/edit/${id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const getTheColumns = type => {
    switch (type) {
      case 'send_sms':
        return smsColumns;
      case 'send_email':
        return emailColumns;
      case 'send_rvm':
        return rvmColumns;
      case 'send_call':
        return callColumns;
      case 'send_post':
        setActiveColumns(dataPostColumns);
        return dataPostColumns;
      default:
        break;
    }
  };

  useEffect(() => {
    if (type) {
      dispatch(clearListReporting(type, dispatch));
    }
  }, [location, dispatch, type]);

  useEffect(() => {
    if (type) {
      let columns = getTheColumns(type);
      let percents = 100 / columns.length;
      setColumnWidth(`${percents}%`);
      setActiveColumns(columns);
    }
  }, [type]);

  const renderHeaders = activeColumns
    ? activeColumns.map(column => {
        return (
          <div
            className={
              column.id === 'name' ? classes.headerCellName : classes.headerCell
            }
            style={{ width: columnWidth }}
            key={column.id}
          >
            {column.id === 'name' ? `Step ${column.label}` : column.label}
          </div>
        );
      })
    : null;

  const renderData =
    data && activeColumns
      ? activeColumns.map(column => {
          let {
            flow_step_map_id,
            Step_Name,
            current_version,
            archived,
            flow_step_position,
          } = data;
          let key = `${column.id}${
            column.name
          }${flow_step_map_id}${Step_Name}${current_version}${archived}${flow_step_position}${Math.floor(
            Math.random() * 44255,
          )}`;
          if (column.id === 'name') {
            const flowStepId = getFlowStepId(
              data.flow_step_map_id,
              flowStepMapsList,
            );
            return (
              <div
                ref={stepRef}
                onClick={handleClickLink(flowStepId)}
                className={classes.dripCellName}
                style={{ width: columnWidth }}
                key={key}
              >
                {data[column.id]}
              </div>
            );
          } else {
            return (
              <div
                className={classes.dripCell}
                style={{ width: columnWidth, height: rowHegith + 1 }}
                key={key}
              >
                {data[column.id]}
              </div>
            );
          }
        })
      : null;

  const renderHeaderLabelText = isActive
    ? `Created: ${currentDate}`
    : 'Inactive step';

  const renderDripTableClass = hasTransitions
    ? classes.dripTableWithTransiton
    : classes.dripTable;

  return (
    <div className={classes.dripRowContainer}>
      <div className={classes.stepNumber}>
        <span>STEP</span>
        <div className={classes.number}>{order}</div>
      </div>
      <div className={renderDripTableClass}>
        <div className={classes.headerLabel}>
          <span>{renderHeaderLabelText}</span>
        </div>
        <div className={classes.headers}>{renderHeaders}</div>
        <div className={classes.dripData}>{renderData}</div>
      </div>
    </div>
  );
};

export default SingleReportingStep;
