import React, { useState, Suspense, lazy, useEffect } from 'react';
import { Formik, Form } from 'formik';
import NameField from '../../../../components/form/name';
import ParametersField from '../../../../components/form/parameters';
import PublishButton from '../../../../components/customButtons/customPublish';
import Grid from '@material-ui/core/Grid';
import {
  createStart,
  editStart,
  saveFSMandFSTM,
} from '../../../../store/actions/actions/rest';
import { useDispatch, useSelector } from 'react-redux';
import {
  populateValues,
  populateFSMandFSTMValues,
  compareInitialValuesAndCurrentValues,
} from '../config';
import Loader from '../../../../components/loader';
import ModelTags from '../../../../components/modelTags/modelTags';
import { useHistory } from 'react-router-dom';
import FlowStep from './flowStep';
import HoursOfOperation from './hoursOfOperation/hoursOfOperation';
import CustomStatusField from '../../../../components/form/status';
import PropTypes from 'prop-types';
import {
  filters,
  renderFlowStatus,
  checkIfTheFlowIsLocked,
  isStopStatus,
  convertCells,
} from '../config';
import { getFilters } from '../../../../hoc/withTable/getFilters';
import SaveAsDraftButton from '../../../../components/customButtons/customSaveAsDraft';
import SaveChangesButton from '../../../../components/customButtons/customSaveChanges';
import RouteLeavingGuard from '../../../../components/customPrompt/customPrompt';
import Checkbox from '../../../../components/form/checkbox';

const SingleFlowForm = ({
  formValues,
  classes,
  flowStepsList,
  flowStepTransitionList,
  elementId,
  setFormValues,
  setCells,
  cells,
  defaultCells,
  flowsList,
  flowStepMapsList,
  flowStepTransitionMapsList,
  showSteps,
  setShowSteps,
  element,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const user_name = useSelector(state => state.auth.username);
  const auth = useSelector(state => state.auth);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const NotesModal = lazy(() => import('../../../../components/notes/notes'));
  const AlertModal = lazy(() =>
    import('../../../../components/customModalText/customModalText'),
  );
  const [isStatusDraft, setIsStatusDraft] = useState(false);
  const [isStatusLive, setIsStatusLive] = useState(false);
  const [isStatusFinished, setisStatusFinished] = useState(false);
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const [flag, setFlag] = useState(false);
  const [changesFlag, setChangesFlag] = useState(true);
  const [formikState, setFormikState] = useState(null);

  useEffect(() => {
    if (element) {
      const { status } = element;
      setCurrentStatus(status);
      setisStatusFinished(isStopStatus(status));
    }
  }, [element]);

  useEffect(() => {
    if (currentStatus) {
      setFormValues({
        ...formikState,
        status: +currentStatus,
      });
      setIsStatusDraft(+currentStatus === 1 ? true : false);
      setIsStatusLive(checkIfTheFlowIsLocked(currentStatus));
    }
    // eslint-disable-next-line
  }, [currentStatus]);

  const handleFlowStatus = id => () => {
    if (id === '4' && formValues.status === 4) {
      handleOpenAlertModal();
    } else {
      setCurrentStatus(id);
    }
  };

  useEffect(() => {
    if (!isStatusDraft && !isStatusFinished) {
      setIsStatusDisabled(false);
    } else {
      setIsStatusDisabled(true);
    }
  }, [isStatusFinished, isStatusDraft]);

  const onSubmit = values => {
    setChangesFlag(false);
    if (elementId) {
      if (isStopStatus(values.status)) {
        handleOpenAlertModal();
      } else {
        handleOpenNotesModal();
      }
    } else {
      let allValues = populateValues(values);
      let days_times = convertCells(cells);
      let data = {
        body: {
          ...allValues,
          account_id,
          vertical_id,
          days_times: JSON.stringify(days_times),
          notes: [
            {
              account_id,
              vertical_id,
              model_name: 'Flow',
              model_id: '',
              user_name,
              note: `Created ${values && values.name ? values.name : ''}`,
            },
          ],
        },
      };
      dispatch(createStart('flows', data, dispatch));
      history.push({
        pathname: '/flows/list',
        search: `vertical_id=${vertical_id}`,
      });
    }
  };

  const submitForm = (values, notes) => {
    setFlag(false);
    let allFilters = getFilters(filters, auth);
    let allValues = populateValues(values);
    let days_times = convertCells(cells);
    let data = {
      body: {
        ...allValues,
        account_id,
        vertical_id,
        days_times: JSON.stringify(days_times),
        notes,
      },
    };
    const bodyMaps = populateFSMandFSTMValues(values, vertical_id);
    let dataMaps = {
      id: elementId,
      body: {
        ...bodyMaps,
      },
      filters: allFilters,
    };
    dispatch(saveFSMandFSTM(dataMaps));
    dispatch(editStart(`flows`, data, dispatch));
    history.push({
      pathname: '/flows/list',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleCloseNotesModal = () => {
    setNotesModalOpen(false);
  };

  const handleOpenNotesModal = () => {
    setNotesModalOpen(true);
  };

  const handleCloseAlertModal = () => {
    setIsAlertModalOpen(false);
  };

  const handleOpenAlertModal = () => {
    setIsAlertModalOpen(true);
  };

  const handlePromptFlag = () => {
    setFlag(true);
  };

  const renderNotesModal = props =>
    notesModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={notesModalOpen} />
          </Grid>
        }
      >
        <NotesModal
          props={props}
          classes={classes}
          model_name="Flow"
          model_id={elementId}
          open={notesModalOpen}
          handleClose={handleCloseNotesModal}
          submitForm={submitForm}
        />
      </Suspense>
    ) : null;

  const renderAlertModal = () =>
    isAlertModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={isAlertModalOpen} />
          </Grid>
        }
      >
        <AlertModal
          open={isAlertModalOpen}
          handleClose={handleCloseAlertModal}
          handleOpenNotesModal={handleOpenNotesModal}
          text="Are you sure that you want to finish the flow? If you finish the flow you will not be able to get back to the live status again. If you finish the flow you would be able to clone the flow."
          title="Are you sure you want to end the flow?"
          textConfirmButton="End Flow"
        />
      </Suspense>
    ) : null;

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      onSubmit={values => {
        onSubmit(values);
      }}
    >
      {props => (
        <Form className={classes.formContainer}>
          <NameField
            {...props}
            placeholder="Flow Name"
            labelText="Flow Name"
            name="name"
            wrapperClass={classes.textField}
            type="flows"
            id={elementId}
          />
          <Checkbox
            {...props}
            labelText="List Database Flow"
            name="list_database_enabled"
            classes={classes}
          />
          <ParametersField
            {...props}
            placeholder="Parameters"
            labelText="Parameters"
            name="parameters"
            wrapperClass={classes.textField}
          />
          <Grid item xs={12} container className={classes.statusButtons}>
            <Grid item xs={12} className={classes.statusTitle}>
              Flow State:{renderFlowStatus(formValues.status, classes)}
            </Grid>
            <Grid item>
              <CustomStatusField
                {...props}
                classes={classes}
                disabled={isStatusDisabled}
                isRequired={!isStatusDraft}
                handleFlowStatus={handleFlowStatus}
                setFormikState={setFormikState}
              />
            </Grid>
            {elementId && (
              <FlowStep
                formik={props}
                flowStepsList={flowStepsList}
                flowStepTransitionList={flowStepTransitionList}
                flowsList={flowsList}
                elementId={elementId}
                setFormValues={setFormValues}
                formValues={formValues}
                dispatch={dispatch}
                account_id={account_id}
                vertical_id={vertical_id}
                flowStepMapsList={flowStepMapsList}
                flowStepTransitionMapsList={flowStepTransitionMapsList}
                showSteps={showSteps}
                setShowSteps={setShowSteps}
                isFlowLive={isStatusLive}
                element={element}
                handlePromptFlag={handlePromptFlag}
              />
            )}
            <Grid item xs={12} className={classes.tags}>
              <ModelTags
                props={props}
                classes={classes}
                model_name="Flow"
                model_id={`${elementId}`}
                formValues={formValues}
                setFormValues={setFormValues}
                handlePromptFlag={handlePromptFlag}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <HoursOfOperation
              setCells={setCells}
              cells={cells}
              defaultCells={defaultCells}
              elementId={elementId}
              isFlowLive={isStatusFinished || isStatusLive}
              handlePromptFlag={handlePromptFlag}
            />
          </Grid>
          <Grid item xs={12} className={classes.actionButtons}>
            {isStatusDraft && (
              <SaveAsDraftButton
                margin="true"
                onClick={() => {
                  setFormValues({
                    ...props.values,
                    status: 1,
                  });
                  props.handleSubmit();
                }}
              />
            )}
            {isStatusDraft && (
              <PublishButton
                onClick={() => {
                  setFormValues({
                    ...props.values,
                    status: 2,
                  });
                  props.handleSubmit();
                }}
              />
            )}
            {!isStatusDraft && <SaveChangesButton type="submit" />}
          </Grid>
          {renderNotesModal(props)}
          {renderAlertModal()}
          {changesFlag && (
            <RouteLeavingGuard
              when={
                flag ||
                compareInitialValuesAndCurrentValues(element, props.values)
              }
              navigate={path => history.push(path)}
              shouldBlockNavigation={() => true}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
export default SingleFlowForm;

SingleFlowForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  flowStepsList: PropTypes.array,
  flowStepTransitionList: PropTypes.array,
  elementId: PropTypes.string,
  setFormValues: PropTypes.func.isRequired,
  setCells: PropTypes.func.isRequired,
  cells: PropTypes.array,
  defaultCells: PropTypes.string,
  flowsList: PropTypes.array,
  flowStepMapsList: PropTypes.array,
  flowStepTransitionMapsList: PropTypes.array,
  stepsContainerHeight: PropTypes.number,
  showSteps: PropTypes.bool,
  setShowSteps: PropTypes.func.isRequired,
};
