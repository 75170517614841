import React, { useState } from 'react';
import useStyles from './styles';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const Days = ({ days, handleChangeCheckBox, isFlowLive }) => {
  const classes = useStyles();
  const [allDays] = useState([
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]);

  const renderDays = () =>
    allDays.map((day, index) => (
      <div key={day}>
        <span>{day}</span>
        <Checkbox
          checked={days[day.toLowerCase()]}
          onChange={event => handleChangeCheckBox(event, index)}
          className={classes.checkbox}
          color="primary"
          name={day.toLowerCase()}
          disabled={isFlowLive}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
    ));

  return <div className={classes.days}>{renderDays()}</div>;
};

export default Days;

Days.propTypes = {
  days: PropTypes.object.isRequired,
  handleChangeCheckBox: PropTypes.func.isRequired,
  isFlowLive: PropTypes.bool.isRequired,
};
