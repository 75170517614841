export const initialValues = (model_id, model_name) => ({
  id: '',
  account_id: '',
  model_id,
  model_name,
});

export const actionOptions = [
  { id: '1', name: 'Accept only' },
  { id: '2', name: 'Block all' },
];

export const fieldNameOptions = [
  { id: 'home_city', name: 'City' },
  { id: 'home_state', name: 'State' },
  { id: 'home_zip', name: 'Zip code' },
  { id: 'dob', name: 'Date of Birth' },
  { id: 'currently_insured', name: 'Currently Insured' },
  { id: 'homeowner', name: 'Homeowner' },
  { id: 'older_than_64', name: 'Older Than 64' },
  { id: 'model_score', name: 'Model Score' },
  { id: 'channel_id', name: 'Channel ID' },
];

export const operatorOptions = [
  { id: 'equal', name: 'Equals' },
  { id: 'notequal', name: 'Not equals' },
  { id: 'greaterthan', name: 'Greater than' },
  { id: 'lessthan', name: 'Less than' },
  { id: 'lengreaterthan', name: 'Length greater than' },
  { id: 'lenlessthan', name: 'Length less than' },
  { id: 'in', name: 'In' },
  { id: 'notin', name: 'Not in' },
  { id: 'notstartwith', name: 'Not starts with' },
  { id: 'notendwith', name: 'Not ends with' },
  { id: 'startwith', name: 'Starts with' },
  { id: 'endwith', name: 'Ends with' },
];
