import React, { useState, useEffect, Suspense, lazy } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import DripsIcon from '../../../assets/images/drips-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FolderList from './folderList';
import {
  getList,
  cancelList,
  editStart,
  createStart,
  saveFilters,
  saveContain,
  clearNameUnique,
} from '../../../store/actions/actions/rest';
import Loader from '../../../components/loader';
import EditIcon from '@material-ui/icons/Edit';
import SMSFolder from '../../../assets/images/sms.svg';
import PhoneFolder from '../../../assets/images/calls.svg';
import EmailFolder from '../../../assets/images/mail.svg';
import DripFolder from '../../../assets/images/drip.svg';
import DocumentIcon from '../../../assets/images/docIconBlue.png';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { contain, filters } from './config';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Archive from '@material-ui/icons/Archive';
import CloseIcon from '@material-ui/icons/Close';

const DripsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [dripFolders, setDripFolders] = useState(null);
  const [newChanges, setNewChanges] = useState(false);
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [dripFolderOrdered, setDripFolderOrdered] = useState(null);
  const [allChanges, setAllChanges] = useState([]);
  const [clickedFolder, setClickedFolder] = useState(null);
  const [rootFolder, setRootFolder] = useState(null);
  const [dripFolderOrderFromDB, setDripFolderOrderFromDB] = useState(null);
  const dripFoldersList = useSelector(state => state.dripFolders.list);
  const messageTypesList = useSelector(state => state.messageTypes.list);
  const dripsList = useSelector(state => state.drips.list);
  const loader = useSelector(state => state.app.loader);
  const account_id = useSelector(state => state.auth.account_id);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [modalType, setModalType] = useState('new');
  const [dripListWithoutRoot, setDripListWithoutRoot] = useState(null);
  const [isDripTypeModalOpen, setIsDripTypeModalOpen] = useState(false);
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  const [searchFoundCount, setSearchFoundCount] = useState(null);
  const [searchFlag, setSearchFlag] = useState(false);
  const [
    isSaveAsCopyConfirmationModalOpen,
    setIsSaveAsCopyConfirmationModalOpen,
  ] = useState(false);
  const [
    isArchiveConfirmationModalOpen,
    setArchiveConfirmationModalOpen,
  ] = useState(false);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const NewFolderModal = lazy(() => import('./newFolderModal'));
  const DripTypeModal = lazy(() => import('./dripTypeModal'));
  const SaveAsCopyConfirmationDialog = lazy(() =>
    import('../../../components/saveAsCopyConfirmationModal'),
  );
  const ArchiveConfirmationDialog = lazy(() =>
    import('../../../components/archiveConfirmationModal'),
  );

  useEffect(() => {
    if (vertical_id) {
      dispatch(saveFilters('drips', filters(vertical_id)));
      dispatch(saveFilters('drip-folders', filters(vertical_id)));
      dispatch(saveContain('drips', contain));
      dispatch(cancelList('drips', null, dispatch));
      dispatch(cancelList('drip-folders', null, dispatch));
      dispatch(cancelList('message-types', null, dispatch));
      dispatch(
        getList('drips', { contain, filters: filters(vertical_id) }, dispatch),
      );
      dispatch(
        getList('drip-folders', { filters: filters(vertical_id) }, dispatch),
      );
      dispatch(getList('message-types', null, dispatch));
      dispatch(clearNameUnique('drips'));
      return () => {
        dispatch(cancelList('drips', null, dispatch));
        dispatch(cancelList('drip-folders', null, dispatch));
        dispatch(cancelList('message-types', null, dispatch));
      };
    }
  }, [dispatch, vertical_id]);

  useEffect(() => {
    if (dripsList && vertical_id) {
      dispatch(cancelList('drip-folders', null, dispatch));
      dispatch(
        getList(
          'drip-folders',
          {
            filters: filters(vertical_id),
          },
          dispatch,
        ),
      );
    }
    return () => {
      dispatch(cancelList('drip-folders', null, dispatch));
    };
  }, [dripsList, dispatch, vertical_id]);

  const renderIcon = icon => {
    if (icon === 'phone') {
      return <img src={PhoneFolder} alt="folder-icon" />;
    } else if (icon === 'email') {
      return <img src={EmailFolder} alt="folder-icon" />;
    } else if (icon === 'sms') {
      return <img src={SMSFolder} alt="folder-icon" />;
    } else if (icon === 'drip') {
      return <img src={DripFolder} alt="folder-icon" />;
    } else {
      return <img src={DocumentIcon} alt="folder-icon" />;
    }
  };

  const isRootFolder = folder =>
    folder.substring(0, 4) === 'ROOT' ? true : false;

  useEffect(() => {
    if (dripFoldersList && dripsList) {
      let allDrips = [...dripsList];
      allDrips.forEach(drip => (drip.folderType = 'drip'));
      dripFoldersList.forEach(drip => (drip.folderType = 'folder'));
      let folders = [...dripFoldersList, ...allDrips];
      folders.forEach(folder => {
        if (isRootFolder(folder.name)) {
          if (folder.contents) {
            let array = [];
            array.push(folder);
            let dripOrder = [...JSON.parse(folder.contents)];
            dripOrder.forEach(drip => {
              let dripFolder = folders.find(item => item.id === drip);
              if (dripFolder) {
                array.push(dripFolder);
              }
            });
            setDripFolderOrderFromDB(array);
          } else {
            setDripFolderOrderFromDB(folders);
          }
        }
      });
    }
  }, [dripFoldersList, dripsList]);

  useEffect(() => {
    let array = [];
    if (dripFolderOrderFromDB) {
      dripFolderOrderFromDB.forEach(drip => {
        if (drip) {
          drip.title = (
            <div className={classes.dripContent}>
              {renderIcon(drip.icon)}
              <span>{drip.name}</span>
            </div>
          );
          array.push(drip);
        }
      });
      setDripFolders(array);
    }
  }, [dripFolderOrderFromDB, classes.dripContent]);

  useEffect(() => {
    if (dripFoldersList) {
      dripFoldersList.forEach(folder => {
        if (!folder.parent_id) {
          setRootFolder(folder);
        }
      });
    }
  }, [dripFoldersList]);

  const handleCloseNewFolderModal = () => {
    setOpenFolderModal(false);
    setClickedFolder(null);
    setSelectedNodeId(null);
  };

  const handleOpenNewFolderModal = type => event => {
    event.stopPropagation();
    setModalType(type);
    setOpenFolderModal(true);
  };

  const handleSearchInput = event => {
    setSearchValue(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setSearch('');
    setSearchFlag(false);
  };

  const handleSearchButton = () => {
    setSearchFlag(true);
    setSearch(searchValue);
  };

  const handleOpenDripTypeModal = () => {
    setIsDripTypeModalOpen(true);
  };

  const handleCloseDripTypeModal = () => {
    setIsDripTypeModalOpen(false);
  };

  const handleClickNewDrip = e => {
    e.stopPropagation();
    handleOpenDripTypeModal();
  };

  const handleSearchInputKey = event => {
    if (event.key === 'Enter') {
      setSearch(searchValue);
      setSearchFlag(true);
      if (searchFoundCount) {
        selectNextMatch();
      }
    }
  };

  const handleClickedFolder = folder => {
    setClickedFolder(folder);
  };

  const handleClearClickedFolder = () => {
    setClickedFolder(null);
    setSelectedNodeId(null);
  };

  const selectPrevMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
        : searchFoundCount - 1,
    );
  };

  const selectNextMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0,
    );
  };

  const handleSaveChanges = () => {
    allChanges.forEach(changes => {
      if (changes.folderType === 'drip') {
        dispatch(editStart('drips', { body: changes }, dispatch));
      } else {
        dispatch(editStart('drip-folders', { body: changes }, dispatch));
      }
    });
    setAllChanges([]);
    setNewChanges(false);
  };

  const renderNewFolderModal = openFolderModal ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={true} />
        </Grid>
      }
    >
      <NewFolderModal
        open={openFolderModal}
        classes={classes}
        handleClose={handleCloseNewFolderModal}
        clickedFolder={clickedFolder}
        rootFolder={rootFolder}
        modalType={modalType}
        dripListWithoutRoot={dripListWithoutRoot}
        list={dripFolders}
      />
    </Suspense>
  ) : null;

  const renderDripTypeModal = isDripTypeModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={true} />
        </Grid>
      }
    >
      <DripTypeModal
        open={isDripTypeModalOpen}
        classes={classes}
        handleClose={handleCloseDripTypeModal}
        clickedFolder={clickedFolder}
        rootFolder={rootFolder}
      />
    </Suspense>
  ) : null;

  const getRouteByType = typeName => {
    return `${typeName.split('_')[1]}-drip`;
  };

  const handleEditDrip = event => {
    event.stopPropagation();
    let typeName = '';
    messageTypesList.forEach(type => {
      if (type.id === clickedFolder.message_type_id) {
        typeName = type.name;
      }
    });
    history.push({
      pathname: `/drips/${getRouteByType(typeName)}/edit/${clickedFolder.id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleClickArchiveDrip = event => {
    event.stopPropagation();
    handleArchiveModalOpen();
  };

  const handleClickCloneDrip = event => {
    event.stopPropagation();
    handleSaveAsCopyModalOpen();
  };

  const handleCloneDrip = notes => {
    let data = {
      body: {
        ...clickedFolder,
        name: `Copy of ${clickedFolder.name}`,
        account_id,
        vertical_id,
        notes,
      },
    };
    delete data.body.id;
    dispatch(createStart('drips', data, dispatch));
  };

  const handleArchiveDrip = notes => {
    let data = {
      body: {
        ...clickedFolder,
        archived: 1,
        account_id,
        vertical_id,
        notes,
      },
    };
    dispatch(createStart('drips', data, dispatch));
  };

  const handleSaveAsCopyModalClose = () => {
    setIsSaveAsCopyConfirmationModalOpen(false);
  };

  const handleSaveAsCopyModalOpen = () => {
    setIsSaveAsCopyConfirmationModalOpen(true);
  };

  const handleArchiveModalClose = () => {
    setArchiveConfirmationModalOpen(false);
  };

  const handleArchiveModalOpen = () => {
    setArchiveConfirmationModalOpen(true);
  };

  const renderSaveAsCopyConfirmationDialog =
    isSaveAsCopyConfirmationModalOpen && clickedFolder ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={Boolean(loader)} />
          </Grid>
        }
      >
        <SaveAsCopyConfirmationDialog
          open={isSaveAsCopyConfirmationModalOpen}
          handleSaveAsCopy={handleCloneDrip}
          handleClose={handleSaveAsCopyModalClose}
          model_id={clickedFolder && clickedFolder.id}
          model_name="Drip"
        />
      </Suspense>
    ) : null;

  const renderArchiveConfirmationDialog =
    isArchiveConfirmationModalOpen && clickedFolder ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={Boolean(loader)} />
          </Grid>
        }
      >
        <ArchiveConfirmationDialog
          open={isArchiveConfirmationModalOpen}
          handleArchive={handleArchiveDrip}
          handleClose={handleArchiveModalClose}
          itemName={'drip'}
          model_id={clickedFolder && clickedFolder.id}
          model_name="Drip"
        />
      </Suspense>
    ) : null;

  const hideNewFolderButton =
    clickedFolder && clickedFolder.folderType === 'drip' ? false : true;

  const renderSearchIcon = searchValue ? (
    <CloseIcon className={classes.searchIcon} onClick={handleClearSearch} />
  ) : (
    <SearchIcon className={classes.searchIcon} onClick={handleSearchButton} />
  );

  return (
    <Grid
      container
      className={classes.container}
      onClick={handleClearClickedFolder}
    >
      <Grid item xs={12} container className={classes.topSection}>
        <img src={DripsIcon} alt="Flows icon" className={classes.titleIcon} />
        <Grid container item xs={12} alignItems="center">
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Drips
          </Typography>
        </Grid>
        <Grid container item xs={12} justify="flex-end" alignItems="flex-end">
          <Grid item>
            <TextField
              value={searchValue}
              onChange={handleSearchInput}
              className={classes.searchField}
              onKeyPress={handleSearchInputKey}
              variant="outlined"
              label="Search Folders"
              placeholder="Search Folders"
              size="small"
              disabled={!dripFoldersList}
              InputLabelProps={{
                classes: {
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                endAdornment: searchFoundCount ? (
                  <div className={classes.searchArrows}>
                    <ArrowBackIosIcon onClick={selectPrevMatch} />
                    <span>{`${
                      searchFoundCount > 0 ? searchFocusIndex + 1 : 0
                    } / ${searchFoundCount}`}</span>
                    <ArrowForwardIosIcon onClick={selectNextMatch} />
                  </div>
                ) : (
                  ''
                ),
                startAdornment: <>{renderSearchIcon}</>,
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justify="space-between"
        className={classes.actionButtonsContainer}
      >
        <Grid item xs={4} container>
          {newChanges && (
            <Button
              className={classes.saveChangesBtn}
              onClick={handleSaveChanges}
            >
              Save Changes
            </Button>
          )}
        </Grid>
        <Grid item container xs={8} justify="flex-end">
          {clickedFolder && clickedFolder.folderType !== 'drip' && (
            <Grid item className={classes.addFolderButton}>
              <Button
                onClick={handleOpenNewFolderModal('edit')}
                startIcon={<EditIcon />}
              >
                Edit Drip Folder
              </Button>
            </Grid>
          )}
          <Grid item className={classes.addFolderButton}>
            {hideNewFolderButton && (
              <Button
                disabled={!rootFolder}
                onClick={handleOpenNewFolderModal('new')}
                startIcon={<AddIcon />}
              >
                New Drip Folder
              </Button>
            )}
          </Grid>
          <Grid item className={classes.addButton}>
            {hideNewFolderButton && (
              <Button
                disabled={!rootFolder}
                onClick={handleClickNewDrip}
                startIcon={<AddIcon />}
              >
                New Drip
              </Button>
            )}
            {!hideNewFolderButton && (
              <Button
                className={classes.cloneDripBtn}
                onClick={handleClickArchiveDrip}
                startIcon={<Archive />}
              >
                Archive
              </Button>
            )}
            {!hideNewFolderButton && (
              <Button
                className={classes.cloneDripBtn}
                onClick={handleClickCloneDrip}
                startIcon={<FileCopyIcon />}
              >
                Clone
              </Button>
            )}
            {!hideNewFolderButton && (
              <Button onClick={handleEditDrip} startIcon={<EditIcon />}>
                Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {rootFolder && !Boolean(loader) && (
          <FolderList
            classes={classes}
            list={dripFolders}
            dripFolderOrdered={dripFolderOrdered}
            handleClickedFolder={handleClickedFolder}
            setDripFolderOrdered={setDripFolderOrdered}
            setSelectedNodeId={setSelectedNodeId}
            selectedNodeId={selectedNodeId}
            rootFolder={rootFolder}
            search={search}
            setSearch={setSearch}
            setSearchValue={setSearchValue}
            setDripListWithoutRoot={setDripListWithoutRoot}
            dripListWithoutRoot={dripListWithoutRoot}
            searchFocusIndex={searchFocusIndex}
            setSearchFocusIndex={setSearchFocusIndex}
            setSearchFoundCount={setSearchFoundCount}
            setClickedFolder={setClickedFolder}
            setNewChanges={setNewChanges}
            setAllChanges={setAllChanges}
            allChanges={allChanges}
            dispatch={dispatch}
            setSearchFlag={setSearchFlag}
            searchFlag={searchFlag}
          />
        )}
        {Boolean(loader) && <Loader open={Boolean(loader)} />}
      </Grid>
      {renderNewFolderModal}
      {renderDripTypeModal}
      {renderSaveAsCopyConfirmationDialog}
      {renderArchiveConfirmationDialog}
    </Grid>
  );
};

export default withTitle(withDashboard(DripsList, false), 'Drips');
