export const styles = theme => ({
  backgroundLayer: {
    backgroundColor: '#2D435F',
    width: '100%',
    height: '100vh',
  },
  loginContainer: {
    background: `linear-gradient(180deg, #42638E 0%, rgba(41, 77, 122, 0) 100%)`,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: "'Lato', sans-serif",
    '& > div': {
      textAlign: 'center',
      '& > form': {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& > img': {
          width: 334,
          marginBottom: 65,
        },
      },
    },
  },
  forgotPasswordLink: {
    color: '#9DC4F6',
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'all .4s',
    '&:hover': {
      color: '#FFBF58',
    },
  },
  logo: {
    marginBottom: 50,
    width: 270,
    [theme.breakpoints.up(340)]: {
      width: 334,
    },
    [theme.breakpoints.up(1200)]: {
      marginBottom: 100,
    },
  },
  inputField: {
    backgroundColor: theme.palette.common.white,
    color: '#828282',
    margin: '15px 0',
    borderRadius: 2,
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 433,
    },
    '& > input': {
      padding: 15,
    },
  },
  loginButton: {
    backgroundColor: '#FFBF58',
    color: theme.palette.common.white,
    fontSize: 24,
    fontWeight: 900,
    width: 246,
    padding: '11px 0',
    marginTop: 45,
    '&:hover': {
      backgroundColor: '#FF9E00',
    },
  },
  message: {
    fontSize: 20,
    margin: '0 0 10px 0',
    color: theme.palette.common.white,
  },
  loader: {
    color: 'white',
  },
});
