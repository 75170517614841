import { formatToLocalDate } from '../../../config/helpers';

export const columns = [
  { id: 'name', label: 'Name', minWidth: 60, align: 'left' },
  { id: 'description', label: 'Description', minWidth: 60, align: 'left' },
  {
    id: 'created',
    label: 'Created',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'modified',
    label: 'Modified',
    minWidth: 150,
    align: 'left',
  },
];

const createData = (name, description, created, modified, item) => {
  return {
    name,
    description,
    created,
    modified,
    item,
  };
};

export const renderElements = (list, setRows) => {
  let row = [];
  list &&
    list.forEach(item => {
      const { description, name, created, modified } = item;
      row.push(
        createData(
          name,
          description,
          formatToLocalDate(created, 'MMM, dd yyyy'),
          formatToLocalDate(modified, 'MMM, dd yyyy'),
          item,
        ),
      );
    });
  setRows(row);
};

export const sortBy = {
  id: 'asc',
  name: 'asc',
  description: 'asc',
  status: 'asc',
  created: 'asc',
  modified: 'desc',
};

export const initialValues = {
  id: '',
  name: '',
  description: '',
  parameters: '',
  notes: [],
};

export const populateValues = values => {
  const { id, name, description, parameters, notes } = values;
  return {
    id,
    name,
    description,
    parameters,
    notes: notes || [],
  };
};
