import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import UploadsIcon from '../../../assets/images/upload_icon.svg';
import NameField from '../../../components/form/name';
import CustomSelect from '../../../components/form/customSelect';
import { Formik, Form } from 'formik';
import PublishButton from '../../../components/customButtons/customPublish';
import CustomUploadButton from '../../../components/customButtons/customUpload';
import { useSelector, useDispatch } from 'react-redux';
import {
  cancelList,
  uploadStart,
  getList,
} from '../../../store/actions/actions/rest';
import Loader from '../../../components/loader';
import { initialValues, populateValues, paramsFlow } from './config';
import CustomDateTimePicker from '../../../components/form/dateTimePicker';
import { generateUTCDate } from '../../reporting/singleReportingPage/config';

const SingleUplaod = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const loader = useSelector(state => state.app.loader);
  const flowsList = useSelector(state => state.flows.list);
  const element = useSelector(
    state =>
      (state.uploads.list &&
        state.uploads.list.find(item => item.id === +match.params.id)) ||
      state.uploads.item,
  );
  const [formValues, setFormValues] = useState(initialValues());
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    if (element) {
      setFormValues(populateValues(element));
    }
  }, [element]);

  useEffect(() => {
    dispatch(cancelList('flows', null, dispatch));
    dispatch(getList('flows', { params: paramsFlow }, dispatch));
    return () => {
      dispatch(cancelList('flows', null, dispatch));
    };
  }, [dispatch]);

  const onFileChange = event => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      setUploadedFile(event.target.files[0]);
    }
  };

  const onSubmit = values => {
    const formData = new FormData();
    formData.append('file', uploadedFile, uploadedFile.name);
    formData.append('filename', uploadedFile.name);
    formData.append('account_id', account_id);
    formData.append('vertical_id', vertical_id);
    formData.append('name', values.name);
    formData.append('flow_id', values.flow_id);
    let UTCDate = generateUTCDate(values.delay);
    formData.append('delay', UTCDate);

    const data = {
      body: formData,
    };

    dispatch(uploadStart('uploads', data, dispatch));
    setUploadedFile(null);
    history.push({
      pathname: '/flows/uploads',
      search: `vertical_id=${vertical_id}`,
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} container alignItems="flex-end">
        <Grid item xs={1} className={classes.iconContainer}>
          <img
            src={UploadsIcon}
            alt="Uploads icon"
            className={classes.titleIcon}
          />
        </Grid>
        <Grid container item xs={6} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h1" component="h2" className={classes.title}>
              Add Upload
            </Typography>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.subtitle}
            >
              Upload files
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <Grid item xs={12} className={classes.subtitleText}>
          <div>
            * if sending to a telelead step, make sure telelead_key is set on
            the template
          </div>
          <div>* csv should have "email" or "phone" as headers</div>
        </Grid>
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          onSubmit={values => {
            onSubmit(values);
          }}
        >
          {props => (
            <Form className={classes.formWrapper}>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <NameField
                    {...props}
                    placeholder="File Name"
                    labelText="File Name"
                    name="name"
                    type="uploads"
                    wrapperClass={classes.textField}
                  />
                  <CustomSelect
                    {...props}
                    placeholder="Flow"
                    labelText="Flow"
                    name="flow_id"
                    wrapperClass={classes.textField}
                    options={flowsList ? flowsList : []}
                  />
                  <CustomDateTimePicker {...props} name="delay" label="Delay" />
                  <CustomUploadButton
                    onChange={onFileChange}
                    {...props}
                    uploadedFile={uploadedFile}
                    setUploadedFile={setUploadedFile}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.actionButtons}>
                <PublishButton type="submit" text="Submit" />
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(withDashboard(SingleUplaod, false), 'Single Uplaod');

SingleUplaod.propTypes = {
  match: PropTypes.object.isRequired,
};
