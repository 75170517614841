import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';

const SmsPreview = ({ classes, placeholders }) => {
  const { sms_body, smsSenderName } = placeholders;
  const renderSenderName = smsSenderName ? smsSenderName : '';
  const renderSmsBody = sms_body ? sms_body : '';
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.testPreviewTitle}
        >
         SMS Body Text Preview
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.mobilePreviewContainer}>
          <div className={classes.mobilePreview}>
            <h2>{renderSenderName}</h2>
            <div className={classes.message}>
              {sms_body && <p><Linkify>{renderSmsBody}</Linkify></p>}
            </div>
          </div>
        </div>
      </Grid>
    </Fragment>
  );
};

export default SmsPreview;

SmsPreview.propTypes = {
  placeholders: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
