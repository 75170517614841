import { formatToLocalDate } from '../../../config/helpers';

export const columns = [
  {
    id: 'name',
    label: 'Upload Name',
    minWidth: 60,
    align: 'left',
    table: 'upload',
  },
  {
    id: 'filename',
    label: 'File Name',
    minWidth: 60,
    align: 'left',
    table: 'upload',
  },
  {
    id: 'valid_records',
    label: 'Records',
    minWidth: 150,
    align: 'left',
    table: 'upload',
  },
  {
    id: 'flow_id',
    label: 'Flow',
    minWidth: 150,
    align: 'left',
    table: 'upload',
  },
  {
    id: 'status',
    label: 'Upload Status',
    minWidth: 150,
    align: 'left',
    table: 'upload',
  },
  {
    id: 'created',
    label: 'Date Created',
    minWidth: 100,
  },
];

export const contain = ['flows'];

export const createData = (
  name,
  filename,
  valid_records,
  flow_id,
  status,
  created,
  item,
) => {
  return { name, filename, valid_records, flow_id, status, created, item };
};

export const getUploadStatus = status => {
  if (status === 1) {
    return 'In Progress';
  } else if (status === 2) {
    return 'Finished';
  } else if (status === 3) {
    return 'Error';
  }
};

export const renderElements = (list, setRows) => {
  let row = [];
  list &&
    list.forEach(item => {
      const {
        name,
        filename,
        valid_records,
        total_records,
        status,
        created,
      } = item;
      row.push(
        createData(
          name,
          filename,
          `${valid_records}/${total_records}`,
          item.flow.name,
          getUploadStatus(status),
          formatToLocalDate(created, 'MMM, dd yyyy'),
          item,
        ),
      );
    });
  setRows(row);
};

export const sortBy = {
  created: 'asc',
};

export const initialValues = () => ({
  name: '',
  delay: new Date(),
  file: '',
  flow_id: '',
  notes: [],
});

export const params = {
  direction: 'asc',
  sort: 'name',
  page: 1,
  limit: 500,
  search: '',
};

export const filters = [
  {
    field: 'vertical_id',
    operator: '=',
    path: 'vertical_id',
  },
  {
    field: 'account_id',
    operator: '=',
    path: 'account_id',
  },
];

export const populateValues = values => {
  const { name, flow_id, delay, notes, file } = values;
  return {
    name,
    flow_id,
    file,
    delay,
    notes: notes || [],
  };
};

export const paramsFlow = {
  direction: 'asc',
  sort: 'name',
  page: 1,
  limit: 500,
  search: '',
};
