export const filters = [
  {
    field: 'vertical_id',
    operator: '=',
    path: 'vertical_id'
  },
  {
    field: 'account_id',
    operator: '=',
    path: 'account_id'
  },
];

export const params = {
  direction: 'asc',
  sort: 'name',
  page: 1,
  limit: 500,
  search: '',
};
