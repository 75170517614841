import {
  GET_ITEM,
  GET_LIST,
  GET_LIST_COUNT,
  CREATE,
  REMOVE,
  CHECK_NAME_UNIQUE,
  CLEAR_NEW_FLOW,
  CLEAR_NEW_FLOW_STEP_MAP,
  SAVE_CONTAIN,
  SAVE_FILTERS,
  SAVE_PARAMS,
  CLEAR_NAME_UNIQUE,
  CLEAR,
} from '../actions/actionTypes/rest';

export default type => {
  const REDUCER_TYPE = type.toUpperCase();
  const initialState = {
    list: null,
    item: null,
    count: 0,
    params: null,
    filters: null,
    contain: null,
  };

  const isRootFolder = folder =>
    folder.substring(0, 4) === 'ROOT' ? true : false;

  const updateRootFolder = (action, state) => {
    if (action.route === 'drip-folders' || action.route === 'drips') {
      state.list.forEach(el => {
        if (isRootFolder(el.name)) {
          el.contents = action.payload.root_folder_contents;
        }
      });
    }
  };

  const saveSingleFlow = (action, state) => {
    if (action.route === 'flows') {
      state.newFlow = action.payload;
    }
  };

  const saveSingleFlowStepMap = (action, state) => {
    if (action.route === 'flow-step-maps') {
      state.newFlowStepMap = action.payload;
    }
  };

  const orderFlowStepMaps = (action, state) => {
    if (action.route === 'flow-step-maps') {
      let steps = [...state.list];
      steps.splice(action.payload.position, 0, action.payload);
      steps.forEach((step, index) => (step.position = index));
      return steps;
    } else {
      return null;
    }
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case GET_ITEM(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          item: action.payload,
        };
      case GET_LIST(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          list: action.payload,
        };
      case GET_LIST_COUNT(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          count: action.payload,
        };
      case CREATE(REDUCER_TYPE).actionTypes.SUCCESS:
        updateRootFolder(action, state);
        saveSingleFlow(action, state);
        saveSingleFlowStepMap(action, state);
        let flows = orderFlowStepMaps(action, state);
        let list = null;
        if (flows) {
          list = flows;
        } else {
          list = [...state.list];
        }
        return {
          ...state,
          list: list,
          count: state.count + 1,
        };
      case REMOVE(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          count: state.count - 1,
        };
      case CHECK_NAME_UNIQUE(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          nameUnique: action.payload[0],
        };
      case CLEAR_NAME_UNIQUE(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          nameUnique: null,
        };
      case SAVE_CONTAIN(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          contain: action.payload,
        };
      case SAVE_FILTERS(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          filters: action.payload,
        };
      case SAVE_PARAMS(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          params: action.payload,
        };
      case CLEAR(REDUCER_TYPE).actionTypes.SUCCESS:
        return {
          ...state,
          list: null,
        };
      case CLEAR_NEW_FLOW:
        return {
          ...state,
          newFlow: undefined,
        };
      case CLEAR_NEW_FLOW_STEP_MAP:
        return {
          ...state,
          newFlowStepMap: undefined,
        };
      default:
        return state;
    }
  };
};
