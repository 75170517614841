import React, { Fragment } from 'react';
import classNames from 'classnames';
import SingleReportingDeletedStep from './singleReportingDeletedStep';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import SingleReportTransition from './singleReportingTransition';

const DeletedSteps = ({
  classes,
  showDeletedSteps,
  handleDeletedSteps,
  allSteps,
  vertical_id,
}) => {
  const renderStepTransitions = transitions => {
    if (transitions && transitions.length > 0) {
      return transitions.map(transition => {
        let {
          To_Flow_Name,
          flow_step_transition_map_id,
          to_flow_id,
        } = transition;
        let key = `${To_Flow_Name}${flow_step_transition_map_id}${to_flow_id}`;
        return (
          <SingleReportTransition
            key={key}
            transition={transition}
            isDeleted={true}
            vertical_id={vertical_id}
            isActiveStep={false}
          />
        );
      });
    }
  };

  const renderDeletedSteps = () => {
    if (allSteps && allSteps.length > 0) {
      return allSteps.map(step => {
        let {
          archived,
          current_version,
          drip_type,
          flow_step_map_id,
          flow_step_position,
          flow_step_transitions,
        } = step;
        if (archived) {
          let key = `${archived}_${current_version}_${flow_step_map_id}_${flow_step_position}_${drip_type}`;
          return (
            <Fragment key={key}>
              <SingleReportingDeletedStep type={drip_type} data={step} />
              {renderStepTransitions(flow_step_transitions)}
            </Fragment>
          );
        }else {
            return null
        }
      });
    }
  };

  return (
    <div
      className={classNames(classes.deletedSteps, {
        [classes.deletedStepsOpen]: showDeletedSteps,
      })}
    >
      <div
        className={classNames(classes.deletedStepsLabel, {
          [classes.deletedStepsLabelOpen]: showDeletedSteps,
        })}
      >
        <div className={classes.deletedStepsLabelText}>Deleted Steps</div>
        <div className={classes.deletedShow} onClick={handleDeletedSteps}>
          <span>{showDeletedSteps ? 'See less' : 'See more'}</span>
          <KeyboardArrowDownIcon
            className={classNames(classes.showMoreArrow, {
              [classes.showMoreArrowActive]: showDeletedSteps,
            })}
          />
        </div>
      </div>
      <Collapse in={showDeletedSteps}>
        <>
          {renderDeletedSteps()}
        </>
      </Collapse>
    </div>
  );
};

export default DeletedSteps;
