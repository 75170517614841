import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';

const Search = props => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchInput = event => {
    setSearchValue(event.target.value);
  };

  const handleSearchButton = () => {
    props.setSearch(searchValue);
  };

  const handleSearchInputKey = event => {
    if (event.key === 'Enter') {
      props.setSearch(searchValue);
    }
  };

  const handleClearInput = () => {
    setSearchValue('');
    props.setSearch('');
    props.handleClearSearch && props.handleClearSearch();
  };

  const renderIcon = searchValue ? (
    <IconButton onClick={handleClearInput}>
      <CloseIcon />
    </IconButton>
  ) : (
    <IconButton onClick={handleSearchButton}>
      <SearchIcon />
    </IconButton>
  );

  return (
    <TextField
      value={searchValue}
      onChange={handleSearchInput}
      onKeyPress={handleSearchInputKey}
      className={props.classes.searchField}
      variant="outlined"
      label={`Search ${props.type}`}
      placeholder={`Search ${props.type}`}
      size="small"
      InputLabelProps={{
        classes: {
          focused: props.classes.cssFocused,
        },
      }}
      InputProps={{
        endAdornment: (
          <>{renderIcon}</>
        ),
        classes: {
          notchedOutline: props.classes.notchedOutline,
        },
      }}
    />
  );
};

export default Search;
