export const findFolderPath = (list, id) => {
  if (list) {
    let folderPath = [];
    list.forEach(item => {
      if (item.id === id) {
        if (item.parent_id) {
          folderPath.push(item.name);
          let items = findFolderPath(list, item.parent_id);
          folderPath = [...folderPath, ...items];
        }
      }
    });
    return folderPath;
  }
};

export const getPathString = list => {
  if (list.length > 0) {
    let pathString = list.reverse().join();
    let result = pathString.split(',').join('/');
    return `Root/${result}`;
  } else {
    return 'Root/';
  }
};

export const checkIfParentExist = (list, id) =>
  list.find(folder => folder.id === id);


export const checkIfElementExist = (list, id) =>
  list.find(folder => folder.id === id);

export const getMessageTypeID = (list, name) =>
  list.forEach(item => {
    if (item.name === name) {
      return item.id;
    }
  });
