import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 270;

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  drawer: {
    transition: 'all .4s',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: theme.palette.blue[400],
    height: 90,
    [theme.breakpoints.up('sm')]: {
      width: `100%`,
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.up(1200)]: {
      height: 82,
    },
    '& > div': {
      height: '100%',
      padding: '0 15px',
      [theme.breakpoints.up(1200)]: {
        padding: '0 40px',
      },
    },
  },
  logo: {
    marginTop: -20,
    '& > img': {
      width: 80,
    },
    [theme.breakpoints.up(1200)]: {
      marginTop: 0,
      '& > img': {
        width: 142,
      },
    },
  },
  hamburgerMenuIcon: {
    display: 'block',
    marginLeft: 27,
    position: 'relative',
    bottom: 1,
    cursor: 'pointer',
    '& > svg': {
      fontSize: 30,
    },
    [theme.breakpoints.up(1200)]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerClosed: {
    left: -200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('xl')]: {
      left: 0,
    },
  },
  drawerOpen: {
    left: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      left: 0,
    },
  },
  drawerPaper: {
    transition: 'all .4s',
    width: drawerWidth,
    zIndex: 1000,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.blue[250],
  },
  content: {
    flexGrow: 1,
    overflowX: 'hidden',
    padding: '24px 15px',
    [theme.breakpoints.up(1200)]: {
      padding: theme.spacing(3),
    },
  },
  smallDrawer: {
    display: 'block',
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  desktopDrawer: {
    display: 'none',
    [theme.breakpoints.up('xl')]: {
      display: 'block',
    },
  },
  desktopDrawerContainer: {
    display: 'none',
    [theme.breakpoints.up(1200)]: {
      display: 'block',
    },
  },
  mobileDrawer: {
    width: 273,
    height: '100%',
    '& > nav': {
      height: '100%',
      paddingLeft: 0,
      paddingTop: 25,
    },
  },
  mobileDrawerPaper: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.blue[250],
  },
}));
