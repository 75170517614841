import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const DesneTableSwitch = ({ dense, setDense, wrapperClass }) => {
  const handleChange = event => {
    setDense(event.target.checked);
  };

  return (
    <div className={wrapperClass}>
      <FormControlLabel
        control={
          <Switch
            checked={dense}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Dense table view"
      />
    </div>
  );
};

export default DesneTableSwitch;
