import React, { useState, useEffect, Suspense, lazy } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import { useHistory, useRouteMatch } from 'react-router-dom';
import RvmDripImage from '../../../assets/images/drip-component-image.svg';
import NameField from '../../../components/form/name';
import IdField from '../../../components/form/id';
import { Formik, Form } from 'formik';
import DeleteButton from '../../../components/customButtons/customDelete';
import PublishButton from '../../../components/customButtons/customPublish';
import { useSelector, useDispatch } from 'react-redux';
import {
  editStart,
  removeStart,
  createStart,
} from '../../../store/actions/actions/rest';
import Loader from '../../../components/loader';
import ModelTags from '../../../components/modelTags/modelTags';
import { initialValues, populateValues, contain } from './config';
import withSingleDripPage from '../../../hoc/withSingleDripPage/withSingleDripPage';
import PropTypes from 'prop-types';

const SingleRvmPage = ({ element }) => {
  const match = useRouteMatch();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const user_name = useSelector(state => state.auth.username);
  const loader = useSelector(state => state.app.loader);
  const [formValues, setFormValues] = useState(initialValues());
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const DeleteConfirmationDialog = lazy(() =>
    import('../../../components/deleteConfirmationModal'),
  );
  const NotesModal = lazy(() => import('../../../components/notes/notes'));

  useEffect(() => {
    if (element) {
      setFormValues(populateValues(element));
    }
  }, [element]);

  const handleDelete = notes => {
    const data = {
      body: {
        id: formValues.id,
        notes,
      },
    };
    dispatch(removeStart('rvm-components', data, dispatch));
    history.push({
      pathname: '/drip-components/rvm-list',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleDeleteModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const onSubmit = values => {
    if (match.params.id) {
      handleOpenNotesModal();
    } else {
      let allValues = populateValues(values);
      let data = {
        body: {
          ...allValues,
          account_id: account_id,
          vertical_id: vertical_id,
          notes: [
            {
              account_id,
              vertical_id,
              model_name: 'RvmComponent',
              model_id: '',
              user_name,
              note: `Created ${values && values.name ? values.name : ""}`,
            },
          ],
        },
      };
      dispatch(createStart('rvm-components', data, dispatch));
      history.push({
        pathname: '/drip-components/rvm-list',
        search: `vertical_id=${vertical_id}`,
      });
    }
  };

  const submitForm = (values, notes) => {
    let allValues = populateValues(values);
    let data = {
      body: {
        ...allValues,
        account_id: account_id,
        vertical_id: vertical_id,
        notes,
      },
    };
    dispatch(editStart('rvm-components', data, dispatch));
    history.push({
      pathname: '/drip-components/rvm-list',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleOpenNotesModal = () => {
    setNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setNotesModalOpen(false);
  };

  const renderDeleteConfirmationDialog = isDeleteConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <DeleteConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        handleDelete={handleDelete}
        handleClose={handleDeleteModalClose}
        itemName={'rvm list'}
        model_id={+match.params.id || ''}
        model_name="RvmComponent"
      />
    </Suspense>
  ) : null;

  const renderNotesModal = props =>
    notesModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={notesModalOpen} />
          </Grid>
        }
      >
        <NotesModal
          props={props}
          classes={classes}
          model_name="RvmComponent"
          model_id={+match.params.id || ''}
          open={notesModalOpen}
          handleClose={handleCloseNotesModal}
          submitForm={submitForm}
        />
      </Suspense>
    ) : null;

  return (
    <Grid container item xs={12} className={classes.tableWrapper}>
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {props => (
          <Form className={classes.formWrapper}>
            <NameField
              {...props}
              placeholder="RVM List Name"
              labelText="RVM List Name"
              name="name"
              wrapperClass={classes.textField}
              id={element && element.id}
              dripComponent="rvm-components"
            />
            <IdField
              {...props}
              placeholder="ID"
              labelText="ID"
              name="list_id"
              wrapperClass={classes.textField}
            />
            <ModelTags
              props={props}
              classes={classes}
              model_name="RvmComponent"
              model_id={match.params.id || ''}
              formValues={formValues}
              setFormValues={setFormValues}
            />
            <Grid item xs={12} className={classes.actionButtons}>
              {match.params.id && (
                <DeleteButton onClick={handleDeleteModalOpen} />
              )}
              <PublishButton type="submit" />
            </Grid>
            {renderDeleteConfirmationDialog}
            {renderNotesModal(props)}
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default withSingleDripPage(
  withTitle(withDashboard(SingleRvmPage, false), 'RVM List'),
  {
    title: 'New RVM List',
    image: RvmDripImage,
    path: 'rvm-components',
    contain: contain,
    lists: ['rvm-components'],
  },
);

SingleRvmPage.propTypes = {
  element: PropTypes.object,
};
