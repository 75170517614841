import { get, post } from '../../../config/api';
import { switchMap, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  getAuth,
  authFulfilled,
  saveUserAccounts,
  getAccountVerticals,
  saveAccountVerticals,
  hideVerticalLoader,
} from '../../actions/actions/auth';
import { displaySnackbar, hideLoader } from '../../actions/actions/root';
import {
  SET_ACTIVE_VERTICAL,
  SET_ACTIVE_ACCOUNT,
  GET_USER_ACCOUNTS,
  GET_ACCOUNT_VERTICALS,
} from '../../actions/actionTypes/auth';
import { of, merge } from 'rxjs';
import { consoleError } from '../../../config/helpers';

export const handleErrorMessage = error => {
  let message = 'Service temporarily unavailable.';
  if (error && error.xhr) {
    if (error.xhr.response) {
      if (error.xhr.response && error.xhr.response.Error) {
        message = error.xhr.response.Error;
      }
    }
  }
  return message;
};

export const setActiveAccountEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SET_ACTIVE_ACCOUNT.actionTypes.START),
    switchMap(action => of(getAuth(action.payload))),
    switchMap(({ payload }) =>
      payload.auth.then(data => ({
        token: data.signInUserSession.accessToken.jwtToken,
        account_id: payload.data,
      })),
    ),
    switchMap(({ account_id, token }) =>
      post(
        'user-settings',
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        JSON.stringify({ active_account_id: `${account_id}` }),
      ).pipe(
        switchMap(() => of(getAuth({}, true))),
        switchMap(({ payload }) => payload.auth),
        mergeMap(data => [
          authFulfilled(data),
          getAccountVerticals(),
          displaySnackbar({
            type: 'success',
            message: 'Successfully updated active account.',
          }),
        ]),
        takeUntil(action$.pipe(ofType(SET_ACTIVE_ACCOUNT.actionTypes.FAILURE))),
      ),
    ),
    catchError((error, caught) => {
      consoleError(error);
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
        caught,
      );
    }),
  );
};

export const setActiveVerticalEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SET_ACTIVE_VERTICAL.actionTypes.START),
    switchMap(action => of(getAuth(action.payload))),
    switchMap(({ payload }) =>
      payload.auth.then(data => ({
        token: data.signInUserSession.accessToken.jwtToken,
        vertical_id: payload.data,
      })),
    ),
    switchMap(({ vertical_id, token }) =>
      post(
        'user-settings',
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        JSON.stringify({ active_vertical_id: `${vertical_id}` }),
      ).pipe(
        switchMap(() => of(getAuth({}, true))),
        switchMap(({ payload }) => payload.auth),
        mergeMap(data => [
          authFulfilled(data),
          hideVerticalLoader(),
          displaySnackbar({
            type: 'success',
            message: 'Successfully updated active vertical.',
          }),
        ]),
        takeUntil(
          action$.pipe(ofType(SET_ACTIVE_VERTICAL.actionTypes.FAILURE)),
        ),
      ),
    ),
    catchError((error, caught) => {
      consoleError(error);
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
        caught,
      );
    }),
  );
};

export const getUserAccountsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(GET_USER_ACCOUNTS.actionTypes.START),
    switchMap(action => of(getAuth(action.payload))),
    switchMap(({ payload }) =>
      payload.auth.then(data => ({
        token: data.signInUserSession.accessToken.jwtToken,
      })),
    ),
    switchMap(({ token }) =>
      get('user-settings', {
        'Faucet-Token': token,
        'Account-ID': state$.value.auth.account_id,
      }).pipe(
        mergeMap(data => [saveUserAccounts(data)]),
        takeUntil(action$.pipe(ofType(GET_USER_ACCOUNTS.actionTypes.FAILURE))),
      ),
    ),
    catchError((error, caught) => {
      consoleError(error);
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
        caught,
      );
    }),
  );
};

export const getAccountVerticalsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(GET_ACCOUNT_VERTICALS.actionTypes.START),
    switchMap(action => of(getAuth(action.payload))),
    switchMap(({ payload }) =>
      payload.auth.then(data => ({
        token: data.signInUserSession.accessToken.jwtToken,
      })),
    ),
    switchMap(({ token }) =>
      get('verticals', {
        'Faucet-Token': token,
        'Account-ID': state$.value.auth.account_id,
      }).pipe(
        mergeMap(data => [saveAccountVerticals(data.response)]),
        takeUntil(
          action$.pipe(ofType(GET_ACCOUNT_VERTICALS.actionTypes.FAILURE)),
        ),
      ),
    ),
    catchError((error, caught) => {
      consoleError(error);
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
        caught,
      );
    }),
  );
};
