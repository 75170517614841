import React, { useState, useEffect } from 'react';
import TableDragSelect from 'react-table-drag-select';
import 'react-table-drag-select/style.css';
import useStyles from './styles';
import './style.css';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
// import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Hours from './hours';
import Days from './days';
import { populateCells, createEmptyRow, populateDefaultDays } from './helper';
import PropTypes from 'prop-types';

const HoursOfOperation = ({
  setCells,
  cells,
  defaultCells,
  elementId,
  isFlowLive,
  handlePromptFlag,
}) => {
  const classes = useStyles();
  const COLUMNS = 48;
  const ROWS = 7;
  const [days, setDays] = useState({
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
  });

  const handleClear = () => {
    handlePromptFlag && handlePromptFlag();
    let allGeneratedCells = populateCells(ROWS, COLUMNS);
    setCells(allGeneratedCells);
  };

  const handleUndo = () => {
    handlePromptFlag && handlePromptFlag();
    let allGeneratedCells = populateCells(ROWS, COLUMNS);
    let allCells = populateDefaultDays(defaultCells, allGeneratedCells);
    setCells(allCells);
  };

  const handleChangeCheckBox = (event, index) => {
    handlePromptFlag && handlePromptFlag();
    if (event.target.checked) {
      let allCells = [...cells];
      let resetedValue = createEmptyRow(COLUMNS);
      allCells.splice(index, 1, resetedValue);
      setCells(allCells);
    }
    setDays({
      ...days,
      [event.target.name]: event.target.checked,
    });
  };

  // const handleChangePreset = event => {
  //   setPreset(event.target.value);
  // };

  const setIfDayIsAvaliable = (index, flag, allDays) => {
    // eslint-disable-next-line
    Object.keys(allDays).map((key, i) => {
      if (i === index) {
        allDays[key] = flag ? false : true;
      }
    });
  };

  useEffect(() => {
    if (cells && cells.length > 0) {
      let allDays = { ...days };
      cells.forEach((cell, index) => {
        let flag = cell.find(c => c === true);
        setIfDayIsAvaliable(index, flag, allDays);
      });
      setDays(allDays);
    }
    // eslint-disable-next-line
  }, [cells]);

  return (
    <>
      {cells && cells.length ? (
        <div className={classes.hoursOfOperationContainer}>
          <div className={classes.topSection}>
            <div className={classes.title}>
              <h3>Hours of Operation</h3>
              <h4>
                Click and drag active hours/days or select a common preset
              </h4>
            </div>
            <div className={classNames(classes.textField, classes.select)}>
              {/* <select onChange={handleChangePreset}>
                <option value="">Preset Hours</option>
              </select> */}
            </div>
          </div>
          <div className={classes.table}>
            <Days
              days={days}
              handleChangeCheckBox={handleChangeCheckBox}
              isFlowLive={isFlowLive}
            />
            <div className={classes.tableContainer}>
              <Hours />
              <div className={classes.tableBorder}>
                <TableDragSelect
                  value={cells}
                  onChange={cells => {
                    handlePromptFlag && handlePromptFlag();
                    !isFlowLive && setCells(cells);
                  }}
                  disabled={isFlowLive}
                >
                  {Array.from({ length: ROWS }, (_, index) => (
                    <tr key={Math.random() * index * COLUMNS}>
                      {Array.from({ length: COLUMNS }, (_, i) => (
                        <td
                          className={isFlowLive && 'disabled-cell'}
                          key={Math.random() * i * COLUMNS}
                        />
                      ))}
                    </tr>
                  ))}
                </TableDragSelect>
              </div>
            </div>
          </div>
          <div className={classes.actionContainer}>
            {/* <Button className={classes.savePreset} startIcon={<SaveAltIcon />}>
              Save Hours as Preset
            </Button> */}
            <div />
            <div>
              {elementId && (
                <Button
                  variant="contained"
                  className={classNames(
                    classes.undoButton,
                    classes.actionButton,
                  )}
                  onClick={handleUndo}
                  disabled={isFlowLive}
                >
                  Undo
                </Button>
              )}
              <Button
                variant="contained"
                className={classes.actionButton}
                onClick={handleClear}
                disabled={isFlowLive}
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HoursOfOperation;

HoursOfOperation.propTypes = {
  setCells: PropTypes.func.isRequired,
  cells: PropTypes.array,
  defaultCells: PropTypes.string,
  elementId: PropTypes.string,
  isFlowLive: PropTypes.bool.isRequired,
};
