import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

const ActionsModal = ({ open, handleClose, classes }) => {
  const [values, setValues] = useState({
    subject: '',
    decription: '',
  });

  const handleInputValues = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.modalContainer}
      >
        <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
          Alert
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <h5 className={classes.inputLabel}>Subject</h5>
          <TextField
            id="outlined-basic"
            label="Subject"
            placeholder="Name"
            variant="outlined"
            name="subject"
            fullWidth
            onChange={handleInputValues}
            className={classes.textField}
          />
          <h5 className={classes.inputLabel}>Description</h5>
          <TextField
            id="outlined-basic"
            label="Description"
            placeholder="Description"
            variant="outlined"
            name="description"
            fullWidth
            onChange={handleInputValues}
            className={classes.textField}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions className={classes.actionsContainer}>
          <Button
            onClick={handleClose}
            color="secondary"
            className={classes.deleteActionBtn}
          >
            <DeleteIcon />
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.saveActionBtn}
          >
            <SaveAltIcon /> <span>Save Alert</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActionsModal;
