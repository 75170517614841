import React from 'react';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  publish: {
    fontSize: 20,
    width: 155,
    borderRadius: 5,
    height: 60,
    fontWeight: 900,
    backgroundColor: theme.palette.green[300],
    '& > span > span > svg': {
      fontSize: '28px !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.green[400],
    },
    '&:disabled': {
      color: '#FFFFFF',
      boxShadow: 'none',
      backgroundColor: '#D8D8D8',
    },
  },
}));

const CustomPublish = props => {
  const classes = useStyles();

  return (
    <Button
      className={classes.publish}
      variant="contained"
      color="secondary"
      {...props}
      startIcon={<PublishIcon />}
    >
      {props.text ? props.text : "Publish"}
    </Button>
  );
};

export default CustomPublish;
