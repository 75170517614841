import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '0 0 20px 0',
    overflowX: 'hidden',
    paddingLeft: 0,
    marginTop: 30,
    [theme.breakpoints.up(1200)]: {
      paddingLeft: 60,
      marginTop: -55,
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 0 20px 0',
      paddingLeft: 270,
    },
  },
  title: {
    paddingLeft: 80,
    marginBottom: 0,
    position: 'relative',
    left: -32,
    top: 22,
    fontSize: 20,
    fontWeight: 900,
    [theme.breakpoints.up(1200)]: {
      fontSize: 36,
      fontWeight: 400,
      top: 121,
      left: 30,
    },
  },
  titleContainer: {
    position: 'relative',
    top: -15,
  },
  titleIcon: {
    width: 40,
    position: 'absolute',

    [theme.breakpoints.up(1200)]: {
      width: 75,
      left: 106,
      top: 117,
    },
    [theme.breakpoints.up('xl')]: {
      left: 317,
    },
  },
  contentContainer: {
    [theme.breakpoints.up(1200)]: {
      marginTop: 115,
      paddingLeft: 23,
    },
  },
  exportButton: {
    position: 'absolute',
    right: 15,
    top: 48,
  },
  filterContainer: {
    marginBottom: 24,
  },
  timelineContainer: {
    alignItems: 'flex-start',
    paddingLeft: '0px !important',
    '& > li': {
      width: '100%',
      '&:before': {
        display: 'none',
      },
    },
  },
  timelineContent: {
    paddingTop: '3px !important',
  },
  logDate: {
    fontSize: 14,
    color: '#828282',
    fontFamily: 'Lato',
    [theme.breakpoints.up(1200)]: {
      fontSize: 16,
    },
  },
  singleLog: {
    border: ' 1px solid #E0E0E0',
    borderRadius: 6,
    padding: '5px 50px 5px 14px',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      fontSize: 14,
      color: '#828282',
      fontFamily: 'Lato',
      [theme.breakpoints.up(1200)]: {
        fontSize: 16,
        padding: '5px 14px',
      },
    },
  },
  logContent: {
    '& > div > span': {
      fontWeight: 900,
    },
  },
  firstSingleLog: {
    borderRadius: '6px 6px 0px 0px',
    marginTop: 10,
  },
  middleSingleLog: {
    borderRadius: 0,
    marginTop: 0,
    borderTop: 0,
  },
  lastSingleLog: {
    borderRadius: '0px 0px 6px 6px',
    marginTop: 0,
    borderTop: 0,
  },
  borderBottom: {
    marginBottom: 15,
  },
  logHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  notesTitle: {
    fontSize: 10,
    color: '#828282',
    fontFamily: 'Lato',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    paddingRight: 0,
    right: 23,
    top: 37,
    '& > span': {
      display: 'block',
      width: '100%',
    },
    [theme.breakpoints.up(1200)]: {
      fontSize: 16,
      flexDirection: 'row',
      position: 'relative',
      paddingRight: 50,
      top: 0,
      right: 0,
    },
  },
  notesIcon: {
    padding: 0,
    color: theme.palette.lightBlue[400],
    [theme.breakpoints.up(1200)]: {
      padding: 12,
      fontSize: 16,
      position: 'absolute',
      right: 0,
      bottom: -11,
    },
  },
  modalLoader: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: 0,
    zIndex: 10,
  },
  log: {
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
  noLogs: {
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: 900,
    color: '#828282',
    [theme.breakpoints.up(1200)]: {
      fontSize: 22,
    },
  },
}));

export default useStyles;
