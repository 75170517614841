import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import classNames from 'classnames';
import SingleFlow from './singleFlow/singleFlow';
import Collapse from '@material-ui/core/Collapse';

const SingleRow = ({
  classes,
  item,
  showMoreList,
  handleShowMoreBtn,
  handleClickName,
  stepsData,
}) => {
  const rowRef = useRef();
  const [rowHegith, setRowHeight] = useState(53);
  useEffect(() => {
    if (rowRef && rowRef.current) {
      setRowHeight(rowRef.current.clientHeight);
    }
  }, [rowRef]);

  return (
    <div
      className={classNames(classes.rowItem, {
        [classes.rowItemActive]: showMoreList[item.id],
      })}
    >
      <div className={classes.singleRow}>
        <div
          ref={rowRef}
          className={classes.tableCellLink}
          onClick={handleClickName(item.id)}
        >
          {item.name}
        </div>
        <div className={classes.tableCell} style={{ height: rowHegith }}>
          {item.startedFlow}
        </div>
        <div className={classes.tableCell} style={{ height: rowHegith }}>
          {item.finishedFlow}
        </div>
        <div className={classes.tableCell} style={{ height: rowHegith }}>
          {item.undelivered}
        </div>
        <div className={classes.tableCell} style={{ height: rowHegith }}>
          {item.unsubscribed}
        </div>
        <div
          style={{ height: rowHegith }}
          className={classes.tableCellShowMore}
          onClick={handleShowMoreBtn(item.id)}
        >
          <span>{showMoreList[item.id] ? 'See less' : 'See more'}</span>
          <KeyboardArrowDownIcon
            className={classNames(classes.showMoreArrow, {
              [classes.showMoreArrowActive]: showMoreList[item.id],
            })}
          />
        </div>
      </div>
      <Collapse in={showMoreList[item.id]}>
        <SingleFlow
          item={item}
          classes={classes}
          rowIsOpen={showMoreList[item.id]}
          stepsData={stepsData}
        />
      </Collapse>
    </div>
  );
};

export default SingleRow;

SingleRow.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  handleShowMoreBtn: PropTypes.func.isRequired,
  showMoreList: PropTypes.object.isRequired,
  handleClickName: PropTypes.func.isRequired,
};
