import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import DripsIcon from '../../../../assets/images/drips-icon.svg';
import Typography from '@material-ui/core/Typography';
import withDashboard from '../../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../../hoc/withTitle';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getList, cancelList } from '../../../../store/actions/actions/rest';
import { displaySnackbar } from '../../../../store/actions/actions/root';
import Loader from '../../../../components/loader';
import queryString from 'query-string';
import {
  checkIfParentExist,
  findFolderPath,
  getPathString,
  checkIfElementExist,
} from '../helper';
import PropTypes from 'prop-types';
import { filters, params } from './config';
import { getFilters } from '../../../../hoc/withTable/getFilters';

const SingleDrip = ({
  location,
  name,
  Form,
  messageType,
  urlPath,
  childComponents,
  config,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const allVerticals = useSelector(state => state.auth.verticals);
  const auth = useSelector(state => state.auth);
  const loader = useSelector(state => state.app.loader);
  const serviceProvidersList = useSelector(
    state => state.serviceProviders.list,
  );
  const dripFoldersList = useSelector(state => state.dripFolders.list);
  const dripsList = useSelector(state => state.drips.list);
  const messageTypesList = useSelector(state => state.messageTypes.list);
  const [folderPath, setFolderPath] = useState('');
  const [rootFolderId, setRootFolderId] = useState(null);
  const { initialValues, populateValues, contain } = config;
  const [elementId, setElementId] = useState(null);
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (rootFolderId) {
      if (location && location.search) {
        let params = queryString.parse(location.search);
        let path = findFolderPath(dripFoldersList, params.parent_id);
        setFolderPath(getPathString(path));
        if (checkIfParentExist(dripFoldersList, params.parent_id)) {
          setFormValues({
            ...formValues,
            parent_id: params.parent_id,
          });
        } else {
          setFolderPath('Root/');
          setFormValues({
            ...formValues,
            parent_id: rootFolderId,
          });
        }
      } else {
        setFormValues({
          ...formValues,
          parent_id: rootFolderId,
        });
        setFolderPath('Root/');
      }
    }
    // eslint-disable-next-line
  }, [location.search, rootFolderId]);

  useEffect(() => {
    if (
      location &&
      dripsList &&
      dripsList.length > 0 &&
      dripFoldersList &&
      dripFoldersList.length > 0
    ) {
      let id = location.pathname.replace(`/drips/${urlPath}/edit/`, '');
      if (checkIfElementExist(dripsList, id)) {
        let element = dripsList.find(drips => drips.id === id);
        let path = findFolderPath(dripFoldersList, element.parent_id);
        setFolderPath(getPathString(path));
        setElementId(id);
      } else {
        if (location.pathname.includes(`/drips/${urlPath}/edit/`)) {
          history.push({
            pathname: '/drips/list',
            search: `vertical_id=${vertical_id}`,
          });
          dispatch(
            displaySnackbar({
              type: 'warning',
              message: `Drip not found.`,
            }),
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [location, dripsList, dripFoldersList]);

  useEffect(() => {
    if (
      dripsList &&
      dripsList.length > 0 &&
      dripFoldersList &&
      dripFoldersList.length > 0
    ) {
      let element = dripsList.find(drips => drips.id === elementId);
      if (element) {
        setFormValues(populateValues(element));
      }
    }
    // eslint-disable-next-line
  }, [elementId, dripsList, dripFoldersList]);

  useEffect(() => {
    if (
      dripFoldersList &&
      dripFoldersList.length > 0 &&
      allVerticals &&
      allVerticals.length > 0 &&
      vertical_id
    ) {
      let currentVerticalName = '';
      allVerticals.forEach(vertical => {
        if (vertical.id === vertical_id) {
          currentVerticalName = `ROOT ${vertical.name}`;
        }
      });
      dripFoldersList.forEach(folder => {
        if (folder.name === currentVerticalName) {
          setRootFolderId(folder.id);
        }
      });
    }
    // eslint-disable-next-line
  }, [dripFoldersList, allVerticals, vertical_id]);

  const cancelAllAPICalls = () => {
    dispatch(cancelList('service-providers', null, dispatch));
    childComponents.forEach(path =>
      dispatch(cancelList(path, { contain }, dispatch)),
    );
    dispatch(cancelList('message-types', null, dispatch));
    dispatch(cancelList('drip-folders', null, dispatch));
    dispatch(cancelList('drips', null, dispatch));
  };

  useEffect(() => {
    if (auth && auth.vertical_id && auth.account_id) {
      let allFilters = getFilters(filters, auth);
      cancelAllAPICalls();
      childComponents.forEach(path =>
        dispatch(
          getList(
            path,
            { params, contain, filters: [...allFilters] },
            dispatch,
          ),
        ),
      );
      dispatch(getList('service-providers', { params }, dispatch));
      dispatch(getList('drip-folders', null, dispatch));
      dispatch(getList('drips', null, dispatch));
      dispatch(getList('message-types', null, dispatch));
      return () => {
        cancelAllAPICalls();
      };
    }
    // eslint-disable-next-line
  }, [dispatch, auth]);

  useEffect(() => {
    if (messageTypesList && messageTypesList.length > 0) {
      messageTypesList.forEach(type => {
        if (type.name === messageType) {
          setFormValues({
            ...formValues,
            message_type_id: type.id,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [messageTypesList]);

  const renderFolderPath = folderPath ? folderPath : '';

  return (
    <Grid container className={classes.container}>
      <img src={DripsIcon} alt="Flows icon" className={classes.titleIcon} />
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            component="h5"
            gutterBottom
            className={classes.title}
          >
            {renderFolderPath}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.contentContainer}>
        <Form
          formValues={formValues}
          classes={classes}
          serviceProvidersList={serviceProvidersList}
          elementId={elementId}
          populateValues={populateValues}
        />
      </Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(withDashboard(SingleDrip, false), 'Drip');

SingleDrip.propTypes = {
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  Form: PropTypes.elementType.isRequired,
  messageType: PropTypes.string.isRequired,
  urlPath: PropTypes.string.isRequired,
  childComponents: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
};
