import React from 'react';
import { Formik, Form } from 'formik';
import NameField from '../../../../components/form/name';
import ParametersField from '../../../../components/form/parameters';
import CustomSelect from '../../../../components/form/customSelect';
import DeleteButton from '../../../../components/customButtons/customDelete';
import PublishButton from '../../../../components/customButtons/customPublish';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import withDripForm from '../../../../hoc/withDripForm';

const SmsDripFrom = ({
  onSubmit,
  handleDeleteModalOpen,
  classes,
  formValues,
  elementId,
  serviceProvidersList,
  renderDeleteConfirmationDialog,
  renderNotesModal,
}) => {
  const smsBodyComponentsList = useSelector(
    state => state.smsBodyComponents.list,
  );

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      onSubmit={values => {
        onSubmit(values);
      }}
    >
      {props => (
        <Form className={classes.formContainer}>
          <NameField
            {...props}
            placeholder="SMS Drip Name"
            labelText="SMS Drip Name"
            name="name"
            wrapperClass={classes.textField}
            type="drips"
            dripType="1"
            id={elementId}
          />
          <CustomSelect
            {...props}
            placeholder="SMS Body Component"
            labelText="SMS Body Component"
            name="sms_body_component_id"
            wrapperClass={classes.textField}
            options={smsBodyComponentsList}
          />
          <CustomSelect
            {...props}
            placeholder="Service Provider"
            labelText="Service Provider"
            name="service_provider_id"
            wrapperClass={classes.textField}
            options={serviceProvidersList}
          />
          <ParametersField
            {...props}
            placeholder="Parameters"
            labelText="Parameters"
            name="parameters"
            wrapperClass={classes.textField}
          />
          <Grid item xs={12} className={classes.actionButtons}>
            {elementId && <DeleteButton onClick={handleDeleteModalOpen} />}
            <PublishButton type="submit" />
            {renderDeleteConfirmationDialog}
          </Grid>
          {renderNotesModal(props)}
        </Form>
      )}
    </Formik>
  );
};
export default withDripForm(SmsDripFrom);
