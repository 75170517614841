import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    secondary: { A400: '#D6595C' },
    grey: {
      100: '#F2F2F2',
      200: '#BDBDBD',
    },
    blue: {
      100: '#99AFCC',
      200: '#6582A7',
      250: '#42638E',
      300: '#294D7A',
      400: '#153660',
    },
    red: {
      100: '#FEB9BB',
      200: '#FC8F92',
      300: '#B93336',
      400: '#901619',
    },
    green: {
      100: '#A8E3A5',
      200: '#79CE75',
      300: '#2F972A',
      400: '#177612',
    },
    orange: {
      100: '#FFD99A',
      200: '#FFA719',
      300: '#FFBF58',
      400: '#FF9E00',
    },
    lightBlue: {
      100: '#9DC4F6',
      200: '#2A82F2',
      300: '#61A1F3',
      400: '#036BF0',
    },
    text: {
      100: '#4F4F4F',
    },
    white: {
      100: '#FFFFFF',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 2,
      },
    },
    MuiToggleButton: {
      root: {
        '&$selected': {
          backgroundColor: '#61A1F3',
        },
      },
    },
  },
  breakpoints: {
    values: {
      lg: 1200,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontSize: 36,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    h2: {
      fontSize: 29,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    subtitle1: {
      fontSize: 20,
    },
    body1: {
      fontSize: 16,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    body2: {
      fontSize: 18,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    button: {
      textTransform: 'none',
      borderRadius: 0,
      fontSize: 16,
    },
  },
  spacing: 8,
});

export default theme;
