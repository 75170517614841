import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    '& > div.MuiDialog-container': {
      '& > div': {
        width: 545,
        backgroundColor: '#F3F8FE',
      },
    },
  },
  modalTitle: {
    fontFamily: "'Lato'",
    fontSize: 24,
    color: '#4F4F4F',
    paddingTop: 56,
    fontWeight: 900,
    '& > h2': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  content: {
    fontSize: 16,
    color: '#828282',
    fontFamily: "'Lato'",
  },
  cancelButton: {
    padding: 18,
    fontSize: 20,
    color: '#828282',
    border: '1px solid #828282',
    width: 66,
    height: 60,
    borderRadius: 5,
  },
  confirmButton: {
    padding: 18,
    fontSize: 20,
    width: 66,
    height: 60,
    borderRadius: 5,
    marginLeft: '40px !important',
    marginRight: 56,
    boxShadow: 'none',
  },
  actionsContainer: {
    paddingBottom: 40,
  },
}));

export default useStyles;
