import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import SuppressionsListForm from './form';
import Loader from '../../../components/loader';
import { sendSuppressionsList } from '../../../store/actions/actions/suppressions';
import { displayLoader } from '../../../store/actions/actions/root';

const SuppressionsList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loader = useSelector(state => state.app.loader);
    const [formValues] = useState({
        type: '',
        suppressions: '',
    });
    const [currentType, setCurrentType] = useState('');

    const handleChangeType = event => {
        setCurrentType(event.target.value);
    }

    const handleSubmit = values => {
        dispatch(sendSuppressionsList(values));
        dispatch(displayLoader());
        setCurrentType('');
    };

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Typography
                    variant="h1"
                    component="h2"
                    className={classes.title}
                    gutterBottom
                >
                    Suppressions list
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Grid
                    item
                    xs={12}
                    container
                    alignContent="flex-end"
                    justify="flex-start"
                >
                    <Typography
                        variant="subtitle1"
                        component="h2"
                        className={classes.subtitle}
                    >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.tableWrapper}>
                <Grid item xs={12}>
                    <SuppressionsListForm
                        handleSubmit={handleSubmit}
                        formValues={formValues}
                        classes={classes}
                        handleChangeType={handleChangeType}
                        currentType={currentType}
                    />
                </Grid>
            </Grid>
            {Boolean(loader) && <Loader open={Boolean(loader)} />}
        </Grid>
    );
};

export default withTitle(
    withDashboard(SuppressionsList, true),
    'Suppressions List',
);
