export const createData = (
  name,
  callInitiated,
  // callCompleted,
  item,
) => {
  return {
    name,
    callInitiated,
    // callCompleted,
    item,
  };
};

export const sortBy = {
  name: 'asc',
  callInitiated: 'asc',
  //   callCompleted: 'asc',
};

export const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 150,
  },
  {
    id: 'callInitiated',
    label: 'Call Initiated',
    align: 'left',
    minWidth: 100,
  },
  //   {
  //     id: 'callCompleted',
  //     label: 'Call Completed',
  //     align: 'left',
  //   },
];

export const renderElements = (list, setRows) => {
  let allRows = [];
  list &&
    list.forEach(item => {
      const { name, callInitiated } = item;
      allRows.push(createData(name, callInitiated, item));
    });
  setRows(allRows);
};

export const filters = vertical_id => [
  JSON.stringify({
    field: 'vertical_id',
    operator: '=',
    value: vertical_id,
  }),
  JSON.stringify({
    field: 'message_type_id',
    operator: '=',
    value: '4',
  }),
];
