import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import Dashboard from './pages/dashboard/dashboard';
import Vertical from './pages/vertical/vertical';
import FlowsList from './pages/flows/list/flowsList';
import DripsList from './pages/drips/list/list';
import Settings from './pages/settings/settings';
import WebhookActions from './pages/settings/webhookActions/webhookActions';
import ServiceProviders from './pages/settings/serviceProviders/serviceProviders';
import SuppressionLists from './pages/settings/suppressionLists/suppressionsList';
import ManageAccounts from './pages/settings/manageAccounts/manageAccounts';
import Verticals from './pages/settings/verticals/verticals';
import Tags from './pages/settings/tags/tags';
import Uploads from './pages/flows/uploads/uploads';
import SingleUpload from './pages/flows/uploads/singleUpload';
import Alerts from './pages/alerts/alerts';
import EmailDrip from './pages/drips/drip/emailDrip/emailDrip';
import CallDrip from './pages/drips/drip/callDrip/callDrip';
import SmsDrip from './pages/drips/drip/smsDrip/smsDrip';
import RvmDrip from './pages/drips/drip/rvmDrip/rvmDrip';
import PostDrip from './pages/drips/drip/postDrip/postDrip';
import SwitchAccount from './pages/settings/switchAccount/switchAccount';
import FromEmail from './pages/dripComponents/fromEmail/fromEmail';
import EmailFriendlyFrom from './pages/dripComponents/emailFriendlyForm/emailFriendlyFrom';
import EmailSubjectText from './pages/dripComponents/emailSubjectText/emailSubjectText';
import SmsBodyText from './pages/dripComponents/smsBodyText/smsBodyText';
import SingleSmsBodyPage from './pages/dripComponents/smsBodyText/singleSmsPage';
import EmailHtmlBody from './pages/dripComponents/emailHtmlBody/emailHtmlBody';
import SingleEmailHtmlBody from './pages/dripComponents/emailHtmlBody/singleEmailBody';
import CallCampaignIdPage from './pages/dripComponents/callCampaignId/callCampaignId';
import HttpRequestComponent from './pages/dripComponents/httpRequestComponent/httpRequestComponent';
import RvmPage from './pages/dripComponents/rvmList/rvmList';
import SingleRvmPage from './pages/dripComponents/rvmList/singleRvmPage';
import FlowSteps from './pages/flows/steps/flowSteps';
import SingleFlowStep from './pages/flows/steps/singleFlowStep';
import FlowTransitions from './pages/flows/transitions/flowTransitions';
import SingleFlowTransition from './pages/flows/transitions/singleFlowTransition';
import SingleFlow from './pages/flows/list/singleFlow/singleFlow';
import ReportingOverall from './pages/reporting/overall/table';
import ReportingEmails from './pages/reporting/emails/table';
import ReportingSms from './pages/reporting/sms/table';
import ReportingRvm from './pages/reporting/rvm/table';
import ReportingGenericDataPost from './pages/reporting/genericDataPost/table';
import ReportingCalls from './pages/reporting/calls/table';
import ReportingFlows from './pages/reporting/flows/table';
import SnackBar from './components/snackBar';
import { useDispatch } from 'react-redux';
import { authFulfilled } from './store/actions/actions/auth';
import NotFoundPage from './pages/notFound/notFound';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ScrollToTop from './components/scrollToTop';
import ChangeLogs from './pages/changeLogs/changeLogs';
import Notes from './pages/notes/notes';

const AppRoutes = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authFulfilled(props.authData));
    // eslint-disable-next-line
  }, []);

  return (
    <SnackbarProvider maxSnack={2}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ScrollToTop />
        <Switch>
          <Route path="/" component={Dashboard} exact />
          <Route path="/vertical" component={Vertical} exact />
          <Route path="/settings/alerts" component={Alerts} exact />
          <Route path="/flows/list" component={FlowsList} exact />
          <Route path="/flows/flow/edit/:id" component={SingleFlow} exact />
          <Route path="/flows/steps" component={FlowSteps} exact />
          <Route
            path="/flows/steps/edit/:id"
            component={SingleFlowStep}
            exact
          />
          <Route path="/flows/steps/add" component={SingleFlowStep} exact />
          <Route path="/flows/transitions" component={FlowTransitions} exact />
          <Route
            path="/flows/transitions/edit/:id"
            component={SingleFlowTransition}
            exact
          />
          <Route
            path="/flows/transitions/add"
            component={SingleFlowTransition}
            exact
          />
          <Route path="/flows/uploads" component={Uploads} exact />
          <Route path="/flows/uploads/add" component={SingleUpload} exact />
          <Route path="/drips/list" component={DripsList} exact />
          <Route path="/drips/email-drip/add" component={EmailDrip} exact />
          <Route
            path="/drips/email-drip/edit/:id"
            component={EmailDrip}
            exact
          />
          <Route path="/drips/call-drip/add" component={CallDrip} exact />
          <Route path="/drips/call-drip/edit/:id" component={CallDrip} exact />
          <Route path="/drips/sms-drip/add" component={SmsDrip} exact />
          <Route path="/drips/sms-drip/edit/:id" component={SmsDrip} exact />
          <Route path="/drips/rvm-drip/add" component={RvmDrip} exact />
          <Route path="/drips/rvm-drip/edit/:id" component={RvmDrip} exact />
          <Route path="/drips/post-drip/add" component={PostDrip} exact />
          <Route path="/drips/post-drip/edit/:id" component={PostDrip} exact />
          <Route path="/settings" component={Settings} exact />
          <Route
            path="/settings/webhook-actions"
            component={WebhookActions}
            exact
          />
          <Route
            path="/settings/provider-config"
            component={ServiceProviders}
            exact
          />
          <Route path="/settings/verticals" component={Verticals} exact />
          {/* <Route path="/settings/users" component={Users} exact /> */}
          <Route path="/settings/tags" component={Tags} exact />
          <Route path="/settings/uploads" component={Uploads} exact />
          <Route path="/settings/suppression-list" component={SuppressionLists} exact />
          <Route
            path="/settings/switch-accounts"
            component={SwitchAccount}
            exact
          />
          <Route
            path="/settings/manage-accounts"
            component={ManageAccounts}
            exact
          />
          <Route
            path="/drip-components/from-email"
            component={FromEmail}
            exact
          />
          <Route
            path="/drip-components/email-friendly-from"
            component={EmailFriendlyFrom}
            exact
          />
          <Route
            path="/drip-components/email-subject-text"
            component={EmailSubjectText}
            exact
          />
          <Route
            path="/drip-components/sms-body-text"
            component={SmsBodyText}
            exact
          />
          <Route
            path="/drip-components/sms-body-text/add"
            component={SingleSmsBodyPage}
            exact
          />
          <Route
            path="/drip-components/sms-body-text/edit/:id"
            component={SingleSmsBodyPage}
            exact
          />
          <Route
            path="/drip-components/email-html-body"
            component={EmailHtmlBody}
            exact
          />
          <Route
            path="/drip-components/email-html-body/edit/:id"
            component={SingleEmailHtmlBody}
            exact
          />
          <Route
            path="/drip-components/email-html-body/add"
            component={SingleEmailHtmlBody}
            exact
          />
          <Route path="/drip-components/rvm-list" component={RvmPage} exact />
          <Route
            path="/drip-components/rvm-list/edit/:id"
            component={SingleRvmPage}
            exact
          />
          <Route
            path="/drip-components/rvm-list/add"
            component={SingleRvmPage}
            exact
          />
          <Route
            path="/drip-components/email-friendly-from"
            component={EmailFriendlyFrom}
            exact
          />
          <Route
            path="/drip-components/email-subject-text"
            component={EmailSubjectText}
            exact
          />
          <Route
            path="/drip-components/sms-body-text"
            component={SmsBodyText}
            exact
          />
          <Route
            path="/drip-components/single-sms-body-page"
            component={SingleSmsBodyPage}
            exact
          />
          <Route
            path="/drip-components/email-html-body"
            component={EmailHtmlBody}
            exact
          />
          <Route
            path="/drip-components/email-html-body/edit/:id"
            component={SingleEmailHtmlBody}
            exact
          />
          <Route
            path="/drip-components/email-html-body/add"
            component={SingleEmailHtmlBody}
            exact
          />
          <Route
            path="/drip-components/call-campaign-id"
            component={CallCampaignIdPage}
            exact
          />
          <Route
            path="/drip-components/http-request"
            component={HttpRequestComponent}
            exact
          />
          <Route path="/reporting/overall" component={ReportingOverall} exact />
          <Route path="/reporting/emails" component={ReportingEmails} exact />
          <Route path="/reporting/sms" component={ReportingSms} exact />
          <Route path="/reporting/rvm" component={ReportingRvm} exact />
          <Route
            path="/reporting/generic-data-post"
            component={ReportingGenericDataPost}
            exact
          />
          <Route path="/reporting/flows" component={ReportingFlows} exact />
          <Route path="/reporting/calls" component={ReportingCalls} exact />
          <Route path="/change-logs/:id" component={ChangeLogs} exact />
          <Route path="/notes" component={Notes} exact />
          <Route path="/404" component={NotFoundPage} exact />
          <Route component={NotFoundPage} />
        </Switch>
        <SnackBar />
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  );
};

export default AppRoutes;
