import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';

const useStyles = makeStyles(theme => ({
  pickerContainer: {
    marginBottom: 20,
    width: 500,
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
  },
  picker: {
    width: 240,
    '& > div > input': {
      borderRadius: 4,
      fontSize: 16,
      width: '100%',
      border: '1px solid #828282',
      padding: '13px 14px',
      '&:hover': {
        border: '1px solid #828282',
      },
    },
  },
}));

const CustomPicker = ({
  label,
  field: { value, name },
  form: { setFieldValue },
}) => {
  const classes = useStyles();
  return (
    <div className={classes.pickerContainer}>
      <label>{label}</label>
      <DateTimePicker
        label=" "
        inputVariant="outlined"
        name={name}
        value={value}
        onChange={pickedValue => {
          setFieldValue(name, pickedValue);
        }}
        showTodayButton
        color="primary"
        className={classes.picker}
        InputLabelProps={{ shrink: false }}
        minDate={new Date()}
      />
    </div>
  );
};

const validate = values => {
  if (!values) {
    return 'Required';
  }
};
const CustomDateTimePicker = ({ name, label, handleChange }) => {
  return (
    <Field
      component={CustomPicker}
      name={name}
      label={label}
      validate={validate}
    />
  );
};

export default CustomDateTimePicker;
