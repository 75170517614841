import React from 'react';
import withDashboard from '../../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../../hoc/withTitle';
import PostDripForm from './postDripForm';
import * as config from './config';
import SingleDrip from '../singleDrip/singleDrip';
import PropTypes from 'prop-types';

const PostDrip = ({ location }) => {
  return (
    <SingleDrip
      name="Post Drip"
      urlPath="post-drip"
      messageType="send_post"
      childComponents={['generic-post-components']}
      location={location}
      config={config}
      Form={PostDripForm}
    />
  );
};

export default withTitle(withDashboard(PostDrip, false), 'Post Drip');

PostDrip.propTypes = {
  location: PropTypes.object.isRequired,
};
