import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TransitionArrow from '../../../../assets/images/transition_arrow.svg';
import classNames from 'classnames';
import DeletedTransitonLabel from './deletedTransitonLabel';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  transitionContainer: {
    paddingLeft: 88,
    position: 'relative',
    marginTop: 33,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  deletedTransitionContainer: {
    background: '#DADADA',
    margin: 0,
    paddingBottom: 20,
  },
  deletedTransitionInActiveStepContainer: {
    background: 'transparent',
    marginTop: 20,
    paddingBottom: 20,
    marginBottom: 0,
  },
  transitionReportingTable: {
    display: 'flex',
    alignItems: 'center',
  },
  cellName: {
    height: 51,
    width: 180,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    background: '#FFFFFF',
    border: '1px solid #828282',
  },
  cell: {
    height: 51,
    width: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    background: '#FFFFFF',
    borderRight: '1px solid #828282',
    borderTop: '1px solid #828282',
    borderBottom: '1px solid #828282',
  },
  value: {
    fontSize: 16,
    fontFamily: 'Lato',
  },
  deletedValue: {
    color: theme.palette.grey[200],
  },
  cellHeader: {
    fontSize: 10,
    color: '#4F4F4F',
    textTransform: 'uppercase',
    position: 'absolute',
    top: -15,
    left: 0,
  },
  transitionArrow: {
    position: 'absolute',
    left: 55,
    top: 5,
  },
  toFlowName: {
    marginLeft: 10,
    color: theme.palette.lightBlue[400],
    cursor: 'pointer',
    fontSize: 10,
    transition: 'all .4s',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const SingleReportingTransition = ({
  isDeleted,
  isActiveStep,
  date,
  transition,
  vertical_id,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickToFlowId = () => {
    if (transition && transition.to_flow_id) {
      history.push({
        pathname: `/flows/flow/edit/${transition.to_flow_id}`,
        search: `vertical_id=${vertical_id}`,
      });
    }
  };
  return (
    <>
      {date && <DeletedTransitonLabel date={date} />}
      <div
        className={classNames(classes.transitionContainer, {
          [classes.deletedTransitionContainer]: isDeleted,
          [classes.deletedTransitionInActiveStepContainer]: isActiveStep,
        })}
      >
        <div className={classes.transitionArrow}>
          <img src={TransitionArrow} alt="Reporting Transition arrow" />
        </div>
        <div className={classes.transitionReportingTable}>
          <div className={classes.cellName}>
            <span className={classes.cellHeader}>Transition</span>
            <div
              className={classNames(classes.value, {
                [classes.deletedValue]: isDeleted,
              })}
            >
              {transition && transition.Transition_Name}
            </div>
          </div>
          {/* <div className={classes.cell}>
            <span className={classes.cellHeader}>Number of users</span>
            <div
              className={classNames(classes.value, {
                [classes.deletedValue]: isDeleted,
              })}
            >
              40
            </div>
          </div> */}
        </div>
        <span className={classes.toFlowName} onClick={handleClickToFlowId}>
          To Flow: {transition && transition.To_Flow_Name}
        </span>
      </div>
    </>
  );
};

export default SingleReportingTransition;
