export const initialValues = (model_id, model_name) => ({
  id: '',
  account_id: '',
  model_id,
  model_name,
  tag_id: '',
});

// generate unique name dropdown options
export const generateNames = tags => {
  const names =
    tags &&
    tags
      .reduce((acc, curr) => {
        return !acc.some(x => x === curr.name) ? [...acc, curr.name] : acc;
      }, [])
      .sort((a, b) => (a < b ? -1 : 1));

  return (
    names &&
    names.reduce((acc, curr) => {
      return [...acc, { id: curr, name: curr }];
    }, [])
  );
};

// generate value dropdown options
export const generateValues = (
  name,
  value,
  formValues,
  tags,
  model_id,
  model_name,
) => {
  // let's get all the values for the given tag name
  const allValues =
    tags && tags.filter(tag => tag.name === name).map(tag => tag.value);

  // let's get all the takenValues that the object already has as a model tag name/value pair for the given tag name
  const takenValues = formValues.model_tags
    .filter(tag => {
      return (
        // from emailDrip.js we are getting model_id without + (match.params.id)
        tag.model_id === model_id &&
        tag.model_name === model_name &&
        tag.tag.name === name &&
        tag.tag.value !== value
      );
    })
    .map(tag => tag.tag.value);

  // now let's get values by checking allValues against takenValues
  const values =
    allValues &&
    allValues
      .map(value => !takenValues.some(val => value === val) && value)
      .filter(val => val !== false);

  return (
    values &&
    values.reduce((acc, curr) => {
      return [...acc, { id: curr, name: curr }];
    }, [])
  );
};
