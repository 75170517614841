import { getModelTags } from '../../../config/helpers';
import { formatToLocalDate } from '../../../config/helpers';

export const contain = ['model_tags.Tags'];

export const columns = [
  { id: 'name', label: 'RVM List Name', minWidth: 60, align: 'left' },
  {
    id: 'created',
    label: 'Created',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'modified',
    label: 'Modified',
    minWidth: 150,
    align: 'left',
  },
];

export const filters = [
  {
    field: 'vertical_id',
    operator: '=',
    path: 'vertical_id',
  },
  {
    field: 'account_id',
    operator: '=',
    path: 'account_id',
  },
];

const createData = (name, created, modified, item) => {
  return {
    name,
    created,
    modified,
    item,
  };
};

export const renderElements = (list, setRows) => {
  let row = [];
  list &&
    list.forEach(item => {
      const { name, created, modified } = item;
      row.push(
        createData(
          name,
          formatToLocalDate(created, 'MMM, dd yyyy'),
          formatToLocalDate(modified, 'MMM, dd yyyy'),
          item,
        ),
      );
    });
  setRows(row);
};

export const sortBy = {
  name: 'asc',
  created: 'asc',
  modified: 'desc',
};

export const initialValues = () => ({
  id: '',
  name: '',
  list_id: '',
  model_tags: [],
  notes: [],
});

export const populateValues = (values, copy) => {
  const { id, name, list_id, model_tags, notes } = values;
  return {
    id: copy ? '' : id,
    name: copy ? `Copy of ${name}` : name,
    list_id,
    model_tags: getModelTags(model_tags, copy),
    notes: notes || [],
  };
};
