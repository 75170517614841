import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Loader from '../../components/loader';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { hyphenToCamel } from '../../store/config';
import {
  getList,
  cancelList,
  getItem,
  cancelItem,
} from '../../store/actions/actions/rest';
import { setItemFlag } from '../../store/actions/actions/root';
import PropTypes from 'prop-types';
import { filters } from './config';
import { getFilters } from '../withTable/getFilters';

const withSingleDripPage = (Component, props) => () => {
  const { title, image, path, contain, lists } = props;
  const dispatch = useDispatch();
  const loader = useSelector(state => state.app.loader);
  const itemFlag = useSelector(state => state.app.itemFlag);
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const element = useSelector(
    state =>
      (state[hyphenToCamel(path)].list &&
        state[hyphenToCamel(path)].list.find(
          item => item.id === +match.params.id,
        )) ||
      state[hyphenToCamel(path)].item,
  );
  useEffect(() => {
    if (match.params.id && !element && auth) {
      let allFilters = getFilters(filters, auth);
      dispatch(
        getItem(
          path,
          {
            id: match.params.id,
            contain,
            filters: [...allFilters],
          },
          dispatch,
        ),
      );
      return () => {
        dispatch(cancelItem(path, null, dispatch));
      };
    }
  }, [element, match.params.id, dispatch, contain, path, auth]);

  useEffect(() => {
    if (itemFlag) {
      history.push('/404');
      dispatch(setItemFlag(false));
    }
  }, [itemFlag, dispatch, history]);

  useEffect(() => {
    lists.forEach(list => dispatch(cancelList(list, null, dispatch)));
    lists.forEach(list => dispatch(getList(list, { contain }, dispatch)));
    return () => {
      lists.forEach(list => dispatch(cancelList(list, null, dispatch)));
    };
  }, [dispatch, contain, lists]);

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        container
        alignItems="flex-end"
        className={classes.topSection}
      >
        <Grid item xs={1} className={classes.iconContainer}>
          <img src={image} alt="Flows icon" className={classes.titleIcon} />
        </Grid>
        <Grid container item xs={6} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <Component {...props} element={element} />
      </Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withSingleDripPage;

withSingleDripPage.propTypes = {
  props: PropTypes.obj,
  elementType: PropTypes.elementType.isRequired,
};
