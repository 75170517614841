import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, ErrorMessage } from 'formik';
import { checkNameUnique } from './../../store/actions/actions/rest';
import PropTypes from 'prop-types';
import { hyphenToCamel } from '../../store/config';

const flowUniqueFilters = (vertical_id, name, id) => [
  JSON.stringify({
    field: 'vertical_id',
    operator: '=',
    value: vertical_id,
  }),
  JSON.stringify({
    field: 'name',
    operator: '=',
    value: name,
  }),
  JSON.stringify({
    field: 'id',
    operator: '!=',
    value: id ? id : '',
  }),
];

const dripUniqueFilters = (vertical_id, dripType, name, id) => [
  JSON.stringify({
    field: 'vertical_id',
    operator: '=',
    value: vertical_id,
  }),
  JSON.stringify({
    field: 'message_type_id',
    operator: '=',
    value: dripType,
  }),
  JSON.stringify({
    field: 'name',
    operator: '=',
    value: name,
  }),
  JSON.stringify({
    field: 'id',
    operator: '!=',
    value: id ? id : '',
  }),
];

const dripComponentUniqueFilters = (vertical_id, name, id) => [
  JSON.stringify({
    field: 'vertical_id',
    operator: '=',
    value: vertical_id,
  }),
  JSON.stringify({
    field: 'name',
    operator: '=',
    value: name,
  }),
  JSON.stringify({
    field: 'id',
    operator: '!=',
    value: id ? id : '',
  }),
];

const Name = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
  disabled,
  type,
  dripType,
  flowStep,
  flowTransition,
  id,
  dripComponent,
}) => {
  const dispatch = useDispatch();
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const nameUnique = useSelector(
    state =>
      state[type || (dripComponent && hyphenToCamel(dripComponent))] &&
      state[type || (dripComponent && hyphenToCamel(dripComponent))].nameUnique,
  );
  const [nameValue, setNameValue] = useState('');

  const validate = values => {
    if (!values) {
      return 'Required';
    }
    if (type === 'drip-folders' && values.substring(0, 4) === 'ROOT') {
      return `The name field can't contain the word ROOT in the name.`;
    }
    if (nameValue !== values || nameUnique) {
      if (dripComponent) {
        const filters = dripComponentUniqueFilters(vertical_id, values, id);
        dispatch(checkNameUnique(dripComponent, { filters }, dispatch));
      }
      if (dripType) {
        const filters = dripUniqueFilters(vertical_id, dripType, values, id);
        dispatch(checkNameUnique('drips', { filters }, dispatch));
      }
      if (flowStep || flowTransition) {
        const filters = flowUniqueFilters(vertical_id, values, id);
        const route = flowStep ? 'flow-steps' : 'flow-step-transitions';
        dispatch(checkNameUnique(route, { filters }, dispatch));
      }
      if (type === 'flows') {
        const filters = flowUniqueFilters(vertical_id, values, id);
        dispatch(checkNameUnique('flows', { filters }, dispatch));
      }
      if (nameUnique) return 'Name already in use';
    }
    setNameValue(values);
  };

  return (
    <div className={wrapperClass}>
      {hideLabel ? null : <label htmlFor="name">{labelText || 'Name'}</label>}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'name'] && touched[name || 'name']
            ? errorClass || 'has-error'
            : ''
        }`}
        name={name || 'name'}
        placeholder={placeholder || 'Name'}
        type="text"
        disabled={disabled}
        validate={validate}
        onChange={e => {
          handleChange(e);
        }}
      />
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'name'}
          component="div"
          className="field-error"
          data-testid="name-error"
        />
      )}
    </div>
  );
};

export default Name;

Name.propTypes = {
  hideLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  wrapperClass: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.any,
  dripType: PropTypes.string,
  flowStep: PropTypes.bool,
  flowTransition: PropTypes.bool,
  id: PropTypes.any,
};
