export const createData = (name, totalSends, delivered, cannotRvm, item) => {
  return {
    name,
    totalSends,
    delivered,
    cannotRvm,
    item,
  };
};

export const sortBy = {
  name: 'asc',
  totalSends: 'asc',
  delivered: 'asc',
  cannotRvm: 'asc',
};

export const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 150,
  },
  {
    id: 'totalSends',
    label: 'Total Sends',
    align: 'left',
    minWidth: 100,
  },
  {
    id: 'delivered',
    label: 'Delivered',
    align: 'left',
    minWidth: 100,
  },
  {
    id: 'cannotRvm',
    label: 'Cannot RVM',
    align: 'left',
    minWidth: 100,
  },
];

export const renderElements = (list, setRows) => {
  let allRows = [];
  list &&
    list.forEach(item => {
      const { name, totalSends, delivered, cannotRvm } = item;
      allRows.push(createData(name, totalSends, delivered, cannotRvm, item));
    });
  setRows(allRows);
};

export const filters = vertical_id => [
  JSON.stringify({
    field: 'vertical_id',
    operator: '=',
    value: vertical_id,
  }),
  JSON.stringify({
    field: 'message_type_id',
    operator: '=',
    value: '3',
  }),
];
