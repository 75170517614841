import React, { useState, lazy, Suspense } from 'react';
import Grid from '@material-ui/core/Grid';
import EmailPreview from './emailPreview';
import { Formik, Form } from 'formik';
import NameField from '../../../components/form/name';
import DescriptionField from '../../../components/form/description';
import DeleteButton from '../../../components/customButtons/customDelete';
import PublishButton from '../../../components/customButtons/customPublish';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createStart,
  editStart,
  removeStart,
} from '../../../store/actions/actions/rest';
import Loader from '../../../components/loader';
import ModelTags from '../../../components/modelTags/modelTags';
import PropTypes from 'prop-types';

const EmailBodyForm = ({
  id,
  classes,
  formValues,
  setFormValues,
  textPlainPreview,
  emailPreview,
  setEmailPreview,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const NotesModal = lazy(() => import('../../../components/notes/notes'));
  const DeleteConfirmationDialog = lazy(() =>
    import('../../../components/deleteConfirmationModal'),
  );
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const user_name = useSelector(state => state.auth.username);
  const loader = useSelector(state => state.app.loader);
  const [activeTab, setActiveTab] = useState({
    emailTemplate: true,
    mobileTemplate: false,
    plainText: false,
  });

  const handleSwitchTab = value => () => {
    setActiveTab({
      emailTemplate: false,
      mobileTemplate: false,
      plainText: false,
      [value]: true,
    });
  };

  const handleChangeEmailHtmlBody = e => {
    setEmailPreview(e.target.value);
  };

  const handleOpenNotesModal = () => {
    setNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setNotesModalOpen(false);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const onSubmit = values => {
    if (id) {
      handleOpenNotesModal();
    } else {
      values.email_plain_text_body = values.email_html_body
        .replace(/<style([\s\S]*?)<\/style>/gi, ' ')
        .replace(/<script([\s\S]*?)<\/script>/gi, ' ')
        .replace(/(<(?:.|\n)*?>)/gm, ' ')
        .replace(/\s+/gm, ' ');
      values.account_id = account_id;
      values.vertical_id = vertical_id;
      values.notes = [
        {
          account_id,
          vertical_id,
          model_name: 'EmailBodyComponent',
          model_id: '',
          user_name,
          note: `Created ${values && values.name ? values.name : ""}`,
        },
      ];

      const data = { body: values };
      dispatch(createStart('email-body-components', data, dispatch));
      history.push({
        pathname: '/drip-components/email-html-body',
        search: `vertical_id=${vertical_id}`,
      });
    }
  };

  const submitForm = (values, notes) => {
    values.email_plain_text_body = values.email_html_body
      .replace(/<style([\s\S]*?)<\/style>/gi, ' ')
      .replace(/<script([\s\S]*?)<\/script>/gi, ' ')
      .replace(/(<(?:.|\n)*?>)/gm, ' ')
      .replace(/\s+/gm, ' ');
    values.account_id = account_id;
    values.vertical_id = vertical_id;
    values.notes = notes;

    const data = { body: values };
    data.body.id = id;
    dispatch(editStart('email-body-components', data, dispatch));
    history.push({
      pathname: '/drip-components/email-html-body',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleDelete = notes => {
    if (id) {
      const data = { body: { id, notes } };
      dispatch(removeStart('email-body-components', data, dispatch));
      history.push({
        pathname: '/drip-components/email-html-body',
        search: `vertical_id=${vertical_id}`,
      });
    }
  };

  const renderNotesModal = props =>
    notesModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={notesModalOpen} />
          </Grid>
        }
      >
        <NotesModal
          props={props}
          classes={classes}
          model_name="EmailBodyComponent"
          model_id={id}
          open={notesModalOpen}
          handleClose={handleCloseNotesModal}
          submitForm={submitForm}
        />
      </Suspense>
    ) : null;

  const renderDeleteConfirmationDialog = isDeleteConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <DeleteConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        handleDelete={handleDelete}
        handleClose={handleDeleteModalClose}
        itemName={'email html body'}
        model_id={id}
        model_name="EmailBodyComponent"
      />
    </Suspense>
  ) : null;

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {props => (
        <Form>
          <Grid
            container
            item
            xs={12}
            className={classes.contentContainer}
            spacing={1}
          >
            <Grid item xs={6} className={classes.dripFormContainer}>
              <Grid item xs={12} md={12} className={classes.formContainer}>
                <NameField
                  {...props}
                  placeholder="Email HTML Body Name"
                  labelText="Email HTML Body Name"
                  name="name"
                  wrapperClass={classes.textField}
                  id={id}
                  dripComponent="email-body-components"
                />
                <DescriptionField
                  {...props}
                  placeholder="Email HTML Body"
                  labelText="Email HTML Body"
                  name="email_html_body"
                  wrapperClass={classes.textField}
                  onChangeDescription={handleChangeEmailHtmlBody}
                />
              </Grid>
              <ModelTags
                props={props}
                classes={classes}
                model_name="EmailBodyComponent"
                model_id={`${id}`}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Grid>
            <Grid item xs={6}>
              <EmailPreview
                classes={classes}
                activeTab={activeTab}
                handleSwitchTab={handleSwitchTab}
                emailPreview={emailPreview}
                textPlainPreview={textPlainPreview}
                elementId={id}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.actionsContainer}>
            <Grid item xs={6}>
              {id ? <DeleteButton onClick={handleDeleteModalOpen} /> : null}
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <PublishButton type="submit" />
            </Grid>
          </Grid>
          {renderDeleteConfirmationDialog}
          {renderNotesModal(props)}
        </Form>
      )}
    </Formik>
  );
};

export default EmailBodyForm;

EmailBodyForm.propTypes = {
  classes: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  textPlainPreview: PropTypes.string.isRequired,
  emailPreview: PropTypes.string.isRequired,
  setEmailPreview: PropTypes.func.isRequired,
};
