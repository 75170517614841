import React, { useState, useEffect } from 'react';
import DashboardNotes from './dashboardNotes';
import VerticalNotes from './verticalNotes';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const Notes = () => {
  const location = useLocation();
  const [hasVertical, setHasVertical] = useState(false);

  useEffect(() => {
    if (location) {
      let locationParams = queryString.parse(location.search);
      let vertical_id = locationParams.vertical_id;
      if (vertical_id) {
        setHasVertical(true);
      } else {
        setHasVertical(false);
      }
    }
  }, [location, location.search]);

  const renderNotesComponent = hasVertical ? (
    <VerticalNotes />
  ) : (
    <DashboardNotes />
  );

  return <>{renderNotesComponent}</>;
};

export default Notes;
