import { combineEpics } from 'redux-observable';
import {
  setActiveAccountEpic,
  setActiveVerticalEpic,
  getUserAccountsEpic,
  getAccountVerticalsEpic,
} from './epics/auth';
import * as restEpics from './epics/rest';
import * as reportingEpics from './epics/reporting';
import { sendTestSmsEpic, sendTestEmailEpic } from './epics/test';
import { sendSuppressionsListEpic } from './epics/suppressions';
import { getChangeLogsEpic } from './epics/changeLogs';
import { createMapsEpic } from './epics/maps';
import { generateRestEpics, generateReportingEpics } from '../config';

const rootEpic = combineEpics(
  setActiveAccountEpic,
  setActiveVerticalEpic,
  getUserAccountsEpic,
  getAccountVerticalsEpic,
  sendTestSmsEpic,
  sendTestEmailEpic,
  createMapsEpic,
  getChangeLogsEpic,
  sendSuppressionsListEpic,
  ...generateRestEpics(restEpics),
  ...generateReportingEpics(reportingEpics),
);

export default rootEpic;
