import { makeStyles } from '@material-ui/core/styles';
import SelectArrow from '../../../assets/images/select-arrow-down.png';
import MobileImage from '../../../assets/images/sms-body-text-phone.png';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '50px 0 20px 60px',
    [theme.breakpoints.up('xl')]: {
      padding: '50px 0 20px 270px',
    },
  },
  titleIcon: {
    position: 'relative',
    top: 8,
    marginRight: 10,
  },
  subtitle: {
    fontSize: 18,
    lineHeight: '22px',
    marginTop: 5,
    [theme.breakpoints.up(1900)]: {
      marginTop: 10,
    },
  },
  modalLoader: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: 0,
    zIndex: 10,
  },
  actionButtons: {
    marginTop: 100,
    '& > button': {
      marginRight: 27,
    },
  },
  messageSetingsTitle: {
    fontSize: 20,
    fontFamily: 'Lato',
    marginTop: 40,
    paddingBottom: 8,
    marginBottom: 22,
    borderBottom: '1px solid #828282',
  },
  sendTestSMSBtn: {
    marginTop: 20,
    background: theme.palette.orange[300],
    borderRadius: 2,
    color: theme.palette.white[100],
    width: 223,
    height: 59,
    fontSize: 20,
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.orange[400],
    },
  },
  rightSideContainer: {
    paddingLeft: 40,
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 80,
    },
  },
  testPreviewTitle: {
    fontSize: 24,
    fontFamily: 'Lato',
    margin: '20px 0',
  },
  mobilePreviewContainer: {
    width: '100%',
    maxWidth: 748,
    minWidth: 450,
    height: '734px',
    border: '1px solid #BDBDBD',
    backgroundImage: `url(${MobileImage})`,
    backgroundPosition: 'center',
    backgroundSize: '290px 644px',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  },
  message: {
    maxHeight: 465,
    overflowY: 'scroll',
    paddingBottom: 30,
    '& > p': {
      padding: 6,
      width: 200,
      background: '#E7F3FF',
      fontSize: 16,
      borderRadius: 12,
      'word-wrap': 'break-word',
    },
  },
  mobilePreview: {
    position: 'absolute',
    left: '48%',
    transform: 'translate(-50%)',
    top: 101,
    fontSize: 18,
    overflow: 'hidden',
    height: 496,
    '& > h2': {
      marginBottom: 20,
      fontSize: 19,
      fontFamily: 'Lato',
      fontWeight: 400,
      paddingLeft: 50,
      maxWidth: 220,
      minHeight: 27,
    },
    '& > p': {
      padding: 6,
      width: 200,
      background: '#E7F3FF',
      fontSize: 16,
      borderRadius: 12,
      'word-wrap': 'break-word',
    },
  },
  helperTextContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconContainer: {
    maxWidth: 90,
  },
  checkBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 50,
    flex: 1,
    '& > span': {
      marginLeft: 0,
      fontSize: 16,
      fontFamily: 'Lato',
    },
  },
  infoIcon: {
    color: theme.palette.green[300],
    position: 'relative',
    top: -7,
    left: -2,
    cursor: 'help',
    '& > svg': {
      fontSize: 16,
    },
  },
  textField: {
    marginBottom: 13,
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
    '& > textarea.field': {
      width: '100%',
      minHeight: 400,
      backgroundColor: theme.palette.white[100],
      padding: 10,
      fontFamily: 'Lato',
      fontSize: 16,
      borderRadius: 4,
      marginBottom: -10,
    },
    '& > textarea.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > input': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      backgroundColor: theme.palette.white[100],
    },
    '& > input.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '98% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
}));

export default useStyles;
