import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Pagination from './pagination';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

const PaginationWrapper = ({
  classes,
  handlePerPageArrow,
  elementsPerPage,
  handleElementsPerPage,
  handleChangeDirectlyPage,
  page,
  rowsPerPage,
  totalCount,
  onChangePagination,
  jumpToPage,
  handleJumpToPageInput,
  handleJumpToPageButton,
}) => {
  return (
    <Grid container alignItems="center">
      <Grid container item xs={3} lg={2} xl={3} justify="flex-end">
        <div className={classes.resultsPerPageLabel}>
          Results <span>Per Page</span>
        </div>
        <TextField
          value={elementsPerPage}
          onKeyPress={handleElementsPerPage}
          onChange={handleChangeDirectlyPage}
          className={classes.resultsPerPage}
          variant="outlined"
          placeholder="10"
          InputLabelProps={{
            classes: {
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            endAdornment: (
              <div className={classes.expandIcons}>
                <ExpandLessIcon onClick={handlePerPageArrow('more')} />{' '}
                <ExpandMoreIcon onClick={handlePerPageArrow('less')} />
              </div>
            ),
            classes: {
              notchedOutline: classes.notchedOutline,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} lg={8} xl={6}>
        <Pagination
          activePage={page}
          itemsPerPage={+rowsPerPage}
          totalItemsCount={totalCount}
          onChange={onChangePagination}
        />
      </Grid>
      <Grid item xs={3} lg={2} xl={3}>
        <TextField
          value={jumpToPage}
          onChange={handleJumpToPageInput}
          onKeyPress={handleJumpToPageInput}
          className={classes.pageInput}
          variant="outlined"
          placeholder="Page #"
          InputLabelProps={{
            classes: {
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            endAdornment: <span onClick={handleJumpToPageButton}>Go</span>,
            classes: {
              notchedOutline: classes.notchedOutline,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PaginationWrapper;

PaginationWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  handlePerPageArrow: PropTypes.func.isRequired,
  elementsPerPage: PropTypes.any,
  handleElementsPerPage: PropTypes.func.isRequired,
  handleChangeDirectlyPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.any,
  totalCount: PropTypes.number.isRequired,
  onChangePagination: PropTypes.func.isRequired,
  jumpToPage: PropTypes.string.isRequired,
  handleJumpToPageInput: PropTypes.func.isRequired,
  handleJumpToPageButton: PropTypes.func.isRequired,
};
