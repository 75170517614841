import React from 'react';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  exportButton: {
    fontSize: 16,
    width: 167,
    borderRadius: 5,
    height: 42,
    fontWeight: 900,
    backgroundColor: theme.palette.lightBlue[300],
    '& > span > span > svg': {
      fontSize: '28px !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
  },
}));

const CustomExport = props => {
  const classes = useStyles();

  return (
    <Button
      className={classNames(classes.exportButton, props.customClass)}
      variant="contained"
      color="primary"
      startIcon={<DescriptionIcon />}
      onClick={props.onClick}
    >
      {props.label ? props.label : "Export Report"}
    </Button>
  );
};

export default CustomExport;
