import { formatToLocalDate } from '../../../config/helpers';

export const columns = [
  { id: 'name', label: 'Name', minWidth: 60, align: 'left' },
  { id: 'value', label: 'Value', minWidth: 60, align: 'left' },
  {
    id: 'created',
    label: 'Created',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'modified',
    label: 'Modified',
    minWidth: 150,
    align: 'left',
  },
];

export const createData = (name, value, created, modified, item) => {
  return { name, value, created, modified, item };
};

export const renderElements = (list, setRows) => {
  let row = [];
  list &&
    list.forEach(item => {
      const { name, value, created, modified } = item;
      row.push(
        createData(
          name,
          value,
          formatToLocalDate(created, 'MMM, dd yyyy'),
          formatToLocalDate(modified, 'MMM, dd yyyy'),
          item,
        ),
      );
    });
  setRows(row);
};

export const sortBy = {
  name: 'asc',
  value: 'asc',
  created: 'asc',
  modified: 'desc',
};

export const initialValues = {
  id: '',
  name: '',
  value: '',
  notes: [],
};

export const populateValues = (values, account_id) => {
  const { id, name, value, notes } = values;
  return {
    id,
    name,
    value,
    slug: (values.name + '--' + values.value + '--' + account_id)
      .replace(/\s+/g, '-')
      .toLowerCase(),
    notes: notes || [],
  };
};
