import { createOperation } from '../../../config/createOperation';

export const GET_ITEM = type =>
  createOperation(`${type.toUpperCase()}_GET_ITEM`);

export const GET_LIST = type =>
  createOperation(`${type.toUpperCase()}_GET_LIST`);

export const GET_LIST_COUNT = type =>
  createOperation(`${type.toUpperCase()}_GET_LIST_COUNT`);

export const CREATE = type => createOperation(`${type.toUpperCase()}_CREATE`);

export const EDIT = type => createOperation(`${type.toUpperCase()}_EDIT`);

export const REMOVE = type => createOperation(`${type.toUpperCase()}_REMOVE`);

export const CLEAR = type => createOperation(`${type.toUpperCase()}_CLEAR`);

export const CHECK_NAME_UNIQUE = type =>
  createOperation(`${type.toUpperCase()}_CHECK_NAME_UNIQUE`);

export const CLEAR_NEW_FLOW = 'CLEAR_NEW_FLOW';

export const CLEAR_NEW_FLOW_STEP_MAP = 'CLEAR_NEW_FLOW_STEP_MAP';

export const SAVE_PARAMS = type => createOperation(`${type.toUpperCase()}_SAVE_PARAMS`);

export const SAVE_CONTAIN = type => createOperation(`${type.toUpperCase()}_SAVE_CONTAIN`);

export const SAVE_FILTERS = type => createOperation(`${type.toUpperCase()}_SAVE_FILTERS`);

export const CLEAR_NAME_UNIQUE = type =>
  createOperation(`${type.toUpperCase()}_CLEAR_NAME_UNIQUE`);

export const SAVE_FSM_AND_FSTM = createOperation('SAVE_FSM_AND_FSTM');

export const UPLOAD = type => createOperation(`${type.toUpperCase()}_UPLOAD`);
