import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import EmailHtmlBodyIcon from '../../../assets/images/email-from.svg';
import { initialValues, populateValues, contain } from './config';
import EmailBodyForm from './emailHtmlBodyForm';
import withSingleDripPage from '../../../hoc/withSingleDripPage/withSingleDripPage';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

const SingleEmailBody = ({ element }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(initialValues);
  const [emailPreview, setEmailPreview] = useState('');
  const [textPlainPreview, setTextPlainPreview] = useState('');
  const match = useRouteMatch();

  const clear = () => {
    setFormValues(initialValues);
    setEmailPreview('');
    setTextPlainPreview('');
  };

  const getPlainText = richText =>
    richText
      .replace(/<style([\s\S]*?)<\/style>/gi, ' ')
      .replace(/<script([\s\S]*?)<\/script>/gi, ' ')
      .replace(/(<(?:.|\n)*?>)/gm, ' ')
      .replace(/\s+/gm, ' ');

  useEffect(() => {
    if (!element) clear();
    else {
      setFormValues(populateValues(element));
      setEmailPreview(element.email_html_body);
      const plainText = getPlainText(element.email_html_body);
      setTextPlainPreview(plainText);
    }
  }, [element]);

  useEffect(() => {
    const plainText = getPlainText(emailPreview);
    setTextPlainPreview(plainText);
  }, [emailPreview]);

  return (
    <Grid item xs={12}>
      <EmailBodyForm
        id={+match.params.id || ''}
        classes={classes}
        formValues={formValues}
        setFormValues={setFormValues}
        textPlainPreview={textPlainPreview}
        emailPreview={emailPreview}
        setEmailPreview={setEmailPreview}
      />
    </Grid>
  );
};

export default withSingleDripPage(
  withTitle(withDashboard(SingleEmailBody, false), 'Single Email Body'),
  {
    title: 'Email HTML Body',
    image: EmailHtmlBodyIcon,
    path: 'email-body-components',
    contain: contain,
    lists: ['email-body-components'],
  },
);

SingleEmailBody.propTypes = {
  element: PropTypes.object,
};
