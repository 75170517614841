import {
  GET_CHANGE_LOGS,
  CLEAR_CHANGE_LOGS,
} from '../actions/actionTypes/changeLogs';

const initalState = {
  list: null,
};

const changeLogsReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_CHANGE_LOGS.actionTypes.SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case CLEAR_CHANGE_LOGS.actionTypes.SUCCESS:
      return {
        list: null,
      };
    default:
      return state;
  }
};

export default changeLogsReducer;
