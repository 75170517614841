import { makeStyles } from '@material-ui/core/styles';
import SelectArrow from '../../../assets/images/select-arrow-down.png';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: 0,
    overflowX: 'hidden',
    [theme.breakpoints.up(1200)]: {
      marginTop: 0,
      paddingLeft: 60,
      marginLeft: 0,
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 0 20px 16px',
      maxWidth: 1650,
      margin: '0 auto !important',
    },
  },
  subtitle: {
    fontSize: 16,
    marginTop: 10,
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
    },
  },
  title: {
    position: 'relative',
    top: 20,
  },
  errorText: {
    fontFamily: 'Lato',
    color: theme.palette.red[300],
  },
  textField: {
    marginBottom: 20,
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
    '& > input': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      backgroundColor: theme.palette.white[100],
    },
    '& > input.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > textarea': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 90,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      paddingTop: 10,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
    },
    '& > textarea.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
    '& > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '98% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
  },
  formContainer: {
    marginTop: 26,
    [theme.breakpoints.up(500)]: {
      maxWidth: 433,
    },
  },
  suppressionField: {
    position: 'relative',
  },
  infoIcon: {
    position: 'absolute',
    top: 11,
    right: 0,
    width: 15,
    height: 15,
    color: '#2F972A',
    cursor: 'help',
  },
}));

export default useStyles;
