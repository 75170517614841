import React, { Fragment, useState, useEffect, lazy, Suspense } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  createStart,
  editStart,
  removeStart,
  clearNameUnique,
} from '../../store/actions/actions/rest';
import { Formik, Form } from 'formik';
import DeleteConfirmationDialog from '../deleteConfirmationModal';
import useStyles from './styles';
import { renderFields } from './config';
import Loader from '../../components/loader';
import PropTypes from 'prop-types';
import AccountsPermissions from './accountsPermissions';

const CustomModal = ({
  open,
  fields,
  populateValues,
  element,
  title,
  handleClose,
  parameters,
  path,
  encodingIsVisible,
  handlePostTypeField,
  model_name,
  setEditNewFlow,
  dripComponent,
  clearUniqueObject,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const user_name = useSelector(state => state.auth.username);
  const NotesModal = lazy(() => import('../../components/notes/notes'));
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [values, setValues] = useState({});
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);

  useEffect(() => {
    if (model_name === 'GenericPostComponent') {
      let httpRequestElement = { ...element };
      element.post_encoding = element.post_encoding
        ? element.post_encoding
        : '';
      setValues(httpRequestElement);
    } else {
      setValues(element);
    }
    if (element) setValues(element);
  }, [element, model_name]);

  const handleDeleteModalOpen = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleOpenNotesModal = () => {
    setNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setNotesModalOpen(false);
  };

  const handleDelete = notes => {
    if (values.id) {
      const data = {
        body: {
          id: values.id,
          notes,
        },
        params: parameters,
      };
      dispatch(removeStart(path, data, dispatch));
      handleClose();
    }
  };

  const onSubmit = values => {
    if (values.id) {
      handleOpenNotesModal();
    } else {
      let data = {
        body: {
          ...populateValues(values, account_id),
          account_id: account_id,
          vertical_id: vertical_id,
          notes: [
            {
              account_id,
              vertical_id,
              model_name,
              model_id: '',
              user_name,
              note: `Created ${values && values.name ? values.name : ''}`,
            },
          ],
        },
        params: parameters,
      };
      if (path === 'flows') {
        data.body.days_times = '{}';
      }
      dispatch(createStart(path, data, dispatch));
      handleClose();
    }
  };

  const handleCreateAndEdit = async (values, validateForm, setFieldTouched) => {
    let validateObj = await validateForm();
    if (
      validateObj &&
      Object.keys(validateObj).length === 0 &&
      Object.getPrototypeOf(validateObj) === Object.prototype
    ) {
      setEditNewFlow && setEditNewFlow(true);
      let data = {
        body: {
          ...populateValues(values),
          account_id: account_id,
          vertical_id: vertical_id,
          notes: [
            {
              account_id,
              vertical_id,
              model_name: 'Flow',
              model_id: '',
              user_name,
              note: `Created ${values && values.name ? values.name : ''}`,
            },
          ],
        },
        params: parameters,
      };
      data.body.days_times = '{}';
      data.body.id = values.id;
      dispatch(createStart(path, data, dispatch));
      handleClose();
    } else {
      setFieldTouched('name', true);
    }
  };

  const submitForm = (values, notes) => {
    let data = {
      body: {
        ...populateValues(values, account_id),
        account_id: account_id,
        vertical_id: vertical_id,
        notes,
      },
      params: parameters,
    };
    if (path === 'flows') {
      data.body.days_times = '{}';
    }
    if (path === 'generic-post-components') {
      if (data.body.post_type === 'get') {
        data.body.post_encoding = null;
      }
    }
    data.body.id = values.id;
    dispatch(editStart(path, data, dispatch));
    handleClose();
    setNotesModalOpen(false);
  };

  const renderDeleteButton = values.id ? (
    <Button
      onClick={handleDeleteModalOpen}
      color="secondary"
      className={classes.deleteActionBtn}
    >
      <DeleteIcon />
    </Button>
  ) : (
    <div />
  );

  const renderNotesModal = props =>
    notesModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={notesModalOpen} />
          </Grid>
        }
      >
        <NotesModal
          props={props}
          classes={classes}
          model_name={model_name}
          model_id={values.id || ''}
          open={notesModalOpen}
          handleClose={handleCloseNotesModal}
          submitForm={submitForm}
        />
      </Suspense>
    ) : null;

  const handleExit = () => {
    dispatch(clearNameUnique(path, null));
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.modalContainer}
      >
        <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
          {values.id ? 'Edit' : 'New'} {title}
          <IconButton onClick={clearUniqueObject ? handleExit : handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
            {props => (
              <Form>
                {renderFields(
                  props,
                  classes,
                  fields,
                  encodingIsVisible,
                  handlePostTypeField,
                  setEditNewFlow,
                  dripComponent,
                  element.id,
                )}
                {model_name === 'User' && (
                  <AccountsPermissions
                    formik={props}
                    classes={classes}
                    setValues={setValues}
                    values={values}
                  />
                )}
                <DialogActions className={classes.actionsContainer}>
                  {path === 'flows' ? (
                    <Fragment>
                      <Button className={classes.createEmptyFlow} type="submit">
                        Create Empty Flow
                      </Button>
                      <Button
                        className={classes.saveActionBtn}
                        onClick={() =>
                          handleCreateAndEdit(
                            props.values,
                            props.validateForm,
                            props.setFieldTouched,
                          )
                        }
                      >
                        Create And Edit Flow
                      </Button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {renderDeleteButton}
                      <Button
                        type="submit"
                        color="primary"
                        className={classes.saveActionBtn}
                      >
                        <SaveAltIcon /> <span>Save {title}</span>
                      </Button>
                    </Fragment>
                  )}
                </DialogActions>
                {renderNotesModal(props)}
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <DeleteConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        handleDelete={handleDelete}
        handleClose={handleDeleteModalClose}
        itemName={title}
        model_id={values.id}
        model_name={model_name}
      />
    </div>
  );
};

export default CustomModal;

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  populateValues: PropTypes.func.isRequired,
  element: PropTypes.object,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  parameters: PropTypes.any,
  path: PropTypes.string.isRequired,
  encodingIsVisible: PropTypes.bool,
  handlePostTypeField: PropTypes.any,
  model_name: PropTypes.string,
  setEditNewFlow: PropTypes.func,
};
