import { formatToLocalDate } from '../../../config/helpers';

export const columns = [
  {
    id: 'name',
    label: 'Component Name',
    minWidth: 60,
    align: 'left',
  },
  { id: 'post_type', label: 'Post Type', minWidth: 60, align: 'left' },
  { id: 'post_encoding', label: 'Post Encoding', minWidth: 60, align: 'left' },
  {
    id: 'post_response',
    label: 'Success Message',
    minWidth: 60,
    align: 'left',
  },
  {
    id: 'created',
    label: 'Created',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'modified',
    label: 'Modified',
    minWidth: 150,
    align: 'left',
  },
];

export const filters = [
  {
    field: 'vertical_id',
    operator: '=',
    path: 'vertical_id',
  },
  {
    field: 'account_id',
    operator: '=',
    path: 'account_id',
  },
];

const createData = (
  name,
  post_type,
  post_encoding,
  post_response,
  created,
  modified,
  item,
) => {
  return {
    name,
    post_type,
    post_encoding,
    post_response,
    created,
    modified,
    item,
  };
};

export const renderElements = (list, setRows) => {
  let row = [];
  list &&
    list.forEach(item => {
      const {
        name,
        post_type,
        post_encoding,
        post_response,
        created,
        modified,
      } = item;
      row.push(
        createData(
          name,
          post_type,
          post_encoding ? post_encoding : '-',
          post_response,
          formatToLocalDate(created, 'MMM, dd yyyy'),
          formatToLocalDate(modified, 'MMM, dd yyyy'),
          item,
        ),
      );
    });
  setRows(row);
};

export const sortBy = {
  name: 'asc',
  post_type: 'asc',
  post_encoding: 'asc',
  post_response: 'asc',
  created: 'asc',
  modified: 'desc',
};

export const initialValues = {
  id: '',
  name: '',
  post_url: '',
  post_response: '',
  post_type: '',
  post_encoding: '',
  post_fields: '',
  notes: [],
};

export const populateValues = values => {
  const {
    id,
    name,
    post_url,
    post_response,
    post_type,
    post_encoding,
    post_fields,
    notes,
  } = values;
  let fields = post_fields.replace(/\s/gm, '\r\n');
  return {
    id,
    name,
    post_url,
    post_response,
    post_type,
    post_encoding,
    post_fields: fields || post_fields,
    notes: notes || [],
  };
};
