export const createData = (
  name,
  totalSends,
  delivered,
  undelivered,
  clicks,
  item,
) => {
  return {
    name,
    totalSends,
    delivered,
    undelivered,
    clicks,
    item,
  };
};

export const sortBy = {
  name: 'asc',
  totalSends: 'asc',
  delivered: 'asc',
  undelivered: 'asc',
  clicks: 'asc',
};

export const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 150,
  },
  {
    id: 'totalSends',
    label: 'Total Sends',
    align: 'left',
    minWidth: 100,
  },
  {
    id: 'delivered',
    label: 'Delivered',
    align: 'left',
    minWidth: 100,
  },
  {
    id: 'undelivered',
    label: 'Undelivered',
    align: 'left',
    minWidth: 100,
  },
  {
    id: 'clicks',
    label: 'Clicks',
    align: 'left',
    minWidth: 100,
  },
];

export const renderElements = (list, setRows) => {
  let allRows = [];
  list &&
    list.forEach(item => {
      const { name, totalSends, delivered, undelivered, clicks } = item;
      allRows.push(
        createData(name, totalSends, delivered, undelivered, clicks, item),
      );
    });
  setRows(allRows);
};

export const filters = vertical_id => [
  JSON.stringify({
    field: 'vertical_id',
    operator: '=',
    value: vertical_id,
  }),
  JSON.stringify({
    field: 'message_type_id',
    operator: '=',
    value: '5',
  }),
];
