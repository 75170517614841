import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 70,
    width: 550,
    margin: '0 auto',
    textAlign: 'left',
    height: 60,
    backgroundColor: theme.palette.white[100],
    fontSize: 18,
    borderRadius: ' 6px 6px 0 0',
    padding: '18px 18px 18px 46px',
    textTransform: 'uppercase',
    position: 'relative',
    bottom: -2,
  },
  tableContainer: {
    width: 550,
    margin: '0px auto 20px auto',
    maxHeight: 440,
  },
  table: {
    width: 550,
  },
  tableCell: {
    width: '50%',
    paddingLeft: 46,
  },
  tableRow: {
    cursor: 'pointer',
  },
  active: {
    backgroundColor: '#F6F3F3',
  },
}));

const AccountsList = ({ list, selected, setSelected }) => {
  const classes = useStyles();
  const renderList = list ? (
    list.map(item => {
      return (
        <TableRow
          key={item.id}
          className={classNames(classes.tableRow, {
            [classes.active]: item.id === selected,
          })}
          onClick={() => {
            if (item.id !== selected) setSelected(item.id);
          }}
        >
          <TableCell align="left" className={classes.tableCell}>
            {item.id}
          </TableCell>
          <TableCell align="left" className={classes.tableCell}>
            {item.name}
          </TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow>
      <TableCell>Loading Accounts...</TableCell>
    </TableRow>
  );

  return (
    <div className={classes.container}>
      <div className={classes.title}>Select Account:</div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableCell}>
                Account
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                Role
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderList}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AccountsList;
