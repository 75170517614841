import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: 1200,
    margin: '0 auto',
    padding: '20px 0',
    [theme.breakpoints.up('xl')]: {
      width: '100% !important',
      padding: 20,
      maxWidth: 1650,
      margin: '0 auto !important',
    },
  },
  subtitle: {
    fontSize: 16,
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
    },
  },
  addButton: {
    paddingRight: 10,
    '& > button': {
      backgroundColor: '#F2F2F2',
      width: 170,
      height: 45,
      '& > span': {
        color: '#828282',
        fontWeight: 900,
      },
    },
  },
  searchField: {
    backgroundColor: '#F2F2F2',
    '& > .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& > .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
      },
      '& > button': {
        padding: 9,
      },
      '& > svg': {
        color: '#828282',
        position: 'relative',
        left: 7,
      },
    },
  },
  tableWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  tableContainer: {
    marginTop: 30,
  },
  table: {
    minWidth: 650,
    color: theme.palette.text[100],
    '& > thead > tr > th': {
      color: theme.palette.text[100],
    },
    '& > tbody > tr > th ': {
      color: theme.palette.text[100],
    },
    '& > tbody > tr > td ': {
      color: theme.palette.text[100],
    },
  },
  link: {
    color: '#036BF0',
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'all .7s',
    '&:hover': {
      color: theme.palette.text[100],
    },
  },
  pageInput: {
    backgroundColor: '#F2F2F2',
    width: 115,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
        padding: '13px 10px',
      },
      '& > span': {
        padding: 10,
        cursor: 'pointer',
      },
    },
  },
  cssFocused: {
    color: '#828282 !important',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#828282 !important',
  },
  resultsPerPageLabel: {
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.text[100],
    paddingRight: 10,
    '& > span': {
      display: 'block',
    },
  },
  resultsPerPage: {
    width: 75,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        padding: 13,
      },
      '& > div': {
        backgroundColor: theme.palette.grey[100],
        padding: 2,
        '& > svg': {
          fontSize: 19,
        },
      },
    },
  },
  modalContainer: {
    '& > div.MuiDialog-container': {
      '& > div': {
        width: 545,
        backgroundColor: '#F3F8FE',
      },
    },
  },
  modalTitle: {
    fontFamily: "'Lato'",
    fontSize: 24,
    color: '#4F4F4F',
    '& > h2': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  formControl: {
    width: 262,
    backgroundColor: theme.palette.white[100],
  },
  dropDownSelect: {
    '& > div': {
      padding: '14px 32px 12px 14px',
    },
  },
  selectSection: {
    marginTop: 15,
  },
  switchSection: {
    marginTop: 15,
    '& > h4': {
      color: theme.palette.text[100],
      marginBottom: 10,
      fontWeight: 'normal',
      marginTop: 0,
    },
  },
  newConfigBtn: {
    height: 45,
    backgroundColor: theme.palette.lightBlue[300],
    color: theme.palette.white[100],
    fontSize: 16,
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
  },
  actionsContainer: {
    justifyContent: 'space-between !important',
    padding: '25px 0 10px 0 !important',
  },
  deleteActionBtn: {
    backgroundColor: theme.palette.secondary.main,
    height: 47,
    transition: 'all .4s',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: '#b54a4c !important',
    },
    '& > span': {
      color: theme.palette.white[100],
      fontSize: 20,
    },
  },
  saveActionBtn: {
    backgroundColor: theme.palette.green[300],
    transition: 'all .4s',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: `${theme.palette.green[400]} !important`,
    },
    '& > span': {
      color: theme.palette.white[100],
      fontSize: 20,
      '& > span': {
        marginLeft: 10,
        fontWeight: 'bold',
      },
    },
  },
  inputLabel: {
    color: theme.palette.text[100],
    fontWeight: 400,
    fontFamily: 'Lato',
    fontSize: 16,
    marginTop: 15,
    marginBottom: 9,
  },
  title: {
    position: 'relative',
    top: 20,
  },
  noData: {
    height: 60,
    position: 'relative',
    left: 20,
    fontSize: 20,
  },
  loaderContainer: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: 150,
    zIndex: 10,
  },
  errorText: {
    fontFamily: 'Lato',
    color: theme.palette.red[300],
  },
  textField: {
    marginBottom: 20,
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
    '& > input': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      backgroundColor: theme.palette.white[100],
    },
    '& > input.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > textarea': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 90,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      paddingTop: 10,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
    },
    '& > textarea.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  modalLoader: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: 0,
    zIndex: 10,
  },
}));

export default useStyles;
