export const createEmptyRow = rowLength =>
  [...Array(rowLength).keys()].map(() => false);

export const populateCells = (rows, length, initial = false) => {
  const emptyCells = [...Array(rows).keys()].reduce((acc, curr) => {
    curr = createEmptyRow(length);
    return [...acc, curr];
  }, []);
  return emptyCells;
};

export const checkUsedDays = cells => {
  let array = [];
  if (cells && cells.length > 0) {
    cells.forEach((cell, index) => {
      let used = cell.find(c => c === true);
      if (used) {
        array.push(index);
      }
    });
  }
  return array;
};

export const generateUsedDays = days => {
  let allUsedDays = [];
  days.forEach((day, index) => {
    if (day) {
      allUsedDays.push(index / 2);
    }
  });
  return allUsedDays;
};

export const populateDefaultDays = (days, emptyList) => {
  const dayKeys = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  let allUsedDays = JSON.parse(days);
  // eslint-disable-next-line
  Object.keys(allUsedDays).map(day => {
    // eslint-disable-next-line
    allUsedDays[day].map(value => {
      emptyList[dayKeys[day]][value * 2] = true;
    });
  });
  return emptyList;
};
