import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const validate = values => {
  if (!values) {
    return 'Required';
  }
};

const EncodingTypes = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
  disabled,
}) => {
  return (
    <div className={wrapperClass}>
      {hideLabel ? null : (
        <label htmlFor="post_encoding" name="post_encoding">
          {labelText || 'Post Encoding'}
        </label>
      )}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'post_encoding'] && touched[name || 'post_encoding']
            ? errorClass || 'has-error'
            : ''
        }`}
        htmlFor="post_encoding"
        placeholder={placeholder || 'Post Encoding'}
        component="select"
        name={name || 'post_encoding'}
        validate={disabled && validate}
        disabled={!disabled}
        onChange={e => {
          handleChange(e);
        }}
      >
        <option key={'empty'} value="">
          Select
        </option>
        <option data-testid="post_encoding_json" value="application/json">
          application/json
        </option>
        <option
          data-testid="post_encoding_form_data"
          value="application/x-www-form-urlencoded"
        >
          application/x-www-form-urlencoded
        </option>
      </Field>
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'post_encoding'}
          component="div"
          className="field-error"
          data-testid="post_encoding-error"
        />
      )}
    </div>
  );
};

export default EncodingTypes;

EncodingTypes.propTypes = {
  hideLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  wrapperClass: PropTypes.string,
  disabled: PropTypes.bool,
};
