import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme } from '@material-ui/core/styles';
import Logo from '../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';
import MenuSelect from './menu';
import VerticalMenu from './verticalMenu';
import SmallDrawerList from './smallDrawerList';
import DrawerList from './drawerList';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveDrawer,
  setVerticalFlag,
  displaySnackbar,
} from '../../store/actions/actions/root';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import {
  setActiveVertical,
  cancelActiveVertical,
  logout,
} from '../../store/actions/actions/auth';
import { getList, cancelList } from '../../store/actions/actions/rest';
import { useSnackbar } from 'notistack';
import { Auth } from 'aws-amplify';
import MenuIcon from '@material-ui/icons/Menu';

const withDashboard = (Component, isHidden) => props => {
  const { container } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const drawerState = useSelector(state => state.app.drawer);
  const setVertical = useSelector(state => state.app.setVertical);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const account_id = useSelector(state => state.auth.account_id);
  const user = useSelector(state => state.auth);
  const verticalsList = useSelector(state => state.verticals.list);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [internetProblem, setInternetProblem] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isAccountManager, setIsAccountManager] = useState(false);
  const [isHomePage, setIsHomePage] = useState(true);
  const [isMobileDraweOpen, setIsMobileDrawerOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const signOut = () => {
    Auth.signOut().then(() => {
      dispatch(logout());
      window.location.reload();
    });
    Auth.signOut().then(() => {
      dispatch(logout());
      window.location.reload();
    });
  };

  // Logout user when session expires
  useEffect(() => {
    if (user.error) {
      signOut();
    }
    // eslint-disable-next-line
  }, [user]);

  // Detect if the user has Internet connection
  useEffect(() => {
    if (!window.navigator.onLine && !internetProblem) {
      enqueueSnackbar('There is no internet connection.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        preventDuplicate: true,
        persist: true,
      });
      setInternetProblem(true);
    } else {
      setInternetProblem(false);
      closeSnackbar();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (account_id !== null) {
      setIsAccountManager(account_id === 0 ? true : false);
    }
  }, [account_id]);

  useEffect(() => {
    if (location && location.pathname) {
      location.pathname === '/' ? setIsHomePage(true) : setIsHomePage(false);
    }
  }, [location]);

  useEffect(() => {
    if (location && verticalsList && verticalsList.length > 0 && vertical_id) {
      let params = queryString.parse(location.search);
      let verticalId = params.vertical_id;
      if (verticalId) {
        if (+vertical_id !== +verticalId) {
          let selectedVertical = verticalsList.find(
            vertical => vertical.id === +verticalId,
          );
          if (selectedVertical) {
            dispatch(cancelActiveVertical(null, dispatch));
            dispatch(setActiveVertical(+verticalId, dispatch));
          } else {
            const search = params.parent_id
              ? `parent_id=${params.parent_id}&vertical_id=${vertical_id}`
              : `vertical_id=${vertical_id}`;
            history.push({
              pathname: location.pathname,
              search,
            });
            dispatch(
              displaySnackbar({
                type: 'warning',
                message: `You tried to access to non-existing vertical ${verticalId} through the URL!`,
              }),
            );
          }
        }
      }
    }
  }, [location, dispatch, vertical_id, verticalsList, history]);

  useEffect(() => {
    if (!setVertical) {
      dispatch(cancelList('verticals', null, dispatch));
      dispatch(getList('verticals', null, dispatch));
      dispatch(setVerticalFlag(true));
    }
  }, [dispatch, setVertical]);

  useEffect(() => {
    if (location) {
      let path = location.pathname.substring(1, 6);
      if (path === 'flows') {
        let data = {
          ...drawerState,
          dripModule: false,
          drips: false,
          flows: true,
          reporting: false,
        };
        dispatch(saveDrawer(data));
      } else {
        if (path === 'drips') {
          let data = {
            ...drawerState,
            dripModule: false,
            drips: true,
            flows: false,
            reporting: false,
          };
          dispatch(saveDrawer(data));
        } else if (path === 'drip-') {
          let data = {
            ...drawerState,
            dripModule: true,
            drips: true,
            flows: false,
            reporting: false,
          };
          dispatch(saveDrawer(data));
        } else if (path === 'repor') {
          let data = {
            ...drawerState,
            dripModule: false,
            drips: false,
            flows: false,
            reporting: true,
          };
          dispatch(saveDrawer(data));
        } else {
          let data = {
            ...drawerState,
            dripModule: false,
            drips: false,
            flows: false,
            reporting: false,
          };
          dispatch(saveDrawer(data));
        }
      }
    }
    // eslint-disable-next-line
  }, [location, dispatch]);

  const handleClick = name => () => {
    if (name === 'drips' && drawerState.drips === true) {
      let data = {
        ...drawerState,
        dripModule: false,
        [name]: !drawerState[name],
      };
      dispatch(saveDrawer(data));
    } else {
      let data = {
        ...drawerState,
        [name]: !drawerState[name],
      };
      dispatch(saveDrawer(data));
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenMobileDrawer = () => {
    if (!isMobileDraweOpen) {
      let drawerLinks = {
        drips: false,
        flows: false,
        dripModule: false,
        reporting: false,
      };
      dispatch(saveDrawer(drawerLinks));
    }
    setIsMobileDrawerOpen(!isMobileDraweOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <div className={classes.toolbar} />
      <div className={classes.smallDrawer}>
        <SmallDrawerList
          isShown={isShown}
          handleClick={handleClick}
          open={drawerState}
        />
      </div>
      <div className={classes.desktopDrawer}>
        <DrawerList
          isShown={isShown}
          handleClick={handleClick}
          open={drawerState}
        />
      </div>
    </div>
  );

  const renderNavClass = isShown ? classes.drawerOpen : classes.drawerClosed;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Link to="/" className={classes.logo}>
            <img src={Logo} alt="Faucet logo" />
          </Link>
          {!isAccountManager && <VerticalMenu />}
          <MenuSelect isAccountManager={isAccountManager} />
          {!isHomePage && (
            <div
              className={classes.hamburgerMenuIcon}
              onClick={handleOpenMobileDrawer}
            >
              <MenuIcon />
            </div>
          )}
        </Toolbar>
      </AppBar>

      {!isHidden && (
        <div className={classes.desktopDrawerContainer}>
          <nav
            className={classes.drawer}
            aria-label="mailbox folders"
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          >
            <Hidden xlUp implementation="css">
              <Drawer
                container={container}
                variant="permanent"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classNames(classes.drawerPaper, renderNavClass),
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xlDown implementation="css">
              <Drawer
                classes={{
                  paper: classNames(classes.drawerPaper, renderNavClass),
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </div>
      )}
      <div className={classes.mobileDrawerContainer}>
        <Drawer
          anchor="right"
          open={isMobileDraweOpen}
          onClose={handleOpenMobileDrawer}
          container={container}
          classes={{
            paper: classes.mobileDrawerPaper,
          }}
        >
          <div className={classes.mobileDrawer}>
            <DrawerList
              isShown={isShown}
              handleClick={handleClick}
              open={drawerState}
            />
          </div>
        </Drawer>
      </div>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Component {...props} />
      </main>
    </div>
  );
};

export default withDashboard;

withDashboard.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  elementType: PropTypes.elementType.isRequired,
};
