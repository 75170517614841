export const styles = theme => ({
  backgroundLayer: {
    backgroundColor: '#2D435F',
    width: '100%',
    height: '100vh',
  },
  loginContainer: {
    background: `linear-gradient(180deg, #42638E 0%, rgba(41, 77, 122, 0) 100%)`,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: "'Lato', sans-serif",
    '& > div': {
      margin: '0 auto',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        width: 334,
        marginBottom: 65,
      },
    },
  },
  requireNewPasswordLink: {
    color: '#9DC4F6',
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'all .7s',
    '&:hover': {
      color: '#FFBF58',
    },
  },
  inputField: {
    backgroundColor: theme.palette.common.white,
    color: '#828282',
    margin: '15px 0',
    borderRadius: 2,
    [theme.breakpoints.up('sm')]: {
      width: 433,
    },
    '& > input': {
      padding: 15,
    },
  },
  inputLabel: {
    display: 'block',
    color: theme.palette.common.white,
    textAlign: 'left',
  },
  requireNewPasswordButton: {
    backgroundColor: '#FFBF58',
    color: theme.palette.common.white,
    fontSize: 24,
    fontWeight: 900,
    width: 246,
    padding: '11px 0',
    marginTop: 20,
    marginBottom: 20,
    '&:hover': {
      backgroundColor: '#FF9E00',
    },
  },
  requireNewPassowrdTitle: {
    color: theme.palette.common.white,
    fontSize: 25,
    marginBottom: 0,
  },
});
