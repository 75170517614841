import { formatToLocalDate } from '../../config/helpers';

export const params = {
  limit: 10,
  page: 1,
  search: '',
  direction: 'desc',
  sort: 'modified',
};

export const contain = [
  'CallComponent',
  'ServiceProvider',
  'DripFolder',
  'Drip',
  'EmailBodyComponent',
  'EmailSubjectComponent',
  'Flow',
  'FlowStepMap',
  'FlowStep',
  'FlowStepTransitionMap',
  'FlowStepTransition',
  'FriendlyFromComponent',
  'FromEmailComponent',
  'GenericPostComponent',
  'MessageType',
  'RvmComponent',
  'ServiceProvider',
  'SmsBodyComponent',
  'Tag',
  'WebhookAction',
  'FilterRule',
  'AbTest',
];

export const filters = [
  {
    field: 'account_id',
    operator: '=',
    path: 'account_id',
  },
];

export const columns = [
  { id: 'vertical_id', label: 'Vertical', minWidth: 150, align: 'left' },
  { id: 'model_name', label: 'Model', minWidth: 150, align: 'left' },
  {
    id: 'name',
    label: 'Name',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'note',
    label: 'Note preview',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'user_name',
    label: 'User',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'created',
    label: 'Date',
    minWidth: 150,
    align: 'left',
  },
];

const convertModelName = model_name =>
  model_name.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');

const createData = (
  vertical_id,
  model_name,
  name,
  note,
  user_name,
  created,
  item,
) => {
  return {
    vertical_id,
    model_name,
    name,
    note,
    user_name,
    created,
    item,
  };
};

const renderModelName = (item, model_name) => {
  let model = model_name
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
  if (item[model]) {
    return item[model].name;
  } else {
    return 'Deleted Item';
  }
};

const reduceNoteName = name => {
  let note = name;
  if (name.length > 39) {
    note = `${name.substring(0, 39)}...`;
  }
  return note;
};

export const getTheVerticalName = (list, id) => {
  let name = 'N/A';
  if (list && list.length > 0) {
    const vertical = list.find(item => +item.id === +id);
    if (vertical) {
      name = vertical.name;
    }
  }
  return name;
};

export const renderElements = (list, setRows, verticalList) => {
  let row = [];
  list &&
    list.forEach(item => {
      const { model_name, note, user_name, created, vertical_id } = item;
      row.push(
        createData(
          getTheVerticalName(verticalList, vertical_id),
          convertModelName(model_name),
          renderModelName(item, item.model_name),
          reduceNoteName(note),
          user_name,
          formatToLocalDate(created, 'MMM dd, yyyy'),
          item,
        ),
      );
    });
  setRows(row);
};

const getModelNamePath = model_name => {
  let path = '';
  switch (model_name) {
    case 'Drip':
      path = '/drips';
      break;
    case 'EmailBodyComponent':
      path = '/drip-components/email-html-body/edit/';
      break;
    case 'Flow':
      path = '/flows/flow/edit/';
      break;
    case 'FlowStep':
      path = '/flows/steps/edit/';
      break;
    case 'FlowStepTransition':
      path = '/flows/transitions/edit/';
      break;
    case 'RvmComponent':
      path = '/drip-components/rvm-list/edit/';
      break;
    case 'SmsBodyComponent':
      path = '/drip-components/sms-body-text/edit/';
      break;

    default:
      break;
  }
  return path;
};

const getDripPathByType = (message_type_id, path) => {
  let dripPath = '';
  switch (message_type_id) {
    case 1:
      dripPath = `${path}/sms-drip/edit/`;
      break;
    case 2:
      dripPath = `${path}/email-drip/edit/`;
      break;
    case 3:
      dripPath = `${path}/rvm-drip/edit/`;
      break;
    case 4:
      dripPath = `${path}/call-drip/edit/`;
      break;
    case 5:
      dripPath = `${path}/post-drip/edit/`;
      break;
    default:
      break;
  }
  return dripPath;
};

export const getItemLink = item => {
  let path = getModelNamePath(item.model_name);
  if (item.model_name === 'Drip') {
    if (item.drip && item.drip.message_type_id) {
      path = getDripPathByType(
        item.drip.message_type_id,
        getModelNamePath(item.model_name),
      );
    } else {
      path = null;
    }
  }
  if (path) {
    return path;
  } else {
    return null;
  }
};
