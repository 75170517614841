import queryString from 'query-string';
import { format } from 'date-fns';

export const consoleError = error => {
  if (process.env.REACT_APP_LOG_DEBUG) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const buildQuery = parameters => {
  let query = '';
  if (parameters) {
    const { id, params, showArchived, contain, filters } = parameters;
    query += id ? `/${id}?` : '?';
    if (params) {
      const { page, limit, direction, search, sort } = params;
      query += `&page=${page}&limit=${limit}&sort=${sort}&direction=${direction}&search=${search}`;
    }
    if (showArchived) query += `&showArchived=${showArchived}`;
    if (contain) {
      query +=
        '&' +
        queryString.stringify(
          // contain here should be an array of strings e.g. ['verticals', 'drips']
          { contain },
          { arrayFormat: 'comma' },
        );
    }
    if (filters) {
      // filters here should be as example below
      // const filters = {
      //   filters: [
      //     JSON.stringify({ field: 'account_id', operator: '=', value: '1' }),
      //     JSON.stringify({ field: 'vertical_id', operator: '=', value: '2' }),
      //   ],
      // };
      query +=
        '&' +
        queryString.stringify(
          { filters: filters },
          {
            arrayFormat: 'bracket',
          },
        );
    }
  }
  return query;
};

export const getModelTags = (model_tags, copy) => {
  if (model_tags && model_tags.length && copy) {
    return model_tags.map(model_tag => ({
      ...model_tag,
      id: '',
      model_id: '',
    }));
  }
  return model_tags || [];
};

export const getFilterRules = (filter_rules, copy) => {
  if (filter_rules && filter_rules.length && copy) {
    return filter_rules.map(filter_rule => ({
      ...filter_rule,
      id: '',
      model_id: '',
    }));
  }
  return filter_rules || [];
};

export const formatToLocalDate = (dateString, dateFormat) => {
  let convertedDate = dateString.replace('+00:00', '.000000Z');
  let date = new Date(convertedDate);
  return format(new Date(date.toString()), dateFormat);
};
