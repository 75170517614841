import { makeStyles } from '@material-ui/core/styles';
import SelectArrow from '../../assets/images/select-arrow-down.png';

const useStyles = makeStyles(theme => ({
  tagsContainer: {
    minWidth: 430,
    maxWidth: 750,
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: `1px solid #828282`,
    paddingBottom: 4,
    marginTop: 30,
  },
  addTagBtn: {
    backgroundColor: theme.palette.lightBlue[300],
    color: theme.palette.white[100],
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 14,
    width: 108,
    height: 35,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
    '&:disabled': {
      color: '#FFFFFF',
      boxShadow: 'none',
      backgroundColor: '#D8D8D8',
    },
  },
  tagsTitle: {
    fontSize: 16,
  },
  tagSelectContainer: {
    minWidth: 430,
    maxWidth: 750,
    width: '100%',
    margin: '8px 0',
  },
  tagsColumnTitle: {
    fontFamily: 'Lato',
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text[100],
    width: '50%',
  },
  tagsSelects: {
    width: '100%',
  },
  tagColumn: {
    width: '50%',
    paddingRight: 10,
    '& > div > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '98% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > div > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  tagsActionButton: {
    height: 35,
    width: 35,
    borderRadius: 2,
    color: theme.palette.white[100],
    position: 'relative',
    backgroundColor: '#D8D8D8',
    top: 5,
    cursor: 'pointer',
    transition: 'all .4s',
    padding: 2,
    '&:hover': {
      backgroundColor: '#b7b5b5',
    },
  },
  editTagButton: {
    backgroundColor: '#61A1F3',
    '&:hover': {
      backgroundColor: '#528cd3',
    },
  },
  deleteTagButton: {
    backgroundColor: '#D6595C',
    '&:hover': {
      backgroundColor: '#b54a4c',
    },
  },
  deleteTagButtonDisabled: {
    backgroundColor: '#D8D8D8',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#D8D8D8',
      color: '#FFFFFF',
    },
  },
}));

export default useStyles;
