import React from 'react';
import Button from '@material-ui/core/Button';
import SaveAlt from '@material-ui/icons/SaveAlt';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  saveAsDraft: {
    fontSize: 20,
    width: 229,
    borderRadius: 5,
    height: 60,
    '& > span > span > svg': {
      fontSize: '28px !important',
    },
    color: theme.palette.green[300],
    fontWeight: 900,
    border: `2px solid ${theme.palette.green[300]}`,
    '&:hover': {
      backgroundColor: theme.palette.green[100],
    },
  },
  marginRight: {
    marginRight: 27,
  },
}));

const CustomSaveAsDraft = props => {
  const classes = useStyles();
  return (
    <Button
      className={classNames(classes.saveAsDraft, {
        [classes.marginRight]: props.margin,
      })}
      variant="outlined"
      {...props}
      startIcon={<SaveAlt />}
    >
      Save As Draft
    </Button>
  );
};

export default CustomSaveAsDraft;
