import {
  GET_ITEM,
  GET_LIST,
  GET_LIST_COUNT,
  CREATE,
  EDIT,
  REMOVE,
  CHECK_NAME_UNIQUE,
  CLEAR_NEW_FLOW,
  CLEAR_NEW_FLOW_STEP_MAP,
  SAVE_PARAMS,
  SAVE_CONTAIN,
  SAVE_FILTERS,
  CLEAR_NAME_UNIQUE,
  SAVE_FSM_AND_FSTM,
  CLEAR,
  UPLOAD,
} from '../actionTypes/rest';
import { displayLoader, hideLoader } from './root';

export const getItem = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(displayLoader());
  }
  return GET_ITEM(type).actionCreators.start(type, payload);
};

// export const clearItem = () => ({ type: 'CLEAR_ITEM' });

export const saveItem = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return GET_ITEM(type).actionCreators.success(type, payload);
};

export const cancelItem = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return GET_ITEM(type).actionCreators.failure(type, payload);
};

export const getList = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(displayLoader());
  }
  return GET_LIST(type).actionCreators.start(type, payload);
};

export const saveList = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return GET_LIST(type).actionCreators.success(type, payload);
};

export const cancelList = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return GET_LIST(type).actionCreators.failure(type, payload);
};

export const getListCount = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(displayLoader());
  }
  return GET_LIST_COUNT(type).actionCreators.start(type, payload);
};

export const saveListCount = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return GET_LIST_COUNT(type).actionCreators.success(type, payload);
};

export const cancelListCount = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return GET_LIST_COUNT(type).actionCreators.failure(type, payload);
};

export const createStart = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(displayLoader());
  }
  return CREATE(type).actionCreators.start(type, payload);
};

export const createSave = (type, payload) =>
  CREATE(type).actionCreators.success(type, payload);

export const createCancel = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return CREATE(type).actionCreators.failure(type, payload);
};

export const editStart = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(displayLoader());
  }
  return EDIT(type).actionCreators.start(type, payload);
};

export const editSave = (type, payload) =>
  EDIT(type).actionCreators.success(type, payload);

export const editCancel = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return EDIT(type).actionCreators.failure(type, payload);
};

export const removeStart = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(displayLoader());
  }
  return REMOVE(type).actionCreators.start(type, payload);
};

export const removeSave = (type, payload) =>
  REMOVE(type).actionCreators.success(type, payload);

export const removeCancel = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(hideLoader());
  }
  return REMOVE(type).actionCreators.failure(type, payload);
};

export const checkNameUnique = (type, payload) => {
  return CHECK_NAME_UNIQUE(type).actionCreators.start(type, payload);
};

export const checkNameUniqueSuccess = (type, payload) => {
  return CHECK_NAME_UNIQUE(type).actionCreators.success(type, payload);
};

export const checkNameUniqueFailure = (type, payload) => {
  return CHECK_NAME_UNIQUE(type).actionCreators.failure(type, payload);
};

export const clearNameUnique = (type, payload) => {
  return CLEAR_NAME_UNIQUE(type).actionCreators.success(type, payload);
};

export const saveParams = (type, payload) => {
  return SAVE_PARAMS(type).actionCreators.success(type, payload);
};

export const saveContain = (type, payload) => {
  return SAVE_CONTAIN(type).actionCreators.success(type, payload);
};

export const saveFilters = (type, payload) => {
  return SAVE_FILTERS(type).actionCreators.success(type, payload);
};

export const clearNewFlow = () => {
  return {
    type: CLEAR_NEW_FLOW,
  };
};

export const clearNewFlowStepMap = () => {
  return {
    type: CLEAR_NEW_FLOW_STEP_MAP,
  };
};

export const saveFSMandFSTM = payload =>
  SAVE_FSM_AND_FSTM.actionCreators.start(false, payload);

export const clearList = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(displayLoader());
  }
  return CLEAR(type).actionCreators.success(type, payload);
};

export const uploadStart = (type, payload, dispatch) => {
  if (dispatch) {
    dispatch(displayLoader());
  }
  return UPLOAD(type).actionCreators.start(type, payload);
};
