import { createOperation } from '../../../config/createOperation';

export const LOGOUT = 'LOGOUT';
export const AUTH_FULFILLED = 'AUTH_FULFILLED';
export const AUTH_REJECTED = 'AUTH_REJECTED';
export const SET_ACTIVE_VERTICAL = createOperation('SET_ACTIVE_VERTICAL');
export const SET_ACTIVE_ACCOUNT = createOperation('SET_ACTIVE_ACCOUNT');
export const GET_USER_ACCOUNTS = createOperation('GET_USER_ACCOUNTS');
export const GET_ACCOUNT_VERTICALS = createOperation('GET_ACCOUNT_VERTICALS');
export const SHOW_VERTICAL_LOADER = 'SHOW_VERTICAL_LOADER';
export const HIDE_VERTICAL_LOADER = 'HIDE_VERTICAL_LOADER';
