import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  singleFlowReportingContainer: {
    display: 'block',
    width: '100%',
    background: theme.palette.grey[100],
    transition: 'all .4s',
    padding: '20px 11px 0 0',
  },
  dripRowContainer: {
    position: 'relative',
    paddingLeft: 55,
  },
  dripTable: {
    width: '100%',
    border: '1px solid #828282',
    color: '#4F4F4F',
  },
  headers: {
    display: 'flex',
    alignItems: 'center',
  },
  dripData: {
    display: 'flex',
    alignItems: 'center',
  },
  headerCellName: {
    height: 42,
    color: '#036BF0',
    transition: 'all .7s',
    cursor: 'pointer',
    paddingLeft: 16,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  headerCell: {
    height: 42,
    textAlign: 'center',
  },
  dripCell: {
    height: '100%',
    minHeight: 52,
    fontSize: 18,
    'word-break': 'break-all',
    textAlign: 'center',
    borderLeft: '1px solid #828282',
  },
  dripCellName: {
    height: '100%',
    minHeight: 52,
    fontSize: 18,
    color: '#036BF0',
    transition: 'all .7s',
    cursor: 'pointer',
    paddingLeft: 16,
    wordBreak: 'break-all',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  deletedSteps: {
    backgroundColor: '#DADADA',
    paddingBottom: 0,
  },
  deletedStepsOpen: {
    paddingBottom: 20,
  },
  deletedStepsLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 16,
    padding: '5px 14px',
    marginBottom: 0,
    backgroundColor: '#C4C4C4',
  },
  deletedStepsLabelOpen: {
    marginBottom: 20,
  },
  deletedStepsLabelText: {
    fontSize: 12,
    fontWeight: 900,
    color: theme.palette.text[100],
  },
  deletedShow: {
    textAlign: 'center',
    fontSize: 12,
    color: '#036BF0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      fontSize: 18,
    },
  },
  showMoreArrow: {
    transition: ' all .4s',
    transform: 'rotate(0deg)',
  },
  showMoreArrowActive: {
    transform: 'rotate(180deg)',
  },
  noStepsFound: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
