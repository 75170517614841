import React, { useState } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/loader';
import Table from './uploadsTable';
import Search from '../../../components/search';
import UploadsIcon from '../../../assets/images/upload_icon.svg';

const Uploads = () => {
  const classes = useStyles();
  const history = useHistory();
  const loader = useSelector(state => state.app.loader);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const [search, setSearch] = useState('');

  const handleNewButton = () => {
    history.push({
      pathname: `/flows/uploads/add`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} container alignItems="flex-end">
        <Grid item xs={1} className={classes.iconContainer}>
          <img
            src={UploadsIcon}
            alt="Upload icon"
            className={classes.titleIcon}
          />
        </Grid>
        <Grid container item xs={10} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Upload
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={6}>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item className={classes.addButton}>
            <Button onClick={handleNewButton} startIcon={<AddIcon />}>
              New Upload
            </Button>
          </Grid>
          <Grid item>
            <Search type="Uploads" setSearch={setSearch} classes={classes} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <Grid item xs={12}>
          <Table
            type="uploads"
            search={search}
            classes={classes}
            model_name="Upload"
          />
        </Grid>
        {Boolean(loader) && <Loader open={Boolean(loader)} />}
      </Grid>
    </Grid>
  );
};

export default withTitle(withDashboard(Uploads, false), 'Uploads');
