import { combineReducers } from 'redux';
import rootReducer from './reducer';
import authReducer from './auth';
import restReducer from './rest';
import reportingReducer from './reporting';
import changeLogsReducer from './changeLogs';
import { generateRestReducers, generateReportingReducers } from '../config';

const reducer = combineReducers({
  app: rootReducer,
  auth: authReducer,
  changeLogs: changeLogsReducer,
  ...generateReportingReducers(reportingReducer),
  ...generateRestReducers(restReducer),
});

export default reducer;
