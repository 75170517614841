import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const validate = values => {
  if (!values) {
    return 'Required';
  }
};

const PostTypes = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
  disabled,
  customHandleChange,
}) => {
  return (
    <div className={wrapperClass}>
      {hideLabel ? null : (
        <label htmlFor="post_type" name="post_type">
          {labelText || 'Post Type'}
        </label>
      )}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'post_type'] && touched[name || 'post_type']
            ? errorClass || 'has-error'
            : ''
        }`}
        htmlFor="post_type"
        placeholder={placeholder || 'Post Type'}
        component="select"
        name={name || 'post_type'}
        validate={validate}
        onChange={e => {
          customHandleChange && customHandleChange(e);
          handleChange(e);
        }}
        disabled={disabled}
      >
        <option key={'empty'} value="">
          Select
        </option>
        <option data-testid="post_type_json" value="get">
          GET
        </option>
        <option data-testid="post_type_form_data" value="post">
          POST
        </option>
      </Field>
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'post_type'}
          component="div"
          className="field-error"
          data-testid="post_type-error"
        />
      )}
    </div>
  );
};

export default PostTypes;

PostTypes.propTypes = {
  hideLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  wrapperClass: PropTypes.string,
  disabled: PropTypes.bool,
  customHandleChange: PropTypes.func,
};
