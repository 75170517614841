import Table from '../../../hoc/withTable/table';
import withTable from '../../../hoc/withTable/withTable';
import { columns, renderElements, sortBy, filters } from './config';

export default withTable(Table, {
  columns,
  renderElements,
  sortBy,
  filters,
});
