import React, { useState, Suspense, lazy, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CustomSelect from '../../../../components/form/customSelect';
import Loader from '../../../../components/loader';
import { getTheFlowName, filterArraysForUniqueElements } from './helper';
import PropTypes from 'prop-types';

const TransitionRow = ({
  classes,
  formik,
  first,
  stepIndex,
  index,
  transitionRow,
  handleAddBellowTransition,
  flowStepTransitionList,
  handleChangeTransition,
  flowsList,
  handleDelete,
  loader,
  setCurrentStepTransitionRowId,
  setCurrentStepRowId,
  handleDeleteEmptyTransition,
  isFlowLive,
  steps,
  unselectedTransition,
  allUsedTransitions,
}) => {
  const transitionRef = useRef();
  const { transition } = transitionRow;
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const DeleteConfirmationDialog = lazy(() =>
    import('../../../../components/deleteConfirmationModal'),
  );
  const [currentHeight, setCurrentHeight] = useState(0);
  useEffect(() => {
    if (transitionRef && transitionRef.current) {
      setCurrentHeight(transitionRef.current.clientHeight);
    }
  }, [transitionRef]);
  const [avaliableTransitions, setAvaliableTransitions] = useState(null);

  // filter unique flow step transitions dropdown options
  useEffect(() => {
    if (flowStepTransitionList && flowStepTransitionList.length > 0) {
      let currentStep = steps[stepIndex];
      if (
        currentStep &&
        currentStep.transitions &&
        currentStep.transitions.length > 0
      ) {
        let newArray = filterArraysForUniqueElements(
          flowStepTransitionList,
          currentStep.transitions,
        );
        if (transition && transition.id) {
          let usedTransitions = flowStepTransitionList.find(
            trans => trans.id === transition.id,
          );
          setAvaliableTransitions([...newArray, usedTransitions]);
        } else {
          setAvaliableTransitions([...newArray]);
        }
      } else {
        setAvaliableTransitions(flowStepTransitionList);
      }
    }
  }, [stepIndex, steps, flowStepTransitionList, transition, allUsedTransitions]);

  const handleDeleteModalClose = () => {
    setCurrentStepTransitionRowId(null);
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteModalOpen = (stepIndex, index) => () => {
    setCurrentStepTransitionRowId(index);
    setCurrentStepRowId(stepIndex);
    setIsDeleteConfirmationModalOpen(true);
  };

  const renderDeleteConfirmationDialog = isDeleteConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <DeleteConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        handleDelete={handleDelete}
        handleClose={handleDeleteModalClose}
        itemName={'flow step transition'}
        model_id={(transition && transition.id) || ''}
        model_name="FlowStepTransitionMap"
      />
    </Suspense>
  ) : null;

  const renderDeleteButton =
    transition && transition.id ? (
      <Button
        color="default"
        className={classNames(
          classes.flowStepButton,
          classes.transitionButton,
          classes.deleteColor,
          { [classes.transitionButtonMarginTop]: !first },
          { [classes.disabledColor]: isFlowLive },
        )}
        onClick={handleDeleteModalOpen(stepIndex, index)}
        disabled={isFlowLive}
      >
        <DeleteIcon />
      </Button>
    ) : (
      <Button
        color="default"
        className={classNames(
          classes.flowStepButton,
          classes.transitionButton,
          classes.deleteColor,
          { [classes.transitionButtonMarginTop]: !first },
          { [classes.disabledColor]: isFlowLive },
        )}
        onClick={handleDeleteEmptyTransition(stepIndex, index)}
        disabled={isFlowLive}
      >
        <DeleteIcon />
      </Button>
    );

  return (
    <Grid
      item
      xs={12}
      container
      ref={transitionRef}
      className={classNames(classes.singleFlowStepTransition, {
        [classes.singleFlowStepTransitionPaddingTop]: !first,
      })}
    >
      <Grid item className={classes.transitionName}>
        {first && <div className="label">Transition Name</div>}
        <div className="value">
          <CustomSelect
            {...formik}
            placeholder="Form Step Transition"
            labelText=" "
            name={`steps[${stepIndex}].transitions[${index}].id`}
            wrapperClass={classNames(
              classes.textField,
              classes.transitionTextField,
            )}
            disableDefault
            disabled={isFlowLive}
            options={avaliableTransitions}
            customHandleChange={event =>
              handleChangeTransition(event, index, stepIndex)
            }
          />
        </div>
      </Grid>
      <Grid item>
        <Button
          color="default"
          className={classNames(
            classes.flowStepButton,
            classes.transitionButton,
            classes.addColor,
            { [classes.transitionButtonMarginTop]: !first },
            { [classes.disabledColor]: isFlowLive || unselectedTransition },
          )}
          onClick={handleAddBellowTransition(stepIndex, index)}
          disabled={isFlowLive || unselectedTransition}
        >
          <AddIcon />
        </Button>
      </Grid>
      <Grid item>{renderDeleteButton}</Grid>
      {transition &&
        transition.to_flow_id &&
        flowsList &&
        flowsList.length > 0 && (
          <Grid item className={classes.toFlow}>
            To Flow: {getTheFlowName(transition.to_flow_id, flowsList)}
          </Grid>
        )}
      {transition && transition.id && renderDeleteConfirmationDialog}
      {isFlowLive && (
        <div
          style={{ height: first ? currentHeight : currentHeight + 1 }}
          className={classes.transitionReportBox}
        >
          <div
            className={classNames(classes.reportTransitionNumber, {
              [classes.reportTransitionNumberFirst]: first,
            })}
          >
            0
          </div>
        </div>
      )}
    </Grid>
  );
};

export default TransitionRow;

TransitionRow.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  first: PropTypes.bool.isRequired,
  stepIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  transitionRow: PropTypes.object.isRequired,
  handleAddBellowTransition: PropTypes.func.isRequired,
  flowStepTransitionList: PropTypes.array,
  handleChangeTransition: PropTypes.func.isRequired,
  flowsList: PropTypes.array,
  handleDelete: PropTypes.func.isRequired,
  loader: PropTypes.number.isRequired,
  setCurrentStepTransitionRowId: PropTypes.func.isRequired,
  setCurrentStepRowId: PropTypes.func.isRequired,
  handleDeleteEmptyTransition: PropTypes.func.isRequired,
  isFlowLive: PropTypes.bool.isRequired,
  unselectedTransition: PropTypes.bool.isRequired,
};
