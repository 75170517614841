import React, { useState, useEffect, lazy, Suspense } from 'react';
import useStyles from './styles';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import Pagination from '../../components/pagination/paginationWrapper';
import Loader from '../../components/loader';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { getItemLink } from './config';

const CustomNotesTable = ({
  list,
  renderElements,
  columns,
  handleClickName,
  itemSortBy,
  setRowsPerPage,
  rowsPerPage,
  sortBy,
  setSortBy,
  setSortedElement,
  sortedElement,
  setPage,
  page,
  totalCount,
  totalPages,
  setIsUserChanged,
  setElementsPerPage,
  elementsPerPage,
  verticalsList,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallTable = useMediaQuery(theme.breakpoints.down(1200));
  const loader = useSelector(state => state.app.loader);
  const count = useSelector(state => state.notes.count);
  const [jumpToPage, setJumpToPage] = useState('');
  const [rows, setRows] = useState([]);
  const [clickedNote, setClickedNote] = useState(null);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const NoteModal = lazy(() => import('./noteModal'));

  useEffect(() => {
    if (list && list.length > 0 && verticalsList && verticalsList.length > 0) {
      renderElements(list, setRows, verticalsList);
    } else {
      setRows([]);
    }
  }, [list, renderElements, verticalsList]);

  const handleClickViewMore = () => {
    let elementsNumber = rowsPerPage + 10 < count ? rowsPerPage + 10 : count;
    setRowsPerPage(elementsNumber);
    setElementsPerPage(elementsNumber);
    setIsUserChanged(true);
    setPage(1);
  };

  const onChangePagination = pageNumber => {
    setPage(pageNumber);
  };

  const handleChangeDirectlyPage = event => {
    if (Number.isInteger(+event.target.value)) {
      setElementsPerPage(event.target.value);
    }
  };

  const handleElementsPerPage = event => {
    if (event.key === 'Enter') {
      if (+event.target.value === 0) {
        setRowsPerPage(10);
        setElementsPerPage(10);
        setIsUserChanged(true);
        setPage(1);
        setJumpToPage('');
      } else {
        setRowsPerPage(event.target.value);
        setElementsPerPage(event.target.value);
        setIsUserChanged(true);
        setPage(1);
        setJumpToPage('');
      }
    }
  };

  const handlePerPageArrow = data => () => {
    if (data === 'less') {
      if (+elementsPerPage === 1) {
        setElementsPerPage(+elementsPerPage);
        setRowsPerPage(+rowsPerPage);
      } else {
        setElementsPerPage(+elementsPerPage - 1);
        setRowsPerPage(+rowsPerPage - 1);
      }
    } else if (data === 'more') {
      setElementsPerPage(+elementsPerPage + 1);
      setRowsPerPage(+rowsPerPage + 1);
    }
  };

  const handleJumpToPageInput = event => {
    if (Number.isInteger(+event.target.value)) {
      if (+event.target.value <= totalPages) {
        setJumpToPage(event.target.value);
      }
    }
    if (event.key === 'Enter') {
      setPage(+event.target.value);
    }
  };

  const handleJumpToPageButton = () => {
    setPage(+jumpToPage);
  };

  const handleSortBy = name => () => {
    if (sortBy[name] === 'asc') {
      setSortBy({
        ...itemSortBy,
        [name]: 'desc',
      });
      setSortedElement(name);
    } else {
      setSortBy({
        ...itemSortBy,
        [name]: 'asc',
      });
      setSortedElement(name);
    }
  };

  const renderNoDataSection = () => {
    if (!list || list.length === 0) {
      return (
        <TableRow className={classes.tableRow}>
          <td className={classes.noData}>No Items</td>
        </TableRow>
      );
    }
  };

  // eslint-disable-next-line
  const renderPaginaton = () => {
    if (list || (list && list.length > 0)) {
      return (
        totalCount !== 0 && (
          <Pagination
            classes={classes}
            handlePerPageArrow={handlePerPageArrow}
            elementsPerPage={elementsPerPage}
            handleElementsPerPage={handleElementsPerPage}
            handleChangeDirectlyPage={handleChangeDirectlyPage}
            page={page}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            onChangePagination={onChangePagination}
            jumpToPage={jumpToPage}
            handleJumpToPageInput={handleJumpToPageInput}
            handleJumpToPageButton={handleJumpToPageButton}
          />
        )
      );
    }
  };

  const handleOpenNoteModal = () => {
    setIsNoteModalOpen(true);
  };

  const handleCloseNoteModal = () => {
    setIsNoteModalOpen(false);
    setClickedNote(null);
  };

  const renderNotesModal = isNoteModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <NoteModal
        open={isNoteModalOpen}
        handleClose={handleCloseNoteModal}
        item={clickedNote}
      />
    </Suspense>
  ) : null;

  const handleClickNote = note => () => {
    setClickedNote(note);
    handleOpenNoteModal();
  };

  const renderColumn = (id, row, value) => {
    if (id === 'name') {
      const path = getItemLink(row.item);
      const renderLinkClassName =
        path && value !== 'Deleted Item'
          ? classes.tableCellLink
          : classes.tableCell;
      if (value === 'Deleted Item') {
        return <span className={classes.userValue}>{value}</span>;
      } else {
        return (
          <span
            className={renderLinkClassName}
            onClick={handleClickName(row.item)}
          >
            <span className={classes.userValue}>{value}</span>
          </span>
        );
      }
    } else if (id === 'note') {
      if (value.length > 18) {
        return (
          <span>
            {value}
            <span
              className={classes.seeMore}
              onClick={handleClickNote(row.item)}
            >
              See More
            </span>
          </span>
        );
      } else {
        return value;
      }
    } else return <span className={classes.userValue}>{value}</span>;
  };

  const renderHeaderClassName = active =>
    active ? classes.tableCellHeaderActive : classes.tableCellHeader;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.tableContent}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
            size={smallTable ? 'small' : 'medium'}
          >
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className={renderHeaderClassName(
                      sortedElement === column.id,
                    )}
                    sortDirection={sortBy[sortedElement]}
                  >
                    <TableSortLabel
                      onClick={handleSortBy(column.id)}
                      active={sortedElement === column.id}
                      className={classes.tableSortLable}
                      direction={
                        sortedElement === column.id
                          ? sortBy[sortedElement]
                          : 'asc'
                      }
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {rows &&
                rows.map(row => {
                  return (
                    <TableRow
                      role="checkbox"
                      tabIndex={-1}
                      key={row.item.id}
                      className={classes.tableRow}
                    >
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={Math.floor(Math.random() * 123132)}
                            align={column.align}
                            className={classNames(classes.tableCell, {
                              [classes.tableNameCell]: column.id === 'name',
                            })}
                          >
                            {renderColumn(column.id, row, value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {renderNoDataSection()}
            </TableBody>
          </Table>
        </TableContainer>
        {renderNotesModal}
      </Grid>
      <Grid item xs={12} className={classes.pagination}>
        {renderPaginaton()}
      </Grid>
      {list && list.length > 0 && count > 10 && (
        <Grid item xs={12} className={classes.viewMore}>
          <span onClick={handleClickViewMore}>View more</span>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomNotesTable;

CustomNotesTable.propTypes = {
  list: PropTypes.any,
  renderElements: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  handleClickName: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  itemSortBy: PropTypes.object.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  sortBy: PropTypes.object.isRequired,
  setSortBy: PropTypes.func.isRequired,
  setSortedElement: PropTypes.func.isRequired,
  sortedElement: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setIsUserChanged: PropTypes.func.isRequired,
  setElementsPerPage: PropTypes.func.isRequired,
};
