import React from 'react';
import AppRoutes from './appRoutes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import configureStore, { epicMiddleware } from './store/store';
import rootEpic from './store/epics/rootEpic';

const App = props => {
  if (props.authState !== 'signedIn') {
    return null;
  } else {
    const store = configureStore();
    epicMiddleware.run(rootEpic);

    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <AppRoutes authData={props.authData} />
          </BrowserRouter>
        </MuiThemeProvider>
      </Provider>
    );
  }
};

export default App;
