import { formatToLocalDate } from '../../../config/helpers';
import { getModelTags, getFilterRules } from '../../../config/helpers';

export const contain = ['model_tags.Tags', 'filter_rules', 'drips'];

export const filters = [
  {
    field: 'vertical_id',
    operator: '=',
    path: 'vertical_id',
  },
  {
    field: 'account_id',
    operator: '=',
    path: 'account_id',
  },
];

export const dripFilters = [
  {
    field: 'vertical_id',
    operator: '=',
    path: 'vertical_id',
  },
];

export const delayTypeList = [
  //   {
  //     id: 1,
  //     name: 'Second',
  //     short: 'sec',
  //   },
  {
    id: 2,
    name: 'Minute',
    short: 'min',
  },
  {
    id: 3,
    name: 'Hour',
    short: 'hour',
  },
  {
    id: 4,
    name: 'Day',
    short: 'day',
  },
  {
    id: 5,
    name: 'Week',
    short: 'week',
  },
  //   {
  //     id: 6,
  //     name: 'Month',
  //     short: 'month',
  //   },
];

export const scheduleTypeList = [
  {
    id: 1,
    name: 'Normal',
  },
  {
    id: 2,
    name: `Skip`,
  },
  {
    id: 3,
    name: 'Reschedule',
  },
];

export const columns = [
  { id: 'name', label: 'Name', minWidth: 150, align: 'left' },
  { id: 'drip_id', label: 'Drip', minWidth: 120, align: 'left' },
  { id: 'is_async', label: 'Async', minWidth: 60, align: 'left' },
  { id: 'delay_in_time', label: 'Delay', minWidth: 60, align: 'left' },
  {
    id: 'schedule_type',
    label: 'Shedule',
    minWidth: 60,
    align: 'left',
  },
  {
    id: 'created',
    label: 'Created',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'modified',
    label: 'Modified',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 150,
    align: 'center',
  },
];

export const getDripUrl = type => {
  if (type === 'send_sms') {
    return `sms-drip`;
  } else if (type === 'send_call') {
    return `call-drip`;
  } else if (type === 'send_email') {
    return `email-drip`;
  } else if (type === 'send_rvm') {
    return `rvm-drip`;
  } else if (type === 'send_post') {
    return `post-drip`;
  }
};

export const generateDelayValueList = () => {
  const totalNumbers = 61;
  let value = [...Array(totalNumbers).keys()].map(number => ({
    id: number,
    name: `${number}`,
  }));
  return value;
};

const createData = (
  name,
  drip_id,
  is_async,
  delay_in_time,
  schedule_type,
  created,
  modified,
  actions,
  item,
) => {
  return {
    name,
    drip_id,
    is_async,
    delay_in_time,
    schedule_type,
    created,
    modified,
    actions,
    item,
  };
};

export const findDelayType = (id, time) => {
  let delayType = delayTypeList.find(delayType => delayType.id === id);
  return time > 1 ? `${delayType.short}s` : delayType.short;
};

const getScheduleTypeName = id => {
  let scheduleType = scheduleTypeList.find(
    scheduleType => scheduleType.id === id,
  );
  return scheduleType.name;
};

export const renderElements = (list, setRows) => {
  let row = [];
  list &&
    list.forEach(item => {
      const {
        name,
        is_async,
        delay_in_time,
        delay_type,
        schedule_type,
        created,
        modified,
      } = item;
      const dripName = item.drip ? item.drip.name : '';
      !item.archived &&
        row.push(
          createData(
            name,
            dripName,
            is_async ? 'True' : 'False',
            `${delay_in_time} ${findDelayType(delay_type, delay_in_time)}`,
            getScheduleTypeName(schedule_type),
            formatToLocalDate(created, 'MMM, dd yyyy'),
            formatToLocalDate(modified, 'MMM, dd yyyy'),
            '',
            item,
          ),
        );
    });
  setRows(row);
};

export const sortBy = {
  name: 'asc',
  drip_id: 'asc',
  is_async: 'asc',
  delay_in_time: 'asc',
  schedule_type: 'asc',
  created: 'asc',
  modified: 'desc',
};

export const initialValues = () => ({
  id: '',
  name: '',
  drip_id: '',
  is_async: false,
  delay_in_time: '',
  delay_type: '',
  drip_type: '',
  schedule_type: '1',
  model_tags: [],
  filter_rules: [],
  notes: [],
});

export const populateValues = (values, copy) => {
  const {
    id,
    name,
    drip_id,
    is_async,
    delay_in_time,
    delay_type,
    schedule_type,
    model_tags,
    filter_rules,
    drip_type,
    notes,
  } = values;
  return {
    id: copy ? '' : id,
    name: copy ? `Copy of ${name}` : name,
    drip_id,
    is_async: is_async ? true : false,
    delay_in_time,
    delay_type,
    drip_type,
    schedule_type,
    model_tags: getModelTags(model_tags, copy),
    filter_rules: getFilterRules(filter_rules, copy),
    notes: notes || [],
  };
};

export const params = {
  direction: 'asc',
  sort: 'name',
  page: 1,
  limit: 500,
  search: '',
};
