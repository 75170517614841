import React, { useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';
import { ToggleButtonGroup } from 'formik-material-ui-lab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import PropTypes from 'prop-types';
import StopIcon from '@material-ui/icons/Stop';

const Status = ({
  name,
  className,
  errorClass,
  noErrorMessage,
  errors,
  touched,
  classes,
  disabled,
  isRequired,
  handleChange,
  handleFlowStatus,
  values,
  setFormikState,
}) => {
  const validate = values => {
    if (!values) {
      return 'Required';
    }
  };

  useEffect(() => {
    setFormikState(values);
  }, [values, setFormikState]);

  return (
    <div className={classes.toggleGroup}>
      <Field
        component={ToggleButtonGroup}
        name="status"
        type="checkbox"
        validate={isRequired ? validate : null}
        exclusive
        className={`${className || 'field'} ${
          errors[name || 'status'] && touched[name || 'status']
            ? errorClass || 'has-error'
            : ''
        }`}
      >
        <ToggleButton
          value={1}
          aria-label="left aligned"
          className={classes.draftButton}
          disabled={disabled}
        >
          <PlayArrowIcon /> <span>Draft</span>
        </ToggleButton>
        <ToggleButton
          value={2}
          aria-label="left aligned"
          className={classes.statusButton}
          disabled={disabled}
          onClick={handleFlowStatus(2)}
        >
          <PlayArrowIcon /> <span>Live</span>
        </ToggleButton>
        <ToggleButton
          value={3}
          aria-label="centered"
          className={classes.statusButton}
          disabled={disabled}
          onClick={handleFlowStatus(3)}
        >
          <PauseIcon /> <span>Pause</span>
        </ToggleButton>
        <ToggleButton
          value={4}
          aria-label="centered"
          className={classes.statusButton}
          disabled={disabled}
          onClick={handleFlowStatus(4)}
        >
          <StopIcon /> <span>Stop</span>
        </ToggleButton>
      </Field>
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'status'}
          component="div"
          className="field-error"
          data-testid="name-error"
        />
      )}
    </div>
  );
};

export default Status;

Status.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};
