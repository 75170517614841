import { post } from '../../../config/api';
import { switchMap, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { SEND_TEST_SMS, SEND_TEST_EMAIL } from '../../actions/actionTypes/test';
import { displaySnackbar, hideLoader } from '../../actions/actions/root';
import { of, merge } from 'rxjs';
import { consoleError } from '../../../config/helpers';
import { getAuth, authRejected } from '../../actions/actions/auth';
import { handleErrorMessage } from './auth';

const auth = action$ => {
  return action$.pipe(
    mergeMap(action => of(getAuth(action))),
    mergeMap(({ payload }) =>
      payload.auth
        .then(data => ({
          token: data.signInUserSession.accessToken.jwtToken,
          params: payload.data.payload,
        }))
        .catch(() => window.location.reload()),
    ),
    catchError(error => {
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          authRejected(error),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
      );
    }),
  );
};

export const sendTestSmsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SEND_TEST_SMS.actionTypes.START),
    action$ => auth(action$),
    switchMap(({ params, token }) =>
      post(
        'send-test-sms',
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        JSON.stringify(params),
      ).pipe(
        mergeMap(({ response }) => {
          return [
            displaySnackbar({
              type: 'success',
              message: `Successfully sent SMS`,
            }),
            hideLoader(),
          ];
        }),
        takeUntil(action$.pipe(ofType(SEND_TEST_SMS.actionTypes.FAILURE))),
      ),
    ),
    catchError((error, caught) => {
      consoleError(error);
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
        caught,
      );
    }),
  );
};

export const sendTestEmailEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SEND_TEST_EMAIL.actionTypes.START),
    action$ => auth(action$),
    switchMap(({ params, token }) =>
      post(
        'send-test-email',
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        JSON.stringify(params),
      ).pipe(
        mergeMap(({ response }) => {
          return [
            displaySnackbar({
              type: 'success',
              message: `Successfully sent email`,
            }),
            hideLoader(),
          ];
        }),
        takeUntil(action$.pipe(ofType(SEND_TEST_EMAIL.actionTypes.FAILURE))),
      ),
    ),
    catchError((error, caught) => {
      consoleError(error);
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
        caught,
      );
    }),
  );
};
