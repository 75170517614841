import { ajax } from 'rxjs/ajax';
import { buildQuery } from './helpers';

const baseURL = process.env.REACT_APP_API_URL;

export const get = (url, headers, params) => {
  const query = buildQuery(params);
  const path = `${baseURL}${url}${query}`;
  return ajax({
    url: path,
    method: 'GET',
    headers,
  });
};

export const post = (url, headers, data, params) => {
  const query = buildQuery(params);
  const path = `${baseURL}${url}${query}`;
  return ajax({
    url: path,
    method: 'POST',
    headers,
    body: data,
  });
};

export const patch = (url, headers, data) => {
  const path = `${baseURL}${url}`;
  return ajax({
    url: path,
    method: 'PATCH',
    headers,
    body: data,
  });
};

export const remove = (url, headers, data) => {
  const path = `${baseURL}${url}`;
  return ajax({
    url: path,
    method: 'DELETE',
    headers,
    body: data,
  });
};
