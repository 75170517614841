import React from 'react';
import withDashboard from '../../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../../hoc/withTitle';
import CallDripForm from './callDripForm';
import * as config from './config';
import SingleDrip from '../singleDrip/singleDrip';
import PropTypes from 'prop-types';

const CallDrip = ({ location }) => {
  return (
    <SingleDrip
      name="Call Drip"
      urlPath="call-drip"
      messageType="send_call"
      childComponents={['call-components']}
      location={location}
      config={config}
      Form={CallDripForm}
    />
  );
};

export default withTitle(withDashboard(CallDrip, false), 'Call Drip');

CallDrip.propTypes = {
  location: PropTypes.object.isRequired,
};
