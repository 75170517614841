import React from 'react';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import { useHistory } from 'react-router-dom';
import Table from './rvmListTable';
import DripComponent from '../dripComponent/dripComponent';
import { initialValues, populateValues } from './config';
import { useSelector } from 'react-redux';

const RvmList = () => {
  const history = useHistory();
  const vertical_id = useSelector(state => state.auth.vertical_id);

  const handleClickRvm = item => () => {
    history.push({
      pathname: `/drip-components/rvm-list/edit/${item.id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleNewButton = () => {
    history.push({
      pathname: '/drip-components/rvm-list/add',
      search: `vertical_id=${vertical_id}`,
    });
  };

  return (
    <DripComponent
      title="RVM List"
      item="RVM List"
      urlPath="rvm-components"
      model_name=""
      fields={[]}
      Table={Table}
      populateValues={populateValues}
      initialValues={initialValues}
      modal={false}
      customHandleNewButton={handleNewButton}
      customHandleClickItem={handleClickRvm}
    />
  );
};

export default withTitle(withDashboard(RvmList, false), 'RVM List');
