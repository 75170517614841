import React, { useState, Suspense, lazy, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CustomSelect from '../../../../components/form/customSelect';
import { findDelayType } from '../../steps/config';
import Loader from '../../../../components/loader';
import PropTypes from 'prop-types';
import { filterArraysForUniqueElements } from './helper';

const StepRow = ({
  classes,
  formik,
  handleDeleteStep,
  index,
  handleAddStepBellow,
  handleAddTransition,
  flowStepsList,
  handleChangeSteps,
  steps,
  setCurrentStepRowId,
  handleDeleteEmptyStep,
  loader,
  handleClickStepReporting,
  stepRow,
  stepsReporting,
  isFlowLive,
  unselectedTransition,
}) => {
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const DeleteConfirmationDialog = lazy(() =>
    import('../../../../components/deleteConfirmationModal'),
  );
  const [avaliableSteps, setAvaliableSteps] = useState(null);

  // filter unique flow steps dropdown options
  useEffect(() => {
    if (flowStepsList && flowStepsList.length > 0) {
      if (steps && steps.length > 0) {
        let newArray = filterArraysForUniqueElements(flowStepsList, steps);
        if (stepRow.id) {
          let currentStep = flowStepsList.find(step => step.id === stepRow.id);
          newArray.push(currentStep);
        }
        setAvaliableSteps(newArray);
      } else {
        setAvaliableSteps(flowStepsList);
      }
    }
  }, [flowStepsList, stepRow, steps]);

  const handleDeleteModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteModalOpen = index => () => {
    setCurrentStepRowId(index);
    setIsDeleteConfirmationModalOpen(true);
  };

  const renderDeleteConfirmationDialog = isDeleteConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <DeleteConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        handleDelete={handleDeleteStep}
        handleClose={handleDeleteModalClose}
        itemName={'flow step'}
        model_id={
          (steps &&
            steps[index] &&
            steps[index].step &&
            steps[index].step.id) ||
          ''
        }
        model_name="FlowStepMap"
      />
    </Suspense>
  ) : null;

  const renderDeleteButton =
    steps && steps[index] && steps[index].step && steps[index].step.id ? (
      <Button
        color="default"
        variant="contained"
        className={classNames(classes.flowStepButton, classes.delete)}
        onClick={handleDeleteModalOpen(index)}
        disabled={isFlowLive}
      >
        <DeleteIcon />
      </Button>
    ) : (
      <Button
        color="default"
        variant="contained"
        className={classNames(classes.flowStepButton, classes.delete)}
        onClick={handleDeleteEmptyStep(index)}
        disabled={isFlowLive}
      >
        <DeleteIcon />
      </Button>
    );

  return (
    <Grid item xs={12} container className={classes.singleFlowStep}>
      <Grid item className={classes.order}>
        <div className={classes.label}>order</div>
        <div className="value">{index + 1}</div>
      </Grid>
      <Grid item className={classes.stepName}>
        <div className="label">Step Name</div>
        <div className="value">
          <CustomSelect
            {...formik}
            placeholder="Form Step"
            labelText=" "
            name={`steps[${index}].id`}
            wrapperClass={classes.textField}
            disableDefault
            disabled={isFlowLive}
            options={avaliableSteps}
            customHandleChange={event => handleChangeSteps(event, index)}
          />
        </div>
      </Grid>
      <Grid item>
        <div className={classes.label}>Actions</div>
        <Button
          color="default"
          variant="contained"
          className={classNames(
            classes.flowStepButton,
            classes.flowStepButtonAdd,
            classes.add,
          )}
          disabled={isFlowLive}
          onClick={handleAddStepBellow(index)}
        >
          <AddIcon />
        </Button>
      </Grid>
      <Grid item>{renderDeleteButton}</Grid>
      <Grid item>
        {
          <Button
            color="default"
            variant="contained"
            startIcon={<AddIcon />}
            className={classes.addTransition}
            onClick={handleAddTransition(index)}
            disabled={isFlowLive || unselectedTransition}
          >
            Transition
          </Button>
        }
      </Grid>
      {steps[index].step && (
        <Grid item xs={12} className={classes.delay}>
          Delay: {steps[index].step.delay_in_time}{' '}
          {findDelayType(
            steps[index].step.delay_type,
            steps[index].step.delay_in_time,
          )}
        </Grid>
      )}
      {steps[index].step && renderDeleteConfirmationDialog}
      {isFlowLive && stepsReporting && stepRow && (
        <div
          className={classNames(classes.stepReportBox, {
            [classes.stepReportBoxLong]:
              stepRow &&
              stepRow.transitions &&
              stepRow.transitions.length === 0,
          })}
        >
          <span className={classes.stepReportTitle}>Reach</span>
          <div
            className={classNames(classes.reportStepNumber, {
              [classes.activeReportStepNumber]: stepsReporting[stepRow.id],
              [classes.reportStepNumberLong]:
                stepRow &&
                stepRow.transitions &&
                stepRow.transitions.length === 0,
            })}
            onClick={handleClickStepReporting(stepRow.id)}
          >
            0
          </div>
        </div>
      )}
    </Grid>
  );
};

export default StepRow;

StepRow.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  handleDeleteStep: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  handleAddStepBellow: PropTypes.func.isRequired,
  handleAddTransition: PropTypes.func.isRequired,
  flowStepsList: PropTypes.array,
  handleChangeSteps: PropTypes.func.isRequired,
  steps: PropTypes.array,
  setCurrentStepRowId: PropTypes.func.isRequired,
  handleDeleteEmptyStep: PropTypes.func.isRequired,
  loader: PropTypes.number.isRequired,
  stepRow: PropTypes.object.isRequired,
  isFlowLive: PropTypes.bool.isRequired,
  unselectedTransition: PropTypes.bool.isRequired,
};
