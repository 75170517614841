import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import './tree.css';
import PropTypes from 'prop-types';
import { displaySnackbar } from '../../../store/actions/actions/root';

const FolderList = ({
  classes,
  list,
  handleClickedFolder,
  setDripFolderOrdered,
  dripFolderOrdered,
  selectedNodeId,
  setSelectedNodeId,
  rootFolder,
  search,
  setSearch,
  setSearchValue,
  setDripListWithoutRoot,
  dripListWithoutRoot,
  searchFocusIndex,
  setSearchFocusIndex,
  setSearchFoundCount,
  setClickedFolder,
  setNewChanges,
  setAllChanges,
  allChanges,
  dispatch,
  setSearchFlag,
  searchFlag,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findParentFolder = folder => {
    let element = '';
    if (list && list.length > 0) {
      list.forEach(item => {
        if (item.id === folder.parent_id) {
          element = item;
        }
      });
    }
    return element;
  };

  const filterRootFolders = list => {
    let array = [];
    if (list) {
      list.forEach(item => {
        if (!item.parent_id) {
          array.push(item);
        }
      });
    }
    return array;
  };

  useEffect(() => {
    if (list) {
      let allFolders = [...list];
      allFolders.forEach(folder => (folder.children = []));

      list.forEach(item => {
        if (item.parent_id !== null) {
          let parentFolder = findParentFolder(item);
          let parentIndex = allFolders.findIndex(
            element => element.id === parentFolder.id,
          );
          if (allFolders[parentIndex]) {
            allFolders[parentIndex].children.push(item);
          }
        }
      });
      let rootList = filterRootFolders(allFolders);
      setDripFolderOrdered(rootList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (dripFolderOrdered) {
      dripFolderOrdered.forEach((drip, index) => {
        if (index === 0) {
          setDripListWithoutRoot(drip.children);
        }
      });
    }
  }, [dripFolderOrdered, setDripListWithoutRoot]);

  const nodeClicked = (event, rowInfo) => {
    event.stopPropagation();
    if (
      event.target.className.includes('collapseButton') ||
      event.target.className.includes('expandButton')
    ) {
      setSearch('');
      setSearchValue('');
    } else {
      setSelectedNodeId(rowInfo.node.id);
      handleClickedFolder(rowInfo.node);
    }
  };

  const handleClickContainer = e => {
    e.stopPropagation();
    setClickedFolder(null);
    setSelectedNodeId(null);
  };

  const checkIfItemIsAlreadyChanges = item => {
    let index = '';
    let status = '';
    if (allChanges.length > 0) {
      allChanges.forEach((changes, i) => {
        if (changes.id === item.id) {
          status = true;
          index = i;
        } else {
          status = false;
          index = 0;
        }
      });
    } else {
      status = false;
      index = 0;
    }
    return [index, status];
  };

  const updateFolder = (item, newParent) => {
    if (!newParent) {
      if (item.parent_id === rootFolder.id) {
      } else {
        let data = {
          id: item.id,
          account_id: item.account_id,
          parent_id: rootFolder.id,
          vertical_id: item.vertical_id,
          contents: null,
          name: item.name,
        };
        let [index, status] = checkIfItemIsAlreadyChanges(item);
        if (status) {
          let changes = [...allChanges];
          changes.splice(index, 1);
          setAllChanges([...changes, data]);
        } else {
          setAllChanges([...allChanges, data]);
        }
      }
    } else {
      if (item.parent_id === newParent.id) {
      } else {
        let data = {
          id: item.id,
          account_id: item.account_id,
          parent_id: newParent.id,
          vertical_id: item.vertical_id,
          contents: null,
          name: item.name,
        };
        let [index, status] = checkIfItemIsAlreadyChanges(data);
        if (status) {
          let changes = [...allChanges];
          changes.splice(index, 1);
          setAllChanges([...changes, data]);
        } else {
          setAllChanges([...allChanges, data]);
        }
      }
    }
  };

  const updateDrip = (item, newParent) => {
    if (!newParent) {
      if (item.parent_id === rootFolder.id) {
      } else {
        let data = {
          ...item,
          parent_id: rootFolder.id,
        };
        let [index, status] = checkIfItemIsAlreadyChanges(data);
        if (status) {
          let changes = [...allChanges];
          changes.splice(index, 1);
          setAllChanges([...changes, data]);
        } else {
          setAllChanges([...allChanges, data]);
        }
      }
    } else {
      if (item.parent_id === newParent.id) {
      } else {
        let data = {
          ...item,
          parent_id: newParent.id,
        };
        let [index, status] = checkIfItemIsAlreadyChanges(data);
        if (status) {
          let changes = [...allChanges];
          changes.splice(index, 1);
          setAllChanges([...changes, data]);
        } else {
          setAllChanges([...allChanges, data]);
        }
      }
    }
  };

  const checkIfItsNewParent = (item, newParent) => {
    setNewChanges(true);
    if (item.folderType === 'drip') {
      updateDrip(item, newParent);
    } else if (item.folderType === 'folder') {
      updateFolder(item, newParent);
    }
  };

  const getChildrenIds = list => {
    if (list) {
      let allChildren = [];
      list.forEach(item => {
        allChildren.push(item.id);
        if (item.children && item.children.length > 0) {
          let items = getChildrenIds(item.children);
          allChildren = [...allChildren, ...items];
        }
      });
      return allChildren;
    }
  };

  const updateRootContents = () => {
    let orderedList = [];
    dripListWithoutRoot.forEach(drip => {
      orderedList.push(drip.id);
      if (drip.children && drip.children.length > 0) {
        let orderedDrip = getChildrenIds(drip.children);
        orderedList = [...orderedList, ...orderedDrip];
      }
    });
    let data = {
      id: rootFolder.id,
      account_id: rootFolder.account_id,
      parent_id: null,
      vertical_id: rootFolder.vertical_id,
      contents: JSON.stringify(orderedList),
      name: rootFolder.name,
    };
    let [index, status] = checkIfItemIsAlreadyChanges(data);
    if (status) {
      let allItems = [...allChanges];
      allItems.splice(index, 1);
      setAllChanges([...allItems, data]);
    } else {
      setAllChanges([...allChanges, data]);
    }
  };

  const customSearchMethod = ({ node, searchQuery }) =>
    searchQuery &&
    node.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
  return (
    <Grid
      container
      className={classes.folderContainer}
      onClick={handleClickContainer}
    >
      {dripListWithoutRoot && dripListWithoutRoot.length > 0 && (
        <div className={classes.treeContainer}>
          <SortableTree
            treeData={dripListWithoutRoot}
            onChange={treeData => {
              setDripListWithoutRoot(treeData);
            }}
            rowHeight={70}
            searchQuery={search}
            onMoveNode={({ node, nextParentNode }) => {
              updateRootContents(node);
              checkIfItsNewParent(node, nextParentNode);
              setSearchFoundCount(null);
              setSearchFocusIndex(0);
              setSearch('');
              setSearchValue('');
            }}
            generateNodeProps={rowInfo => {
              let nodeProps = {
                onClick: event => nodeClicked(event, rowInfo),
                style: {
                  width: `50wh`,
                  cursor: 'pointer',
                },
              };
              if (rowInfo.node.folderType === 'drip') {
                nodeProps.className = classes.drip;
              }

              if (selectedNodeId === rowInfo.node.id) {
                if (rowInfo.node.folderType === 'drip') {
                  nodeProps.className = classes.selectedDrip;
                } else {
                  nodeProps.className = classes.selectedFolder;
                }
              }
              return nodeProps;
            }}
            // onlyExpandSearchedNodes
            searchMethod={customSearchMethod}
            searchFocusOffset={searchFocusIndex}
            canNodeHaveChildren={node => node.folderType === 'folder'}
            searchFinishCallback={matches => {
              if (matches.length === 0 && searchFlag && search) {
                dispatch(
                  displaySnackbar({
                    type: 'warning',
                    message: `No results have been found`,
                  }),
                );
                setSearch(false);
                setSearchFoundCount(matches.length);
                setSearchFocusIndex(
                  matches.length > 0 ? searchFocusIndex % matches.length : 0,
                );
              } else {
                setSearchFoundCount(matches.length);
                setSearchFocusIndex(
                  matches.length > 0 ? searchFocusIndex % matches.length : 0,
                );
              }
            }}
          />
        </div>
      )}
    </Grid>
  );
};

export default FolderList;

FolderList.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array,
  handleClickedFolder: PropTypes.func.isRequired,
  setDripFolderOrdered: PropTypes.func.isRequired,
  dripFolderOrdered: PropTypes.array,
  selectedNodeId: PropTypes.string,
  setSelectedNodeId: PropTypes.func.isRequired,
  rootFolder: PropTypes.object,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  setDripListWithoutRoot: PropTypes.func.isRequired,
  dripListWithoutRoot: PropTypes.array,
  searchFocusIndex: PropTypes.number.isRequired,
  setSearchFocusIndex: PropTypes.func.isRequired,
  setSearchFoundCount: PropTypes.func.isRequired,
  setClickedFolder: PropTypes.func.isRequired,
  setNewChanges: PropTypes.func.isRequired,
  setAllChanges: PropTypes.func.isRequired,
  allChanges: PropTypes.array,
};
