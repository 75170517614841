import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  templateTabs: {
    width: 506,
    maxWidth: 506,
    height: 52,
    border: `1px solid #BDBDBD`,
    borderRadius: 40,
    padding: '8px 12px',
    alignItems: 'center',
  },
  tab: {
    color: '#858585',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
    width: 160,
    padding: 8,
    cursor: 'pointer',
    userSelect: 'none',
  },
  activeTab: {
    background: '#2D9CDB',
    borderRadius: 20,
    color: theme.palette.white[100],
  },
}));

export default useStyles;
