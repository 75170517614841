import { makeStyles } from '@material-ui/core/styles';
import SelectArrow from '../../../../assets/images/select-arrow-down.png';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '0 0 20px 0',
    paddingLeft: 60,
    marginTop: -55,
    [theme.breakpoints.up('xl')]: {
      padding: '0 0 20px 0',
      paddingLeft: 270,
    },
  },
  title: {
    paddingLeft: 70,
    marginBottom: 0,
    position: 'relative',
    top: 35,
    left: 15,
  },
  titleContainer: {
    position: 'relative',
    top: -15,
  },
  titleIcon: {
    position: 'absolute',
    left: 106,
    top: 117,
    [theme.breakpoints.up('xl')]: {
      left: 317,
    },
  },
  actionButtons: {
    marginTop: 100,
    '& > button': {
      marginRight: 27,
    },
  },
  contentContainer: {
    marginTop: 100,
  },
  formContainer: {
    width: '100%',
  },
  textField: {
    marginBottom: 13,
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
    '& > input': {
      display: 'block',
      width: 433,
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      backgroundColor: theme.palette.white[100],
    },
    '& > input.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > select': {
      display: 'block',
      width: 433,
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '98% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > textarea': {
      display: 'block',
      width: 433,
      border: '1px solid #828282',
      height: 90,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      paddingTop: 10,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
    },
    '& > textarea.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  disabledField: {
    '& > textarea': {
      cursor: 'no-drop',
    },
    '& > select': {
      cursor: 'no-drop',
    },
    '& > input': {
      cursor: 'no-drop',
    },
  },
}));

export default useStyles;
