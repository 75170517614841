import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/loader';
import Table from './flowStepsTable';
import Search from '../../../components/search';
import FlowStepIcon from '../../../assets/images/flow-step.svg';
import { getDripUrl } from './config';
import {
  getList,
  cancelList,
  clearNameUnique,
} from '../../../store/actions/actions/rest';

const FlowSteps = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const loader = useSelector(state => state.app.loader);
  const messageTypesList = useSelector(state => state.messageTypes.list);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(cancelList('message-types', null, dispatch));
    dispatch(getList('message-types', null, dispatch));
    dispatch(clearNameUnique('flow-steps'));
    return () => {
      dispatch(cancelList('message-types', null, dispatch));
    };
  }, [dispatch]);

  const handleClickFlowStep = item => () => {
    history.push({
      pathname: `/flows/steps/edit/${item.id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleClickDripName = item => () => {
    const { id, message_type_id } = item.drip;
    let dripType = messageTypesList.find(type => type.id === message_type_id);
    history.push({
      pathname: `/drips/${getDripUrl(dripType.name)}/edit/${id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleNewButton = () => {
    history.push({
      pathname: `/flows/steps/add`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} container alignItems="flex-end">
        <Grid item xs={1} className={classes.iconContainer}>
          <img
            src={FlowStepIcon}
            alt="Flows icon"
            className={classes.titleIcon}
          />
        </Grid>
        <Grid container item xs={10} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Flow Steps
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={6}>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item className={classes.addButton}>
            <Button onClick={handleNewButton} startIcon={<AddIcon />}>
              New Flow Step
            </Button>
          </Grid>
          <Grid item>
            <Search type="Flow Step" setSearch={setSearch} classes={classes} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <Grid item xs={12}>
          <Table
            type="flow-steps"
            search={search}
            classes={classes}
            handleClickName={handleClickFlowStep}
            handleClickDripName={handleClickDripName}
            model_name="FlowStep"
            size="small"
          />
        </Grid>
        {Boolean(loader) && <Loader open={Boolean(loader)} />}
      </Grid>
    </Grid>
  );
};

export default withTitle(withDashboard(FlowSteps, false), 'Flow Steps');
