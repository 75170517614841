import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducers/index';
import { createEpicMiddleware } from 'redux-observable';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const epicMiddleware = createEpicMiddleware();

export default () => {
  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(epicMiddleware)),
  );

  return store;
};
