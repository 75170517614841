import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const validate = values => {
  if (values !== 0 && !values) {
    return 'Required';
  }
};

const CustomSelect = ({
  hideLabel,
  labelText,
  name,
  className,
  errorClass,
  placeholder,
  noErrorMessage,
  errors,
  touched,
  handleChange,
  wrapperClass,
  options,
  disabled,
  customHandleChange,
  disableDefault,
}) => {
  const renderOptions = () => {
    if (options && Array.isArray(options)) {
      let items = [{ id: '', name: 'Select' }, ...options];
      return items.map((option, index) => (
        <option
          key={option.id}
          data-testid={option.name}
          value={option.id}
          disabled={index === 0 && disableDefault}
        >
          {option.name || option.email_subject_text}
        </option>
      ));
    } else {
      return (
        <option data-testid="drip_folder_icon_none" value="">
          No Options
        </option>
      );
    }
  };

  return (
    <div className={wrapperClass}>
      {hideLabel ? null : (
        <label htmlFor="icon" name="icon">
          {labelText || 'Folder Icon'}
        </label>
      )}
      <Field
        className={`${className || 'field'} ${
          errors[name || 'icon'] && touched[name || 'icon']
            ? errorClass || 'has-error'
            : ''
        }`}
        htmlFor="icon"
        placeholder={placeholder || 'State'}
        component="select"
        name={name || 'icon'}
        validate={validate}
        onChange={e => {
          customHandleChange && customHandleChange(e);
          handleChange(e);
        }}
        disabled={disabled}
      >
        {renderOptions()}
      </Field>
      {noErrorMessage ? null : (
        <ErrorMessage
          name={name || 'icon'}
          component="div"
          className="field-error"
          data-testid="drip-folder-icon-error"
        />
      )}
    </div>
  );
};

export default CustomSelect;

CustomSelect.propTypes = {
  hideLabel: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  wrapperClass: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  noErrorMessage: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  options: PropTypes.array,
};
