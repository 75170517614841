import {
  LOGOUT,
  AUTH_FULFILLED,
  GET_USER_ACCOUNTS,
  GET_ACCOUNT_VERTICALS,
  SHOW_VERTICAL_LOADER,
  HIDE_VERTICAL_LOADER,
  AUTH_REJECTED,
} from '../actions/actionTypes/auth';

export default (
  state = {
    loader: 0,
  },
  action,
) => {
  if (process.env.REACT_APP_LOG_DEBUG) {
    // eslint-disable-next-line no-console
    // console.log('action: ', action);
  }
  switch (action.type) {
    case AUTH_FULFILLED:
      const permissions = JSON.parse(
        action.payload.attributes['custom:permissions'],
      );
      const account_id = action.payload.attributes['custom:active_account_id'];
      const vertical_id = permissions[account_id].active_vertical;
      return {
        ...state,
        permissions: permissions,
        account_id: +account_id,
        vertical_id: +vertical_id,
        email: action.payload.attributes.email,
        username: action.payload.username,
      };
    case AUTH_REJECTED:
     console.log('action', action);
      return {
        ...action,
      };
    case GET_USER_ACCOUNTS.actionTypes.START:
      return {
        ...state,
      };
    case GET_USER_ACCOUNTS.actionTypes.SUCCESS:
      // convert to array
      const response = action.payload.response.accounts;
      const accounts = Object.keys(response).map(k => ({
        id: +k,
        name: response[k],
      }));
      return {
        ...state,
        accounts,
      };
    case GET_USER_ACCOUNTS.actionTypes.FAILURE:
      return {
        ...state,
      };
    case GET_ACCOUNT_VERTICALS.actionTypes.START:
      return {
        ...state,
      };
    case GET_ACCOUNT_VERTICALS.actionTypes.SUCCESS:
      return {
        ...state,
        verticals: action.payload,
      };
    case GET_ACCOUNT_VERTICALS.actionTypes.FAILURE:
      return {
        ...state,
      };
    case HIDE_VERTICAL_LOADER:
      return {
        ...state,
        loader: state.loader > 0 ? state.loader - 1 : state.loader,
      };
    case SHOW_VERTICAL_LOADER:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};
