import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TemplateTabs from '../../../components/templateTabs/templateTabs';
import PropTypes from 'prop-types';

const EmailPreview = ({
  classes,
  activeTab,
  handleSwitchTab,
  emailPreview,
  textPlainPreview,
  elementId,
}) => {
  const renderTemplateView = () => {
    if (activeTab.emailTemplate) {
      return (
        <div
          className={classes.template}
          dangerouslySetInnerHTML={{
            __html: emailPreview,
          }}
        />
      );
    } else if (activeTab.mobileTemplate) {
      return (
        <div className={classes.mobileTemplate}>
          <div className={classes.mobileTemplateWrapper}>
            <iframe
              className={classes.iframe}
              scrolling="yes"
              srcDoc={emailPreview}
              title={elementId}
            />
          </div>
        </div>
      );
    } else if (activeTab.plainText) {
      return (
        <div
          className={classes.template}
          dangerouslySetInnerHTML={{
            __html: textPlainPreview,
          }}
        />
      );
    }
  };
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          component="h2"
          gutterBottom
          className={classes.dripPreviewTitle}
        >
          Email HTML Body Preview
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TemplateTabs activeTab={activeTab} handleSwitchTab={handleSwitchTab} />
      </Grid>
      <Grid item xs={12} className={classes.previewContainer}>
        {renderTemplateView()}
      </Grid>
    </Fragment>
  );
};

export default EmailPreview;

EmailPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSwitchTab: PropTypes.func.isRequired,
  textPlainPreview: PropTypes.string.isRequired,
  emailPreview: PropTypes.string.isRequired,
  activeTab: PropTypes.object.isRequired,
};
