import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '0 0 20px 0',
    paddingLeft: 60,
    marginTop: 10,
    display: 'flex',
    flex: '1',
    height: 'calc(100vh - 120px)',
    [theme.breakpoints.up('xl')]: {
      padding: '0 0 20px 0',
      paddingLeft: 270,
    },
  },
  holder: {
    margin: 'auto',
    position: 'relative',
  },
  image: {
    width: 700,
    margin: '0 auto',
    '& > img': {
      width: '100%',
    },
  },
  text: {
    position: 'absolute',
    left: 270,
    top: -34,
    '& > h2': {
      fontSize: 148,
      fontFamily: 'Lato',
      margin: 0,
      fontWeight: 400,
      lineHeight: '168px',
      marginBottom: 15,
    },
    '& > h4': {
      fontSize: 33,
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '43px',
      margin: 0,
      '& > span': {
        display: 'block',
      },
    },
  },
  backButton: {
    backgroundColor: theme.palette.green[300],
    color: theme.palette.white[100],
    fontFamily: 'Lato',
    borderRadius: 2,
    fontWeight: 900,
    fontSize: 20,
    height: 60,
    width: 177,
    '&:hover': {
      backgroundColor: theme.palette.green[400],
    },
    "& > span > span > svg": {
      fontSize: '28px !important',
    }
  },
  buttonHolder: {
    marginTop: 85,
  }
}));

export default useStyles;
