import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '../../components/pagination/paginationWrapper';
import {
  getList,
  cancelList,
  getListCount,
  cancelListCount,
  saveParams,
  saveContain,
  saveFilters,
  clearList,
} from '../../store/actions/actions/rest';
import { hyphenToCamel } from '../../store/config';
import { getFilters } from './getFilters';
import PropTypes from 'prop-types';

const withTable = (Component, props) => ({
  type,
  search,
  setParameters,
  handleClickName,
  handleClickDripName,
  handleClickFlowName,
  classes,
  model_name,
  size,
}) => {
  const dispatch = useDispatch();
  const list = useSelector(state => state[hyphenToCamel(type)].list);
  const count = useSelector(state => state[hyphenToCamel(type)].count);
  const auth = useSelector(state => state.auth);
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const [page, setPage] = useState(1);
  const [jumpToPage, setJumpToPage] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortedElement, setSortedElement] = useState('modified');
  const [elementsPerPage, setElementsPerPage] = useState(10);
  const [isUserChanged, setIsUserChanged] = useState(false);
  const [sortBy, setSortBy] = useState(props.sortBy);

  useEffect(() => {
    if (vertical_id && account_id) {
      const params = {
        page: page,
        limit: rowsPerPage,
        direction: sortBy[sortedElement],
        search: search,
        sort: sortedElement,
      };
      let allFilters = getFilters(props.filters, auth);
      dispatch(clearList(type, null, dispatch));
      dispatch(saveParams(type, params));
      dispatch(saveFilters(type, allFilters));
      dispatch(saveContain(type, props.contain));
      setParameters && setParameters(params);
      dispatch(cancelList(type, null, dispatch));
      dispatch(cancelListCount(type, null, dispatch));
      dispatch(
        getList(
          type,
          { params, contain: props.contain, filters: [...allFilters] },
          dispatch,
        ),
      );
      dispatch(
        getListCount(type, { params, filters: [...allFilters] }, dispatch),
      );
      setJumpToPage('');
    }
    return () => {
      dispatch(cancelList(type, null, dispatch));
      dispatch(cancelListCount(type, null, dispatch));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    rowsPerPage,
    search,
    sortBy,
    sortedElement,
    props.filters,
    account_id,
    vertical_id,
  ]);

  useEffect(() => {
    setTotalPages(Math.ceil(count / (list && list.length)));
  }, [count, list]);

  useEffect(() => {
    setTotalCount(count);
    setPage(1);
    if (count === 0) {
      setRowsPerPage(10);
      setElementsPerPage(10);
    } else if (+rowsPerPage > count) {
      setRowsPerPage(count);
      setElementsPerPage(count);
    } else if (+rowsPerPage === count) {
      setRowsPerPage(rowsPerPage);
      setElementsPerPage(rowsPerPage);
    } else if (count > +rowsPerPage && count > 10) {
      setRowsPerPage(+rowsPerPage);
      setElementsPerPage(+rowsPerPage);
    } else if (+rowsPerPage < count) {
      if (search === '') {
        if (isUserChanged) {
          setRowsPerPage(+rowsPerPage);
          setElementsPerPage(+rowsPerPage);
        } else {
          setRowsPerPage(+count);
          setElementsPerPage(+count);
        }
      } else {
        setRowsPerPage(+rowsPerPage);
        setElementsPerPage(+rowsPerPage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (list) {
      props.renderElements(list, setRows);
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const onChangePagination = pageNumber => {
    setPage(pageNumber);
  };

  const handleChangeDirectlyPage = event => {
    if (Number.isInteger(+event.target.value)) {
      setElementsPerPage(event.target.value);
    }
  };

  const handleElementsPerPage = event => {
    if (event.key === 'Enter') {
      if (+event.target.value === 0) {
        setRowsPerPage(10);
        setElementsPerPage(10);
        setIsUserChanged(true);
        setPage(1);
        setJumpToPage('');
      } else {
        setRowsPerPage(event.target.value);
        setElementsPerPage(event.target.value);
        setIsUserChanged(true);
        setPage(1);
        setJumpToPage('');
      }
    }
  };

  const handlePerPageArrow = data => () => {
    if (data === 'less') {
      if (+elementsPerPage === 1) {
        setElementsPerPage(+elementsPerPage);
        setRowsPerPage(+rowsPerPage);
      } else {
        setElementsPerPage(+elementsPerPage - 1);
        setRowsPerPage(+rowsPerPage - 1);
      }
    } else if (data === 'more') {
      setElementsPerPage(+elementsPerPage + 1);
      setRowsPerPage(+rowsPerPage + 1);
    }
  };

  const handleJumpToPageInput = event => {
    if (Number.isInteger(+event.target.value)) {
      if (+event.target.value <= totalPages) {
        setJumpToPage(event.target.value);
      }
    }
    if (event.key === 'Enter') {
      setPage(+event.target.value);
    }
  };

  const handleJumpToPageButton = () => {
    setPage(+jumpToPage);
  };

  const handleSortBy = name => () => {
    if (sortBy[name] === 'asc') {
      setSortBy({
        ...props.sortBy,
        [name]: 'desc',
      });
      setSortedElement(name);
    } else {
      setSortBy({
        ...props.sortBy,
        [name]: 'asc',
      });
      setSortedElement(name);
    }
  };

  const renderNoDataSection = () => {
    if (!list || list.length === 0) {
      return (
        <TableRow>
          <td className={classes.noData}>No Items</td>
        </TableRow>
      );
    }
  };

  const renderPaginaton = () => {
    if (list || (list && list.length > 0)) {
      return (
        totalCount !== 0 && (
          <Pagination
            classes={classes}
            handlePerPageArrow={handlePerPageArrow}
            elementsPerPage={elementsPerPage}
            handleElementsPerPage={handleElementsPerPage}
            handleChangeDirectlyPage={handleChangeDirectlyPage}
            page={page}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            onChangePagination={onChangePagination}
            jumpToPage={jumpToPage}
            handleJumpToPageInput={handleJumpToPageInput}
            handleJumpToPageButton={handleJumpToPageButton}
          />
        )
      );
    }
  };
  return (
    <Component
      {...props}
      list={list}
      rows={rows}
      classes={classes}
      sortBy={sortBy}
      handleSortBy={handleSortBy}
      sortedElement={sortedElement}
      handleClickName={handleClickName}
      handleClickDripName={handleClickDripName}
      handleClickFlowName={handleClickFlowName}
      renderPaginaton={renderPaginaton}
      renderNoDataSection={renderNoDataSection}
      model_name={model_name}
      type={type}
      dispatch={dispatch}
      size={size}
      itemContain={props.contain}
    />
  );
};

export default withTable;

withTable.propTypes = {
  elementType: PropTypes.elementType.isRequired,
  type: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  setParameters: PropTypes.func.isRequired,
  handleClickName: PropTypes.func,
  handleClickDripName: PropTypes.func,
  handleClickFlowName: PropTypes.func,
  classes: PropTypes.object.isRequired,
  model_name: PropTypes.string,
  size: PropTypes.string,
};
