import { makeStyles } from '@material-ui/core/styles';
import MobileImage from '../../../assets/images/mobilepreview.png';
import SelectArrow from '../../../assets/images/select-arrow-down.png';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '0 0 20px 0',
    paddingLeft: 60,
    marginTop: 10,
    [theme.breakpoints.up('xl')]: {
      padding: '0 0 20px 0',
      paddingLeft: 270,
    },
  },
  titleIcon: {
    position: 'relative',
    top: 8,
    marginRight: 10,
  },
  subtitle: {
    fontSize: 18,
    lineHeight: '22px',
    marginTop: 5,
    [theme.breakpoints.up(1900)]: {
      marginTop: 10,
    },
  },
  addButton: {
    paddingRight: 10,
    '& > button': {
      backgroundColor: '#F2F2F2',
      width: 210,
      height: 45,
      '& > span': {
        color: '#828282',
        fontWeight: 900,
      },
    },
  },
  searchField: {
    backgroundColor: '#F2F2F2',
    '& > .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& > .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
      },
      '& > button': {
        padding: 9,
      },
      '& > svg': {
        color: '#828282',
        position: 'relative',
        left: 7,
      },
    },
  },
  searchContainer: {
    '& > div': {
      width: 260,
    },
  },
  tableWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  tableContainer: {
    marginTop: 30,
  },
  table: {
    minWidth: 650,
    color: theme.palette.text[100],
    '& > thead > tr > th': {
      color: theme.palette.text[100],
    },
    '& > tbody > tr > th ': {
      color: theme.palette.text[100],
    },
    '& > tbody > tr > td ': {
      color: theme.palette.text[100],
    },
  },
  link: {
    color: '#036BF0',
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'all .7s',
    '&:hover': {
      color: theme.palette.text[100],
    },
  },
  pageInput: {
    backgroundColor: '#F2F2F2',
    width: 115,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
        padding: '13px 10px',
      },
      '& > span': {
        padding: 10,
        cursor: 'pointer',
      },
    },
  },
  cssFocused: {
    color: '#828282 !important',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#828282 !important',
  },
  resultsPerPageLabel: {
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.text[100],
    paddingRight: 10,
    '& > span': {
      display: 'block',
    },
  },
  resultsPerPage: {
    width: 75,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        padding: 13,
      },
      '& > div': {
        backgroundColor: theme.palette.grey[100],
        padding: 2,
        '& > svg': {
          fontSize: 19,
        },
      },
    },
  },
  formContainer: {
    marginTop: 90,
  },
  formControl: {
    width: 262,
    backgroundColor: theme.palette.white[100],
  },
  dropDownSelect: {
    '& > div': {
      padding: '14px 32px 12px 14px',
    },
  },
  selectSection: {
    marginTop: 15,
  },
  switchSection: {
    marginTop: 15,
    '& > h4': {
      color: theme.palette.text[100],
      marginBottom: 10,
      fontWeight: 'normal',
      marginTop: 0,
    },
  },
  newConfigBtn: {
    height: 45,
    backgroundColor: theme.palette.lightBlue[300],
    color: theme.palette.white[100],
    fontSize: 16,
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
  },
  actionsContainer: {
    justifyContent: 'space-between !important',
    padding: '25px 0 10px 0 !important',
  },
  deleteActionBtn: {
    backgroundColor: theme.palette.secondary.main,
    height: 47,
    transition: 'all .4s',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: '#b54a4c !important',
    },
    '& > span': {
      color: theme.palette.white[100],
      fontSize: 20,
    },
  },
  saveActionBtn: {
    backgroundColor: theme.palette.green[300],
    transition: 'all .4s',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: `${theme.palette.green[400]} !important`,
    },
    '& > span': {
      color: theme.palette.white[100],
      fontSize: 20,
      '& > span': {
        marginLeft: 10,
        fontWeight: 'bold',
      },
    },
  },
  inputLabel: {
    color: theme.palette.text[100],
    fontWeight: 400,
    fontFamily: 'Lato',
    fontSize: 16,
    marginTop: 15,
    marginBottom: 9,
  },
  title: {
    position: 'relative',
    top: 20,
  },
  noData: {
    height: 60,
    position: 'relative',
    left: 20,
    fontSize: 20,
  },
  loaderContainer: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: 150,
    zIndex: 10,
  },
  errorText: {
    fontFamily: 'Lato',
    color: theme.palette.red[300],
  },
  textField: {
    marginBottom: 20,
    width: '90%',
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 10,
      display: 'block',
    },
    '& > input': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      backgroundColor: theme.palette.white[100],
    },
    '& > input.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > textarea': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 90,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      paddingTop: 10,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      minHeight: 500,
    },
    '& > textarea.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  filterRulesTextField: {
    marginBottom: 20,
    width: '90%',
    '& > label': {
      color: '#4F4F4F',
      display: 'block',
    },
    '& > input': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      backgroundColor: theme.palette.white[100],
    },
    '& > input.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > textarea': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 90,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      paddingTop: 10,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      minHeight: 500,
    },
    '& > textarea.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  modalLoader: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: 0,
    zIndex: 10,
  },
  testTab: {
    background: '#F3F3F3',
    color: '#BDBDBD',
    borderRadius: 2,
    fontSize: 18,
    fontWeight: 900,
    fontFamily: 'Lato',
    padding: '8px 54px',
    maxWidth: 234,
    textAlign: 'center',
    marginRight: 30,
    cursor: 'pointer',
  },
  activeTestTab: {
    background: '#2D9CDB',
    color: theme.palette.white[100],
  },
  testTabsContainer: {
    paddingBottom: 40,
    borderBottom: '1px solid #DFDFDF',
    marginBottom: 30,
  },
  dripPreviewTitle: {
    color: theme.palette.text[100],
    fontSize: 24,
    fontFamily: 'Lato',
    marginBottom: 24,
  },
  previewContainer: {
    maxHeight: 800,
    height: 800,
    width: '100%',
    maxWidth: 950,
    border: `1px solid #BDBDBD`,
    borderRadius: 2,
    marginTop: 20,
    padding: '40px 50px',
  },
  template: {
    width: '100%',
    minWidth: 402,
    height: 700,
    margin: '0 auto',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  mobileTemplate: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  mobileTemplateWrapper: {
    backgroundImage: `url(${MobileImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: 350,
    height: 650,
    position: 'relative',
  },
  mobileViewTemplate: {
    position: 'absolute',
    top: 22,
    left: 10,
    width: 181,
    height: 370,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  tagsContainer: {
    minWidth: 430,
    maxWidth: 750,
    width: '90%',
    justifyContent: 'space-between',
    borderBottom: `1px solid #828282`,
    paddingBottom: 4,
    marginTop: 30,
  },
  addTagBtn: {
    backgroundColor: theme.palette.lightBlue[300],
    color: theme.palette.white[100],
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 14,
    width: 108,
    height: 35,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue[400],
    },
  },
  tagsTitle: {
    fontSize: 16,
  },
  tagSelectContainer: {
    minWidth: 430,
    maxWidth: 750,
    width: '100%',
    margin: '8px 0',
  },
  tagsColumnTitle: {
    fontFamily: 'Lato',
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text[100],
    width: '50%',
  },
  filtersColumnTitle: {
    fontFamily: 'Lato',
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text[100],
    width: '33%',
  },
  tagsSelects: {
    width: '100%',
  },
  tagColumn: {
    width: '50%',
    paddingRight: 10,
    '& > div > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '98% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > div > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  filterColumn: {
    width: '33%',
    paddingRight: 10,
    '& > div > select': {
      display: 'block',
      width: '100%',
      border: '1px solid #828282',
      height: 45,
      borderRadius: 4,
      fontSize: 16,
      paddingLeft: 15,
      fontFamily: 'Lato',
      backgroundColor: theme.palette.white[100],
      backgroundImage: `url(${SelectArrow})`,
      backgroundSize: '13px 8px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '98% 18px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    '& > div > select.has-error': {
      border: `1px solid ${theme.palette.red[300]}`,
    },
    '& > div > div.field-error': {
      color: theme.palette.red[300],
      fontSize: 12,
      marginTop: 5,
    },
  },
  tagsActionButton: {
    height: 35,
    width: 35,
    borderRadius: 2,
    color: theme.palette.white[100],
    position: 'relative',
    backgroundColor: '#D8D8D8',
    top: 5,
    cursor: 'pointer',
    transition: 'all .4s',
    padding: 2,
    '&:hover': {
      backgroundColor: '#b7b5b5',
    },
  },
  editTagButton:{
    backgroundColor: '#61A1F3',
    '&:hover': {
      backgroundColor: '#528cd3',
    },
  },
  deleteTagButton: {
    backgroundColor: '#D6595C',
      '&:hover': {
        backgroundColor: '#b54a4c',
      },
  },
  iconContainer: {
    maxWidth: 90,
  },
  iframe: {
    width: 320,
    height: 566,
    transform: 'scale(1)',
    display: 'block',
    margin: '0 auto',
    border: 'none',
    marginTop: 42,
  }
}));

export default useStyles;
