const rest = [
  'accounts',
  'drip-folders',
  'drips',
  'flows',
  'service-providers',
  'verticals',
  'tags',
  'from-email-components',
  'friendly-from-components',
  'email-subject-components',
  'sms-body-components',
  'email-body-components',
  'call-components',
  'message-types',
  'rvm-components',
  'generic-post-components',
  'flow-steps',
  'flow-step-transitions',
  'webhook-actions',
  'flow-step-transition-maps',
  'flow-step-maps',
  'notes',
  'uploads',
];

const reporting = ['flow-totals', 'drip-totals', 'step-totals', 'type-totals'];

export const hyphenToCamel = str =>
  str.replace(/-([a-z])/g, (_, w) => w.toUpperCase());

export const generateRestReducers = restReducer =>
  rest.reduce((acc, curr) => {
    return {
      ...acc,
      [hyphenToCamel(curr)]: restReducer(curr),
    };
  }, {});

export const generateRestEpics = restEpics =>
  rest.reduce((acc, curr) => {
    return [
      ...acc,
      ...Object.keys(restEpics).map(epic => restEpics[epic](curr)),
    ];
  }, []);

export const generateReportingEpics = restEpics =>
  reporting.reduce((acc, curr) => {
    return [
      ...acc,
      ...Object.keys(restEpics).map(epic => restEpics[epic](curr)),
    ];
  }, []);

export const generateReportingReducers = reportingReducer =>
  reporting.reduce((acc, curr) => {
    return {
      ...acc,
      [hyphenToCamel(curr)]: reportingReducer(curr),
    };
  }, {});
