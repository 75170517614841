import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import ReportingIcon from '../../assets/images/Reports_blue_icon.svg';
import Typography from '@material-ui/core/Typography';
import withDashboard from '../../hoc/withDashboard/withDashboard';
import withTitle from '../../hoc/withTitle';
import Loader from '../../components/loader';
import CustomFilterDropDown from '../reporting/customFilterDropDown/customFilterDropDown';
import { useSelector, useDispatch } from 'react-redux';
import Timeline from './timeline';
import { useHistory, useParams } from 'react-router-dom';
import {
  getList,
  cancelList,
  saveFilters,
} from '../../store/actions/actions/rest';
import {
  getChangeLogs,
  cancelChangeLogs,
  clearChangeLogs,
} from '../../store/actions/actions/changeLogs';
import { getFilters } from '../../hoc/withTable/getFilters';
import { filters } from './config';
import { getFilterDates } from '../reporting/singleReportingPage/config';

const ChangeLogs = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: flow_id } = useParams();
  const loader = useSelector(state => state.app.loader);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const auth = useSelector(state => state.auth);
  const account_id = useSelector(state => state.auth.account_id);
  const flowList = useSelector(state => state.flows.list);
  const [filterByDate, setFilterByDate] = useState('today');
  const [filterDates, setFilterDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [currentFlow, setCurrentFlow] = useState(null);

  useEffect(() => {
    if (auth && vertical_id && account_id) {
      let allFilters = getFilters(filters, auth);
      dispatch(clearChangeLogs(null, dispatch));
      dispatch(saveFilters('flows', allFilters));
      dispatch(cancelList('flows', null, dispatch));
      dispatch(
        getList(
          'flows',
          { filters: [...allFilters] },
          dispatch,
        ),
      );
      return () => {
        dispatch(cancelList('flows', null, dispatch));
      };
    }
  }, [dispatch, account_id, vertical_id, auth]);

  useEffect(() => {
    const redirectToNotFound = () => {
      history.push({
        pathname: `/404`,
      });
    };

    if (flowList && flowList.length > 0) {
      if (flow_id) {
        let flow = flowList.find(item => item.id === flow_id);
        if (flow) {
          setCurrentFlow(flow);
        } else {
          redirectToNotFound();
        }
      } else {
        redirectToNotFound();
      }
    }
  }, [flowList, history, flow_id]);

  const getChangeLogsData = () => {
    dispatch(cancelChangeLogs(null, dispatch));
    let filterData = getFilterDates(filterByDate, filterDates, true);
    const data = {
      id: currentFlow.id,
      params: {
        filters: [...filterData],
      },
    };
    dispatch(getChangeLogs(data, dispatch));
  };

  useEffect(() => {
    if (currentFlow && filterByDate !== 'custom-filter') {
      getChangeLogsData();
    }
    // eslint-disable-next-line
  }, [currentFlow, filterByDate]);

  const handleChangeFilter = event => {
    setFilterByDate(event.target.value);
  };

  const handleChangeDate = (event, name) => {
    setFilterDates({
      ...filterDates,
      [name]: event,
    });
  };

  const getChangeLogsFilters = () => {
    let filterData = getFilterDates(filterByDate, filterDates, true);
    return [...filterData];
  };

  const handleApplyFilter = () => {
    let customFilter = getChangeLogsFilters();
    dispatch(cancelChangeLogs(null, dispatch));
    const data = {
      id: currentFlow.id,
      params: {
        filters: [...customFilter],
      },
    };
    dispatch(getChangeLogs(data, dispatch));
  };

  return (
    <Grid container className={classes.container}>
      <img
        src={ReportingIcon}
        alt="Reporting icon"
        className={classes.titleIcon}
      />
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Change Log
          </Typography>
        </Grid>
        {/* <CustomExportButton
          customClass={classes.exportButton}
          label="Export Log"
        /> */}
      </Grid>
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} className={classes.filterContainer}>
          <CustomFilterDropDown
            handleChange={handleChangeFilter}
            value={filterByDate}
            handleChangeDate={handleChangeDate}
            filterDates={filterDates}
            handleApplyFilter={handleApplyFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <Timeline classes={classes} loader={loader} />
        </Grid>
      </Grid>
      {Boolean(loader) && <Loader open={Boolean(loader)} />}
    </Grid>
  );
};

export default withTitle(withDashboard(ChangeLogs, false), 'Change Logs');
