import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '0 0 20px 0',
    paddingLeft: 60,
    marginTop: -55,
    overflowX: 'hidden',
    display: 'none',
    [theme.breakpoints.up(1200)]: {
      display: 'block',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 0 20px 0',
      paddingLeft: 270,
    },
  },
  mobileContainer: {
    display: 'block',
    [theme.breakpoints.up(1200)]: {
      display: 'none',
    },
  },
  title: {
    paddingLeft: 80,
    marginBottom: 0,
    position: 'relative',
    top: 46,
    left: 48,
  },
  titleContainer: {
    position: 'relative',
    top: 60,
  },
  titleIcon: {
    position: 'absolute',
    left: 106,
    top: 117,
    [theme.breakpoints.up('xl')]: {
      left: 317,
    },
  },
  contentContainer: {
    marginTop: 120,
    paddingLeft: 23,
  },
  formContainer: {
    width: '100%',
  },
  searchField: {
    backgroundColor: '#F2F2F2',
    '& > .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& > .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
      },
      '& > button': {
        padding: 9,
      },
      '& > svg': {
        color: '#828282',
        position: 'relative',
        left: 7,
      },
    },
  },
  searchContainer: {
    '& > div': {
      width: 200,
    },
  },
  searchGridContainer: {
    maxWidth: 700,
    width: 700,
    position: 'absolute',
    right: 40,
    top: 90,
  },
  table: {
    marginTop: 50,
    paddingRight: 15,
  },
  tableContainer: {
    marginTop: 30,
    boxShadow: 'none',
    background: '#FAFAFA',
    paddingRight: 17,
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 11,
    borderBottom: '1px solid #828282',
    fontWeight: 900,
    position: 'sticky',
    top: 0,
    background: '#fafafa',
    zIndex: 1,
  },
  tableCellHeader: {
    maxWidth: '16%',
    width: '16%',
    fontSize: 14,
    paddingLeft: 10,
    display: 'flex',
    fontWeight: 900,
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& > svg': {
        opacity: 1,
      },
    },
  },
  sortingArrow: {
    opacity: 0,
    transition: 'all .4s',
    fontSize: 15,
    '&:hover': {
      opacity: 1,
    },
  },
  sortingArrowActive: {
    opacity: 1,
    fontSize: 15,
    transition: 'all .4s',
    '&:hover': {
      opacity: 1,
    },
  },
  sortingArrowAsc: {
    transform: 'rotate(0deg)',
  },
  sortingArrowDesc: {
    transform: 'rotate(180deg)',
  },
  tableCellHeaderActive: {
    fontWeight: 900,
  },
  rowItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
  },
  rowItemActive: {
    backgroundColor: '#EEEEEE',
  },
  singleRow: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
  },
  tableCellNameHeader: {
    width: '20%',
    maxWidth: '20%',
    justifyContent: 'flex-start',
    paddingLeft: 10,
  },
  tableCellLink: {
    paddingTop: 15,
    paddingLeft: 10,
    paddingBottom: 15,
    fontSize: 16,
    maxWidth: '20%',
    width: '20%',
    color: '#036BF0',
    transition: 'all .7s',
    cursor: 'pointer',
    'word-break': 'break-all',
    paddingRight: 5,
    borderRight: '1px solid #E0E0E0',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableCell: {
    paddingTop: 15,
    paddingBottom: 15,
    maxWidth: '16%',
    width: '16%',
    fontSize: 16,
    paddingLeft: 10,
    borderRight: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    'word-break': 'break-all',
    '&:nth-child(7)': {
      borderRight: 'none',
    },
  },
  showMoreArrow: {
    transition: ' all .4s',
    transform: 'rotate(0deg)',
  },
  showMoreArrowActive: {
    transform: 'rotate(180deg)',
  },
  tableCellShowMore: {
    paddingTop: 15,
    paddingBottom: 15,
    maxWidth: '16%',
    width: '16%',
    textAlign: 'center',
    fontSize: 14,
    color: '#036BF0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid #E0E0E0',
    '& > svg': {
      fontSize: 18,
    },
  },
  expandIcons: {
    '& > svg': {
      cursor: 'pointer',
    },
  },
  cssFocused: {
    color: '#828282 !important',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#828282 !important',
  },
  pageInput: {
    backgroundColor: '#F2F2F2',
    width: 115,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
        padding: '13px 10px',
      },
      '& > span': {
        padding: 10,
        cursor: 'pointer',
      },
    },
  },
  resultsPerPageLabel: {
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.text[100],
    paddingRight: 10,
    '& > span': {
      display: 'block',
    },
  },
  resultsPerPage: {
    width: 75,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        padding: 13,
      },
      '& > div': {
        backgroundColor: theme.palette.grey[100],
        padding: 2,
        '& > svg': {
          fontSize: 19,
        },
      },
    },
  },
  noData: {
    height: 60,
    fontSize: 20,
    paddingTop: 20,
  },
  paginationWrapper: {
    width: 1000,
    margin: '0 auto',
    marginTop: 30,
  },
  topActionContainer: {
    height: 42,
    marginRight: 229,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    top: 42,
  },
  modalLoader: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: 0,
    zIndex: 10,
  },
  noItems: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
