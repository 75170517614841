import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { hideSnackbar } from '../store/actions/actions/root';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  messageContainer: {
    [theme.breakpoints.up(1200)]: {
      right: 40,
      left: 'auto',
      top: 72,
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBar = () => {
  const classes = useStyles();
  const open = useSelector(state => state.app.isSnackBarOpen);
  const type = useSelector(state => state.app.snackBarType);
  const message = useSelector(state => state.app.snackBarMessage);
  const dispatch = useDispatch();
  const [snackMessage, setSnackMessage] = useState('');
  const [snackType, setSnackType] = useState('');

  useEffect(() => {
    if (message) {
      setSnackMessage(message);
    }
    if (type) {
      setSnackType(type);
    }
  }, [message, type]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideSnackbar());
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.messageContainer}
      >
        <Alert
          onClose={handleClose}
          severity={snackType || 'error'}
          style={{ alignItems: 'center' }}
        >
          {snackMessage || 'Something went wrong!'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBar;
